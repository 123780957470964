import { helpers } from "vuelidate/lib/validators";

export function checkSum(value) {
  if (!helpers.req(value)) {
    return true;
  }
  const checkSumValue = parseInt(value.slice(9), 10);

  //строка как массив(для старых браузеров)
  value = "" + value;
  value = value.split("");

  const sum =
    value[0] * 9 +
    value[1] * 8 +
    value[2] * 7 +
    value[3] * 6 +
    value[4] * 5 +
    value[5] * 4 +
    value[6] * 3 +
    value[7] * 2 +
    value[8] * 1;

  if (sum < 100 && sum == checkSumValue) {
    return true;
  } else if ((sum == 100 || sum == 101) && checkSumValue == 0) {
    return true;
  } else if (
    sum > 101 &&
    (sum % 101 == checkSumValue || (sum % 101 == 100 && checkSumValue == 0))
  ) {
    return true;
  } else {
    return false;
  }
}
