<template>
  <a class="arrow-link" :href="href" :target="target">
    <span>{{ value }}</span>
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7944 0.695511C12.5355 0.43483 12.1047 0.43483 11.8368 0.695511C11.578 0.947385 11.578 1.36659 11.8368 1.61788L16.6921 6.34243L0.67034 6.34243C0.296856 6.34301 0 6.63188 0 6.9953C0 7.35873 0.296856 7.65699 0.67034 7.65699L16.6921 7.65699L11.8368 12.3727C11.578 12.6334 11.578 13.0532 11.8368 13.3045C12.1047 13.5652 12.5361 13.5652 12.7944 13.3045L18.7991 7.46148C19.067 7.2096 19.067 6.7904 18.7991 6.53911L12.7944 0.695511Z"
        fill="#B7BBC1"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "ArrowLink",
  props: {
    value: { type: String, default: "" },
    target: { type: String, default: "_self" },
    href: { type: String, default: "#" }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";
.arrow-link {
  display: inline-block;
  position: relative;
  padding: 10px 0;
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;

  @include md-desktop-only {
    padding: 10px * $zoom 0;
    font-size: 16px * $zoom;
  }

  &:hover {
    svg {
      left: calc(100% + 15px);

      @include md-desktop-only {
        left: calc(100% + #{15px * $zoom});
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    transform: translateY(-50%);
    transition: 0.3s;

    @include md-desktop-only {
      left: calc(100% + #{10px * $zoom});
    }
  }

  &._np {
    padding: 0;
  }

  &_low {
    text-transform: none;
  }

  &_underline {
    text-decoration: underline;
  }

  &_dark {
    color: #131313;

    svg {
      path {
        fill: #131313;
      }
    }
  }

  &_light {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  &_reversed {
    padding-left: 33px;

    &:hover {
      svg {
        left: -5px;
      }
    }

    svg {
      left: 0;
      transform: translateY(-50%) rotateY(180deg);
    }

    @include md-desktop-only {
      padding-left: 33px * $zoom;

      &:hover {
        svg {
          left: -5px * $zoom;
        }
      }
    }
  }

  &_decorated {
    text-transform: none;
    text-decoration: underline;
  }

  &_nowrap {
    white-space: nowrap;
  }

  &_wrap {
    white-space: normal;
    line-height: 22px;

    svg {
      display: inline-block;
      vertical-align: middle;
      position: static;
      top: auto;
      transform: none;
      margin-left: 15px;
    }

    @include md-desktop-only {
      line-height: 22px * $zoom;

      svg {
        margin-left: 15px * $zoom;
      }
    }
  }

  &_indent {
    @include tablet {
      margin-top: 50px;
    }
  }

  &_root-news-bg {
    @include tablet {
      margin-top: -14px;
      margin-bottom: 70px;
    }
    @include mobile {
      margin-top: -10px;
      margin-bottom: 50px;
    }
  }

  &_root-news {
    @include tablet {
      margin-top: -14px;
    }
  }

  &_visible-tablet {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }
}
</style>
