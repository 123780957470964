<template>
  <div class="registration_wrapper">
    <template>
      <button
        id="button-registration-popup"
        class="button button_base button_wide button_icon"
        @click.prevent="openRegistrationPopup"
      >
        <template v-if="editMode">
          <span>
            {{ $t("registered") }}
          </span>
          <span class="button__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M19.172 2c-.724 0-1.448.276-2 .828L16 4l4 4 1.172-1.172a2.83 2.83 0 00-2-4.828zM14.5 5.5L3 17v4h4L18.5 9.5l-4-4z"
              />
            </svg>
          </span>
        </template>
        <template v-else>
          <span>
            {{ $t("register_now") }}
          </span>
        </template>
      </button>

      <!-- eventRegistrationPopup -->
      <notify-popup popup="eventRegistrationPopup">
        <div class="notify-popup__body">
          <div class="notify-popup__title _g-text-center">
            <span v-if="editMode">
              {{ $t("register_change") }}
            </span>
            <span v-else>
              {{ $t("register") }}
            </span>
          </div>

          <div v-show="stage === 'update-profile'" class="stage-1">
            <user-edit-form-professional
              :hide-dadata="hideDadata"
              :dadata-api-key="dadataApiKey"
              :dadata-company-url="dadataCompanyUrl"
              action="/local/ajax/user.php?action=edit"
              :ar-result="profileData"
              :button-text="buttonText"
              is-inline="N"
              @update-success="updateProfileComplete"
            />
          </div>

          <div v-if="stage === 'registration'">
            <event-format-form
              :intramural="intramural"
              :lead="lead"
              edit-registration="edit-registration"
              edit-mode="editMode"
              @submit="submitEventRegistration"
            />
          </div>
        </div>
      </notify-popup>

      <!-- eventRegSuccessPopup -->
      <notify-popup popup="eventRegSuccessPopup" @onClose="reloadPage">
        <div class="notify-popup__body _g-text-center">
          <div class="notify-popup__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="34"
              height="34"
              viewBox="0 0 34 34"
            >
              <circle
                cx="17"
                cy="17"
                r="16"
                stroke="#18B346"
                stroke-width="2"
              />
              <path
                stroke="#18B346"
                stroke-linecap="round"
                stroke-width="2"
                d="M14 17l2.625 3L21 14"
              />
            </svg>
          </div>
          <div class="notify-popup__title notify-popup__title_small">
            {{ $t("success_text") }}
          </div>
          <div class="notify-popup__text" style="max-width: 360px;">
            <p>
              {{ $t("success_email") }}
            </p>
          </div>
          <div class="notify-popup__buttons">
            <a class="button button_dark" href="#" @click.prevent="reloadPage">
              {{ $t("success_more") }}
            </a>
          </div>
        </div>
      </notify-popup>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EventRegistration",
  props: {
    item: { type: Object, required: true },
    hideDadata: { type: Boolean, default: false },
    dadataApiKey: { type: String, required: true },
    checkProfile: { type: Boolean, default: false },
    getProfileUrl: { type: String, required: true },
    eventRegistrationUrl: { type: String, required: true },
    dadataCompanyUrl: { type: String, default: "" },
    editRegistration: { type: Boolean, default: false },
    buttonText: { type: String, default: "" }
  },
  data() {
    return {
      stage: String,
      intramural: false,
      profileData: {},
      requiredFields: {
        business: ["WORK_COMPANY", "WORK_POSITION"],
        study: ["WORK_COMPANY", "WORK_POSITION"],
        other: ["WORK_POSITION"]
      },
      lead: {}
    };
  },
  computed: {
    editMode() {
      return typeof this.lead.ID !== "undefined";
    },
    profileComplete() {
      if (!this.checkProfile) {
        return false;
      }

      let id;
      for (let prop in this.profileData.UF_BUSINESS_OR_STUDY.VALUES) {
        if (this.profileData.UF_BUSINESS_OR_STUDY.VALUES[prop].CHECKED === 1) {
          id = this.profileData.UF_BUSINESS_OR_STUDY.VALUES[prop].XML_ID;
          break;
        }
      }

      if (!id) {
        return;
      }

      const fields = this.requiredFields[id];
      let completed = true;
      fields.map(field => {
        if (!this.profileData[field]) {
          completed = false;
        }
      });
      return completed;
    }
  },
  mounted() {
    this.getLead();
    if (this.editRegistration) {
      this.openRegistrationPopup();
    }
    this.isIntramural();
    this.setStage();
    this.$root.$on("openRegistrationPopup", this.openRegistrationPopup);
  },
  methods: {
    isIntramural() {
      this.intramural = this.item.IS_INTRAMURAL;
    },
    async setStage() {
      try {
        let response = await axios.post(
          `${this.eventRegistrationUrl}?action=check&eventId=${this.item.ID}`
        );
        if (!response.data.success) {
          throw new Error(response.data.message);
        }
        this.stage = "update-profile";
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      }
    },
    async getLead() {
      try {
        const url = `${this.eventRegistrationUrl}?action=get_lead&eventId=${this.item.ID}`;
        const response = await axios.get(url);
        this.lead = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async openRegistrationPopup() {
      try {
        let response = await axios.post(this.getProfileUrl);
        if (!response.data.success) {
          throw new Error(response.data.message);
        }
        this.profileData = response.data.data;
        this.$modal.show("eventRegistrationPopup");
        if (this.profileComplete) {
          this.stage = "registration";
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      }
    },
    updateProfileComplete() {
      if (this.intramural) {
        this.stage = "registration";
      } else {
        const data = {
          PRESENSE_TYPE: "online",
          CAR_MODEL: "",
          CAR_NUMBER: "",
          CAR_TAXI: false,
          NEED_TRANSFER: false
        };
        this.submitEventRegistration(data);
      }
    },
    async submitEventRegistration(data) {
      const params = {
        EVENT_ID: this.item.ID,
        PRESENSE_TYPE: data.PRESENSE_TYPE,
        CAR_MODEL: data.CAR_MODEL,
        CAR_NUMBER: data.CAR_NUMBER,
        CAR_TAXI: data.CAR_TAXI ? "Y" : "N",
        NEED_TRANSFER: data.NEED_TRANSFER ? "Y" : "N",
        ITEM: this.item
      };
      try {
        const response = await axios.post(
          `${this.eventRegistrationUrl}?action=register`,
          params
        );
        if (!response.data.success) {
          throw new Error(response.data.message);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      } finally {
        this.$modal.hide("eventRegistrationPopup");
        this.$modal.show("eventRegSuccessPopup");
      }
    },
    reloadPage() {
      this.getLead();
      location.reload();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "registered": "Registered",
    "register_now": "Register now",
    "register_change": "Register now",
    "register": "Registration",
    "success_text": "You have registered for the event!",
    "success_email": "A letter with further instructions has been sent to your e-mail",
    "success_more": "Continue"
  },
  "ru": {
    "registered": "Вы зарегистрированы",
    "register_now": "Зарегистрироваться",
    "register_change": "Изменить регистрацию",
    "register": "Регистрация",
    "success_text": "Вы зарегистрировались на мероприятие!",
    "success_email": "На ваш e-mail отправлено письмо с дальнейшими инструкциями",
    "success_more": "Продолжить"
  }
}
</i18n>
