<template>
  <component
    :is="tag"
    :href="item.LINK || item.DETAIL_LINK_URL || item.DETAIL_PAGE_URL"
    :target="item.LINK_NEW_WINDOW ? `_blank` : ''"
    :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
    class="edu-cards__item"
    @click="onClick(item, $event)"
  >
    <!-- Кнопка плей для Видео и Спикеры -->
    <div
      v-if="item.STATUS === 'Video' || item.STATUS === 'Speakers'"
      class="edu-cards__play"
    ></div>

    <!-- Обычная дата для Анонс -->
    <div v-if="item.STATUS === 'Anounce'" class="edu-cards__date">
      <p>{{ item.START_TIME_STRUCT.DAY }}</p>
      <p>{{ item.START_TIME_STRUCT.MONTH }}</p>
    </div>

    <!-- Дата для Программ -->
    <div
      v-if="
        !isPersonalPage &&
          item.ELEMENT_CODE === 'programs' &&
          !item.EMPTY_REGISTRATION_LAST_DATE
      "
      class="edu-cards__future-date"
    >
      <div class="edu-cards__future-date__inner">
        <span class="edu-cards__future-date__text">{{
          item.IS_CLOSED ? $t("closed") : $t("start")
        }}</span>
        <span v-if="!item.IS_CLOSED" class="edu-cards__future-date__num">
          <strong>{{ item.REGISTRATION_LAST_DATE.DAY }}</strong>
          {{ item.REGISTRATION_LAST_DATE.MONTH }}
        </span>
      </div>
    </div>

    <!-- Теги -->
    <ul v-if="item.SECTIONS.length" class="edu-cards__tags">
      <li
        v-for="(section, key2) in item.SECTIONS"
        :key="key2"
        v-text="section.NAME"
      />
    </ul>

    <!-- Заголовок -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="item.NAME" class="edu-cards__title" v-html="item.NAME" />

    <!-- Место для Программ -->
    <div
      v-if="item.ELEMENT_CODE === 'programs' && item.PLACE"
      class="edu-cards__place"
    >
      {{ item.PLACE }}
    </div>

    <!-- Время и место для Мероприятий -->
    <div
      v-if="item.ELEMENT_CODE !== 'programs'"
      class="edu-cards__time edu-cards__time_white"
    >
      <span v-if="item.TEXT_TIME">
        {{ item.TEXT_TIME }}
      </span>
      <span
        v-else-if="item.STATUS === 'Anounce' && item.START_TIME_STRUCT.TIME"
      >
        {{ item.START_TIME_STRUCT.TIME }}
      </span>
      <span v-else-if="item.START_TIME_STRUCT.FULL_DATE">
        {{ item.START_TIME_STRUCT.FULL_DATE }}
      </span>
      <span v-if="item.PLACE">
        {{ item.PLACE }}
      </span>
    </div>

    <div v-if="item.SHOW_BUY_INFO" class="edu-cards__buy-info">
      {{ item.SHOW_BUY_INFO }}
    </div>

    <div v-if="item.SCHOOL_STREAM" class="edu-cards__buttons">
      <button
        class="button button_base"
        type="button"
        @click.stop.prevent="goToSchool(item.SCHOOL_STREAM)"
      >
        {{ $t("link_to_school") }}
      </button>
    </div>

    <!-- Кнопки для Программ -->
    <div
      v-if="item.ELEMENT_CODE === 'programs' && !item.IS_LK_ITEM"
      class="edu-cards__buttons"
    >
      <!-- Записаться -->
      <template v-if="!item.IS_CLOSED && !item.HIDE_SIGN_UP">
        <a
          v-if="item.LINK_URL"
          :href="item.LINK_URL"
          :target="`${item.LINK_NEW_WINDOW === 'Y' ? '_blank' : ''}`"
          class="button button_light"
        >
          {{ $t("sign_up") }}
        </a>
        <button
          v-else
          class="button button_light"
          type="button"
          @click.stop.prevent="showRegistrationPopup(item)"
        >
          {{ $t("sign_up") }}
        </button>
      </template>
      <!-- Подробнее -->
      <a
        class="edu-cards__buttons__link "
        :class="{
          'edu-cards__buttons__link_closed': item.IS_CLOSED
        }"
        :href="item.LINK || item.DETAIL_LINK_URL || item.DETAIL_PAGE_URL"
        :target="`${item.DETAIL_LINK_NEW_WINDOW === 'Y' ? '_blank' : ''}`"
      >
        {{ $t("more") }}
      </a>
    </div>

    <!-- Статусы оплаты -->
    <div
      v-if="item.IS_LK_ITEM"
      class="edu-cards__payment-status"
      :class="{
        _waiting: item.PAYMENT_CODE === 'not_paid',
        _success: item.PAYMENT_CODE !== 'not_paid'
      }"
    >
      <svg
        v-if="item.PAYMENT_CODE === 'not_paid'"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          d="M9 .563A8.437 8.437 0 1017.438 9 8.447 8.447 0 009 .562zm0 15A6.562 6.562 0 1115.563 9 6.57 6.57 0 019 15.563zm-.938-6.25V5.25a.938.938 0 011.876 0v4.063a.938.938 0 11-1.876 0zm2.188 3.124a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
        />
      </svg>
      <svg v-else viewBox="0 0 34 34">
        <path
          d="M17 34C7.6 34 0 26.4 0 17S7.6 0 17 0s17 7.6 17 17-7.6 17-17 17zm0-32C8.7 2 2 8.7 2 17s6.7 15 15 15 15-6.7 15-15S25.3 2 17 2z"
        />
        <path
          d="M16.7 21.6l-3.4-3.9c-.4-.4-.3-1 .1-1.4.4-.4 1-.3 1.4.1l1.8 2.1 3.6-5c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4l-5.1 6.9z"
        />
      </svg>
      <span v-if="item.SCHOOL_FINISHED">
        {{ $t("finished_application") }}
      </span>
      <span v-else-if="item.PAYMENT_STATUS">
        {{ item.PAYMENT_STATUS }}
      </span>
      <span v-else>
        {{ $t("success_application") }}
      </span>
    </div>
  </component>
</template>

<script>
export default {
  name: "CardItem",
  props: {
    tag: { type: String, default: "a" },
    item: { type: Object, required: true },
    preventDefault: { type: Boolean, default: false },
    isPersonalPage: { type: Boolean, default: false }
  },
  methods: {
    onClick(item, event) {
      if (this.preventDefault) {
        event.preventDefault();
      }
      this.$emit("click", item);
    },
    goToSchool(stream) {
      window.open(
        `/bitrix/services/main/ajax.php?mode=class&c=dalee:virtual.school&action=jwt&stream_id=` +
          stream,
        "_blank"
      );
    }
  }
};
</script>

<i18n>
{
  "en": {
    "start": "Start:",
    "closed": "Closed",
    "sign_up": "Sign up",
    "more": "More",
    "success_application": "Application sent",
    "finished_application": "You have completed the training",
    "link_to_school": "Go to training"
  },
  "ru": {
    "start": "Старт:",
    "closed": "Набор закрыт",
    "sign_up": "Записаться",
    "more": "Подробнее",
    "success_application": "Заявка отправлена",
    "finished_application": "Вы завершили обучение",
    "link_to_school": "Перейти к обучению"
  }
}
</i18n>
