<template>
  <div v-show="isActive" class="program-structure-tabs__tab">
    <div class="program-structure-tabs__tab__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramStructureTab",
  props: {
    name: { type: String, default: "" },
    desc: { type: String, default: "" },
    date: { type: String, default: "" },
    selected: { type: Boolean, default: false }
  },
  data() {
    return {
      isActive: false
    };
  },
  mounted() {
    this.isActive = this.selected;
  }
};
</script>
