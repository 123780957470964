<template>
  <div>
    <form
      class="form"
      @input="onUpdateForm"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <div class="form-body form-body_width-medium">
        <div class="form-row">
          <form-input
            id="oldPassword"
            v-model="form.oldPassword"
            :label="$t('old_password')"
            autocomplete="off"
            type="password"
            :error="$v.form.oldPassword.$error || !errors.wrongPassword"
            :messages="[
              {
                type: 'error',
                i18: 'error.required',
                state:
                  $v.form.oldPassword.$error && !$v.form.oldPassword.required
              },
              {
                type: 'error',
                i18: 'error.password.invalid',
                state: !errors.wrongPassword
              }
            ]"
          />
        </div>
        <div class="form-row">
          <form-input
            id="newPassword"
            v-model="form.password"
            :label="$t('new_password')"
            autocomplete="off"
            type="password"
            :description="$t('password_description')"
            :error="$v.form.password.$error"
            :messages="[
              {
                type: 'error',
                i18: 'error.required',
                state: $v.form.password.$error && !$v.form.password.required
              },
              {
                type: 'error',
                text: passwordDetail,
                state: $v.form.password.$error && !$v.form.password.goodPassword
              }
            ]"
            @blur="$v.form.password.$touch()"
          />
        </div>
        <div class="form-row">
          <form-input
            id="repeatNewPassword"
            v-model="form.confirmPassword"
            :label="$t('new_password_again')"
            autocomplete="off"
            type="password"
            :error="$v.form.confirmPassword.$error"
            :messages="[
              {
                type: 'error',
                i18: 'error.required',
                state:
                  $v.form.confirmPassword.$error &&
                  !$v.form.confirmPassword.required
              },
              {
                type: 'error',
                i18: 'error.password.mismatch',
                state: !$v.form.confirmPassword.sameAsPassword
              }
            ]"
          />
        </div>

        <p class="form-light-text mt-6">
          <small>
            {{ $t("required_text") }}
          </small>
        </p>

        <button
          class="button button_base mt-15"
          type="submit"
          :disabled="isDisabled"
        >
          {{ $t("password_change") }}
        </button>
      </div>
    </form>

    <notify-popup popup="successChangePassPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              fill="#18B346"
              d="M21.086 14.711a1.063 1.063 0 100-2.125 1.063 1.063 0 000 2.125z"
            />
            <path
              fill="#18B346"
              d="M25.817 14.508c-.355-.903-1.005-2.206-2.09-3.285-1.08-1.075-2.368-1.707-3.26-2.048a2.67 2.67 0 00-2.832.6l-2.607 2.596a2.657 2.657 0 00-.555 2.946c.137.308.286.609.445.9L9.195 21.94a.664.664 0 00-.195.47v2.922c0 .366.297.664.664.664h2.922a.664.664 0 00.664-.664v-1.196h1.195a.664.664 0 00.664-.664v-1.195h1.196a.664.664 0 000-1.328h-1.86a.664.664 0 00-.664.664v1.195h-1.195a.664.664 0 00-.664.664v1.196h-1.594v-1.983l5.889-5.889a.664.664 0 00.098-.814c-.23-.38-.441-.785-.628-1.205a1.322 1.322 0 01.278-1.465l2.607-2.595a1.34 1.34 0 011.421-.301c.771.294 1.883.837 2.798 1.748.92.917 1.482 2.044 1.79 2.829.192.49.075 1.046-.298 1.417l-2.64 2.627a1.337 1.337 0 01-1.46.285 8.622 8.622 0 01-1.196-.61.664.664 0 10-.695 1.132c.437.268.901.505 1.38.704 1.001.417 2.143.193 2.909-.57l2.639-2.626a2.646 2.646 0 00.597-2.845z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("change_title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("change_success") }}
          </p>
        </div>
      </div>
      <div class="notify-popup__footer _g-text-center">
        <a
          class="notify-popup__link"
          href="#"
          @click.prevent="$modal.hide('successChangePassPopup')"
        >
          {{ $t("close") }}
        </a>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ChangePasswordProfile",
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        oldPassword: "",
        password: "",
        confirmPassword: ""
      },
      errors: {
        wrongPassword: true
      },
      passwordDetail: "",
      isDisabled: true
    };
  },
  validations: {
    form: {
      oldPassword: {
        required
      },
      password: {
        required,
        goodPassword(password) {
          let result = true;
          this.passwordDetail = "";

          // Проверка длины пароля
          if (password.length < 8) {
            this.passwordDetail += this.$i18n.t("requirement.length") + "<br>";
            result = false;
          }

          // Проверка на наличие одного заглавного символа
          if (!/[A-Z]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.upper") + "<br>";
            result = false;
          }

          // Проверка на наличие одного спецсимвол
          if (!/[\W_]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.symbol") + "<br>";
            result = false;
          }

          // Проверка на наличие одного числа
          if (!/[0-9]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.number") + "<br>";
            result = false;
          }

          return result;
        }
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }

      return flag;
    },
    onUpdateForm() {
      if (
        this.form.confirmPassword.length &&
        this.form.oldPassword.length &&
        this.form.password.length
      ) {
        this.$v.form.confirmPassword.sameAsPassword &&
        this.form.oldPassword.length &&
        this.$v.form.password.goodPassword
          ? (this.isDisabled = false)
          : (this.isDisabled = true);
      }
    },
    async onSubmit() {
      if (this.validate()) {
        const data = new FormData();
        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "multipart/form-data" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            this.isDisabled = true;

            if (response.data.code > 0) {
              if (response.data.code === 200) {
                this.form.oldPassword = "";
                this.form.password = "";
                this.form.confirmPassword = "";
                this.$v.$reset();
                this.$modal.show("successChangePassPopup");
                this.errors.wrongPassword = true;
              } else if (response.data.code === 500) {
                this.errors.wrongPassword = false;
              }
            }
          }
        });
      }
    },
    onReset(e) {
      e.preventDefault();
      this.form.oldPassword = "";
      this.form.password = "";
      this.form.confirmPassword = "";
    }
  }
};
</script>

<i18n>
{
  "en": {
    "old_password": "Old password *",
    "new_password": "New password *",
    "new_password_again": "Repeat new password *",
    "password_description": "Should contain at least 8 characters, uppercase, lowercase Latin letters, non-alphabetic characters and numbers",
    "required_text": "Fields marked * are required",
    "password_change": "Change password",
    "change_title": "Change password",
    "change_success": "Your password has been successfully changed!",
    "close": "Close a window",
    "requirement": {
      "length" : "Must contain at least 8 characters",
      "upper" : "Must contain at least one uppercase character",
      "symbol" : "Must contain at least one special character",
      "number" : "Must contain at least one digit"
    }
  },
  "ru": {
    "old_password": "Старый пароль *",
    "new_password": "Новый пароль *",
    "new_password_again": "Новый пароль еще раз *",
    "password_description": "Должен содержать не менее 8 символов, заглавные, строчные латинские буквы, небуквенные символы и цифры",
    "required_text": "Поля, отмеченные * обязательные к заполнению",
    "password_change": "Изменить пароль",
    "change_title": "Изменение пароля",
    "change_success": "Ваш пароль успешно изменен!",
    "close": "Закрыть окно",
    "requirement": {
      "length" : "Должен содержать не менее 8 символов",
      "upper" : "Должен содержать хотя бы один заглавный символ",
      "symbol" : "Должен содержать хотя бы один спецсимвол",
      "number" : "Должен содержать хотя бы одну цифру"
    }
  }
}
</i18n>
