<template>
  <div class="container">
    <form class="form mb-10" @submit.prevent="onSubmit">
      <div class="form-body form-body_width-medium">
        <div class="form-row">
          <form-input
            id="email"
            v-model="form.email"
            :label="$t('enter_email') + ' *'"
            type="email"
            :error="
              $v.form.email.$error || errors.emailNotFound || errors.anyError
            "
            :messages="[
              {
                type: 'error',
                i18: 'error.email.invalid',
                state: !$v.form.email.email
              },
              {
                type: 'error',
                i18: 'error.required',
                state: $v.form.email.$error && !$v.form.email.required
              },
              {
                type: 'error',
                i18: 'error.email.not-found',
                state: errors.emailNotFound
              },
              {
                type: 'error',
                text: $t('email_error'),
                state: errors.anyError
              }
            ]"
            @input="onInput"
            @blur="$v.form.email.$touch()"
          />
        </div>
        <div class="form-row">
          <div class="form__horizontal-items">
            <button class="button button_base" type="submit">
              {{ $t("send") }}
            </button>
            <a class="form-link" :href="haveAccountUrl">
              {{ $t("back_to_auth") }}
            </a>
          </div>
        </div>
      </div>
    </form>
    <notify-popup popup="successRegPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              fill="#18B346"
              d="M21.086 14.711a1.063 1.063 0 100-2.125 1.063 1.063 0 000 2.125z"
            />
            <path
              fill="#18B346"
              d="M25.817 14.508c-.355-.903-1.005-2.206-2.09-3.285-1.08-1.075-2.368-1.707-3.26-2.048a2.67 2.67 0 00-2.832.6l-2.607 2.596a2.657 2.657 0 00-.555 2.946c.137.308.286.609.445.9L9.195 21.94a.664.664 0 00-.195.47v2.922c0 .366.297.664.664.664h2.922a.664.664 0 00.664-.664v-1.196h1.195a.664.664 0 00.664-.664v-1.195h1.196a.664.664 0 000-1.328h-1.86a.664.664 0 00-.664.664v1.195h-1.195a.664.664 0 00-.664.664v1.196h-1.594v-1.983l5.889-5.889a.664.664 0 00.098-.814c-.23-.38-.441-.785-.628-1.205a1.322 1.322 0 01.278-1.465l2.607-2.595a1.34 1.34 0 011.421-.301c.771.294 1.883.837 2.798 1.748.92.917 1.482 2.044 1.79 2.829.192.49.075 1.046-.298 1.417l-2.64 2.627a1.337 1.337 0 01-1.46.285 8.622 8.622 0 01-1.196-.61.664.664 0 10-.695 1.132c.437.268.901.505 1.38.704 1.001.417 2.143.193 2.909-.57l2.639-2.626a2.646 2.646 0 00.597-2.845z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("password_title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("password_instruction") }}
          </p>
        </div>
      </div>
      <div class="notify-popup__footer _g-text-center">
        <a
          v-if="showAgain"
          href="#"
          class="notify-popup__link"
          @click.prevent="onSubmit('again')"
        >
          {{ $t("password_reply") }}
        </a>
        <p v-else>{{ $t("password_reply_success") }}</p>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "RecoveryPassword",
  props: {
    action: {
      type: String,
      default: ""
    },
    haveAccountUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        email: ""
      },
      errors: {
        emailNotFound: false,
        anyError: false
      },
      showAgain: true
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    onInput() {
      this.errors.emailNotFound = false;
      this.errors.anyError = false;
    },
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit(formType = "default") {
      if (this.validate()) {
        if (formType === "again") {
          this.showAgain = false;
        }

        const data = new FormData();

        data.append("email", this.form.email);
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data.code === 200) {
            this.$modal.show("successRegPopup");
          } else if (response.data.code === 500) {
            this.errors.emailNotFound = true;
          } else if (response.data.code === 300) {
            this.errors.anyError = true;
          }
        });
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "enter_email": "Enter your email address",
    "email_error": "An error occurred",
    "send": "Send",
    "back_to_auth": "Back to authorization",
    "password_title": "Password change",
    "password_instruction": "An email with further instructions has been sent to your email address.",
    "password_reply": "Send email again",
    "password_reply_success": "The letter has been sent again!"
  },
  "ru": {
    "enter_email": "Введите E-mail",
    "email_error": "Произошла ошибка",
    "send": "Отправить",
    "back_to_auth": "Назад к авторизации",
    "password_title": "Изменение пароля",
    "password_instruction": "На ваш e-mail отправлено письмо с дальнейшими инструкциями.",
    "password_reply": "Отправить письмо повторно",
    "password_reply_success": "Письмо отправлено повторно!"
  }
}
</i18n>
