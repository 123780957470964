<template>
  <div class="tabs-line tabs-line_low mb-10">
    <div id="pulseOverflowLine" class="tabs-line__overflow">
      <ul class="tabs-line__list">
        <li v-for="(item, i) in items" :key="i">
          <a
            class="tabs-line__list__item"
            :class="{ _active: !!item.selected }"
            :href="item.link + '#menu'"
          >
            {{ item.text }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PulseMarketMenu",
  props: {
    items: { type: Array, default: () => [] }
  },
  mounted() {
    window.addEventListener("load", this.scrollToMenu());
    window.addEventListener("load", this.scrollToActive());
  },
  methods: {
    scrollToActive() {
      const container = document.querySelector("#pulseOverflowLine");
      const element = document.querySelector(".tabs-line__list__item._active");
      container.scrollLeft = element.offsetLeft;
    },
    scrollToMenu() {
      if (window.location.hash === "#menu") {
        this.$scrollTo("#menu", 0, { offset: -78 });
      }
    }
  }
};
</script>
