<template>
  <social-sharing
    :url="url"
    :title="title"
    :description="description"
    :quote="quote"
    :network-tag="networkTag"
    :hashtags="hashtags"
    :twitter-user="twitterUser"
    :vk="$t('vk')"
    :twitter="$t('twitter')"
    :email="$t('email')"
    inline-template
  >
    <div class="edu-social">
      <network id="share_vk" network="vk" class="edu-social__link">
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0736 40.1471C31.1599 40.1471 40.1471 31.1599 40.1471 20.0736C40.1471 8.98724 31.1599 0 20.0736 0C8.98724 0 0 8.98724 0 20.0736C0 31.1599 8.98724 40.1471 20.0736 40.1471Z"
            fill="#131313"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.2745 26.9498H20.5297C20.5297 26.9498 20.9089 26.9081 21.1025 26.699C21.2806 26.5071 21.2749 26.1468 21.2749 26.1468C21.2749 26.1468 21.2504 24.4597 22.0321 24.2112C22.8027 23.9665 23.7923 25.8418 24.8411 26.5628C25.6343 27.1085 26.2369 26.9889 26.2369 26.9889L29.0414 26.9498C29.0414 26.9498 30.5084 26.8592 29.8128 25.7039C29.7558 25.6093 29.4077 24.8492 27.7277 23.2871C25.9692 21.6523 26.2047 21.9167 28.3229 19.0888C29.613 17.3666 30.1287 16.3152 29.9676 15.8648C29.8139 15.4359 28.8652 15.5493 28.8652 15.5493L25.7075 15.569C25.7075 15.569 25.4735 15.537 25.2998 15.641C25.1302 15.7429 25.021 15.9805 25.021 15.9805C25.021 15.9805 24.5213 17.3132 23.8547 18.4466C22.4487 20.8382 21.8865 20.9644 21.6567 20.8159C21.1221 20.4698 21.2556 19.4255 21.2556 18.6836C21.2556 16.366 21.6065 15.3997 20.5722 15.1495C20.229 15.0664 19.9764 15.0116 19.0986 15.0027C17.9719 14.991 17.0183 15.0062 16.4783 15.2712C16.1191 15.4473 15.842 15.84 16.0108 15.8626C16.2195 15.8905 16.6921 15.9902 16.9427 16.332C17.2663 16.7729 17.2549 17.7632 17.2549 17.7632C17.2549 17.7632 17.4408 20.4915 16.8207 20.8305C16.3951 21.0629 15.8112 20.5883 14.5577 18.4186C13.9154 17.3072 13.4304 16.0787 13.4304 16.0787C13.4304 16.0787 13.3369 15.8491 13.1701 15.7263C12.9677 15.5776 12.6849 15.5302 12.6849 15.5302L9.68435 15.5499C9.68435 15.5499 9.23391 15.5624 9.06855 15.7586C8.92145 15.9331 9.05687 16.294 9.05687 16.294C9.05687 16.294 11.406 21.7991 14.0659 24.5733C16.5048 27.1168 19.2745 26.9498 19.2745 26.9498Z"
            fill="white"
          />
        </svg>
        <span>
          {{ $attrs.vk }}
        </span>
      </network>
      <network id="share_email" network="email" class="edu-social__link">
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0734 40.1468C31.1596 40.1468 40.1467 31.1596 40.1467 20.0734C40.1467 8.98716 31.1596 0 20.0734 0C8.98715 0 0 8.98716 0 20.0734C0 31.1596 8.98715 40.1468 20.0734 40.1468Z"
            fill="#131313"
          />
          <path
            d="M27.3927 26.5746C27.7972 26.5746 28.1478 26.4451 28.4458 26.1894L23.3465 21.2455C23.2241 21.3304 23.1055 21.413 22.9935 21.4915C22.6119 21.7641 22.3022 21.9768 22.0643 22.1292C21.8265 22.282 21.5102 22.4377 21.1152 22.5967C20.72 22.7558 20.3518 22.8352 20.0103 22.8352H20.0003H19.9903C19.6487 22.8352 19.2805 22.7559 18.8853 22.5967C18.4901 22.4377 18.1738 22.282 17.9362 22.1292C17.6984 21.9768 17.3888 21.7641 17.007 21.4915C16.9006 21.4159 16.7826 21.3329 16.655 21.2441L11.5547 26.1894C11.8527 26.4451 12.2035 26.5746 12.608 26.5746H27.3927Z"
            fill="white"
          />
          <path
            d="M12.0146 18.5699C11.633 18.3233 11.2946 18.0408 11 17.7227V25.2444L15.4946 20.887C14.5954 20.2784 13.4369 19.507 12.0146 18.5699Z"
            fill="white"
          />
          <path
            d="M27.9952 18.5699C26.6272 19.4676 25.4644 20.2404 24.5068 20.8887L28.9996 25.2444V17.7227C28.7116 18.0344 28.3768 18.3167 27.9952 18.5699Z"
            fill="white"
          />
          <path
            d="M27.3915 13H12.6069C12.0912 13 11.6946 13.1688 11.4168 13.5061C11.1388 13.8436 11 14.2656 11 14.7717C11 15.1805 11.1842 15.6235 11.5524 16.1007C11.9205 16.5777 12.3123 16.9525 12.7275 17.225C12.9551 17.3809 13.6415 17.8434 14.7866 18.6123C15.4048 19.0276 15.9424 19.3895 16.4043 19.7018C16.7981 19.9677 17.1377 20.198 17.4181 20.3891C17.4502 20.411 17.5009 20.4461 17.5681 20.4927C17.6404 20.5431 17.732 20.607 17.845 20.6861C18.0626 20.8386 18.2434 20.9619 18.3874 21.0561C18.5312 21.1504 18.7054 21.2556 18.9097 21.3725C19.114 21.4892 19.3066 21.577 19.4873 21.6354C19.6681 21.6937 19.8355 21.723 19.9895 21.723H19.9995H20.0095C20.1634 21.723 20.3308 21.6937 20.5117 21.6354C20.6924 21.577 20.8849 21.4894 21.0892 21.3725C21.2934 21.2556 21.4674 21.1502 21.6116 21.0561C21.7556 20.9619 21.9364 20.8387 22.154 20.6861C22.2668 20.607 22.3584 20.543 22.4308 20.4928C22.498 20.4461 22.5486 20.4112 22.581 20.3891C22.7994 20.2418 23.1398 20.0125 23.5974 19.7045C24.4301 19.1437 25.6564 18.3183 27.2816 17.225C27.7703 16.8941 28.1787 16.4948 28.5069 16.0276C28.8345 15.5605 28.9987 15.0705 28.9987 14.5577C28.9987 14.1293 28.8395 13.7628 28.5217 13.4575C28.2035 13.1525 27.8268 13 27.3915 13Z"
            fill="white"
          />
        </svg>
        <span>
          {{ $attrs.email }}
        </span>
      </network>
    </div>
  </social-sharing>
</template>

<script>
export default {
  name: "EduSocial",
  props: {
    url: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    quote: {
      type: String,
      default: ""
    },
    networkTag: {
      type: String,
      default: ""
    },
    hashtags: {
      type: String,
      default: ""
    },
    twitterUser: {
      type: String,
      default: ""
    }
  }
};
</script>

<i18n>
{
  "en": {
    "vk": "Share on VK",
    "email": "Send to E-mail"
  },
  "ru": {
    "vk": "Поделиться в VK",
    "email": "Отправить на электронную почту"
  }
}
</i18n>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

.edu-social {
  display: flex;
  border-radius: 16px;
  background-color: $drop-hover;
  padding: 32px 52px;
  justify-content: space-between;

  @include md-desktop-only {
    border-radius: 16px * $zoom;
    padding: 32px * $zoom 52px * $zoom;
  }
  @include tablet {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 40px 0 60px;
  }
  @include mobile {
    padding: 30px 30px 0;
  }

  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    @include tablet {
      width: 50%;
      margin-bottom: 30px;
    }
    @include mobile {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 52px;

      @include md-desktop-only {
        margin-right: 52px * $zoom;
      }
      @include tablet {
        margin-right: 0;
      }
    }
  }

  svg,
  span {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    flex-shrink: 0;

    @include md-desktop-only {
      width: 40px * $zoom;
      height: 40px * $zoom;
      margin-right: 12px * $zoom;
    }
  }

  span {
    font-size: 14px;
    line-height: 1.29;

    @include md-desktop-only {
      font-size: 14px * $zoom;
    }
  }
}
</style>
