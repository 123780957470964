<template>
  <div class="personal-area__line-row">
    <div class="personal-area__title" v-text="category.value"></div>
    <label
      v-for="(item, index) in category.options"
      :key="index"
      class="radio-input radio-input_inline"
    >
      <input
        class="radio-input__input"
        type="radio"
        :checked="!!item.checked"
        :name="`option_${category.id}`"
        @change="
          $emit('onUpdateForm', { category: category.id, option: item.id })
        "
      />
      <span class="radio-input__text" v-text="item.value"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "UserNotifyOptions",
  props: {
    category: {
      type: Object,
      required: true
    }
  }
};
</script>
