<template>
  <div class="notify-popup__body">
    <div class="notify-popup__title _g-text-center">
      {{ title }}
    </div>
    <form
      v-if="!form.resend"
      class="form"
      :action="url"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <input type="hidden" :value="legal" />
      <div class="form-row">
        <form-input
          id="programm"
          v-model="form.programm"
          :label="$t('program') + ' *'"
          :error="$v.form.programm.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.programm.$error && !$v.form.programm.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="fio"
          v-model="form.fio"
          :label="$t('fio') + ' *'"
          :error="$v.form.fio.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.fio.$error && !$v.form.fio.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="company"
          v-model="form.company"
          :label="$t('company_name') + ' *'"
          :error="$v.form.company.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.company.$error && !$v.form.company.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="position"
          v-model="form.position"
          :label="$t('position') + ' *'"
          :error="$v.form.position.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.position.$error && !$v.form.position.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="email"
          v-model="form.email"
          type="email"
          label="E-mail *"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="phone"
          v-model="form.phone"
          :label="$t('phone') + ' *'"
          type="tel"
          :error="$v.form.phone.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.phone.$error && !$v.form.phone.required
            },
            {
              type: 'error',
              min: $v.form.phone.$params.minLength.min,
              state: !$v.form.phone.minLength
            }
          ]"
          @blur="$v.form.phone.$touch()"
        />
      </div>
      <div v-if="legal" class="form-row">
        <custom-select
          v-model="form.english"
          :options="englishOptions"
          :placeholder="$t('english')"
          prop-name-text="name"
          prop-name-value="id"
          is-flat-input
        />
      </div>
      <div v-if="legal" class="form-row">
        <form-input
          id="car"
          v-model="form.car"
          :label="$t('car')"
          :label-tooltip="$t('car_tooltip')"
        />
      </div>
      <div v-if="legal" class="form-row">
        <label class="form-agree">
          <input
            v-model="form.transfer"
            class="form-agree__checkbox"
            type="checkbox"
          />
          <span class="form-agree__text">
            {{ $t("transfer") }}
          </span>
        </label>
      </div>
      <div v-if="!legal" class="form-row">
        <form-input id="city" v-model="form.city" :label="$t('city')" />
      </div>
      <div class="form-row">
        <label class="form-agree">
          <input
            v-model="form.agreement"
            class="form-agree__checkbox"
            :class="{
              'form-agree__checkbox--error': $v.form.agreement.$error
            }"
            type="checkbox"
            name="data-agreement"
            @change="$v.form.agreement.$touch()"
          />
          <span class="form-agree__text">
            {{ $t("agree_left") }}
            <a
              href="/upload/iblock/999/09%20%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BF%D0%BE%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.docx"
            >
              {{ $t("agree_right") }}
            </a>
          </span>
        </label>
        <div
          v-if="$v.form.agreement.$error"
          class="form-input-message is-error"
        >
          {{ $t("required_agree") }}
        </div>
      </div>
      <div class="mt-14 _g-text-center">
        <button type="submit" class="button button_base">
          {{ $t("send_application") }}
        </button>
      </div>
    </form>

    <div v-else>
      <div class="notify-popup__text _g-text-center">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('success_text')" />
      </div>

      <div class="notify-popup__buttons">
        <a
          class="notify-popup__link"
          href="#"
          @click.prevent="$modal.hide('programmRegistrationPopup')"
        >
          {{ $t("close") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "ProgrammRegistrationForm",
  props: {
    url: { type: String, required: true },
    programm: { type: String, required: true },
    programmId: { type: String, default: "" },
    title: { type: String, default: "" },
    legal: { type: Boolean, default: false },
    idForm: { type: String, default: "" },
    isSetPixelTrack: { type: Boolean, default: false },
    englishOptions: { type: Array, default: () => [] }
  },
  data() {
    return {
      form: {
        legal: "",
        programm: "",
        programmId: "",
        company: "",
        fio: "",
        email: "",
        phone: "",
        position: "",
        city: "",
        car: "",
        english: "",
        transfer: "",
        agreement: false,
        resend: false
      }
    };
  },
  validations() {
    if (this.legal) {
      return {
        form: {
          programm: {
            required
          },
          company: {
            required
          },
          fio: {
            required
          },
          email: {
            required,
            email
          },
          phone: {
            required,
            minLength: minLength(10)
          },
          position: {
            required
          },
          agreement: {
            required,
            sameAs: sameAs(() => true)
          }
        }
      };
    } else {
      return {
        form: {
          programm: {
            required
          },
          company: {
            required
          },
          fio: {
            required
          },
          email: {
            required,
            email
          },
          phone: {
            required,
            minLength: minLength(10)
          },
          position: {
            required
          },
          agreement: {
            required,
            sameAs: sameAs(() => true)
          }
        }
      };
    }
  },
  mounted() {
    this.form.programm = this.programm;
    this.form.programmId = this.programmId;
    this.form.legal = !!this.legal;
    this.fetchUserData();

    if (this.title === "") {
      this.title = this.$t("title");
    }
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async fetchUserData() {
      const response = await axios.post(this.url + "?action=init");
      if (response.data.success) {
        const user = response.data.data;
        this.form.company = user.company;
        this.form.fio = user.fio;
        this.form.email = user.email;
        this.form.phone = user.phone;
        this.form.position = user.position;
        this.form.city = user.city;
      }
    },
    async onSubmit() {
      if (this.validate()) {
        const response = await axios.post(
          this.url + "?action=register",
          this.form
        );
        if (response.data.success) {
          this.form.resend = true;
          /* eslint-disable */
          dataLayer.push({
            event: "ga_event",
            event_category: "order",
            event_action: "successfully_sent"
          });

          // eslint-disable-next-line no-undef
          fbq("track", "Lead");
        }
      }
    },
    onReset(e) {
      e.preventDefault();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "title": "Application for the program",
    "program": "Program",
    "company_name": "Company name",
    "fio": "Full name",
    "phone": "Phone",
    "position": "Position",
    "city": "City",
    "car": "Arrive by car",
    "car_tooltip": "Indicate the brand and state. car number",
    "english": "English proficiency",
    "transfer": "Need a shuttle to campus",
    "agree_left": "I consent to",
    "agree_right": "the processing of my personal data",
    "required_agree": "You must agree to the terms",
    "send_application": "Submit an application",
    "success_text": "Thank! Your application has been sent. <br> The manager will contact you shortly.",
    "close": "Close a window"
  },
  "ru": {
    "title": "Заявка на программу",
    "program": "Программа",
    "company_name": "Название компании",
    "fio": "ФИО",
    "phone": "Телефон",
    "position": "Должность",
    "city": "Город",
    "car": "Приедете на автомобиле",
    "car_tooltip": "Укажите марку и гос. номер автомобиля",
    "english": "Уровень владения английским",
    "transfer": "Нужен трансфер до кампуса",
    "agree_left": "Я согласен(а) на",
    "agree_right": "обработку персональных данных",
    "required_agree": "Необходимо согласиться с условиями",
    "send_application": "Подать заявку",
    "success_text": "Спасибо! Ваша заявка отправлена. <br> Менеджер свяжется с&nbsp;Вами в&nbsp;течение 24&nbsp;часов.",
    "close": "Закрыть окно"
  }
}
</i18n>
