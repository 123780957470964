<template>
  <div
    class="scrollable-overflow"
    :style="{ transform: 'translateX(-' + translate + 'px)' }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ScrollableOverflow",
  data() {
    return {
      startPos: null,
      maxTranslate: null,
      translate: 0
    };
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.init);
  },
  destroyed() {
    window.removeEventListener("resize", this.init);
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.maxTranslate = this.$el.clientWidth - (window.innerWidth - 40);
        this.translate = 0;
        if (this.maxTranslate > 0) {
          this.$el.addEventListener("touchstart", this.touchStart);
          this.$el.addEventListener("touchmove", this.touchMove);
          this.$el.addEventListener("touchend", this.touchEnd);
        }
      });
    },
    touchStart(e) {
      this.startPos = e.touches[0].clientX;
    },
    touchMove(e) {
      let pos = e.touches[0].clientX;
      if (pos < this.startPos) {
        if (this.translate + (this.startPos - pos) < this.maxTranslate) {
          this.translate += this.startPos - pos;
        } else {
          this.translate = this.maxTranslate;
        }
      } else {
        if (this.translate - (pos - this.startPos) > 0) {
          this.translate -= pos - this.startPos;
        } else {
          this.translate = 0;
        }
      }
      this.startPos = pos;
    },
    touchEnd() {
      this.startPos = null;
    }
  }
};
</script>

<style>
.scrollable-overflow {
  touch-action: pan-x;
}
</style>
