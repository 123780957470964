<template>
  <div class="application-packages-wrapper">
    <ul class="application-packages">
      <li v-for="(pack, i) in packages" :key="`${pack.id}_${i}`">
        <div class="application-packages__item">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="application-packages__item-content"
            :class="{
              'application-packages__item-content--streams-mode': isStreamsMode
            }"
          >
            <div
              class="application-packages__item-head"
              :class="{
                'application-packages__item-head--streams-mode': isStreamsMode
              }"
            >
              <div class="application-packages__item-title">
                {{ pack.name }}
              </div>
              <div
                v-if="!isStreamsMode"
                class="application-packages__item-cost"
              >
                <template v-if="isFreeApplication || Number(pack.cost) === 0">
                  {{ $t("is_free") }}
                </template>
                <template v-else>
                  <template v-if="promoCode.isDigital && promoCode.discount">
                    <span
                      class="application-packages__item-cost-old"
                      v-html="formatCost(pack.cost, false, pack.isCostFrom)"
                    />
                    <span
                      v-html="
                        formatCost(
                          getDiscountCost(pack.cost),
                          true,
                          pack.isCostFrom
                        )
                      "
                    />
                  </template>
                  <template v-else>
                    <span
                      v-html="formatCost(pack.cost, true, pack.isCostFrom)"
                    />
                  </template>
                </template>
              </div>
              <div
                v-if="promoCode.isDigital"
                class="application-packages__item-promocode"
              >
                {{ $t("by_digital_promocode") }}
              </div>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="!isStreamsMode"
              class="application-packages__item-text"
              v-html="pack.text"
            ></div>
            <div v-else>
              <span
                class="application-packages__item-stream-text application-packages__item-stream-text--secondary"
              >
                {{ $t("packages_stream.title") }}
              </span>
              <span class="application-packages__item-stream-text">
                {{ $t("packages_stream.from") }}
                {{ maybeShortStartDate(pack.dateStart, pack.dateFinish) }}
                {{ $t("packages_stream.to") }}
                {{ pack.dateFinish }}
              </span>
            </div>
            <!--eslint-enable-->
          </div>
          <!--eslint-enable-->
          <div class="application-packages__item-buttons">
            <div
              v-if="pack.isReached"
              class="application-packages__item-reached"
            >
              <span class="application-packages__item-reached-title">
                {{ $t("no_places") }}
              </span>
            </div>
            <a
              v-else-if="pack.customLink"
              :href="pack.customLink"
              target="_blank"
              class="button button_base button_icon"
            >
              <span>{{ $t("select_button") }}</span>
            </a>
            <a
              v-else-if="pack.isBooking"
              class="button button_base button_icon"
              type="button"
              @click.prevent="$modal.show('campusOrder')"
            >
              <span>{{ $t("select_button") }}</span>
            </a>
            <button
              v-else
              class="button button_base button_icon"
              type="button"
              @click.prevent="selectPackage(pack)"
            >
              <span>{{ $t("select_button") }}</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ApplicationPackages",
  props: {
    initPackages: {
      type: Array,
      default: () => {}
    },
    promoCode: {
      type: Object,
      default: () => {}
    },
    isFreeApplication: {
      type: Boolean,
      default: false
    },
    isReapplication: {
      type: Boolean,
      default: false
    },
    isAuthorized: {
      type: Boolean,
      default: false
    },
    authUrl: {
      type: String,
      default: ""
    },
    actionCheckPackageLimits: {
      type: String,
      default: ""
    },
    isStreamsMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: true,
      packages: this.initPackages
    };
  },
  mounted() {
    this.resetPackage();
    this.checkPackageLimits();
    this.checkSelectedPackage();
    this.checkReapplication();
  },
  methods: {
    checkSelectedPackage() {
      if (sessionStorage.getItem("selectedPackage")) {
        const selectedPackage = this.packages.find(
          item => item.id === sessionStorage.getItem("selectedPackage")
        );

        this.selectPackage(selectedPackage);
        sessionStorage.removeItem("selectedPackage");
      }
    },
    async checkPackageLimits() {
      const isContainsLimit = this.packages.some(pack => {
        return pack.hasOwnProperty("limit");
      });

      if (!isContainsLimit) return;

      const data = new FormData();

      this.packages.forEach(pack => {
        if (pack.hasOwnProperty("limit")) {
          data.append("packagesIds[]", pack.id);
        }
      });

      const options = {
        url: this.actionCheckPackageLimits,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: data
      };

      const response = await axios(options);

      if (response.data.status === "success") {
        if (response.data.data) {
          const ids = response.data.data;

          this.packages.forEach((pack, i) => {
            if (!pack.hasOwnProperty("limit")) return;

            if (ids.hasOwnProperty(pack.id) && !ids[pack.id]) {
              this.$set(this.packages[i], "isReached", true);
            }
          });
        }
      }
    },
    resetPackage() {
      this.$emit("onResetPackage");
    },
    selectPackage(pack) {
      if (this.isAuthorized) {
        this.isReapplication
          ? this.$emit("onReapplication", pack)
          : this.$emit("onChangePackage", pack);
      } else {
        const url = new URL(
          `${window.location.protocol}//${window.location.host}${this.authUrl}`
        );

        sessionStorage.setItem("selectedPackage", pack.id);

        window.location.href = url;
      }
    },
    maybeShortStartDate(startDate, finishDate) {
      if (!startDate || !finishDate) return;

      const [sDay, sMonth, sYear] = startDate.split(".");
      const fYear = finishDate.split(".")[2];

      return sYear && sYear === fYear ? `${sDay}.${sMonth}` : startDate;
    },
    checkReapplication() {
      if (!this.isAuthorized) return;

      this.$emit("onCheckReapplication");
    },
    formatCost(cost, showCurrency, isCostFrom = false) {
      cost = cost.toString().replace(/\s+/g, "");

      if (cost.split(".").length >= 2) {
        cost = Number(cost).toFixed(2);
      }

      cost = cost.replace(/\./g, ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      if (isCostFrom) {
        cost = this.$t("cost_from") + cost;
      }

      return showCurrency ? `${cost} ${this.$t("currency")}` : cost;
    },
    getDiscountCost(cost) {
      const trimCost = Number(cost.toString().replace(/\s+/g, ""));

      const discount = Number(this.promoCode.discount);
      const discountCost = (trimCost / 100) * discount;
      const result = Math.ceil(trimCost - discountCost);

      return result;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-packages";

#{$b} {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 0 -96px -55px;

  @include md-desktop-only {
    margin: 0 0 -96px * $zoom -55px * $zoom;
  }

  @include low-desktop {
    margin: 0 0 -50px -30px;
  }

  @include tablet {
    margin: 0 0 -40px -20px;
  }

  @include mobile {
    margin: 0 0 -20px -20px;
  }

  & > li {
    width: 50%;
    padding: 0 0 96px 55px;

    @include md-desktop-only {
      padding: 0 0 96px * $zoom 55px * $zoom;
    }

    @include low-desktop {
      padding: 0 0 50px 30px;
    }

    @include tablet {
      padding: 0 0 40px 20px;
    }

    @include mobile {
      padding: 0 0 20px 20px;
      width: 100%;
    }
  }

  &-wrapper {
    display: flow-root;

    &:not(:first-child) {
      margin-top: 96px;

      @include md-desktop-only {
        margin-top: 96px * $zoom;
      }

      @include low-desktop {
        margin-top: 50px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 70px;

      @include md-desktop-only {
        margin-bottom: 70px * $zoom;
      }

      @include low-desktop {
        margin-bottom: 50px;
      }
    }
  }

  &__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white-true;
    padding: 44px 50px;
    border-radius: 5px;
    color: $black-true;

    @include md-desktop-only {
      padding: 44px * $zoom 50px * $zoom;
      border-radius: 5px * $zoom;
    }

    @include low-desktop {
      padding: 40px 36px;
    }

    @include tablet {
      padding: 32px 24px;
    }

    @include mobile {
      padding: 24px 20px;
    }

    &-content {
      &:not(:last-child) {
        margin-bottom: 46px;

        @include md-desktop-only {
          margin-bottom: 46px * $zoom;
        }

        @include mobile {
          margin-bottom: 26px;
        }
      }

      &--streams-mode {
        margin-bottom: 10px !important;
      }
    }

    &-head {
      display: block;

      &:not(:last-child) {
        margin-bottom: 36px;

        @include md-desktop-only {
          margin-bottom: 36px * $zoom;
        }

        @include low-desktop {
          margin-bottom: 24px;
        }
      }

      &--streams-mode {
        margin-bottom: 10px !important;
      }
    }

    &-title {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.42;
      color: inherit;

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include tablet {
        font-size: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 18px;

        @include md-desktop-only {
          margin-bottom: 18px * $zoom;
        }
      }
    }

    &-cost {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.42;
      color: $color-base;

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include tablet {
        font-size: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        @include md-desktop-only {
          margin-bottom: 8px * $zoom;
        }
      }
    }

    &-cost-old {
      color: $color-bg-dark;
      text-decoration: line-through;

      &:not(:last-child) {
        margin-right: 6px;

        @include md-desktop-only {
          margin-right: 6px * $zoom;
        }
      }
    }

    &-promocode {
      display: block;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $color-text-light-2;

      @include md-desktop-only {
        font-size: 16px * $zoom;
        line-height: 22px * $zoom;
      }

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        @include md-desktop-only {
          margin-bottom: 8px * $zoom;
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 1.5;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }

      p {
        font-size: inherit;
        line-height: inherit;

        &:not(:last-child) {
          margin-bottom: 20px;

          @include md-desktop-only {
            margin-bottom: 20px * $zoom;
          }
        }
      }

      a {
        &:not([class]) {
          color: $color-base;
          text-decoration: underline;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }

      ul,
      ol {
        &:not(:first-child) {
          margin-top: 20px;

          @include md-desktop-only {
            margin-top: 20px * $zoom;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @include md-desktop-only {
            margin-bottom: 20px * $zoom;
          }
        }
      }

      & > ul {
        list-style: none;
        padding: 0;
        margin: 0;

        & > li {
          break-inside: avoid;
          display: block;
          position: relative;
          padding-left: 30px;

          @include md-desktop-only {
            padding-left: 30px * $zoom;
          }

          @include low-desktop {
            padding-left: 20px;
          }

          &::before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: $black-true;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: 0;

            @include md-desktop-only {
              width: 5px * $zoom;
              height: 5px * $zoom;
              border-radius: 5px * $zoom;
              top: 10px * $zoom;
            }

            @include low-desktop {
              width: 4px;
              height: 4px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 20px;

            @include md-desktop-only {
              margin-bottom: 20px * $zoom;
            }

            @include low-desktop {
              margin-bottom: 16px;
            }
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 20px 0 0;

            @include md-desktop-only {
              margin: 20px * $zoom 0 0;
            }

            @include low-desktop {
              margin-top: 16px;
            }

            &:not(:last-child) {
              margin-bottom: 20px;

              @include md-desktop-only {
                margin-bottom: 20px * $zoom;
              }

              @include low-desktop {
                margin-bottom: 16px;
              }
            }

            & > li {
              break-inside: avoid;
              display: block;
              position: relative;
              padding-left: 30px;

              @include md-desktop-only {
                padding-left: 30px * $zoom;
              }

              @include low-desktop {
                padding-left: 20px;
              }

              &::before {
                content: "";
                width: 5px;
                height: 5px;
                background-color: $color-base;
                position: absolute;
                top: 10px;
                left: 0;

                @include md-desktop-only {
                  width: 5px * $zoom;
                  height: 5px * $zoom;
                  top: 10px * $zoom;
                }

                @include low-desktop {
                  width: 4px;
                  height: 4px;
                }
              }

              &:not(:last-child) {
                margin-bottom: 10px;

                @include md-desktop-only {
                  margin-bottom: 10px * $zoom;
                }
              }
            }
          }
        }
      }
    }

    &-stream-text {
      display: block;
      font-size: 16px;
      line-height: 1.5;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }

      &--secondary {
        color: #9c9fa6;
      }
    }

    &-buttons {
      &:not(:first-child) {
        margin-top: auto;
      }

      .button {
        min-width: 148px;

        @include md-desktop-only {
          min-width: 148px * $zoom;
        }
      }
    }

    &-reached {
      display: flex;
      align-items: center;
      min-height: 50px;

      @include md-desktop-only {
        min-height: 50px * $zoom;
      }

      @include mobile {
        min-height: auto;
      }

      &-title {
        display: block;
        font-weight: 600;
        color: $black-true;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "select_button": "Select",
    "no_places": "Places run out",
    "packages_stream": {
      "title": "Training takes place:",
      "from": "from",
      "to": "to"
    },
    "is_free": "Free",
    "currency": "RUB",
    "cost_from": "From ",
    "by_digital_promocode": "Taking into account the personal discount"
  },
  "ru": {
    "select_button": "Выбрать",
    "no_places": "Места закончились",
    "packages_stream": {
      "title": "Обучение проходит:",
      "from": "с",
      "to": "по"
    },
    "is_free": "Бесплатно",
    "currency": "руб",
    "cost_from": "От ",
    "by_digital_promocode": "С учетом персональной скидки"
  }
}
</i18n>
