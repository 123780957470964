<template>
  <div class="form-wrapper">
    <form
      class="form"
      :action="url"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <div class="form-row">
        <form-input
          id="theme"
          v-model="form.topic"
          tag="textarea"
          rows="2"
          :label="$t('theme') + ' *'"
          class="form-input_dark"
          :error="$v.form.topic.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.topic.$error && !$v.form.topic.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="email"
          v-model="form.email"
          :label="$t('email') + ' *'"
          class="form-input_dark"
          type="email"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
          @blur="$v.form.email.$touch()"
        />
      </div>
      <div class="form-row">
        <form-input
          id="fio"
          v-model="form.fio"
          :label="$t('full_name') + ' *'"
          class="form-input_dark"
          :error="$v.form.fio.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.fio.$error && !$v.form.fio.required
            }
          ]"
        />
      </div>
      <div class="root-form__button">
        <InvisibleRecaptcha
          v-if="reCaptchaUse && reCaptchaKey"
          :sitekey="reCaptchaKey"
          :validate="reCaptchaLoaded"
          :callback="reCaptchaHandler"
          class="button button_medium button_light"
          type="submit"
          :disabled="reCaptchaIsLoaded"
        >
          {{ $t("topic_suggest") }}
        </InvisibleRecaptcha>
        <button v-else type="submit" class="button button_medium button_light">
          {{ $t("topic_suggest") }}
        </button>
      </div>
    </form>

    <notify-popup popup="successPropposalPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__title">
          {{ $t("topic_suggest") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("success_left") }}
            <br />
            {{ $t("success_right") }}
          </p>
        </div>
        <div class="notify-popup__buttons _g-text-center">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('successPropposalPopup')"
          >
            {{ $t("close_window") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
  name: "EventProposalForm",
  components: { InvisibleRecaptcha },
  props: {
    url: {
      type: String,
      default: ""
    },
    reCaptchaKey: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        topic: "",
        fio: "",
        email: ""
      },
      reCaptchaToken: "",
      reCaptchaUse: false,
      reCaptchaIsLoaded: false
    };
  },
  validations: {
    form: {
      topic: {
        required
      },
      fio: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  mounted() {
    this.checkRecaptchaStorage();
  },
  methods: {
    checkRecaptchaStorage() {
      if (localStorage.reCaptchaUse) {
        this.reCaptchaUse = localStorage.reCaptchaUse;
      }
    },
    reCaptchaHandler(token) {
      this.reCaptchaToken = token;
      this.onSubmit();
      this.reCaptchaLoaded();
    },
    reCaptchaLoaded() {
      this.reCaptchaIsLoaded = !this.reCaptchaIsLoaded;
    },
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (this.validate()) {
        const options = {
          url: this.url,
          method: "POST",
          headers: { "content-type": "multipart/form-data" },
          data: this.form
        };
        const response = await axios(options);

        if (response.data) {
          if (response.data.success) {
            this.form.resend = true;
            this.$modal.show("successPropposalPopup");
          }

          // Если введено более 3 раз включаем капчу и запоминаем
          if (response.data.countFill > 3) {
            this.reCaptchaUse = true;
            localStorage.reCaptchaUse = this.reCaptchaUse;
          }
        }
      }
    },
    onReset(e) {
      e.preventDefault();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "theme": "Suggest the topic of the event and the reasons for holding",
    "email": "Enter your e-mail",
    "full_name": "Yor full name",
    "topic_suggest": "Suggest a topic",
    "success_left": "If necessary, we will contact you.",
    "success_right": "Suggest a topic",
    "close_window": "Close a window"
  },
  "ru": {
    "theme": "Предложите тему мероприятия и причины проведения",
    "email": "Введите ваш e-mail",
    "full_name": "Ваше ФИО",
    "topic_suggest": "Предложить тему",
    "success_left": "Спасибо! Ваше предложение о теме мероприятия принято.",
    "success_right": "При необходимости мы с Вами свяжемся.",
    "close_window": "Закрыть окно"
  }
}
</i18n>
