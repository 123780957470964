<template>
  <b-form @submit="onSubmit" @reset="onReset">
    <b-button type="submit" variant="primary">Submit</b-button>
    <b-button type="reset" variant="danger">Reset</b-button>
  </b-form>
</template>

<script>
export default {
  name: "SearchForm",
  methods: {
    onSubmit() {
      //
    },
    onReset() {
      //
    }
  }
};
</script>
