<template>
  <modal
    :name="popup"
    width="100%"
    height="auto"
    :scrollable="true"
    :reset="true"
    :shift-y="0.1"
    class="leaf-modal"
    @opened="opened"
    @closed="closed"
  >
    <div
      ref="leafModalSlider"
      class="leaf-modal-slider"
      :class="{ 'is-ready': isSlideReady }"
    >
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <slot />
        </div>
      </div>
      <div class="leaf-modal-slider__control">
        <div class="leaf-modal-slider__prev">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38 26"
          >
            <path
              d="M12.411 25.609c.518.521 1.38.521 1.915 0a1.29 1.29 0 000-1.845l-9.71-9.449h32.043c.747-.001 1.341-.579 1.341-1.306a1.33 1.33 0 00-1.34-1.323H4.615l9.71-9.431a1.312 1.312 0 000-1.864c-.536-.521-1.398-.521-1.915 0L.401 12.077a1.26 1.26 0 000 1.845l12.01 11.687z"
            />
          </svg>
        </div>
        <div class="leaf-modal-slider__next">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38 26"
          >
            <path
              d="M25.589.391c-.518-.521-1.38-.521-1.915 0a1.29 1.29 0 000 1.845l9.71 9.449H1.341C.594 11.686 0 12.264 0 12.99a1.33 1.33 0 001.34 1.323h32.044l-9.71 9.431a1.312 1.312 0 000 1.864c.535.521 1.398.521 1.915 0l12.01-11.686a1.26 1.26 0 000-1.845L25.588.391z"
            />
          </svg>
        </div>
        <div class="leaf-modal-close" @click="$modal.hide(popup)">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
          >
            <path
              d="M1.116 25.116a1.25 1.25 0 001.768 1.768l-1.768-1.768zM26.884 2.884a1.25 1.25 0 00-1.768-1.768l1.768 1.768zm-1.768 24a1.25 1.25 0 001.768-1.768l-1.768 1.768zM2.884 1.116a1.25 1.25 0 10-1.768 1.768l1.768-1.768zm0 25.768l12-12-1.768-1.768-12 12 1.768 1.768zm12-12l12-12-1.768-1.768-12 12 1.768 1.768zm-1.768 0l12 12 1.768-1.768-12-12-1.768 1.768zm1.768-1.768l-12-12-1.768 1.768 12 12 1.768-1.768z"
            />
          </svg>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Swiper from "swiper";

export default {
  name: "LeafModal",
  props: {
    popup: { type: String, default: "" },
    activeSlide: { type: Number, default: 0 }
  },
  data() {
    return {
      slider: null,
      swiper: undefined,
      isSlideReady: false,
      shown: false
    };
  },
  methods: {
    opened() {
      this.swiperInit();

      if (this.activeSlide === 0) {
        this.isSlideReady = true;
      } else {
        this.swiper.slideTo(this.activeSlide, 0);
      }
    },
    closed() {
      this.isSlideReady = false;
    },
    swiperInit() {
      this.swiper = new Swiper(".leaf-modal-slider .swiper-container", {
        centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 10,
        simulateTouch: false,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        slideClass: "leaf-modal-slider__slide",
        navigation: {
          prevEl: ".leaf-modal-slider__prev",
          nextEl: ".leaf-modal-slider__next"
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 30
          }
        },
        on: {
          touchStart: function() {
            this.$el[0].parentNode.classList.add("_dragged");
          },
          slideChange: function() {
            this.$el[0].parentNode.classList.add("_dragged");
          },
          slideChangeTransitionEnd: function() {
            this.$el[0].parentNode.classList.remove("_dragged");
          }
        }
      });

      this.swiper.on("slideChange", () => {
        this.isSlideReady = true;
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".leaf-modal";

#{$b} {
  .vm--overlay {
    background: rgba(19, 19, 19, 0.9);
    backdrop-filter: blur(4px);

    @include md-desktop-only {
      backdrop-filter: blur(4px * $zoom);
    }
  }
  .vm--modal {
    background: transparent;
  }
  &-close {
    cursor: pointer;
    display: inline-block;
    width: 26px;
    height: 26px;
    color: $black-true;
    transition: 0.2s ease;
    transition-property: color;

    @include md-desktop-only {
      width: 26px * $zoom;
      height: 26px * $zoom;
    }
    @include mobile {
      width: 14px;
      height: 14px;
    }
    &:hover,
    &:focus {
      color: $color-base;
    }
    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }
  &-slider {
    position: relative;
    width: 1375px;
    max-width: 100%;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s ease;
    transition-property: opacity, visibility;

    &.is-ready {
      opacity: 1;
      visibility: visible;
    }

    @include md-desktop-only {
      width: 1375px * $zoom;
    }
    @include tablet {
      width: 100%;
      margin: 30px auto;
    }

    &__slide {
      height: auto;

      @include tablet {
        width: calc(100% - 40px);
      }
    }
    &__control {
      position: absolute;
      top: 80px;
      right: 80px;
      z-index: 1;
      display: flex;
      align-items: center;

      @include md-desktop-only {
        top: 80px * $zoom;
        right: 80px * $zoom;
      }
      @include low-desktop {
        top: 50px;
        right: 30px;
      }
      @include tablet {
        top: 50px;
        right: 50px;
      }
      @include mobile {
        top: 15px;
        right: 15px;
      }
      #{$b}-slider._dragged & {
        opacity: 0;
      }
      #{$b}-close {
        margin-left: 80px;

        @include md-desktop-only {
          margin-left: 80px * $zoom;
        }
        @include low-desktop {
          margin-left: 30px;
        }
        @include tablet {
          margin-left: 0;
        }
      }
    }
    &__prev,
    &__next {
      outline: 0;
      width: 38px;
      height: 26px;
      color: $black-true;
      transition: 0.2s ease;
      transition-property: color;

      @include md-desktop-only {
        width: 38px * $zoom;
        height: 26px * $zoom;
      }
      @include tablet {
        display: none !important;
      }
      &:not(:first-child) {
        margin-left: 28px;

        @include md-desktop-only {
          margin-left: 28px * $zoom;
        }
        @include low-desktop {
          margin-left: 20px;
        }
      }
      &:not(.swiper-button-disabled) {
        cursor: pointer;

        &:hover {
          color: $color-base;
        }
      }
      svg {
        width: 100%;
        height: 100%;

        path {
          fill: currentColor;
        }
      }
      &.swiper-button-disabled {
        opacity: 0.35;
      }
    }
  }
  &__item {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 80px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $white-true;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include md-desktop-only {
      padding: 80px * $zoom;
      border-radius: 8px * $zoom;
      box-shadow: 0px 4px * $zoom 4px * $zoom rgba(0, 0, 0, 0.25);
    }
    @include low-desktop {
      padding: 50px 30px;
    }
    @include tablet {
      padding: 50px 20px;
    }
    @include mobile {
      padding: 36px 20px;
      flex-direction: column;
    }
    &__media {
      width: 280px;

      @include md-desktop-only {
        width: 280px * $zoom;
      }
      @include tablet {
        width: 240px;
      }
      @include mobile {
        order: 1;
        width: 100%;
      }
    }
    &__body {
      width: calc(100% - 280px);
      padding-left: 60px;

      @include md-desktop-only {
        width: calc(100% - #{280px * $zoom});
        padding-left: 60px * $zoom;
      }
      @include low-desktop {
        padding-left: 30px;
      }
      @include tablet {
        width: calc(100% - 240px);
      }
      @include mobile {
        order: 0;
        width: 100%;
        padding-left: 0;

        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }
    }
    &__footer {
      width: 100%;
      margin-top: 60px;

      @include md-desktop-only {
        margin-top: 60px * $zoom;
      }
      @include mobile {
        order: 3;
      }
    }
    &__thumb {
      width: 100%;
      height: 360px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
      margin-bottom: 50px;

      @include md-desktop-only {
        height: 360px * $zoom;
        border-radius: 8px * $zoom;
        margin-bottom: 50px * $zoom;
      }

      @include low-desktop {
        height: 270px;
      }

      @include tablet {
        height: 306px;
      }

      @include mobile {
        width: 240px;
        margin-bottom: 20px;
      }
    }
    &__date {
      display: block;
      @include font(18px, 1.78, 600);
      color: $color-bg-dark;
      margin: 0 0 10px;

      @include md-desktop-only {
        font-size: 18px * $zoom;
        margin: 0 0 10px * $zoom;
      }
    }
    &__place {
      display: flex;
      @include font(16px, 1.5);
      color: $color-bg-dark;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }
      &__name {
        &:not(:first-child) {
          position: relative;
          padding-left: 9px;
          margin-left: 9px;

          &:before {
            content: "";
            position: absolute;
            top: 7px;
            left: 0;
            width: 1px;
            height: 12px;
            background-color: $border-gray;
          }
          @include md-desktop-only {
            padding-left: 9px * $zoom;
            margin-left: 9px * $zoom;

            &:before {
              top: 7px * $zoom;
              height: 12px * $zoom;
            }
          }
        }
      }
    }
    &__school {
      display: block;
      margin: 15px 0;
    }
    &__email {
      margin-bottom: 40px;

      @include md-desktop-only {
        margin-bottom: 40px * $zoom;
      }
      &__label {
        display: block;
        @include font(16px, 1.5);
        color: $color-text-base;
        margin-bottom: 15px;

        @include md-desktop-only {
          font-size: 16px * $zoom;
          margin-bottom: 15px * $zoom;
        }
      }
      &__link {
        display: block;
        text-decoration: none;
        @include font(18px, 1.2, 600);
        color: $color-bg-dark;

        @include md-desktop-only {
          font-size: 18px * $zoom;
        }
      }
    }
    &__head {
      display: block;
      margin-top: -15px;
      margin-bottom: 32px;
      padding-right: 220px;

      &:after {
        content: "";
        display: block;
        width: 40px;
        height: 3px;
        background: $color-base;
        margin-top: 35px;
      }
      @include md-desktop-only {
        margin-top: -15px * $zoom;
        margin-bottom: 32px * $zoom;
        padding-right: 220px * $zoom;

        &:after {
          width: 40px * $zoom;
          height: 3px * $zoom;
          margin-top: 35px * $zoom;
        }
      }
      @include low-desktop {
        padding-right: 160px;
      }
      @include tablet {
        padding-right: 30px;
      }
      @include mobile {
        margin-top: -5px;
        margin-bottom: 24px;
        padding-right: 0;

        &:after {
          margin-top: 24px;
        }
      }
    }
    &__title {
      display: block;
      @include font(36px, 1.5, 600);
      color: $color-bg-dark;
      margin-bottom: 10px;

      @include md-desktop-only {
        font-size: 36px * $zoom;
        margin-bottom: 10px * $zoom;
      }
      @include mobile {
        @include font(20px, 1.4);
      }
    }
    &__subtitle {
      @include font(16px, 1.5);
      color: $color-bg-dark;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }
    }
    &__tags {
      &:not(:first-child) {
        margin-top: 25px;
      }
      &:not(:last-child) {
        margin-bottom: 25px;
      }

      @include md-desktop-only {
        &:not(:first-child) {
          margin-top: 25px * $zoom;
        }
        &:not(:last-child) {
          margin-bottom: 25px * $zoom;
        }
      }
      > li {
        display: inline-block;
        vertical-align: top;

        &:not(:first-child) {
          margin-left: 16px;

          @include md-desktop-only {
            margin-left: 16px * $zoom;
          }
        }
      }
      &__item {
        display: block;
        background-color: $color-base;
        color: $white-true;
        padding: 8px 15px;
        font-size: 12px;
        line-height: 15px;
        border-radius: 4px;

        &._rate {
          background-color: transparent;
          padding: 6px 0;
        }
        @include md-desktop-only {
          padding: 8px * $zoom 15px * $zoom;
          font-size: 12px * $zoom;
          line-height: 15px * $zoom;
          border-radius: 4px * $zoom;

          &._rate {
            padding: 6px * $zoom 0;
          }
        }
      }
    }
    &__text {
      max-width: 802px;
      @include font(18px, 1.56);
      font-weight: normal;
      color: $black-true;

      @include md-desktop-only {
        max-width: 802px * $zoom;
        font-size: 18px * $zoom;
      }

      p {
        @include font(inherit, inherit);

        &:not(:last-child) {
          margin-bottom: 20px;

          @include md-desktop-only {
            margin-bottom: 20px * $zoom;
          }
        }
      }

      h2,
      .h2,
      h3,
      .h3,
      h4,
      .h4 {
        margin: 0 0 20px;

        @include md-desktop-only {
          margin: 0 0 20px * $zoom;
        }

        &:not(:first-child) {
          margin-top: 40px;

          @include md-desktop-only {
            margin-top: 40px * $zoom;
          }
        }
      }

      ul,
      ol {
        &:not(:first-child) {
          margin-top: 20px;

          @include md-desktop-only {
            margin-top: 20px * $zoom;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @include md-desktop-only {
            margin-bottom: 20px * $zoom;
          }
        }

        li {
          &:not(:last-child) {
            margin-bottom: 16px;

            @include md-desktop-only {
              margin-bottom: 16px * $zoom;
            }
          }
        }
      }

      &__more {
        display: inline-block;
        position: relative;
        padding-right: 26px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.25;
        text-transform: uppercase;
        margin: 32px 0 0;
        transition: color 0.3s ease;

        @include md-desktop-only {
          font-size: 16px * $zoom;
          padding-right: 26px * $zoom;
        }

        &:active {
          color: #ccc;

          &:after {
            opacity: 0.2;
          }
        }

        &:after {
          content: "";
          background-image: url("/local/templates/main/src/assets/img/icons/arrow-down-lg.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 0;
          width: 13px;
          height: 19px;
          margin-top: -9px;
          transition: opacity 0.3s ease;

          @include md-desktop-only {
            width: 13px * $zoom;
            height: 19px * $zoom;
          }
        }

        &._shown {
          &:after {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
  &__title-line {
    display: flex;
    align-items: center;
    @include font(14px, 1.29);
    text-transform: uppercase;
    color: $color-text-light-2;
    margin-bottom: 30px;

    &:after {
      content: "";
      display: block;
      flex: 1 1 auto;
      background-color: $color-line;
      height: 1px;
      margin-left: 17px;
    }
    @include md-desktop-only {
      font-size: 14px * $zoom;
      margin-bottom: 30px * $zoom;

      &:after {
        margin-left: 17px * $zoom;
      }
    }
  }
  &__social {
    @include tablet {
      &:not(#{$b}-social_horizontal) {
        margin-top: 20px;
      }
    }
    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;

      & > li {
        &:not(:last-child) {
          margin-right: 20px;

          @include md-desktop-only {
            margin-right: 20px * $zoom;
          }
        }
        & > a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          background-color: $drop-hover;
          color: $black-true;
          border-radius: 50px;

          @include md-desktop-only {
            width: 35px * $zoom;
            height: 35px * $zoom;
            border-radius: 50px * $zoom;
          }
          svg {
            width: 100%;
            height: 100%;
            max-height: 60%;
            max-width: 60%;

            path {
              fill: currentColor;
            }
          }
        }
      }
    }
  }
  &__news {
    max-width: 700px;

    @include md-desktop-only {
      max-width: 700px * $zoom;
    }
    &__title {
      display: block;
      @include font(18px, 1.56, 600);
      color: $black-true;
      margin-bottom: 15px;

      @include md-desktop-only {
        font-size: 18px * $zoom;
        margin-bottom: 15px * $zoom;
      }
    }
    &-item {
      &__title {
        display: block;
        text-decoration: none;
        @include font(16px, 1.5);
        color: $black-true;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
        @include md-desktop-only {
          font-size: 16px * $zoom;
        }
        p {
          @include font(inherit, inherit);
        }
      }
      &__date {
        margin-top: 17px;
        @include font(16px, 1.5);
        color: $color-text-dark-3;

        @include md-desktop-only {
          margin-top: 17px * $zoom;
          font-size: 16px * $zoom;
        }
      }
    }
  }
}
</style>
