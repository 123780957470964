<template>
  <nav v-click-outside="hideDropdown" class="anchors-nav">
    <div
      v-if="breakpoint.isMobile"
      class="anchors-nav__current"
      @click="toggleDropdown"
    >
      <span class="anchors-nav__current__text">{{ currentLink.text }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.4 11.4">
        <path
          d="M5.7 9.1L.2 3.7c-.3-.3-.3-.7 0-1s.7-.3 1 0l4.5 4.5 4.5-4.5c.3-.3.7-.3 1 0s.3.7 0 1L5.7 9.1z"
        />
      </svg>
    </div>
    <transition name="slide-up">
      <div
        v-show="!breakpoint.isMobile || isOpen"
        class="anchors-nav__dropdown"
      >
        <ul
          class="anchors-nav__list anchors-nav__list_col-md-2 anchors-nav__list_col-lg-3"
        >
          <li v-for="(item, i) in items" :key="i">
            <a
              :href="item.link"
              class="anchors-nav__list__item"
              @click="select(i, item.link)"
            >
              {{ item.text }}
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "AnchorsNav",
  directives: {
    ClickOutside
  },
  props: {
    links: {
      type: Array,
      default: () => [
        {
          link: "#",
          text: "Текст ссылки"
        }
      ]
    },
    setLink: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      items: [],
      isOpen: false,
      breakpoint: this.getBreakpoint(),
      currentLink: ""
    };
  },
  mounted() {
    this.items = this.links;
    this.currentLink = this.items[this.setLink];
    window.addEventListener("resize", this.updateBreakpoint);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768
      };
    },
    updateBreakpoint() {
      const { isMobile } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
    },
    select(index, url) {
      if (url.length) {
        if (url.indexOf("#") != -1) {
          this.$scrollTo(url, 200, { offset: -70 });
        }
      }
      if (this.breakpoint.isMobile) {
        this.currentLink = this.items[index];
        this.hideDropdown();
      }
    },
    toggleDropdown() {
      if (this.breakpoint.isMobile) {
        this.isOpen = !this.isOpen;
      }
    },
    hideDropdown() {
      if (this.breakpoint.isMobile) {
        this.isOpen = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";
$b: ".anchors-nav";

#{$b} {
  position: relative;

  &__current {
    position: relative;
    background: $white-true;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px 36px 16px 20px;

    &__text {
      font-size: 16px;
      line-height: 1.25;
      color: $color-bg-dark;
    }
    svg {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 10px;
      height: 10px;

      path {
        fill: currentColor;
      }
    }
  }

  &__dropdown {
    @include mobile {
      background: $white-true;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 16px 36px 16px 20px;
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      left: 0;
      z-index: 10;
    }
  }

  &__list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    line-height: 1.62;

    &_col-md-2 {
      @include low-desktop-min {
        padding-left: 50px;
        column-gap: 100px;
      }
      @include md-desktop-only {
        padding-left: 50px * $zoom;
        column-gap: 100px * $zoom;
      }
      @include mobile-min {
        border-left: 1px solid rgba($black-true, 0.1);
        column-count: 2;
        column-gap: 80px;
        padding-left: 40px;
      }
    }

    &_col-lg-3 {
      column-rule: 1px solid rgba($black-true, 0.1);

      @include md-desktop-only {
        column-gap: 100px * $zoom;
      }
      @include low-desktop-min {
        column-gap: 100px;
      }
      @include tablet-min {
        column-count: 3;
        column-gap: 80px;
      }
    }

    & > li {
      break-inside: avoid;
      padding: 14px 0;

      @include md-desktop-only {
        padding: 14px * $zoom 0;
      }
    }

    &__item {
      display: block;
      text-decoration: none;
      color: $color-text-dark-2;

      &._active {
        color: $color-bg-dark;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $color-bg-dark;
      }
    }
  }

  .slide-up-enter-active {
    transition: 0.3s ease;
  }
  .slide-up-leave-active {
    transition: 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-up-enter,
  .slide-up-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>
