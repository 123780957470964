<template>
  <div
    v-click-outside="hide"
    class="select"
    :class="{ select_opened: showDropdown }"
    @click.prevent="toggleDropdown"
  >
    <label class="select__label" :for="id">{{ placeholder }}</label>
    <input
      :id="id"
      class="select__input"
      type="text"
      :placeholder="placeholder"
      :value="valueInput.text"
      readonly
    />
    <transition name="slide">
      <ul v-show="showDropdown" class="select__options">
        <li
          v-for="(option, i) in options"
          :key="i"
          class="select__option"
          @click.prevent="updateInput(option)"
        >
          {{ options[i].text }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "VueSelect",
  directives: {
    ClickOutside
  },
  props: {
    id: {
      default: "",
      type: String
    },
    placeholder: {
      default: "",
      type: String
    },
    eventTag: {
      type: String,
      required: true
    },
    valueInput: {
      default: () => {
        return {
          text: "",
          id: 0,
          checked: 0
        };
      },
      type: Object
    },
    selectOptions: {
      type: Array,
      default: () => {
        return [
          {
            id: 0,
            text: "Text"
          }
        ];
      }
    }
  },
  data() {
    return {
      options: this.selectOptions,
      showDropdown: false
    };
  },
  methods: {
    updateInput(value) {
      this.$emit(this.eventTag, value);
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hide() {
      this.showDropdown = false;
    }
  }
};
</script>
