<template>
  <div class="application-user">
    <div class="application-user__head">
      <div v-if="user.name && isCollapsed" class="application-user__name">
        {{ user.surname }}
        {{ user.name }}
        {{ user.middleName }}
      </div>
      <ul class="application-user__actions">
        <li v-if="user.name">
          <div
            class="application-user__actions-item"
            @click.prevent="toggleCollapse"
          >
            <template v-if="!isCollapsed">
              <svg viewBox="0 0 16 14">
                <path
                  d="M0 10h16V8H0v2zm0 4h16v-2H0v2zm0-8h16V4H0v2zm0-6v2h16V0H0z"
                />
              </svg>
            </template>
            <template v-else>
              <svg viewBox="0 0 19 13.3">
                <path
                  d="M0 13.3h19v-2.2H0v2.2zm18-9.6H1c-.6 0-1 .3-1 .7v4.4c0 .4.4.7 1 .7h17c.5 0 1-.3 1-.7V4.4c0-.4-.5-.7-1-.7zM0 0v2.2h19V0H0z"
                />
              </svg>
            </template>
          </div>
        </li>
        <li v-if="isMultipleUsers">
          <div
            class="application-user__actions-item"
            @click.prevent="removeUser"
          >
            <svg viewBox="0 0 15 16.5">
              <path
                d="M1.1 14.7c0 1 1 1.8 2.1 1.8h8.6c1.2 0 2.1-.8 2.1-1.8v-11H1.1v11zm2.6-6.5l1.5-1.3 2.3 1.9 2.3-1.9 1.5 1.3L9 10.1l2.3 1.9-1.5 1.3-2.3-1.9-2.3 1.9L3.7 12 6 10.1 3.7 8.2zM11.2.9l-1-.9H4.8L3.7.9H0v1.8h15V.9h-3.8z"
              />
            </svg>
          </div>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <div v-show="!isCollapsed" class="application-user__body">
        <div class="form-grid form-grid_large">
          <div class="form-grid__col-md-1-2">
            <div class="form-row">
              <form-input
                :id="'userSurname' + index"
                v-model="user.surname"
                :label="$t('form.surname') + ' *'"
                is-capitalize
                :error="$v.user.surname.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.surname.$error && !$v.user.surname.required
                  }
                ]"
              />
            </div>
            <div class="form-row">
              <form-input
                :id="'userName' + index"
                v-model="user.name"
                :label="$t('form.name') + ' *'"
                is-capitalize
                :error="$v.user.name.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.name.$error && !$v.user.name.required
                  }
                ]"
              />
            </div>
            <div class="form-row">
              <form-input
                :id="'userMiddleName' + index"
                v-model="user.middleName"
                :label="$t('form.middleName') + ' *'"
                is-capitalize
                :error="$v.user.middleName.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state:
                      $v.user.middleName.$error && !$v.user.middleName.required
                  }
                ]"
              />
            </div>
            <template v-if="isShowBigForm">
              <div class="form-row">
                <custom-select
                  v-model="user.training"
                  :options="trainingOptions"
                  :placeholder="$t('form.training') + ' *'"
                  prop-name-text="text"
                  prop-name-value="id"
                  is-flat-input
                />
                <div
                  v-if="$v.user.training.$error && !$v.user.training.required"
                  class="form-input-message is-error"
                >
                  {{ $t("error.required_training") }}
                </div>
              </div>
              <div class="form-row">
                <form-input
                  v-model="user.country"
                  is-dadata
                  dadata-type="country"
                  :dadata-token="dadataApiKey"
                  :label="$t('form.country') + ' *'"
                  :error="$v.user.country.$error"
                  :messages="[
                    {
                      type: 'error',
                      i18: 'error.required',
                      state: $v.user.country.$error && !$v.user.country.required
                    }
                  ]"
                />
              </div>
            </template>
            <div class="form-row">
              <form-input
                :id="'userCompany' + index"
                v-model="user.company"
                :label="$t('form.company')"
                :label-tooltip="$t('tooltip.company')"
              />
            </div>
            <template v-if="isShowIndustry">
              <div class="form-row">
                <custom-select
                  v-model="user.industry"
                  :options="industryOptions"
                  :placeholder="$t('form.industry')"
                  prop-name-text="text"
                  prop-name-value="id"
                  is-flat-input
                />
              </div>
              <div v-if="isShowIndustryOther" class="form-row">
                <form-input
                  :id="'userIndustryOther' + index"
                  v-model="user.industryOther"
                  :label="$t('form.industry_other') + ' *'"
                  :error="$v.user.industryOther.$error"
                  :messages="[
                    {
                      type: 'error',
                      i18: 'error.required',
                      state:
                        $v.user.industryOther.$error &&
                        !$v.user.industryOther.required
                    }
                  ]"
                />
              </div>
              <div v-if="isShowIndustryEducation" class="form-row">
                <custom-select
                  :id="'userIndustryEducation' + index"
                  v-model="user.industryEducation"
                  :options="educationOptions"
                  :placeholder="$t('form.industry_education') + ' *'"
                  prop-name-text="text"
                  prop-name-value="id"
                  is-flat-input
                />
                <div
                  v-if="
                    $v.user.industryEducation.$error &&
                      !$v.user.industryEducation.required
                  "
                  class="form-input-message is-error"
                >
                  {{ $t("error.required_education") }}
                </div>
              </div>
              <div v-if="isShowIndustryEducationOther" class="form-row">
                <form-input
                  :id="'userIndustryOther' + index"
                  v-model="user.industryEducationOther"
                  :label="$t('form.industry_other') + ' *'"
                  :error="$v.user.industryEducationOther.$error"
                  :messages="[
                    {
                      type: 'error',
                      i18: 'error.required',
                      state:
                        $v.user.industryEducationOther.$error &&
                        !$v.user.industryEducationOther.required
                    }
                  ]"
                />
              </div>
            </template>
            <template v-if="isIntramural">
              <div class="form-row">
                <h5 class="h5">{{ $t("type_arrival.title") }}</h5>
                <ul class="application-users__radio-list">
                  <li>
                    <div class="radio-input">
                      <input
                        :id="'typeArrivalCorporate' + index"
                        v-model="user.typeArrival"
                        class="radio-input__input"
                        type="radio"
                        :name="'type_arrival' + index"
                        value="Корпоративный трансфер"
                      />
                      <label
                        class="radio-input__text"
                        :for="'typeArrivalCorporate' + index"
                      >
                        {{ $t("type_arrival.corporate") }}
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="radio-input">
                      <input
                        :id="'typeArrivalPersonal' + index"
                        v-model="user.typeArrival"
                        class="radio-input__input"
                        type="radio"
                        :name="'type_arrival' + index"
                        value="Личный автомобиль"
                      />
                      <label
                        class="radio-input__text"
                        :for="'typeArrivalPersonal' + index"
                      >
                        {{ $t("type_arrival.personal") }}
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="radio-input">
                      <input
                        :id="'typeArrivalTaxi' + index"
                        v-model="user.typeArrival"
                        class="radio-input__input"
                        type="radio"
                        :name="'type_arrival' + index"
                        value="Такси"
                      />
                      <label
                        class="radio-input__text"
                        :for="'typeArrivalTaxi' + index"
                      >
                        {{ $t("type_arrival.taxi") }}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <template v-if="user.typeArrival === 'Личный автомобиль'">
                <div class="form-row">
                  <form-input
                    :id="'carNumber' + index"
                    v-model="user.carNumber"
                    :label="$t('form.carNumber') + ' *'"
                    :error="$v.user.carNumber.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.user.carNumber.$error &&
                          !$v.user.carNumber.required
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    :id="'carBrand' + index"
                    v-model="user.carBrand"
                    :label="$t('form.carBrand') + ' *'"
                    :error="$v.user.carBrand.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.user.carBrand.$error && !$v.user.carBrand.required
                      }
                    ]"
                  />
                </div>
              </template>
            </template>
          </div>
          <div class="form-grid__col-md-1-2">
            <div class="form-row">
              <form-input
                :id="'userEmail' + index"
                v-model="user.email"
                :label="$t('form.email') + ' *'"
                type="email"
                :readonly="index === 0 && !isAnotherUser"
                :error="$v.user.email.$error || !$v.user.email.email"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.email.invalid',
                    state: !$v.user.email.email
                  },
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.email.$error && !$v.user.email.required
                  }
                ]"
                @change="$v.user.email.$touch()"
              />
            </div>
            <div class="form-row">
              <form-input
                :id="'userPhone' + index"
                v-model="user.phone"
                :label="`${$t('form.phone')} ${isPhoneRequired ? ' *' : ''}`"
                type="tel"
                :error="$v.user.phone.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.phone.$error && !$v.user.phone.required
                  }
                ]"
              />
            </div>
            <div class="form-row">
              <form-input
                :id="'userPosition' + index"
                v-model="user.position"
                :label="
                  `${$t('form.position')} ${isPositionRequired ? ' *' : ''}`
                "
                :error="$v.user.position.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.position.$error && !$v.user.position.required
                  }
                ]"
              />
            </div>
            <div class="form-row">
              <form-input
                :id="'userCity' + index"
                v-model="user.city"
                is-dadata
                dadata-type="address"
                :dadata-token="dadataApiKey"
                :label="$t('form.city') + ' *'"
                :error="$v.user.city.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.city.$error && !$v.user.city.required
                  }
                ]"
              />
            </div>
            <div v-if="isShowSnils" class="form-row">
              <form-input
                :id="'userSnils' + index"
                v-model="user.snils"
                :label="$t('form.snils') + ' *'"
                :label-tooltip="$t('tooltip.snils')"
                :error="$v.user.snils.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.user.snils.$error && !$v.user.snils.required
                  },
                  {
                    type: 'error',
                    i18: 'error.snils.numeric',
                    state: !$v.user.snils.numeric
                  },
                  {
                    type: 'error',
                    i18: 'error.snils.checkSum',
                    state: !$v.user.snils.checkSum
                  },
                  {
                    type: 'error',
                    min: $v.user.snils.$params.minLength.min,
                    state: !$v.user.snils.minLength
                  },
                  {
                    type: 'error',
                    max: $v.user.snils.$params.maxLength.max,
                    state: !$v.user.snils.maxLength
                  }
                ]"
              />
            </div>
            <div v-if="isShowEducation" class="form-row">
              <tooltip
                content-class="is-tooltip-small"
                placement="bottom-center"
                is-show-arrow
              >
                <div slot="trigger">
                  <file-uploader
                    :id="'education-' + index"
                    :title="$t('form.education.title')"
                    :description="$t('form.education.description')"
                    extensions="pdf"
                    accept=".pdf"
                    :file-url="user.education"
                    is-show-confirm-status
                    :is-file-confirmed="user.educationConfirm"
                    :size="5"
                    @onAddFile="addFile('education', ...arguments)"
                    @onRemoveFile="removeFile('education', ...arguments)"
                  />
                </div>
                <div slot="content" class="tooltip-content">
                  <p>
                    {{ $t("tooltip.education") }}
                  </p>
                </div>
              </tooltip>
              <div
                v-if="$v.user.education.$error"
                class="form-input-message is-error"
              >
                {{ $t("error.required_file") }}
              </div>
            </div>
            <div v-if="isShowPassport" class="form-row">
              <file-uploader
                :id="'passport-' + index"
                :title="$t('form.passport.title')"
                :description="$t('form.passport.description')"
                extensions="pdf"
                accept=".pdf"
                :file-url="user.passport"
                is-show-confirm-status
                :is-file-confirmed="user.passportConfirm"
                :size="5"
                @onAddFile="addFile('passport', ...arguments)"
                @onRemoveFile="removeFile('passport', ...arguments)"
              />
              <div
                v-if="$v.user.passport.$error"
                class="form-input-message is-error"
              >
                {{ $t("error.required_file") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { checkSum } from "../utils/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: "ApplicationUser",
  props: {
    index: { type: Number, default: 0 },
    initUser: { type: Object, default: () => {} },
    elementCode: { type: String, default: "" },
    isAnotherUser: { type: Boolean, default: false },
    isIntramural: { type: Boolean, default: false },
    isMultipleUsers: { type: Boolean, default: false },
    isShowEducation: { type: Boolean, default: false },
    isShowPassport: { type: Boolean, default: false },
    isShowSnils: { type: Boolean, default: false },
    isShowBigForm: { type: Boolean, default: false },
    dadataApiKey: { type: String, default: "" },
    industryOptions: { type: Array, default: () => [{}] },
    educationOptions: { type: Array, default: () => [{}] },
    trainingOptions: { type: Array, default: () => [] }
  },
  data() {
    return {
      user: this.initUser,
      isCollapsed: false
    };
  },
  computed: {
    snilsRules() {
      let rules;
      if (this.isShowSnils) {
        rules = {
          required,
          numeric,
          checkSum,
          minLength: minLength(11),
          maxLength: maxLength(11)
        };
      } else {
        rules = {};
      }

      return rules;
    },
    isShowIndustry() {
      return (
        this.elementCode === "events" || this.elementCode === "conferences"
      );
    },
    isShowIndustryOther() {
      return this.user.industry === "15";
    },
    isShowIndustryEducation() {
      return this.user.industry === "9";
    },
    isShowIndustryEducationOther() {
      return this.user.industry === "9" && this.user.industryEducation === "33";
    },
    isPhoneRequired() {
      return this.elementCode === "conferences" || this.isShowBigForm;
    },
    isPositionRequired() {
      return this.elementCode === "conferences";
    },
    isArrivalPrivateCar() {
      return this.user.typeArrival === "Личный автомобиль";
    }
  },
  validations() {
    return {
      user: {
        surname: { required },
        name: { required },
        middleName: { required },
        email: { required, email },
        phone: {
          required: requiredIf(() => this.isPhoneRequired)
        },
        position: {
          required: requiredIf(() => this.isPositionRequired)
        },
        city: { required },
        snils: this.snilsRules,
        carNumber: {
          required: requiredIf(() => this.isArrivalPrivateCar)
        },
        carBrand: {
          required: requiredIf(() => this.isArrivalPrivateCar)
        },
        industryOther: {
          required: requiredIf(() => this.isShowIndustryOther)
        },
        industryEducation: {
          required: requiredIf(() => this.isShowIndustryEducation)
        },
        industryEducationOther: {
          required: requiredIf(() => this.isShowIndustryEducationOther)
        },
        training: {
          required: requiredIf(() => this.isShowBigForm)
        },
        country: {
          required: requiredIf(() => this.isShowBigForm)
        },
        education: {
          required: requiredIf(() => this.isShowEducation)
        },
        passport: {
          required: requiredIf(() => this.isShowPassport)
        }
      }
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    removeUser() {
      this.$emit("onRemoveUser", this.index);
    },
    addFile(propName, file) {
      this.user[propName] = file.file;
    },
    removeFile(propName) {
      this.user[propName] = "";
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-user";

#{$b} {
  display: block;
  background-color: $white-true;
  border-radius: 5px;
  padding: 32px 38px;

  @include md-desktop-only {
    border-radius: 5px * $zoom;
    padding: 32px * $zoom 38px * $zoom;
  }

  @include mobile {
    padding: 20px;
  }

  &__head {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__body {
    padding-right: 100px;

    @include md-desktop-only {
      padding-right: 100px * $zoom;
    }

    @include low-desktop {
      padding-right: 0;
    }
  }

  &__name {
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

    @include md-desktop-only {
      font-size: 18px * $zoom;
      line-height: 24px * $zoom;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__actions {
    height: 24px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: auto;

    @include md-desktop-only {
      height: 24px * $zoom;
    }

    & > li {
      &:not(:last-child) {
        margin-right: 24px;

        @include md-desktop-only {
          margin-right: 24px * $zoom;
        }
      }
    }

    &-item {
      @include clickable;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: start;

      @include md-desktop-only {
        width: 16px * $zoom;
        height: 16px * $zoom;
      }

      svg {
        width: 100%;
        height: 100%;
        flex-shrink: 0;

        path {
          fill: currentColor;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "email_invalid": "Invalid e-mail",
    "form": {
      "company": "The name of the company",
      "surname": "Surname",
      "name": "Name",
      "middleName": "Patronymic",
      "email": "E-mail",
      "phone": "Phone number",
      "position": "Position",
      "city": "City",
      "education": {
        "title": "Upload your higher education diploma",
        "description": "PDF (Maximum size 5MB)"
      },
      "passport": {
        "title": "Upload scanned passport (pages 2, 3 and 8)",
        "description": "PDF (Maximum size 5MB)"
      },
      "snils": "Insurance Number of Individual Ledger Account",
      "carNumber": "Car number",
      "carBrand": "Car brand",
      "industry": "Industry",
      "industry_education": "Institution",
      "industry_other": "Other",
      "training": "Education",
      "country": "Citizenship"
    },
    "error": {
      "required_education": "Choose an institution",
      "required_training": "Choose education",
      "required_file": "Upload the file"
    },
    "type_arrival": {
      "title": "How will you get there?",
      "corporate": "Corporate transfer",
      "personal": "Personal car",
      "taxi": "Taxi"
    },
    "tooltip": {
      "snils": "Specify only numbers without spaces",
      "company": "For legal entities",
      "education": "Download the diploma of secondary (professional)/higher education or a certificate from an educational organization of secondary (professional)/higher education about being in the learning process"
    }
  },
  "ru": {
    "email_invalid": "Некорректный e-mail",
    "form": {
      "company": "Название компании",
      "surname": "Фамилия",
      "name": "Имя",
      "middleName": "Отчество",
      "email": "E-mail для связи",
      "phone": "Номер телефона",
      "position": "Должность",
      "city": "Город",
      "education": {
        "title": "Загрузите диплом о высшем образовании",
        "description": "PDF (Максимальный размер 5МБ)"
      },
      "passport": {
        "title": "Загрузите скан паспорта (2, 3 и 8 страницы)",
        "description": "PDF (Максимальный размер 5МБ)"
      },
      "snils": "СНИЛС",
      "carNumber": "Номер авто",
      "carBrand": "Марка авто",
      "industry": "Отрасль",
      "industry_education": "Учреждение",
      "industry_other": "Другое",
      "training": "Образование",
      "country": "Гражданство"
    },
    "error": {
      "required_education": "Выберите учреждение",
      "required_training": "Выберите образование",
      "required_file": "Загрузите файл"
    },
    "type_arrival": {
      "title": "Как будете добираться?",
      "corporate": "Корпоративный трансфер",
      "personal": "Личный автомобиль",
      "taxi": "Такси"
    },
    "tooltip": {
      "snils": "Укажите только цифры без пробела",
      "company": "Для юридических лиц",
      "education": "Загрузить диплом о среднем (профессиональном)/высшем образовании или справку из образовательной организации среднего (профессионального)/высшего образования о нахождении в процессе обучения"
    }
  }
}
</i18n>
