<template>
  <div
    v-waypoint="{ active: autoplayIfVisible, callback: onWaypoint }"
    class="video-player"
    :class="{
      _started: started,
      _error: isError,
      '_no-title': !title,
      'video-player_controls-hide':
        !controls && videoInfo.techOrder === 'html5',
      'video-player_vimeo': videoInfo.techOrder === 'vimeo'
    }"
  >
    <div
      v-touch:tap="playPause"
      class="video-player__container"
      @click="playPause"
    >
      <video
        :id="id"
        ref="videoPlayer"
        class="video-js vjs-default-skin"
        :class="aspect"
        crossorigin="anonymous"
      />
      <div
        v-if="sourceText && started"
        class="video-player__source-text"
      >
        {{ sourceText }}
      </div>
      <div
        v-if="isShowButton && !started && !isError"
        class="video-player__play"
      >
        <svg fill="none" viewBox="0 0 72 72">
          <circle cx="36" cy="36" r="36" fill="#18B346" />
          <path
            fill="#fff"
            d="M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"
          />
        </svg>
      </div>
      <div
        v-if="title.length && !started && !isError"
        class="video-player__bottom"
      >
        <div class="video-player__bottom-icon">
          <svg fill="none" viewBox="0 0 72 72">
            <circle cx="36" cy="36" r="36" fill="#18B346" />
            <path
              fill="#fff"
              d="M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"
            />
          </svg>
        </div>
        <span class="video-player__bottom-text" v-text="title" />
        <span
          v-if="videoLength"
          class="video-player__video-length"
          v-text="videoLength"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "videojs-youtube";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    id: { type: String, default: "" },
    autoplay: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    controls: { type: Boolean, default: false },
    title: { type: String, default: "" },
    src: { type: String, default: "" },
    poster: { type: String, default: "" },
    type: { type: String, default: "" },
    aspect: { type: String, default: "vjs-fluid" },
    width: { type: String, default: "" },
    height: { type: String, default: "" },
    autoplayIfVisible: { type: Boolean, default: false },
    isShowButton: { type: Boolean, default: false },
    videoLength: { type: String, default: "" },
    sourceText: { type: String, default: "" }
  },
  data() {
    return {
      player: null,
      tryingPlay: false,
      paused: true,
      started: false,
      playerOptions: {
        controls: this.controls,
        preload: "auto",
        autoplay: this.autoplay,
        muted: this.muted || this.autoplay,
        language: "ru",
        techOrder: ["html5", "youtube", "vimeo"],
        poster: this.poster,
        width: this.width,
        height: this.height,
        crossOrigin: "anonymous",
        sources: [
          {
            type: this.type,
            src: this.src
          }
        ],
        youtube: {
          ytControls: 2,
          rel: 0
        }
      }
    };
  },
  computed: {
    videoInfo() {
      const url = this.src.match(
        /(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
      );

      let result = {
        techOrder: "",
        type: ""
      };

      if (url) {
        if (url.indexOf("youtube.com") > -1 || url.indexOf("youtu.be") > -1) {
          result.techOrder = "youtube";
          result.type = "video/youtube";
        } else if (url.indexOf("vimeo.com") > -1 || url.indexOf("vimeo") > -1) {
          result.techOrder = "vimeo";
          result.type = "video/vimeo";
        }
      } else {
        result.techOrder = "html5";
        result.type = `video/${this.getVideoFormat(this.src)}`;
      }

      return result;
    },
    isError() {
      return this.tryingPlay && !this.player.hasStarted();
    }
  },
  mounted() {
    this.playerOptions.controls = this.videoInfo.techOrder === "html5";
    this.playerOptions.sources[0].type = this.videoInfo.type;
    this.player = videojs(this.$refs.videoPlayer, this.playerOptions);
    this.player.on("play", this.onPlayerPlay);
    this.player.on("pause", this.onPlayerPause);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    playPause() {
      if (this.paused) {
        this.player.play();
        this.tryingPlay = true;
      } else {
        this.player.pause();
      }
    },
    onPlayerPlay() {
      this.paused = false;

      if (!this.started) {
        this.started = true;
      }
    },
    onPlayerPause() {
      this.paused = true;
    },
    onWaypoint({ going }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.paused) {
          this.player.autoplay("muted");
        }
      }
    },
    getVideoFormat(url) {
      return url.split(".").pop();
    }
  }
};
</script>

<style lang="scss">
@import "~video.js/dist/video-js.css";
@import "../../scss/base/_include.scss";

$b: ".video-player";

#{$b} {
  position: relative;
  background-color: #1e1e1e;
  display: block;
  width: 100%;

  &_controls-hide {
    .vjs-control-bar {
      display: none;
    }
  }

  &_vimeo {
    .video-js {
      &:not(.vjs-has-started):not(.vjs-touch-enabled) {
        .vjs-poster {
          display: block;
        }
      }
    }
  }

  &._error {
    .vjs-poster {
      display: none;
    }
  }

  &._no-title {
    .vjs-poster {
      &:after {
        display: none;
      }
    }
  }

  &__container {
    position: relative;
    height: 100%;
    max-width: 1216px;
    margin: 0 auto;

    #{$b}_narrow & {
      max-width: $container-size-small;
    }

    @include md-desktop-only {
      max-width: 1216px * $zoom;

      #{$b}_narrow & {
        max-width: $container-size-small * $zoom;
      }
    }
  }
  .video-js {
    .vjs-tech {
      outline: 0;
    }
    .vjs-poster {
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 28%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0.7;
        z-index: 1;
      }
    }
    &.vjs-fluid {
      height: 100%;

      .vjs-poster {
        background-size: cover;
      }
    }
  }
  &__play {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8%;

    @include mobile {
      width: 12%;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
      @include full-abs;
    }
  }
  &__bottom {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $white-true;
    position: absolute;
    bottom: 35px;
    left: 38px;
    z-index: 2;

    @include md-desktop-only {
      bottom: 35px * $zoom;
      left: 38px * $zoom;
    }
    @include mobile {
      bottom: 20px;
      left: 20px;
    }
    &-icon {
      min-width: 72px;
      width: 72px;
      height: 72px;
      margin-right: 40px;

      @include md-desktop-only {
        min-width: 72px * $zoom;
        width: 72px * $zoom;
        height: 72px * $zoom;
        margin-right: 40px * $zoom;
      }
      @include mobile {
        min-width: 48px;
        width: 48px;
        height: 48px;
      }
    }
    &-text {
      @include font(24px, 1.67, 700);

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include mobile {
        display: none;
      }
    }
  }
  &__video-length {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    color: inherit;

    &::before {
      content: "";
      display: block;
      height: 40px;
      width: 1px;
      background-color: currentColor;
      margin: 0 24px;
    }
  }
  .vjs-big-play-button {
    display: none !important;
  }

  &__source-text {
    position: absolute;
    bottom: 40px;
    right: 20px;

    @include mobile {
      bottom: 35px;
      right: 10px;
      font-size: 12px;
    }
  }
}
</style>
