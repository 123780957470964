<template>
  <div class="h1 main-title" :data-text="value">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="value"></span>
  </div>
</template>

<script>
export default {
  name: "MainTitle",
  props: {
    value: { type: String, default: "" }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".main-title";

#{$b} {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 50px;

  @include md-desktop-only {
    margin-bottom: 50px * $zoom;
  }

  @include tablet {
    margin-bottom: 32px;
  }

  @include mobile {
    @include font(28px, 40px);
  }

  &_wide {
    display: block;
  }

  &_big {
    @include font(44px, 1.36, 700);
    color: $color-bg-dark;

    @include md-desktop-only {
      font-size: 44px * $zoom;
    }

    @include tablet {
      @include font(36px, 1.5, 700);
    }

    @include mobile {
      @include font(28px, 40px, 600);

      &._social {
        @include font(20px, 28px);
        margin-bottom: 20px;
      }
    }

    > span {
      white-space: nowrap;
      @include tablet {
        white-space: normal;
      }
    }

    &._multiple-rows {
      @include mobile {
        br {
          display: none;
        }
      }
    }
  }

  &_mod {
    margin-bottom: 32px;

    @include md-desktop-only {
      margin-bottom: 32px * $zoom;
    }

    @include mobile {
      margin-bottom: 19px;
    }
  }

  &_small-on-m {
    @include mobile {
      @include font(24px, 36px);
    }
  }

  &_no-shadow {
    &:before {
      display: none;
    }
  }

  &_sm-width {
    @include mobile {
      max-width: 280px;
    }
  }

  ._dark & {
    color: $white-true;
  }

  &_line-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:before {
      content: "";
      display: block;
      flex: 1 1;
      height: 2px;
      background-color: $color-bg-dark;
      margin-right: 5%;

      ._dark & {
        background-color: $white-true;
      }
    }
  }
}
</style>
