<template>
  <form
    v-if="isShown"
    v-click-outside="hideSearch"
    class="header-search"
    :class="{ 'header-search_opened': isOpen }"
    :action="action"
  >
    <div class="header-search__field">
      <div v-show="isOpen" class="header-search__input">
        <input
          ref="searchInput"
          type="text"
          :name="inputName"
          :placeholder="$t('search')"
        />
      </div>

      <div v-if="isOpen" class="header-search__btn" @click="hideSearch">
        <svg width="16" height="16" viewBox="0 0 512.001 512.001">
          <path
            d="M294.111 256.001L504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z"
          />
        </svg>
      </div>

      <div v-else class="header-search__btn" @click="showSearch">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M15.8041 14.8637L11.9576 11.0171C12.911 9.85469 13.4857 8.36571 13.4857 6.74286C13.4857 3.02041 10.4653 0 6.74286 0C3.01714 0 0 3.02041 0 6.74286C0 10.4653 3.01714 13.4857 6.74286 13.4857C8.36571 13.4857 9.85143 12.9143 11.0139 11.9608L14.8604 15.8041C15.1216 16.0653 15.5429 16.0653 15.8041 15.8041C16.0653 15.5461 16.0653 15.1216 15.8041 14.8637ZM6.74286 12.1437C3.76163 12.1437 1.33878 9.72082 1.33878 6.74286C1.33878 3.7649 3.76163 1.33878 6.74286 1.33878C9.72082 1.33878 12.1469 3.7649 12.1469 6.74286C12.1469 9.72082 9.72082 12.1437 6.74286 12.1437Z"
          />
        </svg>
      </div>
    </div>
  </form>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "HeaderSearch",
  directives: {
    ClickOutside
  },
  props: {
    action: {
      type: String,
      required: true
    },
    inputName: {
      type: String,
      required: true
    },
    isBurgerSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      isShown: true
    };
  },
  mounted() {
    this.$root.$on("burgerMenuToggle", this.checkBurgerMenu);
  },
  methods: {
    showSearch() {
      this.isOpen = true;
      this.$root.$emit("headerSearchToggle", this.isOpen);

      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    hideSearch() {
      this.isOpen = false;
      this.$root.$emit("headerSearchToggle", this.isOpen);
    },
    checkBurgerMenu(value) {
      this.hideSearch();

      if (!this.isBurgerSearch) {
        this.isShown = !value;
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "search": "Search"
  },
  "ru": {
    "search": "Поиск"
  }
}
</i18n>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".header-search";

#{$b} {
  color: $color-text-base;

  &_opened {
    width: 100%;
    margin: 0 30px 0 0;

    @include md-desktop-only {
      margin: 0 30px * $zoom 0 0;
    }

    @include tablet {
      margin: 0 30px;
    }
  }

  ._g-body-light & {
    color: $white-true;
  }

  .header_fixed & {
    color: $color-text-base;
  }

  &__field {
    position: relative;

    #{$b}_opened & {
      padding-right: 30px;

      @include md-desktop-only {
        padding-right: 30px * $zoom;
      }
    }
  }

  &__input {
    & > input {
      display: block;
      height: 40px;
      width: 100%;
      padding: 0;
      color: inherit;
      @include font(16px, 40px, 400);
      border-radius: 0;
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid currentColor;

      @include md-desktop-only {
        height: 40px * $zoom;
        @include font((16px * $zoom), (40px * $zoom));
      }

      @include placeholder {
        color: inherit;
        opacity: 0.6;
        font-weight: 400;
      }
    }
  }

  &__btn {
    user-select: none;
    cursor: pointer;
    fill: currentcolor;
    transition: color 0.2s ease;
    color: inherit;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;

    &:hover {
      fill: $color-base;
    }

    #{$b}_opened & {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 30px;
      height: 40px;
      line-height: 45px;
      text-align: center;

      @include md-desktop-only {
        width: 30px * $zoom;
        height: 40px * $zoom;
        line-height: 45px * $zoom;
      }
    }

    &._disabled {
      cursor: default;
      opacity: 0.35;
    }

    svg {
      width: 16px;
      height: 16px;

      @include md-desktop-only {
        width: 16px * $zoom;
        height: 16px * $zoom;
      }

      path {
        fill: currentColor;
      }
    }
  }
}
</style>
