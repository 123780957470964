<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="personal-area__line-row">
      <div class="personal-area__title">Уведомления о мероприятиях</div>

      <label class="radio-input radio-input_inline">
        <input
          class="radio-input__input"
          type="radio"
          name="radio1"
          checked=""
        />
        <span class="radio-input__text">Всегда</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio1" />
        <span class="radio-input__text">За час до начала</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio1" />
        <span class="radio-input__text">За 6 часов до начала</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio1" />
        <span class="radio-input__text">За день до начала</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio1" />
        <span class="radio-input__text">Никогда</span>
      </label>
    </div>
    <div class="personal-area__line-row">
      <div class="personal-area__title">Уведомления о программах</div>

      <label class="radio-input radio-input_inline">
        <input
          class="radio-input__input"
          type="radio"
          name="radio2"
          checked=""
        />
        <span class="radio-input__text">Всегда</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio2" />
        <span class="radio-input__text">За час до начала</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio2" />
        <span class="radio-input__text">За 6 часов до начала</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio2" />
        <span class="radio-input__text">За день до начала</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input class="radio-input__input" type="radio" name="radio2" />
        <span class="radio-input__text">Никогда</span>
      </label>
    </div>
    <div class="personal-area__line-row">
      <div class="personal-area__title">Уведомления о материалах</div>

      <label class="radio-input radio-input_inline">
        <input
          v-model="form.materials"
          class="radio-input__input"
          type="radio"
          name="radio3"
          checked=""
          value="always"
        />
        <span class="radio-input__text">Всегда</span>
      </label>
      <label class="radio-input radio-input_inline">
        <input
          v-model="form.materials"
          class="radio-input__input"
          type="radio"
          name="radio3"
          checked=""
          value="never"
        />
        <span class="radio-input__text">Никогда</span>
      </label>
    </div>
    <button class="button button_base" type="submit">
      Сохранить изменения
    </button>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "LkNotifyForm",
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        materials: "always"
      }
    };
  },
  methods: {
    async onSubmit() {
      const res = await axios.post(this.action, this.form);

      // eslint-disable-next-line no-console
      console.log(res);
    }
  }
};
</script>

<style scoped></style>
