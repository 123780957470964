var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application-packages-wrapper"},[_c('ul',{staticClass:"application-packages"},_vm._l((_vm.packages),function(pack,i){return _c('li',{key:((pack.id) + "_" + i)},[_c('div',{staticClass:"application-packages__item"},[_c('div',{staticClass:"application-packages__item-content",class:{
            'application-packages__item-content--streams-mode': _vm.isStreamsMode
          }},[_c('div',{staticClass:"application-packages__item-head",class:{
              'application-packages__item-head--streams-mode': _vm.isStreamsMode
            }},[_c('div',{staticClass:"application-packages__item-title"},[_vm._v(" "+_vm._s(pack.name)+" ")]),(!_vm.isStreamsMode)?_c('div',{staticClass:"application-packages__item-cost"},[(_vm.isFreeApplication || Number(pack.cost) === 0)?[_vm._v(" "+_vm._s(_vm.$t("is_free"))+" ")]:[(_vm.promoCode.isDigital && _vm.promoCode.discount)?[_c('span',{staticClass:"application-packages__item-cost-old",domProps:{"innerHTML":_vm._s(_vm.formatCost(pack.cost, false, pack.isCostFrom))}}),_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.formatCost(
                        _vm.getDiscountCost(pack.cost),
                        true,
                        pack.isCostFrom
                      )
                    )}})]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatCost(pack.cost, true, pack.isCostFrom))}})]]],2):_vm._e(),(_vm.promoCode.isDigital)?_c('div',{staticClass:"application-packages__item-promocode"},[_vm._v(" "+_vm._s(_vm.$t("by_digital_promocode"))+" ")]):_vm._e()]),(!_vm.isStreamsMode)?_c('div',{staticClass:"application-packages__item-text",domProps:{"innerHTML":_vm._s(pack.text)}}):_c('div',[_c('span',{staticClass:"application-packages__item-stream-text application-packages__item-stream-text--secondary"},[_vm._v(" "+_vm._s(_vm.$t("packages_stream.title"))+" ")]),_c('span',{staticClass:"application-packages__item-stream-text"},[_vm._v(" "+_vm._s(_vm.$t("packages_stream.from"))+" "+_vm._s(_vm.maybeShortStartDate(pack.dateStart, pack.dateFinish))+" "+_vm._s(_vm.$t("packages_stream.to"))+" "+_vm._s(pack.dateFinish)+" ")])])]),_c('div',{staticClass:"application-packages__item-buttons"},[(pack.isReached)?_c('div',{staticClass:"application-packages__item-reached"},[_c('span',{staticClass:"application-packages__item-reached-title"},[_vm._v(" "+_vm._s(_vm.$t("no_places"))+" ")])]):(pack.customLink)?_c('a',{staticClass:"button button_base button_icon",attrs:{"href":pack.customLink,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t("select_button")))])]):(pack.isBooking)?_c('a',{staticClass:"button button_base button_icon",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$modal.show('campusOrder')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("select_button")))])]):_c('button',{staticClass:"button button_base button_icon",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.selectPackage(pack)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("select_button")))])])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }