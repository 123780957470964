<template>
  <div class="library-list-wrapper">
    <div v-if="showSearchForm" class="edu-search">
      <div class="edu-search__input">
        <form
          class="search-field search-field_medium"
          autocomplete="off"
          @submit.prevent="search"
        >
          <div class="search-field__inner">
            <button class="search-field__button" type="submit">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <path
                  d="M17.78 16.722l-4.328-4.328A7.588 7.588 0 007.586 0a7.586 7.586 0 100 15.171 7.545 7.545 0 004.805-1.715l4.327 4.324a.75.75 0 101.062-1.058zm-10.194-3.06a6.084 6.084 0 01-6.08-6.076c0-3.35 2.726-6.08 6.08-6.08a6.09 6.09 0 016.08 6.08c0 3.35-2.73 6.076-6.08 6.076z"
                  fill="#C4C7CC"
                />
              </svg>
            </button>
            <div class="search-field__input">
              <input
                v-model.trim="searchTerm"
                placeholder="Поиск"
                @keyup="throttledSubmit"
              />
            </div>
            <button
              v-if="searchTerm.length"
              class="search-field__clear"
              type="submit"
              @click.prevent="clearField()"
            >
              <svg
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM1.35355 11.3536L6.35355 6.35355L5.64645 5.64645L0.646447 10.6464L1.35355 11.3536ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355ZM5.64645 6.35355L10.6464 11.3536L11.3536 10.6464L6.35355 5.64645L5.64645 6.35355ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
                  fill="#C4C4C4"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
    <section v-if="showSections" class="section section_small section_zt">
      <h4 class="mb-4">{{ $t("section_title") }}</h4>
      <div ref="tabs" class="tabs tabs_light">
        <div class="tabs-container">
          <scrollable-overflow>
            <ul class="tabs-list tabs-list_scrollable">
              <li
                :class="{
                  'tabs-list__item_active': !sectionId
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="switchSection(null, null)"
              >
                {{ $t("all") }}
              </li>
              <template v-for="(section, key) in sections">
                <li
                  v-if="!section.dropdown"
                  :id="section.hash"
                  :key="`tabs_item_${key}`"
                  :class="{
                    'tabs-list__item_active': sectionId === section.id
                  }"
                  class="tabs-list__item tabs-list__item_mr20"
                  @click="switchSection(section.id, section.hash)"
                >
                  {{ section.name }}
                </li>
                <LibraryListDropdown
                  v-else
                  :key="`tabs_item_dropdown_${key}`"
                  :section="section"
                  :current-id="sectionId"
                  @filterChange="switchSection"
                ></LibraryListDropdown>
              </template>
            </ul>
          </scrollable-overflow>
        </div>
      </div>
    </section>
    <section v-if="tags.length" class="section section_small section_zt">
      <h4 class="mb-4">{{ $t("tags_title") }}</h4>
      <div ref="tags" class="tabs tabs_light">
        <div class="tabs-container">
          <scrollable-overflow>
            <ul class="tabs-list tabs-list_scrollable">
              <li
                v-for="(tag, i) in tagsList"
                :key="`tabs_item_${i}`"
                :class="{
                  'tabs-list__item_active':
                    i === 0 && !currentTags.length
                      ? true
                      : currentTags.includes(tag.value)
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="toggleTag(tag.value)"
              >
                <template v-if="i === 0">
                  {{ tag.text }}
                </template>
                <template v-else># {{ tag.text }}</template>
              </li>
            </ul>
          </scrollable-overflow>
        </div>
      </div>
    </section>
    <section class="section section_small section_zt">
      <h4 class="mb-4">{{ $t("sorting_title") }}</h4>
      <div ref="sort" class="tabs tabs_light">
        <div class="tabs-container">
          <custom-select
            v-model="sortBy"
            :placeholder="$t('sorting')"
            :options="sortOptions"
            @input="changeSortBy"
          />
          <custom-select
            v-show="sortBy"
            ref="sortOrder"
            v-model="sortOrder"
            :placeholder="$t('sorting_order')"
            :options="sortOrderOptions"
            @input="changeSortOrder"
          />
        </div>
      </div>
    </section>
    <div class="library-list">
      <div class="library-list__row">
        <div
          v-for="(item, key) in activeItems"
          :key="key"
          class="library-list__col-md-1-2 library-list__col-lg-1-3"
        >
          <a
            :href="item.url"
            class="library-list__item"
            :target="item.blank ? '_blank' : false"
          >
            <div class="library-list__item__thumb">
              <div v-if="item.time" class="library-list__item__thumb-time">
                {{ item.time }} мин
              </div>
              <img :src="item.image" :alt="item.name" />
            </div>
            <div class="library-list__item__content">
              <a
                :href="item.url"
                class="library-list__item__title"
                :target="item.blank ? '_blank' : false"
              >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="item.name"></span>
              </a>
              <div class="library-list__item__author">{{ item.author }}</div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="library-list__item__text" v-html="item.text"></div>
            </div>
          </a>
        </div>
        <not-found v-show="!isLoading && !activeItems.length" />
        <div
          v-show="!isLoading && nextUrl"
          class="root-news__show-more root-news__show-more_bg"
          @click="loadMore(false)"
        >
          <span>
            Показать еще
            <svg
              width="13"
              height="19"
              viewBox="0 0 13 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="root-news__show-more-arrow"
            >
              <path
                d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
                fill="#131313"
              ></path>
            </svg>
          </span>
        </div>
      </div>
      <div v-show="isLoading" class="library-list__preload"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { throttle } from "lodash";
import { useUrlParams } from "../mixins/useUrlParams";

export default {
  name: "LibraryList",
  mixins: [useUrlParams],
  props: {
    params: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    initPaginator: { type: Object, default: () => ({}) },
    initNextUrl: { type: String, default: "" },
    showSearchForm: { type: Boolean, default: true },
    showSections: { type: Boolean, default: true },
    tags: { type: Array, default: () => [] }
  },
  data() {
    return {
      searchTerm: "",
      sections: [],
      sectionId: "",
      moreBtn: null,
      paginator: {},
      activeItems: [],
      nextUrl: "",
      currentTags: [],
      isLoading: false,
      sortBy: "",
      sortOptions: [
        { text: this.$t("sorting_volume"), value: "volume" },
        { text: this.$t("sorting_author"), value: "author" },
        { text: this.$t("sorting_name"), value: "name" }
      ],
      sortOrder: ""
    };
  },
  computed: {
    throttledSubmit() {
      return throttle(this.search, 1000);
    },
    tagsList() {
      return [
        {
          text: this.$t("all"),
          value: ""
        },
        ...this.tags
      ];
    },
    sortOrderOptions() {
      return [
        {
          text:
            this.sortBy === "volume"
              ? this.$t("sorting_asc")
              : this.$t("sorting_asc_alpha"),
          value: "asc"
        },
        {
          text:
            this.sortBy === "volume"
              ? this.$t("sorting_desc")
              : this.$t("sorting_desc_alpha"),
          value: "desc"
        }
      ];
    }
  },
  watch: {
    searchTerm(value) {
      if (!value.trim()) {
        this.loadFirstPage();
      }
    },
    sectionId() {
      this.loadFirstPage();
    }
  },
  mounted() {
    this.sections = this.params.sections;
    this.activeItems = this.items;
    this.nextUrl = this.initNextUrl;
    this.paginator = this.initPaginator;
    this.$root.$on("sendSearch", this.externalSearch);
    this.$root.$on("clearField", this.clearField);
    this.checkHash();
    this.checkUrlParams();
  },
  methods: {
    checkHash() {
      const hash = window.location.hash;

      if (hash.length > 0) {
        for (let section in this.sections) {
          if (hash == "#" + this.sections[section].hash) {
            this.switchSection(this.sections[section].id);
            this.$nextTick(() => {
              this.$scrollTo(this.$refs.tabs, 600, { offset: -250 });
            });
          }
        }
      }
    },
    checkUrlParams() {
      if (this.hasUrlParam("tags")) {
        this.currentTags = this.getUrlParam("tags").split(",");
      }

      if (this.hasUrlParam("sort_by")) {
        this.sortBy = this.getUrlParam("sort_by");

        if (this.hasUrlParam("sort_order")) {
          this.sortOrder = this.getUrlParam("sort_order");
        }
      }
    },
    externalSearch(value) {
      this.searchTerm = value;
      this.throttledSubmit();
    },
    loadFirstPage() {
      let lines = [];

      if (this.sectionId) {
        lines.push(`type=${this.sectionId}`);
      }

      if (this.searchTerm) {
        lines.push(`q=${this.searchTerm}`);
      }

      if (this.currentTags.length) {
        lines.push(`tags=${this.currentTags.join(",")}`);
      }

      if (this.sortBy) {
        lines.push(`sort_by=${this.sortBy}`);

        if (this.sortOrder) {
          lines.push(`sort_order=${this.sortOrder}`);
        }
      }

      const typesStr = lines.join("&");
      this.nextUrl = `?ajax=Y&PAGEN_${this.paginator.NavNum}=1&${typesStr}`;

      this.loadMore(true);
    },
    async loadMore(reset) {
      this.isLoading = true;
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.nextUrl;
      this.paginator = res.data.paginator;
      if (reset) {
        this.activeItems = [];
      }
      this.activeItems = [...this.activeItems, ...res.data.items];
      this.isLoading = false;
    },
    switchSection(id, hash) {
      this.sectionId = id ? id : "";

      if (hash) {
        this.$scrollTo("#" + hash, 600, { offset: -150 });
        this.updateHash(hash);
      } else {
        this.updateHash("");
      }
    },
    search() {
      this.loadFirstPage();
    },
    clearField() {
      this.searchTerm = "";
    },
    toggleTag(tag) {
      if (tag) {
        if (this.currentTags.includes(tag)) {
          this.currentTags = this.currentTags.filter(
            currentTag => currentTag !== tag
          );
        } else {
          this.currentTags.push(tag);
        }

        if (this.currentTags.length) {
          this.setUrlParam("tags", this.currentTags);
        } else {
          this.removeUrlParam("tags");
        }
      } else {
        this.currentTags = [];
        this.removeUrlParam("tags");
      }

      this.loadFirstPage();
    },
    changeSortBy(sortBy) {
      if (sortBy) {
        this.setUrlParam("sort_by", sortBy);
      } else {
        this.removeUrlParam("sort_by");
      }

      this.$refs.sortOrder.reset();
      this.loadFirstPage();
    },
    changeSortOrder(order) {
      if (order) {
        this.setUrlParam("sort_order", order);
      } else {
        this.removeUrlParam("sort_order");
      }

      this.loadFirstPage();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "all": "All",
    "sorting": "Sort by",
    "sorting_order": "Order",
    "sorting_volume": "Volume",
    "sorting_author": "Author",
    "sorting_name": "Name",
    "sorting_asc": "Ascending",
    "sorting_asc_alpha": "A-Z",
    "sorting_desc": "Descending",
    "sorting_desc_alpha": "Z-A",
    "section_title": "Sections",
    "tags_title": "Tags",
    "sorting_title": "Sorting"
  },
  "ru": {
    "all": "Все",
    "sorting": "Сортировать по",
    "sorting_order": "Порядок",
    "sorting_volume": "Тому",
    "sorting_author": "Автору",
    "sorting_name": "Названию",
    "sorting_asc": "По возрастанию",
    "sorting_asc_alpha": "А-Я",
    "sorting_desc": "По убыванию",
    "sorting_desc_alpha": "Я-А",
    "section_title": "Разделы",
    "tags_title": "Теги",
    "sorting_title": "Сортировка"
  }
}
</i18n>
