<template>
  <div class="partners-slider">
    <div class="partners-slider__wrapper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <slot />
        </div>
      </div>
      <div class="partners-slider__arrows">
        <span class="partners-slider__prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              fill="#131313"
              d="M6.206 12.805c.258.26.69.26.957 0a.645.645 0 000-.923L2.308 7.158H18.33c.373-.001.67-.29.67-.653a.665.665 0 00-.67-.662H2.308l4.855-4.716a.656.656 0 000-.931.684.684 0 00-.957 0L.2 6.039a.63.63 0 000 .922l6.005 5.844z"
            />
          </svg>
        </span>
        <span class="partners-slider__next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              fill="#131313"
              d="M12.794.196a.684.684 0 00-.957 0 .645.645 0 000 .922l4.855 4.724H.67c-.373.001-.67.29-.67.653 0 .364.297.662.67.662h16.022l-4.855 4.716a.656.656 0 000 .932c.268.26.7.26.957 0L18.8 6.96a.63.63 0 000-.922L12.794.196z"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "PartnersSlider",
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    const $container = this.$el.querySelector(".swiper-container");
    const $arrows = this.$el.querySelector(".partners-slider__arrows");

    this.slider = new Swiper($container, {
      slidesPerView: "auto",
      spaceBetween: 32,
      observer: true,
      observeParents: true,
      slideActiveClass: "_active",
      autoplay: {
        delay: 5000
      },
      navigation: {
        prevEl: this.$el.querySelector(".partners-slider__prev"),
        nextEl: this.$el.querySelector(".partners-slider__next"),
        disabledClass: "_disabled"
      },
      pagination: {
        el: this.$el.querySelector(".swiper-pagination"),
        clickable: true
      },
      on: {
        init: function() {
          if (this.slides.length < 5) {
            $arrows.classList.add("_hidden");
          }
        }
      },
      breakpoints: {
        // when window width is >= 1280px
        1280: {
          slidesPerView: 4
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";
$b: ".partners-slider";

#{$b} {
  position: relative;

  &:not(:first-child) {
    margin-top: 50px;

    @include md-desktop-only {
      margin-top: 50px * $zoom;
    }
    @include tablet {
      margin-top: 40px;
    }
    @include mobile {
      margin-top: 30px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 50px;

    @include md-desktop-only {
      margin-bottom: 50px * $zoom;
    }
    @include tablet {
      margin-bottom: 40px;
    }
    @include mobile {
      margin-bottom: 30px;
    }
  }

  &_no-mt {
    margin-top: 0 !important;
  }

  &_no-mb {
    margin-bottom: 0 !important;
  }

  &__wrapper {
    position: relative;
  }

  .swiper-container {
    @include low-desktop {
      margin: 0 -40px;
      padding: 0 40px;
    }
    @include mobile {
      margin: 0 -20px;
      padding: 0 20px;
    }
  }

  .swiper-slide {
    @include low-desktop {
      width: 280px;
    }
    @include tablet {
      width: 198px;
    }
    @include mobile {
      width: 176px;
    }
  }

  &__item {
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background-color: $white-true;
      border-radius: 8px;
      width: 100%;
      height: 280px;

      @include md-desktop-only {
        border-radius: 8px * $zoom;
        height: 280px * $zoom;
      }

      @include tablet {
        height: 198px;
      }

      @include mobile {
        height: 176px;
      }

      & > img {
        display: inline-block;
        max-width: 70%;
        max-height: 70%;
        flex-shrink: 0;
      }
    }
  }

  &__arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 10;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0;
    margin: 0 -74px;

    @include md-desktop-only {
      margin: 0 -74px * $zoom;
    }

    @include low-desktop {
      display: none;
    }

    &._hidden {
      display: none;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    width: 38px;
    height: 26px;
    outline: none;
    color: $color-bg-dark;

    &:hover {
      &:not(._disabled) {
        color: $color-base;
      }
    }

    @include md-desktop-only {
      width: 38px * $zoom;
      height: 26px * $zoom;
    }

    &._disabled {
      cursor: default;
      opacity: 0.35;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  .swiper-pagination {
    display: block;
    text-align: center;
    margin-top: 60px;
    @include clickable;

    @include md-desktop-only {
      margin-top: 60px * $zoom;
    }

    @include low-desktop {
      margin-top: 30px;
    }

    @include tablet {
      display: none;
    }

    &-bullet {
      outline: 0;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin: 5px;
      border: 1px solid $color-bg-dark;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-bg-dark;
        transition: 0.3s;
      }

      &:only-child {
        display: none;
      }

      &-active {
        &:before {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}
</style>
