<template>
  <div v-show="isFormLoaded" class="application-users">
    <form
      class="application-users__form"
      :class="{ 'is-not-current-user': !currentUser }"
    >
      <div v-if="currentUser && !isTesting" class="application-users__another">
        <label class="form-checkbox">
          <input
            v-model="anotherUser"
            class="form-checkbox__input"
            type="checkbox"
            name="another"
          />
          <span class="form-checkbox__text">
            {{ $t("another") }}
          </span>
        </label>
      </div>
      <ul :key="usersLength" class="application-users__list">
        <li v-for="(user, index) in users" :key="index">
          <application-user
            ref="userForm"
            :index="index"
            :init-user="user"
            :element-code="elementCode"
            :is-multiple-users="isMultipleUsers"
            :is-another-user="anotherUser"
            :is-show-education="isShowEducation"
            :is-show-passport="isShowPassport"
            :is-show-snils="isShowSnils"
            :is-show-big-form="isShowBigForm"
            :dadata-api-key="dadataApiKey"
            :industry-options="industryOptions"
            :education-options="educationOptions"
            :training-options="trainingOptions"
            @onRemoveUser="removeUser"
          />
        </li>
      </ul>
      <div v-if="!isTesting" class="application-users__add-block">
        <div class="application-users__add">
          <button class="button" type="button" @click="addUser">
            {{ $t("add_user") }}
          </button>
        </div>
        <div class="application-users__upload-users">
          <div class="application-users__upload-users-upload">
            <file-uploader
              id="uploadUsers"
              ref="uploadUsers"
              :title="$t('upload_user_title')"
              extensions="xlsx"
              accept=".xlsx"
              :size="10"
              is-simple-icon
              @onAddFile="loadExcelUsers"
            />
          </div>
          <div class="application-users__upload-users-text">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t('upload_user_left')"></span>
            <a :href="$t('upload_user_link')" target="_blank">
              {{ $t("upload_user_link-text") }}
            </a>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t('upload_user_right')"></span>
          </div>
        </div>
      </div>
    </form>
    <div v-if="!isTesting" class="application-users__promocode">
      <application-promo-code
        v-if="!isFreeApplication"
        :action-add-promo-code="actionAddPromoCode"
        :promo-code="promoCode"
        :package-id="selectedPackage.id"
        :re-captcha-key="reCaptchaKey"
        @onErrorPromoCode="onError"
      />
    </div>
    <div
      v-if="selectedPackage.limit < users.length"
      class="application-users__errors"
    >
      <div class="form-input-message is-error">
        <!-- eslint-disable-next-line -->
        <span v-html="$t('error.users_limit')"></span>
      </div>
    </div>
    <div class="application-users__buttons">
      <button
        class="button button_base"
        :class="{
          'is-imitate-disabled': isNeedAgreementCommon
            ? !(agreementCommon && agreement)
            : !agreement,
          button_loading: isButtonLoading
        }"
        type="button"
        @click.prevent="addApplication"
      >
        {{ $t("submit_button") }}
      </button>
      <div v-if="isNeedAgreementCommon" class="application-users__agreement">
        <label class="form-checkbox">
          <input
            v-model="agreementCommon"
            class="form-checkbox__input"
            :class="{
              'form-checkbox__input--error': $v.agreementCommon.$error
            }"
            type="checkbox"
            name="data-agreement"
            @change="$v.agreementCommon.$touch()"
          />
          <!-- eslint-disable-next-line -->
          <span class="form-checkbox__text" v-html="$t('agreement_common')" />
        </label>
        <div
          v-if="$v.agreementCommon.$error"
          class="form-input-message is-error"
        >
          {{ $t("agreement_need") }}
        </div>
      </div>
      <div class="application-users__agreement">
        <label class="form-checkbox">
          <input
            v-model="agreement"
            class="form-checkbox__input"
            :class="{
              'form-checkbox__input--error': $v.agreement.$error
            }"
            type="checkbox"
            name="data-agreement"
            @change="$v.agreement.$touch()"
          />
          <span class="form-checkbox__text">
            {{ $t("agreement_left") }}
            <a :href="$t('agreement_link')" target="_blank">
              {{ $t("agreement_right") }}
            </a>
          </span>
        </label>
        <div v-if="$v.agreement.$error" class="form-input-message is-error">
          {{ $t("agreement_need") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";
import * as _ from "lodash";

export default {
  name: "ApplicationUsers",
  props: {
    applicationId: { type: String, default: "" },
    elementId: { type: String, default: "" },
    elementCode: { type: String, default: "" },
    isAnotherUser: { type: Boolean, default: false },
    isFreeApplication: { type: Boolean, default: false },
    isShowEducation: { type: Boolean, default: false },
    isShowPassport: { type: Boolean, default: false },
    isShowSnils: { type: Boolean, default: false },
    isShowBigForm: { type: Boolean, default: false },
    selectedPackage: { type: Object, default: () => {} },
    dadataApiKey: { type: String, default: "" },
    initUsers: { type: Array, default: () => [{}] },
    promoCode: { type: Object, default: () => {} },
    actionAddApplication: { type: String, default: "" },
    actionLoadExcelUsers: { type: String, default: "" },
    actionAddPromoCode: { type: String, default: "" },
    actionCurrentUser: { type: String, default: "" },
    reCaptchaKey: { type: String, default: "" },
    industryOptions: { type: Array, default: () => [{}] },
    educationOptions: { type: Array, default: () => [{}] },
    trainingOptions: { type: Array, default: () => [] },
    isTesting: { type: Boolean, default: false }
  },
  data() {
    return {
      isFormLoaded: false,
      isButtonLoading: false,
      isUpdating: false,
      users: this.initUsers,
      currentUser: {},
      clearUser: {
        id: "",
        company: "",
        name: "",
        surname: "",
        middleName: "",
        email: "",
        phone: "",
        position: "",
        city: "",
        educationConfirm: false,
        passportConfirm: false,
        education: "",
        passport: "",
        snils: "",
        typePresence: "Заочно",
        typeArrival: "Корпоративный трансфер",
        carNumber: "",
        carBrand: "",
        industry: "",
        industryOther: "",
        industryEducation: "",
        industryEducationOther: "",
        training: "",
        country: ""
      },
      agreement: false,
      agreementCommon: false,
      formSend: false,
      anotherUser: this.isAnotherUser
    };
  },
  computed: {
    isMultipleUsers() {
      return this.users.length > 1;
    },
    isNeedAgreementCommon() {
      return this.isMultipleUsers || this.isAnotherUser;
    },
    checkIsFreeApplication() {
      if (!this.selectedPackage) return this.isFreeApplication;

      const packageCost = Number(this.selectedPackage.cost);
      const discount = Number(this.promoCode.discount);
      const isFree =
        this.isFreeApplication || packageCost === 0 || discount === 100;

      return isFree;
    },
    isIntramural() {
      return this.selectedPackage.hasOwnProperty("isIntramural")
        ? this.selectedPackage.isIntramural
        : false;
    },
    usersLength() {
      return this.users.length;
    }
  },
  watch: {
    anotherUser() {
      if (this.anotherUser) {
        this.replaceObject(this.users[0], this.clearUser);
      } else {
        this.replaceObject(this.users[0], this.currentUser);
      }

      this.$emit("onChangeAnotherUser", this.anotherUser);
    }
  },
  validations() {
    return {
      agreement: {
        required,
        sameAs: sameAs(() => true)
      },
      agreementCommon: {
        required: requiredIf(() => {
          return this.isNeedAgreementCommon;
        }),
        sameAs: requiredIf(() => {
          return this.isNeedAgreementCommon;
        })
      }
    };
  },
  mounted() {
    // Подгружаем данные пользователя, если он авторизован
    this.loadCurrentUser();

    // Scroll window bug fix
    setTimeout(() => {
      this.isFormLoaded = true;
    }, 10);
  },
  methods: {
    addUser() {
      this.users.push({ ...this.clearUser });
    },
    removeUser(index) {
      this.$delete(this.users, index);
      this.$v.$reset();
    },
    replaceObject(target, source) {
      const copySource = { ...source };

      for (const prop in copySource) {
        if (Object.hasOwnProperty.call(copySource, prop)) {
          this.$set(target, prop, copySource[prop]);
        }
      }
    },
    checkPhones(users) {
      users.forEach(user => {
        if (user.phone.length > 2) return;
        user.phone = "";
      });
    },
    parseObject(obj) {
      Object.keys(obj).forEach(prop => {
        if (
          obj[prop] === null ||
          obj[prop] === undefined ||
          obj[prop] === "undefined" ||
          obj[prop] === "null"
        ) {
          obj[prop] = "";
        }

        if (typeof obj[prop] === "object") {
          this.parseObject(obj[prop]);
        }

        if (obj[prop] === "true") {
          obj[prop] = true;
        }

        if (obj[prop] === "false") {
          obj[prop] = false;
        }
      });

      return obj;
    },
    addExcelUsers(users) {
      if (users.length) {
        users.forEach((user, i) => {
          this.parseObject(users[i]);

          // Проверяем номера
          if (
            !/^((8|\+7)[- ]?)?(\(?\d{3,4}\)?[- ]?)?[\d\- ]{5,10}$/.test(
              user.phone
            )
          ) {
            user.phone = "";
          }

          this.users.push(user);
        });

        this.$emit("onScrollToTop", 300, { offset: -80 });
      }

      // Удаляем пустых пользователей
      this.users.forEach((user, index) => {
        const isEmpty = this.checkEmptyUser(user);

        if (isEmpty) {
          this.removeUser(index);
        }
      });
    },
    checkEmptyUser(user) {
      const isEmpty = Object.values(user)
        .filter(value => typeof value === "string")
        .every(value => {
          return value === "";
        });

      return isEmpty;
    },
    async loadCurrentUser() {
      const data = new FormData();

      const options = {
        url: this.actionCurrentUser,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: data
      };

      const response = await axios(options);

      if (response.data.status === "success") {
        if (response.data.data) {
          this.currentUser = this.parseObject(response.data.data);

          if (!this.isAnotherUser) {
            this.replaceObject(this.users[0], this.currentUser);
          }
        } else {
          this.currentUser = false;
        }
      }
    },
    async loadExcelUsers(file) {
      const data = new FormData();

      data.append("file", file.file);

      const options = {
        url: this.actionLoadExcelUsers,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: data
      };

      const response = await axios(options);

      switch (response.data.status) {
        case "success":
          this.addExcelUsers(response.data.data.users);

          // Удаляем загруженный файл после успешной загрузки участников
          this.$refs.uploadUsers.remove(file);
          break;

        case "error":
          this.onError(response.data.errors[0]);
          break;
      }
    },
    isValidate() {
      this.$v.$touch();

      this.$refs.userForm.forEach(user => {
        user.$v.$touch();
      });

      const usersInvalid = this.$refs.userForm.some(user => user.$v.$invalid);

      return !this.$v.$invalid && !usersInvalid;
    },
    genericBuildFormData(formData, data, previousKey) {
      if (data instanceof File) {
        formData.append(previousKey, data);
      } else if (data instanceof Object) {
        let keys = Object.keys(data);
        for (let k = 0; k < keys.length; ++k) {
          let key = keys[k];
          let value = data[key];
          if (previousKey != "") {
            key = previousKey + "[" + key + "]";
          }
          if (
            !(value instanceof File) &&
            value instanceof Object &&
            !Array.isArray(value)
          ) {
            formData.append(key + "[]", []);
            this.genericBuildFormData(formData, value, key);
          } else {
            if (Array.isArray(value)) {
              formData.append(key + "[]", []);
              for (let a = 0; a < value.length; ++a) {
                this.genericBuildFormData(
                  formData,
                  value[a],
                  key + "[" + a + "]"
                );
              }
            } else {
              formData.append(key, value);
            }
          }
        }
      } else {
        formData.append(previousKey, data);
      }
    },
    async addApplication() {
      if (this.isButtonLoading) {
        return;
      }

      if (this.isValidate()) {
        // Проверяем телефоны участников, если не были заполнены очищаем поле
        this.checkPhones(this.users);

        const generatedUsers = _.cloneDeep(this.users);

        generatedUsers.forEach(user => {
          if (!user.hasOwnProperty("typeArrival")) return;

          user.typePresence = this.isIntramural ? "Очно" : "Заочно";

          if (this.isIntramural) {
            if (user.typeArrival === "Личный автомобиль") {
              user.typeArrival = `${user.typeArrival}, ${user.carNumber}, ${user.carBrand}`;
            }
          } else {
            delete user.typeArrival;
          }

          delete user.carNumber;
          delete user.carBrand;

          if (user.industry !== "15") {
            delete user.industryOther;
          }

          if (user.industry !== "9") {
            delete user.industryEducation;
          }

          if (user.industryEducation !== "33") {
            delete user.industryEducationOther;
          }
        });

        const data = {
          elementId: this.elementId,
          elementCode: this.elementCode,
          packageId: this.selectedPackage.id,
          isAnotherUser: this.anotherUser,
          isFreeApplication: this.checkIsFreeApplication,
          users: generatedUsers,
          promoCodeId: this.promoCode.id,
          applicationId: this.applicationId,
          idStream: this.selectedPackage.idStream
            ? this.selectedPackage.idStream
            : ""
        };

        const myFormData = new FormData();
        this.genericBuildFormData(myFormData, data, "");

        const options = {
          url: this.actionAddApplication,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: myFormData
        };

        this.isButtonLoading = true;
        const response = await axios(options);
        this.isButtonLoading = false;

        switch (response.data.status) {
          case "success":
            this.formSend = true;

            if (response.data.data.applicationId) {
              this.$nextTick(() => {
                this.$emit(
                  "onChangeApplicationId",
                  response.data.data.applicationId
                );
              });
            }

            // eslint-disable-next-line no-undef
            if (typeof fbq === "undefined") return;
            // eslint-disable-next-line no-undef
            fbq("track", "Lead");
            break;

          case "error":
            this.onError(response.data.errors[0]);
            break;
        }
      } else {
        this.$nextTick(() => {
          this.$scrollTo(".is-error", 300, { offset: -150 });
        });
      }
    },
    reset(e) {
      e.preventDefault();
    },
    onError(error) {
      this.$nextTick(() => {
        this.$emit("onErrorUsers", error);
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-users";

#{$b} {
  display: block;

  &__form {
    position: relative;

    @include low-desktop {
      padding-top: 0;
    }

    &.is-not-current-user {
      padding-top: 0;
    }

    &:not(:last-child) {
      margin-bottom: 78px;

      @include md-desktop-only {
        margin-bottom: 78px * $zoom;
      }

      @include low-desktop {
        margin-bottom: 50px;
      }

      @include mobile {
        margin-bottom: 30px;
      }
    }
  }

  &__another {
    display: block;
    position: absolute;
    left: 0;
    top: -40px;

    @include md-desktop-only {
      top: -40px * $zoom;
    }

    @include low-desktop {
      position: static;
      margin: 0 0 20px;
    }
  }

  &__promocode {
    &:not(:last-child) {
      margin-bottom: 40px;

      @include md-desktop-only {
        margin-bottom: 40px * $zoom;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: baseline;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }

    @include mobile {
      align-items: stretch;
    }

    & > * {
      &:not(:last-child) {
        margin-right: 48px;

        @include md-desktop-only {
          margin-right: 48px * $zoom;
        }
      }
    }

    .button {
      flex-shrink: 0;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__list {
    & > li {
      &:not(:last-child) {
        margin-bottom: 40px;

        @include md-desktop-only {
          margin-bottom: 40px * $zoom;
        }

        @include low-desktop {
          margin-bottom: 30px;
        }

        @include mobile {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__add-block {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      justify-content: stretch;
    }

    &:not(:first-child) {
      margin-top: 40px;

      @include md-desktop-only {
        margin-top: 40px * $zoom;
      }
    }
  }

  &__add {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 30px;
      }
    }

    .button {
      min-width: 240px;

      @include md-desktop-only {
        min-width: 240px * $zoom;
      }

      @include mobile {
        min-width: auto;
        width: 100%;
      }
    }
  }

  &__upload-users {
    display: block;
    max-width: 500px;

    @include md-desktop-only {
      max-width: 500px * $zoom;
    }

    @include low-desktop {
      max-width: 100%;
      width: calc((100% - 40px) / 2);
    }

    @include mobile {
      width: 100%;
    }

    &-upload {
      &:not(:last-child) {
        margin-bottom: 15px;

        @include md-desktop-only {
          margin-bottom: 15px * $zoom;
        }

        @include low-desktop {
          margin-bottom: 10px;
        }
      }
    }

    &-text {
      max-width: 420px;
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: $color-text-light-2;

      @include md-desktop-only {
        max-width: 420px * $zoom;
        font-size: 14px * $zoom;
        line-height: 20px * $zoom;
      }

      @include mobile {
        font-size: 12px;
        line-height: 18px;
      }

      a {
        color: $color-base;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  &__agreement {
    max-width: 500px;
    min-width: 260px;
    position: relative;

    @include md-desktop-only {
      max-width: 500px * $zoom;
    }

    @include tablet {
      min-width: auto;
    }

    &:not(:first-child) {
      @include tablet {
        margin-top: 16px;
      }
    }

    .form-checkbox {
      margin: 0;
    }

    .form-input-message {
      margin: 10px 0 0;

      @include md-desktop-only {
        margin: 10px * $zoom 0 0;
      }

      &:before {
        margin-right: 10px;

        @include md-desktop-only {
          margin-right: 10px * $zoom;
        }
      }
    }
  }

  .form-row {
    .h5 {
      margin-bottom: 10px;
    }
  }

  &__radio-list {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__errors {
    &:not(:last-child) {
      margin-bottom: 30px;

      @include md-desktop-only {
        margin-bottom: 30px * $zoom;
      }

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "another": "I only help to register",
    "agreement_left": "I agree to",
    "agreement_right": "personal data processing",
    "agreement_need": "You must agree to the terms",
    "agreement_link": "/upload/about/personal_data_processing_policy.pdf",
    "agreement_common": "I&nbsp;confirm that I&nbsp;have received the&nbsp;consent of&nbsp;third parties to&nbsp;transfer their personal data for&nbsp;processing",
    "submit_button": "Apply",
    "add_user": "Add participant",
    "upload_user_title": "Download the list of participants",
    "upload_user_left": "To&nbsp;upload the list of&nbsp;participants, download",
    "upload_user_link": "/upload/templates/user_templates_en.xlsx",
    "upload_user_link-text": "the template (xlsx)",
    "upload_user_right": "and fill in&nbsp;the required data.",
    "error": {
      "users_limit": "The limit of participants per package has been reached"
    }
  },
  "ru": {
    "another": "Я только помогаю зарегистрироваться",
    "agreement_left": "Я согласен(а) на",
    "agreement_right": "обработку персональных данных",
    "agreement_need": "Необходимо согласиться с условиями",
    "agreement_link": "/upload/about/politika-po-obrabotke-pers-dannih.pdf",
    "agreement_common": "Подтверждаю получение мною согласия третьих лиц на&nbsp;передачу их&nbsp;персональных данных на&nbsp;обработку",
    "submit_button": "Подать заявку",
    "add_user": "Добавить участника",
    "upload_user_title": "Загрузить список участников",
    "upload_user_left": "Для загрузки участников списком скачайте",
    "upload_user_link": "/upload/templates/user_templates.xlsx",
    "upload_user_link-text": "шаблон (xlsx)",
    "upload_user_right": "и&nbsp;заполните необходимые данные.",
    "error": {
      "users_limit": "Достигнут лимит участников на пакет"
    }
  }
}
</i18n>
