<template>
  <div class="container">
    <form class="form mb-10" @submit.prevent="onSubmit">
      <div class="form-body form-body_width-medium">
        <div class="form-row">
          <form-input
            id="newPassword"
            v-model="form.password"
            :label="$t('new_password') + ' *'"
            autocomplete="off"
            type="password"
            :error="$v.form.password.$error"
            :messages="[
              {
                type: 'error',
                i18: 'error.required',
                state: $v.form.password.$error && !$v.form.password.required
              },
              {
                type: 'error',
                text: passwordDetail,
                state: $v.form.password.$error && !$v.form.password.goodPassword
              }
            ]"
            @blur="$v.form.password.$touch()"
          />
        </div>
        <div class="form-row">
          <form-input
            id="repeatNewPassword"
            v-model="form.confirmPassword"
            :label="$t('confirm_password') + ' *'"
            autocomplete="off"
            type="password"
            :error="$v.form.confirmPassword.$error"
            :messages="[
              {
                type: 'error',
                i18: 'error.required',
                state:
                  $v.form.confirmPassword.$error &&
                  !$v.form.confirmPassword.required
              },
              {
                type: 'error',
                i18: 'error.password.mismatch',
                state: !$v.form.confirmPassword.sameAsPassword
              }
            ]"
          />
        </div>
        <p class="form-light-text mt-6">
          <small>
            {{ $t("required_text") }}
          </small>
        </p>
        <p class="form-light-text mt-6">
          <small>
            {{ $t("recommendation_text") }}
          </small>
        </p>
        <button class="button button_base mt-15" type="submit">
          {{ $t("change_password") }}
        </button>
      </div>
    </form>

    <notify-popup popup="successRegPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              fill="#18B346"
              d="M21.086 14.711a1.063 1.063 0 100-2.125 1.063 1.063 0 000 2.125z"
            />
            <path
              fill="#18B346"
              d="M25.817 14.508c-.355-.903-1.005-2.206-2.09-3.285-1.08-1.075-2.368-1.707-3.26-2.048a2.67 2.67 0 00-2.832.6l-2.607 2.596a2.657 2.657 0 00-.555 2.946c.137.308.286.609.445.9L9.195 21.94a.664.664 0 00-.195.47v2.922c0 .366.297.664.664.664h2.922a.664.664 0 00.664-.664v-1.196h1.195a.664.664 0 00.664-.664v-1.195h1.196a.664.664 0 000-1.328h-1.86a.664.664 0 00-.664.664v1.195h-1.195a.664.664 0 00-.664.664v1.196h-1.594v-1.983l5.889-5.889a.664.664 0 00.098-.814c-.23-.38-.441-.785-.628-1.205a1.322 1.322 0 01.278-1.465l2.607-2.595a1.34 1.34 0 011.421-.301c.771.294 1.883.837 2.798 1.748.92.917 1.482 2.044 1.79 2.829.192.49.075 1.046-.298 1.417l-2.64 2.627a1.337 1.337 0 01-1.46.285 8.622 8.622 0 01-1.196-.61.664.664 0 10-.695 1.132c.437.268.901.505 1.38.704 1.001.417 2.143.193 2.909-.57l2.639-2.626a2.646 2.646 0 00.597-2.845z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("change_password_title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("success_password") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <a :href="authUrl" class="button button_base">
            {{ $t("login") }}
          </a>
        </div>
      </div>
    </notify-popup>
    <notify-popup popup="errorPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#FF3939" stroke-width="2" />
            <path
              fill="#FF3939"
              d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("change_password_title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("password_error_left") }}
            <br />
            {{ $t("password_error_right") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('errorPopup')"
          >
            {{ $t("back") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ChangePassword",
  props: {
    action: {
      type: String,
      default: ""
    },
    authUrl: {
      type: String,
      default: ""
    },
    passwordDetail: {
      type: String,
      default: ""
    },
    arResult: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        checkWord: "",
        email: ""
      }
    }
  },
  data() {
    return {
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        goodPassword(password) {
          let result = true;
          this.passwordDetail = "";

          // Проверка длины пароля
          if (password.length < 8) {
            this.passwordDetail += this.$i18n.t("requirement.length") + "<br>";
            result = false;
          }

          // Проверка на наличие одного заглавного символа
          if (!/[A-Z]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.upper") + "<br>";
            result = false;
          }

          // Проверка на наличие одного спецсимвол
          if (!/[\W_]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.symbol") + "<br>";
            result = false;
          }

          // Проверка на наличие одного числа
          if (!/[0-9]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.number") + "<br>";
            result = false;
          }

          return result;
        }
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (this.validate()) {
        const data = new FormData();
        data.append("email", this.arResult.email);
        data.append("checkWord", this.arResult.checkWord);
        data.append("password", this.form.password);
        data.append("confirmPassword", this.form.confirmPassword);
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data
        };
        await axios(options).then(response => {
          if (response.data.code === 200) {
            this.$modal.show("successRegPopup");
          }

          if (response.data.success === false) {
            this.$modal.show("errorPopup");
            this.form.password = "";
            this.form.confirmPassword = "";
          }
        });
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "new_password": "New password",
    "confirm_password": "Confirm password",
    "required_text": "Fields marked with * are required",
    "recommendation_text": "Should contain at least 8 characters, uppercase, lowercase Latin letters, non-alphabetic characters and numbers",
    "change_password": "Change password",
    "change_password_title": "Change password",
    "success_password": "Password changed successfully!",
    "login": "Login",
    "password_error_left": "Something went wrong!",
    "password_error_right": "Try to reset your password again or contact support",
    "back": "Login",
    "requirement": {
      "length" : "Must contain at least 8 characters",
      "upper" : "Must contain at least one uppercase character",
      "symbol" : "Must contain at least one special character",
      "number" : "Must contain at least one digit"
    }
  },
  "ru": {
    "new_password": "Новый пароль",
    "confirm_password": "Повторите пароль",
    "required_text": "Поля, отмеченные * обязательные к заполнению",
    "recommendation_text": "Должен содержать не менее 8 символов, заглавные, строчные латинские буквы, небуквенные символы и цифры",
    "change_password": "Изменить пароль",
    "change_password_title": "Изменение пароля",
    "success_password": "Пароль успешно изменен!",
    "login": "Авторизоваться",
    "password_error_left": "Что-то пошло не так!",
    "password_error_right": "Попробуйте восстановить пароль повторно или обратитесь в службу поддержки",
    "back": "Back",
    "requirement": {
      "length" : "Должен содержать не менее 8 символов",
      "upper" : "Должен содержать хотя бы один заглавный символ",
      "symbol" : "Должен содержать хотя бы один спецсимвол",
      "number" : "Должен содержать хотя бы одну цифру"
    }
  }
}
</i18n>
