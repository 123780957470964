<template>
  <span>
    <slot />
  </span>
</template>

<script>
export default {
  name: "TextTruncate",
  props: {
    tablet: { type: Number, default: 0 },
    mobile: { type: Number, default: 0 }
  },
  data() {
    return {
      sourceText: ""
    };
  },
  mounted() {
    this.sourceText = this.$el.textContent.trim().replace(/\s+/g, " ");
    window.addEventListener("resize", this.checkWidth);
    this.checkWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    checkWidth() {
      let windowWidth = window.outerWidth;
      switch (true) {
        case windowWidth < 1023 && windowWidth >= 768:
          if (this.$props.tablet) {
            this.truncate(this.$props.tablet);
            break;
          }
          this.$el.textContent = this.sourceText;
          break;
        case windowWidth < 767:
          if (this.$props.mobile) {
            this.truncate(this.$props.mobile);
          }
          break;
        default:
          this.$el.textContent = this.sourceText;
      }
    },
    truncate(count) {
      let words = this.sourceText.split(" ");
      const endLine = words.length > count ? "..." : "";
      words = words.splice(0, count).join(" ");
      this.$el.textContent = `${words}${endLine}`;
    }
  }
};
</script>

<style scoped></style>
