import Vue from "vue";

export default function registerVueDirectives() {
  Vue.directive("uc-first", {
    update(el) {
      if (el.value.length > 0) {
        el.value = el.value[0].toUpperCase() + el.value.slice(1);
      }
    }
  });
}

Vue.directive("underconstruct", {
  bind(el, binding, vnode) {
    if (el.classList.contains("underconstruct")) {
      el.addEventListener("click", e => {
        e.preventDefault();
        vnode.context.$modal.show("errorDevelopmentPopup");
      });
    }
  }
});

Vue.directive("one-time-disabled", {
  bind(el) {
    el.addEventListener("touchstart", handler);

    function handler(e) {
      el.style.pointerEvents = "none";

      setTimeout(() => {
        el.style.pointerEvents = "";
      }, 100);

      el.removeEventListener(e.type, handler);
    }
  }
});

Vue.directive("wrap-table", {
  inserted(el) {
    if (!el.querySelectorAll("table").length) return;
    const tables = el.querySelectorAll("table");

    tables.forEach(item => {
      const parent = item.parentNode;
      const wrapper = document.createElement("div");

      // Added class on wrapper
      wrapper.classList.add("_g-wrapper-table");

      // Wrap and insert tables
      parent.insertBefore(wrapper, item);
      wrapper.appendChild(item);
    });
  }
});

Vue.directive("scroll-to-child", {
  inserted(el, binding, vnode) {
    const links = el.querySelectorAll("a");

    if (!links.length) return;

    const appRoot = vnode.context.$root;

    el.clickCallback = e => {
      e.preventDefault();

      const hash = e.target.hash;

      if (!hash) return;

      appRoot.$scrollTo(hash, 150, { offset: -70 });
      window.location.hash = hash;
    };

    links.forEach(link => {
      link.addEventListener("click", el.clickCallback);
    });
  }
});

Vue.directive("open-person", {
  inserted(el, binding, vnode) {
    const appRoot = vnode.context.$root;

    el.classList.add("_g-clickable");

    el.clickCallback = e => {
      e.preventDefault();

      const personId = binding.value;

      if (!personId) return;

      appRoot.$emit("openPersonSlide", personId);
    };

    el.addEventListener("click", el.clickCallback);
  },
  unbind(el) {
    el.removeEventListener("click", el.clickCallback);
  }
});
