<template>
  <div class="events-list-wrapper">
    <transition-group class="events-list" name="list" tag="ul">
      <li v-for="(event, i) in events" :key="`event_card_${i}`">
        <event-card :event="event" />
      </li>
    </transition-group>
    <event-speaker-popup />
  </div>
</template>

<script>
export default {
  name: "EventsList",
  props: {
    events: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/base/_include.scss";

$b: ".events-list";

#{$b} {
  list-style: none;
  padding: 0;
  margin: 0 0 -40px -40px;
  display: flex;
  flex-wrap: wrap;

  @include md-desktop-only {
    @include zoom(margin, 0 0 -40px -40px);
  }

  @include low-desktop {
    margin: 0 0 -24px -24px;
  }

  @include mobile {
    margin: 0 0 -16px -16px;
  }

  & > li {
    width: 50%;
    padding: 0 0 40px 40px;

    @include md-desktop-only {
      @include zoom(padding, 0 0 40px 40px);
    }

    @include low-desktop {
      padding: 0 0 24px 24px;
    }

    @include mobile {
      width: 100%;
      padding: 0 0 16px 16px;
    }
  }

  &-wrapper {
    display: flow-root;

    &:not(:last-child) {
      margin-bottom: 60px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 60px);
      }

      @include low-desktop {
        margin-bottom: 48px;
      }

      @include tablet {
        margin-bottom: 40px;
      }
    }
  }

  .list-enter {
    opacity: 0;
    transform: translateY(30px);
  }

  .list-enter-active,
  .list-leave-active {
    transition: transform 1s, opacity 1s;
  }

  .list-leave-to {
    display: none;
  }
}
</style>
