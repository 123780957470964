<template>
  <div>
    <ul class="edu-rate" :class="{ 'edu-rate_selectable': $props.selectable }">
      <li
        v-for="(item, i) in 5"
        :key="i"
        :class="{
          _fill:
            item <= currentAmount || ($props.selectable && item <= hoveredEl)
        }"
        @mouseover="hoverElement(item)"
        @mouseleave="unHover()"
        @click="changeRating(item)"
      >
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.14244 1.09708L11.4341 5.57305L11.5525 5.80431L11.8098 5.84035L16.8963 6.5529L13.2284 9.99854L13.0311 10.1839L13.0785 10.4505L13.9484 15.3392L9.36847 13.0184L9.14247 12.9039L8.91647 13.0184L4.33619 15.3392L5.20608 10.4505L5.25352 10.1839L5.05615 9.99854L1.38834 6.5529L6.47479 5.84035L6.73207 5.80431L6.85047 5.57307L9.14244 1.09708Z"
            fill="#F5F5F5"
            stroke="#3F3CA5"
          />
        </svg>
      </li>
    </ul>
    <div v-if="countAll && !selectable" class="edu-rate__count">
      {{ countAll }}
    </div>
    <notify-popup v-if="selectable" popup="ratingPopup" :width="580">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__title">
          {{ popupTitle }}
        </div>
        <div class="notify-popup__text">
          <p>{{ popupText }}</p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('ratingPopup')"
          >
            Закрыть
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Rating",
  props: {
    url: { type: String, default: "/local/ajax/rating.php" },
    id: { type: Number, default: 0 },
    selectable: { type: Boolean, default: false },
    personal: { type: Boolean, default: false },
    amount: { type: Number, default: 0 },
    needReInit: { type: Boolean, default: false }
  },
  data: function() {
    return {
      currentAmount: this.$props.amount,
      hoveredEl: 0,
      popupTitle: "",
      popupText: "",
      countAll: 0
    };
  },
  watch: {
    needReInit: async function() {
      await this.initRating();
    }
  },
  async mounted() {
    if (this.amount > 0) {
      this.currentAmount = this.amount;
    } else if (this.id) {
      await this.initRating();
    }
  },
  methods: {
    async changeRating(value) {
      if (value === this.currentAmount) return;

      if (this.$props.selectable) {
        const data = {
          action: "vote",
          id: this.id,
          value: value
        };
        const res = await axios.post(this.url, data);
        if (res.data.success) {
          this.currentAmount = value;
          this.popupTitle = "Спасибо за оценку";

          // Перерисовываем компонент
          let reInitItem = this.$root.$children.find(child => {
            return child.$options.name === "Rating";
          });
          reInitItem._props.needReInit = !reInitItem._props.needReInit;
        } else {
          this.popupTitle = "Произошла ошибка";
        }
        this.popupText = res.data.message;
        this.$modal.show("ratingPopup");
      } else return false;
    },
    hoverElement(indx) {
      this.hoveredEl = indx;
    },
    unHover() {
      this.hoveredEl = 0;
    },
    async initRating() {
      const data = {
        id: this.id
      };
      const res = await axios.post(this.url, data);
      if (res.data.success) {
        if (this.personal) {
          this.currentAmount = res.data.data.personal;
        } else {
          this.currentAmount = res.data.data.public.avg;
          this.countAll = res.data.data.public.countAll;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

.edu-rate {
  display: flex;

  &_big {
    display: flex;

    svg {
      width: 24px;
      height: 22px;

      @include md-desktop-only {
        width: 24px * $zoom;
        height: 22px * $zoom;
      }
    }
  }

  &_selectable {
    > li {
      cursor: pointer;
    }
  }

  > li {
    &:not(:last-child) {
      padding-right: 6px;

      @include md-desktop-only {
        padding-right: 6px * $zoom;
      }
    }

    &._fill {
      path {
        fill: #3f3ca5;
      }
    }
  }

  &__count {
    margin-left: 15px;
    font-size: 20px;

    @include md-desktop-only {
      font-size: 20px * $zoom;
    }
  }
}
</style>
