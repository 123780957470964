<template>
  <div class="center-gallery">
    <div class="center-gallery__container swiper-container">
      <div class="swiper-wrapper">
        <slot />
      </div>

      <div class="center-gallery__arrows">
        <span class="center-gallery__prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              fill="#131313"
              d="M6.206 12.805c.258.26.69.26.957 0a.645.645 0 000-.923L2.308 7.158H18.33c.373-.001.67-.29.67-.653a.665.665 0 00-.67-.662H2.308l4.855-4.716a.656.656 0 000-.931.684.684 0 00-.957 0L.2 6.039a.63.63 0 000 .922l6.005 5.844z"
            />
          </svg>
        </span>
        <span class="center-gallery__next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              fill="#131313"
              d="M12.794.196a.684.684 0 00-.957 0 .645.645 0 000 .922l4.855 4.724H.67c-.373.001-.67.29-.67.653 0 .364.297.662.67.662h16.022l-4.855 4.716a.656.656 0 000 .932c.268.26.7.26.957 0L18.8 6.96a.63.63 0 000-.922L12.794.196z"
            />
          </svg>
        </span>
      </div>

      <div v-if="!hidePagination" class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "CenterGallery",
  props: {
    hidePagination: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    const $slider = this.$el;
    const $container = $slider.querySelector(".center-gallery__container");
    const $slides = $slider.querySelectorAll(".center-gallery__slide");

    this.slider = new Swiper($container, {
      loop: true,
      loopedSlides: $slides.length,
      setWrapperSize: true,
      slidesPerView: "auto",
      spaceBetween: 20,
      centeredSlides: false,
      observer: true,
      observeParents: true,
      slideClass: "center-gallery__slide",
      slideActiveClass: "_active",
      navigation: {
        prevEl: $slider.querySelector(".center-gallery__prev"),
        nextEl: $slider.querySelector(".center-gallery__next"),
        disabledClass: "_disabled"
      },
      pagination: {
        el: this.$el.querySelector(".swiper-pagination"),
        clickable: true
      },
      on: {
        init: function() {
          if (this.realIndex < 1) {
            this.navigation.init();
            this.navigation.nextEl.classList.add("swiper-button-lock");
            this.navigation.prevEl.classList.add("swiper-button-lock");
          }
        }
      },
      breakpoints: {
        // when window width is >= 768px
        768: {
          centeredSlides: true,
          spaceBetween: 30
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".center-gallery";
$thumb-width: 592px;
$thumb-width-tablet: 508px;
$thumb-width-mobile: 240px;
$thumb-height: 388px;
$thumb-height-tablet: 350px;
$thumb-height-mobile: 175px;

#{$b} {
  position: relative;

  &:not(:first-child) {
    margin-top: 50px;
  }

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  @include md-desktop-only {
    &:not(:first-child) {
      margin-top: 50px * $zoom;
    }

    &:not(:last-child) {
      margin-bottom: 50px * $zoom;
    }
  }

  &_no-mt {
    margin-top: 0 !important;
  }

  &_no-mb {
    margin-bottom: 0 !important;
  }

  &#{$b}_light {
    color: $white-true;
  }

  &-outter {
    color: $color-bg-dark;

    ._dark & {
      color: $white-true;
    }

    &__text {
      font-size: 16px;

      p {
        font-size: inherit;
      }

      @include md-desktop-only {
        font-size: 16px * $zoom2;
      }
    }
  }

  &__container {
    width: 100%;
    position: relative;
    overflow: visible;
  }

  &__slide {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    transition: 0.4s ease;
    transition-property: transform;

    &._active {
      z-index: 20;

      #{$b}__item__thumb,
      .video-player {
        @include tablet-min {
          transform: scale(1.25);

          &:before {
            background: transparent;
          }

          &__bottom {
            transform: scale(0.8);
            left: 4%;
          }
        }
      }

      #{$b}__item__content_expanded {
        @include tablet-min {
          max-width: $thumb-width * 1.25;
        }
        @include md-desktop-only {
          max-width: $thumb-width * $zoom * 1.25;
        }
      }
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    max-width: 100%;
    text-decoration: none;
    @include font(16px, 1.5);

    @include md-desktop-only {
      font-size: 16px * $zoom;
    }

    &_video {
      cursor: pointer;
    }

    &__thumb {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      -webkit-backface-visibility: hidden;

      &__button {
        opacity: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: $white-true;
        position: absolute;
        bottom: 45px;
        left: 38px;
        z-index: 2;
        transition: 0.4s ease;
        transition-property: opacity, transform, left;

        #{$b}__slide._active & {
          opacity: 1;
        }

        @include md-desktop-only {
          bottom: 45px * $zoom;
          left: 38px * $zoom;
        }

        @include mobile {
          bottom: 10px;
          left: 10px;
        }
        &__icon {
          min-width: 72px;
          width: 72px;
          height: 72px;
          margin-right: 20px;

          @include md-desktop-only {
            min-width: 72px * $zoom;
            width: 72px * $zoom;
            height: 72px * $zoom;
            margin-right: 20px * $zoom;
          }

          @include mobile {
            min-width: 48px;
            width: 48px;
            height: 48px;
            margin-right: 0;
          }
        }
        &__text {
          @include font(20px, 1.5, 600);

          @include md-desktop-only {
            font-size: 20px * $zoom;
          }

          @include mobile {
            display: none;
          }
        }
      }
    }

    &__thumb,
    .video-player {
      width: $thumb-width;
      height: $thumb-height;
      display: block;
      position: relative;
      overflow: hidden;
      background-color: $white-true;
      border-radius: 8px;
      transition: 0.4s ease;
      transition-property: transform;
      margin-top: 50px;
      margin-bottom: 50px;

      &:before {
        content: "";
        @include full-abs;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.35) 71.43%
        );
        transition: 0.4s ease;
        transition-property: background;
      }

      @include md-desktop-only {
        width: $thumb-width * $zoom;
        height: $thumb-height * $zoom;
        border-radius: 8px * $zoom;
        margin-top: 50px * $zoom;
        margin-bottom: 50px * $zoom;
      }

      @include tablet {
        margin-top: 0;
        margin-bottom: 0;
        width: $thumb-width-tablet;
        height: $thumb-height-tablet;

        &:before {
          display: none;
        }
      }

      @include mobile {
        width: $thumb-width-mobile;
        height: $thumb-height-mobile;
      }
    }

    .video-player {
      &__bottom {
        bottom: 45px;
        opacity: 0;
        transition: 0.4s ease;
        transition-property: opacity, transform, left;

        #{$b}__slide._active & {
          opacity: 1;
        }

        @include md-desktop-only {
          bottom: 45px * $zoom;
        }

        @include mobile {
          bottom: 10px;
          left: 10px;
        }

        &-icon {
          margin-right: 20px;

          @include md-desktop-only {
            margin-right: 20px * $zoom;
          }

          @include mobile {
            min-width: 48px;
            width: 48px;
            height: 48px;
            margin-right: 0;
          }
        }

        &-text {
          font-weight: 600;
          @include font(20px, 1.5);

          @include md-desktop-only {
            font-size: 20px * $zoom;
          }

          @include mobile {
            display: none;
          }
        }
      }
    }

    &__content {
      max-width: $thumb-width;
      margin: 80px -74px 0;
      opacity: 0;
      transition: 0.4s ease;
      transition-property: opacity;

      #{$b}__slide._active & {
        opacity: 1;
      }

      @include md-desktop-only {
        margin: 80px * $zoom -74px * $zoom 0;
      }

      @include tablet {
        max-width: $thumb-width-tablet;
        margin: 36px 0 0;
      }

      @include mobile {
        max-width: $thumb-width-mobile;
      }

      #{$b}_no-text & {
        display: none;
      }

      p {
        &:not(:last-child) {
          margin-bottom: 24px;

          @include md-desktop-only {
            margin-bottom: 24px * $zoom;
          }
        }
      }

      h4 {
        margin: 0 0 20px;

        @include md-desktop-only {
          margin: 0 0 20px * $zoom;
        }
      }

      * + h4 {
        margin-top: 50px;

        @include md-desktop-only {
          margin-top: 50px * $zoom;
        }

        @include tablet {
          margin-top: 40px;
        }
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin: 20px 0;
        column-count: 2;
        column-gap: 20px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @include md-desktop-only {
          margin: 20px * $zoom 0;
          column-gap: 20px * $zoom;
        }

        @include tablet {
          column-count: 1;
          column-gap: 0;
        }

        & > li {
          break-inside: avoid;
          position: relative;
          padding-left: 20px;
          line-height: 1.5;

          &:not(:last-child) {
            margin-bottom: 24px;
          }

          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 10px;
            background-color: $black-true;
          }

          @include md-desktop-only {
            padding-left: 20px * $zoom;

            &:not(:last-child) {
              margin-bottom: 24px * $zoom;
            }

            &:before {
              top: 10px * $zoom;
              width: 5px * $zoom;
              height: 5px * $zoom;
              border-radius: 10px * $zoom;
            }
          }
        }
      }
    }
  }

  &__marked-aside {
    position: relative;
    padding-left: 70px;
    @include font(18px, 1.56);
    max-width: 590px;
    box-sizing: border-box;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      height: 3px;
      width: 40px;
      background-color: $color-base;
    }

    #{$b}__item__content_expanded & {
      max-width: 100%;
    }

    @include md-desktop-only {
      padding-left: 70px * $zoom;
      font-size: 18px * $zoom;
      max-width: 590px * $zoom;

      &:before {
        top: 12px * $zoom;
        height: 3px * $zoom;
        width: 40px * $zoom;
      }
    }

    @include mobile {
      padding-left: 0;
      @include font(16px, 24px);

      &:before {
        position: static;
        display: block;
        margin-bottom: 20px;
      }
    }

    p {
      color: inherit;
      @include font(inherit, inherit, inherit);
    }
  }

  &__arrows {
    position: absolute;
    top: $thumb-height / 2 + 50px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 0;
    max-width: 940px;
    margin: 0 auto;

    @include md-desktop-only {
      top: ($thumb-height * $zoom) / 2 + (50px * $zoom);
      max-width: 940px * $zoom;
    }

    @include tablet {
      display: none;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    width: 38px;
    height: 26px;
    outline: none;
    color: $white-true;

    @include md-desktop-only {
      width: 38px * $zoom;
      height: 26px * $zoom;
    }

    &:hover {
      &:not(._disabled) {
        color: $color-base;
      }
    }

    &._disabled {
      cursor: default;
      opacity: 0.3;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    left: 50%;
    top: $thumb-height + 32px;
    transform: translateX(-50%);
    z-index: 10;
    @include clickable;

    @include md-desktop-only {
      top: ($thumb-height * $zoom) + (32px * $zoom);
    }

    @include tablet {
      display: none;
    }

    &-bullet {
      outline: 0;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin: 5px;
      border: 1px solid $white-true;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $white-true;
        transition: 0.3s;
      }

      &:only-child {
        display: none;
      }

      @include md-desktop-only {
        height: 10px * $zoom;
        width: 10px * $zoom;
        margin: 5px * $zoom;
      }

      &-active {
        &:before {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}
</style>
