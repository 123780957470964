<template>
  <modal
    :name="popup"
    width="100%"
    :max-width="getWidth"
    :height="height"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    :shift-x="0.5"
    :shift-y="0.5"
    class="notify-popup"
    :class="{ 'notify-popup_opened': shown }"
    :styles="{ top: '0px' }"
    @before-open="beforeOpen"
    @opened="opened"
    @before-close="beforeClose"
  >
    <div
      v-if="!isHiddenClose"
      class="notify-popup__close"
      @click.prevent="$modal.hide(popup)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
        <path d="M.017 15.99L15.997.01l.99.99-15.98 15.98z" />
        <path d="M.017 1l.99-.99 15.98 15.98-.99.99z" />
      </svg>
    </div>
    <div class="notify-popup__content">
      <template v-if="isDynamicContent && content">
        <div class="notify-popup__body _g-text-center">
          <div v-if="content.icon" class="notify-popup__icon">
            <template v-if="content.icon === 'error'">
              <svg width="34" height="34" fill="none">
                <circle
                  cx="17"
                  cy="17"
                  r="16"
                  stroke="#FF3939"
                  stroke-width="2"
                />
                <path
                  fill="#FF3939"
                  d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
                />
              </svg>
            </template>
            <template v-if="content.icon === 'success'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="34"
                height="34"
                viewBox="0 0 34 34"
              >
                <circle
                  cx="17"
                  cy="17"
                  r="16"
                  stroke="#18B346"
                  stroke-width="2"
                />
                <path
                  stroke="#18B346"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M14 17l2.625 3L21 14"
                />
              </svg>
            </template>
            <template v-if="content.icon === 'attention'">
              <svg width="34" height="34" fill="none">
                <path
                  fill="#999"
                  d="M16.167 9v9.333a1 1 0 102 0V9a1 1 0 00-2 0zM18.552 25.26a1.666 1.666 0 10-2.77-1.852 1.666 1.666 0 002.77 1.851z"
                />
                <circle cx="17" cy="17" r="16" stroke="#999" stroke-width="2" />
              </svg>
            </template>
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="content.title"
            class="notify-popup__title"
            :class="content.titleClass"
            v-html="content.title"
          />
          <div
            v-if="content.text"
            class="notify-popup__text"
            v-html="content.text"
          />
          <div v-if="content.close" class="notify-popup__buttons">
            <button
              class="button button_dark"
              @click.prevent="$modal.hide(popup)"
              v-html="content.close"
            />
          </div>
          <!--eslint-enable-->
        </div>
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
  </modal>
</template>

<script>
export default {
  name: "NotifyPopup",
  props: {
    popup: { type: String, default: "" },
    width: { type: Number, default: 560 },
    height: { type: String, default: "auto" },
    isHiddenClose: { type: Boolean, default: false },
    openEventCallback: { type: String, default: "" },
    isDynamicContent: { type: Boolean, default: false }
  },
  data() {
    return {
      shown: false,
      content: null,
      htmlBlock: null
    };
  },
  computed: {
    getWidth() {
      return this.isDynamicContent && this.content && this.content.width
        ? this.content.width
        : this.width;
    }
  },
  mounted() {
    this.htmlBlock = document.querySelector("html");
  },
  methods: {
    beforeOpen(event) {
      this.content = event.params;
      this.$emit("onBeforeOpen", event);
      this.htmlBlock.classList.add("g-body-scroll-lock");
    },
    opened(event) {
      this.shown = !this.shown;
      if (this.openEventCallback) {
        let world = new Function("return  " + this.openEventCallback + "();");
        world();
      }
      this.$emit("onOpened", event);
    },
    beforeClose() {
      if (this.isDynamicContent && this.content && this.content.eventName) {
        this.$root.$emit(this.content.eventName, "close");
      } else {
        this.$emit("onClose");
      }

      this.htmlBlock.classList.remove("g-body-scroll-lock");
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".notify-popup";

notify-popup {
  &:not(#{$b}) {
    display: none;
  }
}

#{$b} {
  color: $color-bg-dark;
  @include mobile {
    display: flex;
    align-items: flex-start;
  }

  .vm--overlay {
    background: rgba(19, 19, 19, 0.9);
    backdrop-filter: blur(4px);

    @include md-desktop-only {
      backdrop-filter: blur(4px * $zoom);
    }
  }

  .vm--modal {
    border-radius: 0;
  }

  &_video {
    .video-frame {
      iframe {
        display: none;
      }
    }
  }
  &_opened {
    .video-frame {
      iframe {
        display: block;
      }
    }
  }
  &__close {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $black-true;
    display: flex;
    align-items: center;
    justify-content: center;

    @include md-desktop-only {
      width: 30px * $zoom;
      height: 30px * $zoom;
      top: 10px * $zoom;
      right: 10px * $zoom;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;

      @include md-desktop-only {
        width: 16px * $zoom;
        height: 16px * $zoom;
      }
      path {
        fill: currentColor;
      }
    }
  }
  &__body {
    padding: 55px 45px 60px;

    @include md-desktop-only {
      padding: 55px * $zoom 45px * $zoom 60px * $zoom;
    }
    @include mobile {
      padding: 40px 20px 50px;
    }
  }
  &__footer {
    padding: 30px 45px;

    @include md-desktop-only {
      padding: 30px * $zoom 45px * $zoom;
    }
    @include mobile {
      text-align: center;
      padding: 20px 25px;
    }
  }
  &__icon {
    width: 100%;
    margin-bottom: 20px;

    @include md-desktop-only {
      margin-bottom: 20px * $zoom;
    }
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  &__title {
    display: block;
    @include font(34px, 1.4, 600);
    color: $color-bg-dark;
    margin-bottom: 25px;

    @include md-desktop-only {
      font-size: 34px * $zoom;
      margin-bottom: 25px * $zoom;
    }
    @include mobile {
      font-size: 23px;
    }

    &_small {
      @include font(26px, 1.4);

      @include md-desktop-only {
        font-size: 26px * $zoom;
      }
      @include mobile {
        @include font(18px, 1.3);
      }
    }

    &_medium {
      @include font(30px, 1.36);

      @include md-desktop-only {
        font-size: 30px * $zoom;
      }

      @include mobile {
        @include font(20px, 1.3);
      }
    }
  }
  &__text {
    margin: 25px auto;
    @include font(20px, 1.4);
    color: #292929;
    font-style: normal;

    @include md-desktop-only {
      margin: 25px * $zoom auto;
      font-size: 20px * $zoom;
    }
    @include mobile {
      @include font(16px, 1.25);
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &_small {
      font-size: 18px;
      line-height: 1.4;

      @include md-desktop-only {
        font-size: 18px * $zoom;
      }

      @include mobile {
        @include font(16px, 1.25);
      }
    }
    &_medium {
      line-height: 1.56;
    }
    p {
      @include font(inherit, inherit);
      color: inherit;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        transition: color 0.3s ease;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $color-base;
          text-decoration: none;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @include md-desktop-only {
      margin-top: 40px * $zoom;
    }
    @include mobile {
      margin-top: 30px;
      flex-direction: column;
    }
    & > * {
      & + * {
        margin-left: 30px;

        @include md-desktop-only {
          margin-left: 30px * $zoom;
        }
        @include mobile {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
    .button {
      padding: 0 40px;

      @include md-desktop-only {
        padding: 0 40px * $zoom;
      }

      @include mobile {
        width: 100%;
      }

      #{$b}_early & {
        padding: 0 20px;
      }
    }
  }
  &__link {
    display: inline-block;
    @include font(16px, 1.37);
    text-decoration: underline;
    color: $color-bg-dark;

    &:hover {
      text-decoration: none;
    }
    @include md-desktop-only {
      font-size: 16px * $zoom;
    }
    @include mobile {
      text-align: inherit;

      br {
        display: none;
      }
    }
    &_light {
      color: rgba($color-bg-dark, 0.3);
    }
  }
  .video-player {
    &__bottom {
      &-icon {
        min-width: 66px;
        width: 66px;
        height: 66px;
        margin-right: 30px;

        @include md-desktop-only {
          min-width: 66px * $zoom;
          width: 66px * $zoom;
          height: 66px * $zoom;
          margin-right: 30px * $zoom;
        }
      }

      &-text {
        @include font(20px, 1.5, 600);

        @include md-desktop-only {
          font-size: 20px * $zoom;
        }
        @include mobile {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
