var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-input",class:{
    'form-input_error': _vm.error,
    'form-input_filled': _vm.isFilled,
    'form-input_focused': _vm.isFocused,
    'form-input_tooltip': _vm.labelTooltip,
    'form-input_datepicker': _vm.type === 'date',
    'form-input_phone': _vm.type === 'tel',
    'form-input_search': _vm.isDadata,
    'form-input_textarea': _vm.component === 'textarea'
  },on:{"mouseleave":_vm.mouseleave,"mouseover":_vm.mouseover,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}}},[_c('label',{staticClass:"form-input__field",attrs:{"for":_vm.id}},[_c('div',{staticClass:"form-input__label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}),(_vm.labelTooltip)?_c('tooltip',{attrs:{"content-class":"is-tooltip-small","placement":"bottom-center","is-show-arrow":""}},[_c('div',{staticClass:"tooltip-circle-icon",attrs:{"slot":"trigger"},slot:"trigger"},[_c('svg',{attrs:{"xml:space":"preserve","viewBox":"0 0 51.4 72.3"}},[_c('path',{attrs:{"d":"M31.5 53.9H19c0-1.8-.1-2.9-.1-3.3 0-4 .7-7.3 2-10 1.3-2.6 4-5.5 8-8.8 4-3.2 6.4-5.4 7.2-6.4 1.2-1.6 1.8-3.3 1.8-5.3 0-2.7-1.1-5-3.2-6.9-2.1-1.9-5-2.8-8.6-2.8-3.5 0-6.4 1-8.7 3-2.3 2-4 5-4.8 9.1L0 21c.3-5.8 2.8-10.8 7.4-14.8C12 2 18.1 0 25.6 0c7.9 0 14.1 2.1 18.8 6.2 4.6 4.1 7 8.9 7 14.4 0 3-.9 5.9-2.6 8.6-1.7 2.7-5.4 6.4-11 11-2.9 2.4-4.7 4.4-5.4 5.8s-1 4.1-.9 7.9zM19 72.3V58.6h13.7v13.7H19z"}})])]),_c('div',{staticClass:"tooltip-content",attrs:{"slot":"content"},slot:"content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.labelTooltip)}})])]):_vm._e()],1),_c(_vm.component,_vm._b({ref:"input",tag:"component",class:{
        'form-input__input': _vm.type !== 'date' && _vm.component !== 'textarea',
        'form-input__textarea': _vm.component === 'textarea'
      },attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.inputType,"value":_vm.value,"placeholder":_vm.label,"required":_vm.required,"readonly":_vm.readonly},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur,"change":_vm.onChange}},'component',_vm.currentProps,false)),(_vm.isPassword)?_c('div',{staticClass:"form-input__eye",class:{ 'is-active': _vm.isPasswordShow },on:{"click":_vm.togglePasswordInput}}):_vm._e()],1),(_vm.description)?_c('div',{staticClass:"form-input__desc",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),(_vm.suggestions.length && _vm.suggestionsOpened)?_c('div',{staticClass:"form-input__suggestions"},_vm._l((_vm.suggestions),function(suggestion,index){return _c('div',{key:index,staticClass:"form-input__suggestion",on:{"click":function($event){return _vm.setSuggestion(suggestion.value, index)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.highLight(suggestion.value, _vm.value))}})])}),0):_vm._e(),_vm._l((_vm.getMessages),function(message,i){return _c('div',{key:i,staticClass:"form-input-message",class:{ 'is-error': message.type === 'error' }},[(message.i18)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(message.i18))}}):_vm._e(),(message.text)?_c('span',{domProps:{"innerHTML":_vm._s(message.text)}}):_vm._e(),(message.min)?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('error.min-length.left') +
          message.min +
          _vm.$t('error.min-length.right')
      )}}):_vm._e(),(message.max)?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('error.max-length.left') +
          message.max +
          _vm.$t('error.max-length.right')
      )}}):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }