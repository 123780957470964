<template>
  <transition name="popup-fade">
    <div v-if="isOpen" class="event-speaker-popup">
      <div class="event-speaker-popup__dialog">
        <div class="event-speaker-popup__close" @click.prevent="close" />
        <div class="speaker">
          <div class="speaker__media">
            <div
              class="speaker__photo"
              :style="`background-image: url('${speaker.photo}')`"
            ></div>
          </div>
          <div class="speaker__content">
            <div class="speaker__info">
              <!-- eslint-disable vue/no-v-html -->
              <div class="speaker__name" v-html="$sanitize(speaker.name)" />
              <!-- eslint-disable vue/no-v-html -->
              <div class="speaker__post" v-html="$sanitize(speaker.post)" />
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div class="speaker__quote">
              <div
                class="speaker__quote-text typography"
                v-html="$sanitize(speaker.quote_text)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "EventSpeakerPopup",
  data() {
    return {
      isOpen: false,
      speaker: null,
      htmlBlock: null
    };
  },
  created() {
    this.htmlBlock = document.querySelector("html");
    this.$root.$on("open-speaker-popup", this.open);
  },
  methods: {
    open(speaker) {
      this.htmlBlock.classList.add("g-body-scroll-lock");
      this.isOpen = !this.isOpen;
      this.speaker = speaker;
    },
    close() {
      this.htmlBlock.classList.remove("g-body-scroll-lock");
      this.isOpen = !this.isOpen;
      this.speaker = null;
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/base/_include.scss";

$b: ".event-speaker-popup";

#{$b} {
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 32px;
  background: rgba(19, 19, 19, 0.9);

  @include md-desktop-only {
    @include zoom(padding, 32px);
  }

  @include mobile {
    padding: 24px 16px;
  }

  &__dialog {
    position: relative;
    width: 1044px;
    max-width: 100%;
    background: $white-true;
    padding: 100px 80px;
    margin: auto;

    @include md-desktop-only {
      @include zoom(width, 1044px);
      @include zoom(padding, 100px 80px);
    }

    @include tablet {
      padding: 52px 24px;
    }

    @include mobile {
      padding: 40px 16px;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 10;
    width: 30px;
    height: 30px;

    @include md-desktop-only {
      @include zoom(top, 40px);
      @include zoom(right, 40px);
      @include zoom(width, 30px);
      @include zoom(height, 30px);
    }

    @include tablet {
      top: 20px;
      right: 20px;
    }

    @include mobile {
      top: 10px;
      right: 16px;
      width: 24px;
      height: 24px;
    }

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      background: currentColor;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: opacity 0.2s;
}

.popup-fade-enter,
.popup-fade-leave-to {
  opacity: 0;
}
</style>
