<template>
  <div v-show="isActive" class="tabs-panel">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    id: { type: String, default: "" },
    label: { type: String, default: "" },
    selected: { type: Boolean, default: false },
    tabUrl: { type: String, default: "" },
    tabName: { type: String, default: "" }
  },
  data() {
    return {
      isActive: false
    };
  },
  mounted() {
    this.isActive = this.selected;
  }
};
</script>
