var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"form-body",on:{"change":_vm.onUpdateForm}},[_c('div',{staticClass:"form-row"},[_c('custom-select',{attrs:{"id":"company-branch","options":_vm.wayOfCompanyOptions,"placeholder":_vm.$t('industry'),"error":_vm.$v.form.UF_WAY_OF_COMPANY.$error,"prop-name-text":"text","prop-name-value":"id","is-flat-input":""},model:{value:(_vm.form.UF_WAY_OF_COMPANY),callback:function ($$v) {_vm.$set(_vm.form, "UF_WAY_OF_COMPANY", $$v)},expression:"form.UF_WAY_OF_COMPANY"}}),(
        _vm.$v.form.UF_WAY_OF_COMPANY.$error &&
          !_vm.$v.form.UF_WAY_OF_COMPANY.required
      )?_c('div',{staticClass:"form-input-message is-error"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()],1),(_vm.isSelectedOther)?_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"workNotes","label":_vm.$t('other') + ' *',"error":_vm.$v.form.WORK_NOTES.$error,"messages":[
        {
          type: 'error',
          i18: 'error.required',
          state: _vm.$v.form.WORK_NOTES.$error && !_vm.$v.form.WORK_NOTES.required
        }
      ]},model:{value:(_vm.form.WORK_NOTES),callback:function ($$v) {_vm.$set(_vm.form, "WORK_NOTES", $$v)},expression:"form.WORK_NOTES"}})],1):_vm._e(),(_vm.isSelectedEducation)?_c('div',{staticClass:"form-row"},[_c('custom-select',{attrs:{"id":"education","options":_vm.educationOptions,"placeholder":_vm.$t('education'),"error":_vm.$v.form.UF_EDUCATION.$error,"prop-name-text":"text","prop-name-value":"id","is-flat-input":""},model:{value:(_vm.form.UF_EDUCATION),callback:function ($$v) {_vm.$set(_vm.form, "UF_EDUCATION", $$v)},expression:"form.UF_EDUCATION"}}),(_vm.$v.form.UF_EDUCATION.$error && !_vm.$v.form.UF_EDUCATION.required)?_c('div',{staticClass:"form-input-message is-error"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()],1):_vm._e(),(_vm.isSelectedEducationOther)?_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"personalNotes","label":_vm.$t('other') + ' *',"error":_vm.$v.form.PERSONAL_NOTES.$error,"messages":[
        {
          type: 'error',
          i18: 'error.required',
          state:
            _vm.$v.form.PERSONAL_NOTES.$error && !_vm.$v.form.PERSONAL_NOTES.required
        }
      ]},model:{value:(_vm.form.PERSONAL_NOTES),callback:function ($$v) {_vm.$set(_vm.form, "PERSONAL_NOTES", $$v)},expression:"form.PERSONAL_NOTES"}})],1):_vm._e(),_c('div',{staticClass:"form-row"},[(!_vm.hideDadata)?_c('form-input',{attrs:{"id":"organizationName","is-dadata":"","dadata-type":"party","dadata-token":_vm.dadataApiKey,"label":_vm.$t('organization'),"error":_vm.$v.form.WORK_COMPANY.$error,"messages":[
        {
          type: 'error',
          i18: 'error.required',
          state: _vm.$v.form.WORK_COMPANY.$error && !_vm.$v.form.WORK_COMPANY.required
        }
      ]},model:{value:(_vm.form.WORK_COMPANY),callback:function ($$v) {_vm.$set(_vm.form, "WORK_COMPANY", $$v)},expression:"form.WORK_COMPANY"}}):_c('form-input',{attrs:{"id":"organizationNameText","label":_vm.$t('organization'),"error":_vm.$v.form.WORK_COMPANY.$error,"messages":[
        {
          type: 'error',
          i18: 'error.required',
          state: _vm.$v.form.WORK_COMPANY.$error && !_vm.$v.form.WORK_COMPANY.required
        }
      ]},model:{value:(_vm.form.WORK_COMPANY),callback:function ($$v) {_vm.$set(_vm.form, "WORK_COMPANY", $$v)},expression:"form.WORK_COMPANY"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"position","label":_vm.$t('position'),"error":_vm.$v.form.WORK_POSITION.$error,"messages":[
        {
          type: 'error',
          i18: 'error.required',
          state:
            _vm.$v.form.WORK_POSITION.$error && !_vm.$v.form.WORK_POSITION.required
        }
      ]},model:{value:(_vm.form.WORK_POSITION),callback:function ($$v) {_vm.$set(_vm.form, "WORK_POSITION", $$v)},expression:"form.WORK_POSITION"}})],1),_c('div',{staticClass:"form-row"},[_c('custom-select',{attrs:{"id":"job-level","options":_vm.positionLevelOptions,"placeholder":_vm.$t('level'),"error":_vm.$v.form.UF_POSITION_LEVEL.$error,"prop-name-text":"text","prop-name-value":"id","is-flat-input":""},model:{value:(_vm.form.UF_POSITION_LEVEL),callback:function ($$v) {_vm.$set(_vm.form, "UF_POSITION_LEVEL", $$v)},expression:"form.UF_POSITION_LEVEL"}}),(
        _vm.$v.form.UF_POSITION_LEVEL.$error &&
          !_vm.$v.form.UF_POSITION_LEVEL.required
      )?_c('div',{staticClass:"form-input-message is-error"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()],1),_c('div',{staticClass:"mt-15 _g-text-center"},[_c('button',{staticClass:"button button_base",attrs:{"type":"submit","disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.buttonText)?_c('span',[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }