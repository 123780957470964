import "../scss/init.scss";
import "intersection-observer";
import "./utils/urlChange";

import Vue from "vue";
import VModal from "vue-js-modal";
import registerVueDirectives from "./directives";
import registerVueFilters from "./filters";
import Vuelidate from "vuelidate";
import VueDragscroll from "vue-dragscroll";
import VueMatchHeights from "vue-match-heights";
import VueI18n from "vue-i18n";
import picturefill from "picturefill";
import objectFitPolyfill from "objectFitPolyfill";
import VueWaypoint from "vue-waypoint";
import VueSanitize from "vue-sanitize";
import Vue2TouchEvents from "vue2-touch-events";

registerVueDirectives();
registerVueFilters();

setTimeout(() => {
  // дополнительно реинитит картинки после того как отработает picturefill
  window.objectFitPolyfill();
}, 100);

const SocialSharing = require("vue-social-sharing");

Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(SocialSharing);
Vue.use(VueDragscroll);
Vue.use(VueMatchHeights);
Vue.use(VueI18n);
Vue.use(picturefill);
Vue.use(objectFitPolyfill);
Vue.use(VueWaypoint);
Vue.use(VueSanitize);
Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

// Auto include vue components
const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

const dateTimeFormats = {
  ru: {
    short: {
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
  }
};

const i18n = new VueI18n({
  locale: document.querySelector("html").getAttribute("lang"),
  dateTimeFormats
});

// Mount Vue to conainer
new Vue({
  i18n
}).$mount("#app");
