<template>
  <div class="telegram-link">
    <a :href="src" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 21 19"
        style="enable-background:new 0 0 21 19;"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M21,1.6c-0.1-0.6-0.4-1.1-1-1.4c-0.6-0.3-1.2-0.2-1.7-0.1c-0.5,0.1-1.2,0.4-1.9,0.6l0,0c0,0-0.1,0-0.2,0.1
            C14.7,1.5,7.5,4.5,1.9,7h0C1.5,7.1,1.1,7.3,0.8,7.6C0.5,7.8,0.2,8.1,0,8.6C-0.1,9.2,0.1,9.7,0.5,10c0.3,0.3,0.7,0.5,1.1,0.6l0,0
            l2.5,0.8c0.8,0.2,1.5,0.3,2.1,0.2c0.6-0.1,1.2-0.4,1.6-0.7l0,0c0.2-0.1,0.6-0.4,1.3-0.9c0,0,0,0,0,0c-0.4,0.4-0.7,0.8-0.8,1.2
            c-0.1,0.5-0.1,0.9,0.1,1.3c0.2,0.4,0.4,0.6,0.7,0.8c0.2,0.2,0.4,0.3,0.5,0.4c0,0,0,0,0.1,0.1c0.8,0.6,4.3,2.9,5,3.4l0,0
            c0.7,0.5,1.8,1,2.8,0.5c0.5-0.2,0.8-0.6,1-1.1c0.2-0.4,0.4-1,0.5-1.6v0c0.5-2.6,1.1-6.4,1.5-9.1c0.2-1.1,0.3-2.1,0.4-2.6
            C21,2.8,21,2.2,21,1.6z M19.9,3.3c-0.1,0.6-0.2,1.5-0.4,2.7c-0.4,2.6-1,6.4-1.4,9c-0.4,2.2-1.3,2.5-2.8,1.5c-0.7-0.5-4.2-2.8-5-3.4
            c0,0-0.1,0-0.1-0.1c-0.7-0.5-1.5-1.1-0.4-2.2c0.3-0.3,2.2-2.1,4-3.9c0.3-0.3,0.7-0.7,1-1c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0,0,0,0
            c0,0,0,0,0,0c0.3-0.3-0.1-0.7-0.4-0.5c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.3-0.8,0.5-1.2,0.8
            c-2.6,1.8-5.5,3.7-5.9,4c-0.8,0.5-1.5,0.8-2.9,0.4C3.4,10.2,2,9.7,2,9.7C0.5,9.2,0.8,8.5,2.3,7.9c5.6-2.5,12.8-5.5,14.3-6.1
            c0.1,0,0.1-0.1,0.2-0.1C19.5,0.5,20.3,0.7,19.9,3.3z"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "TelegramLink",
  props: {
    src: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".telegram-link";

#{$b} {
  width: 19px;
  height: 16px;

  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: $white-true;
  }

  @include tablet {
    display: none;
  }

  .header_fixed &,
  ._g-body-default & {
    .st0 {
      fill: $black-true;
    }
  }
}
</style>
