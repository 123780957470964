<template>
  <div>
    <div v-if="showOtherControls" class="tabs-line tabs-line_low mb-10">
      <div id="pulseOverflowLine" class="tabs-line__overflow">
        <ul class="tabs-line__list">
          <li
            v-for="(section, key) in sections"
            :key="key"
            :class="{ _active: currentSection === section.CODE }"
            class="tabs-line__list__item"
            @click="pushSection(section)"
          >
            {{ section.NAME }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="showSearchForm" class="edu-search">
      <search-field
        class="search-field_light search-field_shadow"
        is-static
        placeholder="Поиск"
        placeholder-mobile="Поиск"
        @input="search"
      />
    </div>
    <div class="pulse-market-list">
      <div v-if="isAfisha" class="tabs-container">
        <pulse-dropdowns
          :options="params.FILTER_OPTIONS"
          @change="changeFilter"
        />
        <button
          class="tabs__button button button_base"
          @click.prevent="$modal.show('eventApplicationPopup')"
        >
          Предложить мероприятие
        </button>
      </div>
      <div class="tabs tabs_light">
        <div class="tabs-container">
          <div v-if="showControls" class="tabs-overflow">
            <ul class="tabs-list">
              <li
                :class="{
                  'tabs-list__item_active': !sectionIds.length
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="switchSection(null)"
              >
                Все
              </li>
              <li
                v-for="(section, key) in sections"
                :key="key"
                :class="{
                  'tabs-list__item_active': sectionIds.indexOf(section.ID) >= 0
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="switchSection(section.ID)"
              >
                {{ section.NAME }}
              </li>
            </ul>
          </div>
          <sorting-dropdown
            v-if="!isAfisha"
            v-model="sortBy"
            :options="sortFields"
          />
        </div>
      </div>
      <transition-group class="root-news" name="card-list" tag="div">
        <a
          v-for="item in activeItems"
          :key="item.ID"
          :href="item.DETAIL_PAGE_URL"
          class="root-news__item"
        >
          <div
            :style="`background-image:url(${item.PREVIEW_PICTURE.SRC});`"
            class="root-news__img"
          >
            <ul
              v-if="item.FORMAT"
              class="root-news__tags root-news__tags_right"
            >
              <li>
                <div
                  :style="`background-color: #${item.FORMAT_COLOR};`"
                  class="root-news__tag root-news__tag_purple"
                >
                  {{ item.FORMAT }}
                </div>
              </li>
            </ul>
          </div>
          <div class="root-news__title h3">
            {{ item.NAME }}
          </div>
          <div class="root-news__date para">
            <span v-if="!item.DATE_FROM && !item.DATE_TO">
              {{ item.DATE_CREATE }}
            </span>
            <span v-if="item.DATE_FROM_STR">
              {{ item.DATE_FROM_STR }}
            </span>
            <span v-if="item.DATE_FROM !== item.DATE_TO">
              — {{ item.DATE_TO_STR }}
            </span>
            <span v-if="item.COUNTRY"
              ><span class="root-news__date__divider">|</span
              >{{ item.COUNTRY }}</span
            >
          </div>
        </a>
      </transition-group>
      <not-found v-show="!activeItems.length" />
      <div
        v-show="nextUrl"
        class="root-news__show-more"
        @click="loadMore(false)"
      >
        <span>
          показать еще
          <svg
            class="root-news__show-more-arrow"
            fill="none"
            height="19"
            viewBox="0 0 13 19"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
              fill="#131313"
            />
          </svg>
        </span>
      </div>
    </div>
    <notify-popup :width="580" height="auto" popup="eventApplicationPopup">
      <event-application-form
        title="Предложить мероприятие"
        url="/local/ajax/event_application.php"
      ></event-application-form>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { throttle } from "lodash";

export default {
  name: "PulseMarketList",
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    },
    initPaginator: {
      type: Object,
      default: () => ({})
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    showControls: {
      type: Boolean,
      default: true
    },
    showOtherControls: {
      type: Boolean,
      default: false
    },
    baseUrl: {
      type: String,
      default: ""
    },
    sectionCode: {
      type: String,
      default: ""
    },
    showSearchForm: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchTerm: "",
      sections: [],
      sectionIds: [],
      filterParams: {},
      sortFields: [
        { name: "Сначала новые", value: "desc" },
        { name: "Сначала старые", value: "asc" }
      ],
      sortBy: "desc",
      moreBtn: null,
      paginator: {},
      activeItems: [],
      nextUrl: "",
      currentSection: this.sectionCode
    };
  },
  computed: {
    paginatorText() {
      let shownCount = this.paginator.NavRecordCount;
      if (this.paginator.NavPageNomer !== this.paginator.NavPageCount) {
        shownCount = this.paginator.NavPageNomer * this.paginator.NavPageSize;
      }
      if (this.paginator.NavRecordCount === 0) {
        shownCount = 0;
      }
      return shownCount + "/" + this.paginator.NavRecordCount;
    },
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return "Поиск";
      } else {
        return "Поиск по разделу";
      }
    },
    throttledSubmit() {
      return throttle(this.search, 1000);
    },
    isAfisha() {
      return this.currentSection === "afisha";
    }
  },
  watch: {
    searchTerm(value) {
      if (!value.trim()) {
        this.loadFirstPage();
      }
    },
    sortBy() {
      this.loadFirstPage();
    },
    sectionIds() {
      this.loadFirstPage();
    }
  },
  mounted() {
    this.sections = this.params.SECTIONS;
    this.activeItems = this.items;
    this.paginator = this.initPaginator;
    this.nextUrl = this.initNextUrl;
  },
  methods: {
    loadFirstPage() {
      let lines = [];
      if (this.sectionIds.length) {
        const line = this.sectionIds.map(id => {
          return `type[]=${id}`;
        });
        lines.push(line);
      }
      lines.push("q=" + this.searchTerm);
      let typesStr = lines.join("&");

      if (this.filterParams.direction) {
        typesStr += "&direction=" + this.filterParams.direction;
      }

      if (this.filterParams.format) {
        typesStr += "&format=" + this.filterParams.format;
      }

      if (this.filterParams.country) {
        typesStr += "&country=" + this.filterParams.country;
      }

      if (this.filterParams.date && this.filterParams.date.length !== 0) {
        typesStr +=
          "&date_from=" +
          this.filterParams.date[0] +
          "&date_to=" +
          this.filterParams.date[1];
      }

      this.nextUrl = `?ajax=Y&PAGEN_${this.paginator.NavNum}=1&sort=${this.sortBy}&${typesStr}`;

      this.loadMore(true);
    },
    async loadMore(clearItems) {
      const { data } = await axios.get(this.nextUrl);
      this.nextUrl = data.NEXT_URL;
      this.paginator = data.PAGINATOR;
      if (clearItems) {
        this.activeItems = [];
      }
      this.activeItems = [...this.activeItems, ...data.ITEMS];
    },
    switchSection(id) {
      if (id) {
        // Новая постановка задачи по реализации фильтра не требует множественной фильтрации.
        // Для реализации задачи указываем массив с единственным id.
        this.sectionIds = [id];
        // При этом оставляем возможность вернуть множественную фильтрацию.
        // const idx = this.sectionIds.indexOf(id);
        // if (idx >= 0) {
        //   this.sectionIds.splice(idx, 1);
        // } else {
        //   this.sectionIds.push(id);
        // }
      } else {
        this.sectionIds = [];
      }
    },
    pushSection(item) {
      this.clearField();
      this.$root.$emit("clearField");
      this.currentSection = item.CODE;
      this.switchSection(item.ID);

      if (history.pushState && this.currentSection.length > 0) {
        const url = `${window.location.protocol}//${window.location.host}${this.baseUrl}`;
        const newUrl = `${url}${this.currentSection}/`;
        history.pushState(null, null, newUrl);
      } else {
        console.warn("History API не поддерживается");
      }
    },
    changeFilter(e) {
      this.filterParams = e;
      this.loadFirstPage();
    },
    search(term) {
      this.searchTerm = term;
      this.loadFirstPage();
    },
    clearField() {
      this.searchTerm = "";
    }
  }
};
</script>
