<template>
  <div class="events-registrations-list">
    <div class="edu-cards edu-cards_no-pd edu-cards_lk">
      <div v-if="filters.length" class="tabs tabs_light">
        <div class="tabs-container">
          <div class="tabs-overflow">
            <ul class="tabs-list">
              <li
                :class="{
                  'tabs-list__item_active': !activeCategory
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="setCategory(null)"
              >
                {{ $t("all") }}
              </li>
              <li
                v-for="(filter, i) in filters"
                :key="i"
                :class="{
                  'tabs-list__item_active': activeCategory === filter.category
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="setCategory(filter.category)"
              >
                {{ filter.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="!isLoading">
        <div v-if="Object.keys(activeItems).length" class="edu-cards__list">
          <card-item
            v-for="(item, key) in activeItems"
            :key="key"
            :item="item"
            prevent-default
            is-personal-page
            @click="openModal(key)"
          />
        </div>
        <not-found v-else :text="noFoundText" />
        <leaf-modal :active-slide="activeSlide" :popup="`programsModal_${id}`">
          <div
            v-for="(item, key) in activeItems"
            :key="key"
            class="swiper-slide leaf-modal-slider__slide"
          >
            <leaf-modal-item :item="item" />
          </div>
        </leaf-modal>
      </div>
      <div v-else>
        {{ $t("is_loading") }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EventsRegistrationsList",
  props: {
    filters: { type: Array, default: () => [] },
    url: { type: String, required: true },
    noFoundText: { type: String, default: "" }
  },
  data() {
    return {
      id: null,
      initItems: [],
      activeItems: [],
      activeSlide: 0,
      activeCategory: null,
      isLoading: true
    };
  },
  mounted() {
    this.id = this._uid;
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const response = await axios.post(this.url);
        if (!response.data.success) {
          throw Error(response.data.message);
        }

        this.initItems = response.data.data;
        this.activeItems = this.initItems;
        this.isLoading = false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      }
    },
    openModal(index) {
      this.activeSlide = parseInt(index);
      this.$modal.show(`programsModal_${this.id}`, index);
    },
    setCategory(category) {
      this.activeCategory = category;

      if (this.activeCategory) {
        this.activeItems = this.activeItems.filter(item => {
          return item.ELEMENT_CODE === this.activeCategory;
        });
      } else {
        this.activeItems = this.initItems;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".events-registrations-list";

#{$b} {
  .tabs {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "all": "All",
    "is_loading": "Loading data"
  },
  "ru": {
    "all": "Все",
    "is_loading": "Загрузка данных..."
  }
}
</i18n>
