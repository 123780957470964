<template>
  <div class="teacher-slider">
    <div class="teacher-slider__inner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <slot />
        </div>
      </div>

      <arrow-more-link href="#" value="Показать еще"></arrow-more-link>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "TeacherSlider",
  data() {
    return {
      vm: this,
      slider: null,
      moreBtn: null,
      swiper: undefined,
      init: false
    };
  },
  mounted() {
    this.slider = this.$el.querySelector(".swiper-container");
    this.moreBtn = this.$el.querySelector(".arrow-more-link");

    window.addEventListener("load", this.swiperMode);
    window.addEventListener("resize", this.swiperMode);
  },
  beforeDestroy() {
    window.removeEventListener("load", this.swiperMode);
    window.removeEventListener("resize", this.swiperMode);
  },
  methods: {
    swiperInit() {
      this.swiper = new Swiper(this.slider, {
        slidesPerView: "auto",
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        slideClass: "teacher-slider__slide",
        breakpoints: {
          768: {
            slidesPerView: "auto",
            spaceBetween: 28
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        },
        on: {
          beforeDestroy: function() {}
        }
      });
    },
    swiperDestroy() {
      this.swiper.destroy({
        deleteInstance: true,
        cleanStyles: true
      });
    },
    swiperMode() {
      let mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
      let tablet = window.matchMedia(
        "(min-width: 769px) and (max-width: 1024px)"
      );
      let desktop = window.matchMedia("(min-width: 1025px)");

      if (mobile.matches) {
        if (!this.init) {
          this.init = true;
          this.swiperInit();
          this.moreBtn.style.display = "none";
        }
      } else if (tablet.matches) {
        if (!this.init) {
          this.init = true;
          this.swiperInit();
          this.moreBtn.style.display = "none";
        }
      } else if (desktop.matches) {
        if (this.swiper != undefined) {
          this.swiperDestroy();
          this.vm.$destroy();
          this.moreBtn.style.display = "block";
        }
      }
    }
  }
};
</script>
