<template>
  <div
    class="account-menu"
    :class="{ 'account-menu_opened': isMobile ? false : showDropdown }"
    @mouseover.stop="openDropdown"
    @mouseleave.stop="hideDropdown"
  >
    <a
      v-if="isMobile && isLkPage"
      href="?logout=yes"
      class="button button_small"
      >{{ $t("logout") }}</a
    >

    <a v-else :href="link" class="account-menu__icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.9 24">
        <path
          fill="#1d1d1b"
          d="M15.3 13.5c2.1-1.3 3.5-3.6 3.5-6.2 0-4-3.3-7.3-7.3-7.3S4.2 3.3 4.2 7.3c0 2.6 1.4 4.9 3.5 6.2-4.5 1-7.7 3.8-7.7 7.1C0 23.3 7.2 24 11.5 24S23 23.3 23 20.5c-.1-3.3-3.2-6-7.7-7zM6 7.3c0-3 2.5-5.5 5.5-5.5S17 4.2 17 7.3s-2.5 5.5-5.5 5.5S6 10.3 6 7.3zm5.5 15c-6 0-9.5-1.2-9.7-1.9 0-3.1 4.4-5.7 9.7-5.7 5.3 0 9.7 2.6 9.7 5.7-.2.7-3.8 1.9-9.7 1.9z"
        />
      </svg>
    </a>

    <transition v-if="!isMobile" name="account-menu-slide">
      <div v-show="showDropdown" class="account-menu__dropdown">
        <ul class="account-menu__list">
          <li v-for="(item, key) in links" :key="key">
            <a :href="item.url" v-text="item.title"></a>
          </li>
          <li>
            <a href="?logout=yes" class="account-menu__list__logout-link">{{
              $t("logout")
            }}</a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AccountMenu",
  props: {
    isLkPage: {
      default: "",
      type: String
    },
    links: {
      type: Array,
      default: () => {}
    },
    link: {
      default: "/lk/",
      type: String
    }
  },
  data() {
    return {
      showDropdown: false,
      isMobile: false
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    openDropdown() {
      this.showDropdown = true;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
    onResize() {
      this.isMobile = window.innerWidth < 1024;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "logout": "Logout"
  },
  "ru": {
    "logout": "Выход"
  }
}
</i18n>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".account-menu";

#{$b} {
  cursor: pointer;
  position: relative;
  color: $color-text-base;

  ._g-body-light & {
    color: $white-true;

    .button {
      color: $white-true;
      border-color: $white-true;
    }
  }

  .header_fixed & {
    color: $color-text-base;

    .button {
      color: $color-bg-dark;
      border-color: $color-bg-dark;

      &:hover {
        color: $color-base;
        border-color: $color-base;
      }

      &:active {
        background: rgba($color-base, 0.2);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      display: none;

      .burger-menu & {
        display: flex;
      }
    }

    svg {
      width: 18px;
      height: 18px;

      @include md-desktop-only {
        width: 18px * $zoom;
        height: 18px * $zoom;
      }

      path {
        fill: currentColor;
      }
    }
  }

  &-slide-enter-active {
    transition: all 0.3s ease;
  }

  &-slide-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  &-slide-enter,
  &-slide-leave-to {
    transform: translateY(4px);
    opacity: 0;

    @include md-desktop-only {
      transform: translateY(4px * $zoom);
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: -10px;
    width: auto;
    padding-top: 10px;

    @include md-desktop-only {
      right: -10px * $zoom;
      padding-top: 10px * $zoom;
    }

    #{$b}_opened & {
      display: block;
    }
  }

  &__list {
    padding: 5px 0;
    background: $white-true;
    box-shadow: 0 2px 16px rgba($black-true, 0.1);
    border-radius: 8px;

    @include md-desktop-only {
      padding: 5px * $zoom 0;
      box-shadow: 0 2px * $zoom 16px * $zoom rgba($black-true, 0.1);
      border-radius: 8px * $zoom;
    }

    & > li {
      & > a {
        cursor: pointer;
        display: block;
        position: relative;
        padding: 6px 10px;
        color: #666;
        white-space: nowrap;
        @include font(14px, 18px);

        &:hover {
          background: #f5f5f5;
        }

        @include md-desktop-only {
          padding: 6px * $zoom 10px * $zoom;
          @include font((14px * $zoom), (18px * $zoom));
        }

        &#{$b}__list__logout-link {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
