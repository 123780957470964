<template>
  <div>
    <div class="edu-main-title">
      <main-title
        class="_multiple-rows"
        :value="titleValue"
        :class="titleClass"
      ></main-title>
      <div class="tabs tabs_light">
        <sorting-dropdown
          v-model="sortBy"
          :options="sortFields"
        ></sorting-dropdown>
      </div>
      <div class="edu-main-title__container">
        <div class="edu-main-title__label">
          {{ allComments }}
        </div>
      </div>
    </div>
    <form
      v-if="isAuthorized"
      class="edu-comments__field"
      novalidate
      @submit.prevent="submit"
    >
      <div v-if="replyText" class="edu-comments__reply">
        <input id="parent" v-model="parent" type="hidden" name="parent" />
        {{ replyText }}
        <div class="edu-comments__reply-remove" @click.prevent="removeReply">
          {{ $t("reply.remove") }}
        </div>
      </div>
      <form-input
        id="commentInput"
        v-model.trim="message"
        :label="$t('your_comment')"
        :error="isEmptyClass"
        autocomplete="off"
      />
      <button class="button" type="submit">
        {{ $t("send") }}
      </button>
    </form>
    <div class="edu-comments">
      <ul class="edu-comments__list">
        <li
          v-for="(item, key) in itemsLocal"
          :key="key"
          class="edu-comments__item"
          :class="{ 'edu-comments__item-child': item.PARENT_ID !== '' }"
        >
          <div class="edu-comments__container">
            <div
              v-if="item.CREATOR.PERSONAL_PHOTO_SRC"
              class="edu-comments__photo"
              :style="{
                backgroundImage: 'url(' + item.CREATOR.PERSONAL_PHOTO_SRC + ')'
              }"
            ></div>
            <div
              v-else
              class="edu-comments__photo edu-comments__photo-empty"
            ></div>
            <div class="edu-comments__top">
              <span class="edu-comments__name">
                {{ item.CREATOR.LAST_NAME }} {{ item.CREATOR.NAME }}
              </span>
              <span class="edu-comments__date">
                <span>{{ item.ACTIVE_FROM.TIME }}</span
                ><span>{{ item.ACTIVE_FROM.DATE }}</span>
              </span>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="item.CREATOR.IS_ADMIN"
              class="edu-comments__text"
              v-html="item.DETAIL_TEXT"
            ></div>
            <div v-else class="edu-comments__text">
              {{ item.DETAIL_TEXT }}
            </div>
            <ul
              class="edu-comments__actions"
              :class="{
                'edu-comments__actions-liked':
                  item.LIKES && item.LIKES.IS_LIKED,
                'edu-comments__actions-disabled': !isAuthorized
              }"
            >
              <li @click.prevent="changeLike(item)">
                <svg
                  class="edu-comments__icon _heart"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 14C7.77222 14 7.55261 13.9188 7.38147 13.7714C6.73511 13.2156 6.11194 12.6932 5.56213 12.2324L5.55933 12.23C3.94739 10.879 2.55542 9.71231 1.58691 8.56301C0.504272 7.27817 0 6.05996 0 4.72914C0 3.43613 0.450806 2.24325 1.26929 1.37008C2.09753 0.486588 3.23401 0 4.46973 0C5.39331 0 6.23914 0.287175 6.98364 0.85348C7.35938 1.13933 7.69995 1.48918 8 1.89725C8.30017 1.48918 8.64062 1.13933 9.01648 0.85348C9.76099 0.287175 10.6068 0 11.5304 0C12.766 0 13.9026 0.486588 14.7308 1.37008C15.5493 2.24325 16 3.43613 16 4.72914C16 6.05996 15.4958 7.27817 14.4132 8.56289C13.4447 9.71231 12.0529 10.8789 10.4412 12.2298C9.89038 12.6913 9.26624 13.2145 8.61841 13.7717C8.44739 13.9188 8.22766 14 8 14ZM4.46973 0.921792C3.4989 0.921792 2.60706 1.30285 1.95825 1.99485C1.2998 2.6973 0.937134 3.66832 0.937134 4.72914C0.937134 5.84842 1.36011 6.84945 2.30847 7.97486C3.2251 9.06269 4.5885 10.2054 6.16711 11.5285L6.17004 11.5309C6.72192 11.9935 7.34753 12.5179 7.99866 13.0778C8.65369 12.5168 9.28027 11.9916 9.83325 11.5283C11.4117 10.2051 12.775 9.06269 13.6917 7.97486C14.6399 6.84945 15.0629 5.84842 15.0629 4.72914C15.0629 3.66832 14.7002 2.6973 14.0417 1.99485C13.3931 1.30285 12.5011 0.921792 11.5304 0.921792C10.8192 0.921792 10.1663 1.14414 9.58972 1.58258C9.07593 1.97348 8.71802 2.46764 8.50818 2.8134C8.40027 2.9912 8.21033 3.09733 8 3.09733C7.78967 3.09733 7.59973 2.9912 7.49182 2.8134C7.2821 2.46764 6.92419 1.97348 6.41028 1.58258C5.83374 1.14414 5.18079 0.921792 4.46973 0.921792Z"
                    fill="#131313"
                  ></path>
                </svg>
                <span
                  v-if="item.LIKES && item.LIKES.COUNT"
                  class="edu-comments__icon-number"
                >
                  {{ item.LIKES.COUNT }}
                </span>
              </li>
              <li
                v-if="!item.PARENT_ID && isAuthorized"
                v-scroll-to="{ el: `.edu-comments-section`, offset: -80 }"
                @click.prevent="replyComment(item)"
              >
                <svg
                  class="edu-comments__icon _reply"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.1282 3.75677H2.39143L5.26482 0.883372L4.38145 0L0 4.38142L4.38142 8.76284L5.26479 7.87947L2.39143 5.00607H10.1282C12.677 5.00607 14.7507 7.07969 14.7507 9.62849C14.7507 10.8272 14.1445 11.8459 13.6359 12.4894L13.2487 12.9794L14.2289 13.754L14.6161 13.264C15.2474 12.4651 16 11.186 16 9.62849C15.9999 6.39079 13.3659 3.75677 10.1282 3.75677Z"
                    fill="#131313"
                  ></path>
                </svg>
                <span class="edu-comments__icon-reply">
                  {{ $t("reply.comment") }}
                </span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

export default {
  name: "CommentsForm",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ""
    },
    elementId: {
      type: Number,
      required: true
    },
    sess: {
      type: String,
      default: ""
    },
    signedParameters: {
      type: String,
      default: ""
    },
    isAuthorized: {
      type: Boolean,
      default: false
    },
    titleValue: {
      type: String,
      default: ""
    },
    titleClass: {
      type: String,
      default: ""
    },
    allComments: {
      type: String,
      default: ""
    },
    isEmptyClass: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parent: null,
      itemsLocal: [],
      message: "",
      sortFields: [
        { name: this.$i18n.t("sorting.desc"), value: "desc" },
        { name: this.$i18n.t("sorting.asc"), value: "asc" },
        { name: this.$i18n.t("sorting.popular"), value: "popular" }
      ],
      sortBy: "desc",
      replyText: ""
    };
  },
  watch: {
    sortBy() {
      this.reloadItems();
    }
  },
  mounted() {
    this.itemsLocal = this.items;
  },
  methods: {
    async submit() {
      if (this.message === "") {
        this.isEmptyClass = true;
        return;
      } else {
        this.isEmptyClass = false;
      }
      const data = {
        id: this.elementId,
        parent: this.parent,
        message: this.message
      };
      const res = await axios.post(this.url, data);
      if (!res.data.success) {
        alert(res.data.message);
      } else {
        this.message = "";
        this.parent = null;
        this.replyText = "";
        await this.reloadItems();
      }
    },
    changeLike(comment) {
      if (!this.isAuthorized) return;

      const action = comment.LIKES && comment.LIKES.IS_LIKED ? "delete" : "add";
      this.reloadItems(
        "changeLike",
        "&idComment=" + comment.ID + "&action=" + action
      );
    },
    replyComment(comment) {
      if (!this.isAuthorized) return;

      this.parent = comment.ID;
      this.replyText =
        this.$i18n.t("reply.start") +
        '"' +
        comment.CREATOR.LAST_NAME +
        " " +
        comment.CREATOR.NAME +
        '" ' +
        this.$i18n.t("reply.end") +
        " " +
        comment.ACTIVE_FROM.DATE;
    },
    removeReply() {
      this.parent = null;
      this.replyText = "";
    },
    async reloadItems(method = "reload", additionalData = "") {
      let options = {
        url: `/bitrix/services/main/ajax.php?mode=class&c=dalee:comments&action=${method}`,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data:
          `sessid=${this.sess}&signedParameters=${this.signedParameters}&sort=${this.sortBy}` +
          additionalData
      };
      await axios(options).then(response => {
        if (response.data.data) {
          this.itemsLocal = response.data.data;
        }
      });
    }
  }
};
</script>

<i18n>
{
  "en": {
    "your_comment": "Your comment",
    "send": "Send",
    "sorting": {
      "desc": "Latest first",
      "asc": "Oldest first",
      "popular": "Popular"
    },
    "reply": {
      "comment": "Reply",
      "start": "Reply to comment",
      "end": "from",
      "remove": "Remove"
    }
  },
  "ru": {
    "your_comment": "Ваш комментарий",
    "send": "Отправить",
    "sorting": {
      "desc": "Сначала новые",
      "asc": "Сначала старые",
      "popular": "Популярные"
    },
    "reply": {
      "comment": "Ответить",
      "start": "Ответ на комментарий",
      "end": "от",
      "remove": "Удалить"
    }
  }
}
</i18n>
