<template>
  <div
    v-if="canMakePayments"
    :id="id"
    class="apple-pay-button"
    @click="onClick"
  >
    <span class="apple-pay-button__text">{{ $t("buy_with") }}</span>
    <span class="apple-pay-button__logo" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ApplePayButton",
  props: {
    id: {
      type: String,
      required: false,
      default: "applePayButton"
    },
    options: {
      type: Object,
      default: () => {
        return {
          merchantId: "merchant.com.example.mystore",
          merchantName: "Example merchant name",
          countryCode: "RU", // код страны магазина
          currencyCode: "RUB", //  код валюты платежа
          total: {
            label: "Your Company Inc.", // название компании
            amount: "1.50" // Итоговая сумма платежа
          },
          lineItems: [
            {
              label: "Пакет программы 1", // Название элемента
              type: "final",
              amount: "1.50" // Стоимость элемента
            }
          ],
          supportedNetworks: ["masterCard", "visa"], // принимаемые типы карт
          merchantCapabilities: ["supports3DS"],
          requiredShippingContactFields: ["phone", "email"], // дополнительные данные плательщика
          paymentSession:
            "http://localhost:8080/api/ext/apple-pay/paymentSession"
        };
      }
    }
  },
  data() {
    return {
      canMakePayments: false,
      session: null
    };
  },
  mounted() {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      this.canMakePayments = true;
      this.$emit("onCanMakePayments", "ApplePay");
    }
  },
  methods: {
    onClick() {
      let request = {
        countryCode: this.options.countryCode,
        currencyCode: this.options.currencyCode,
        supportedNetworks: this.options.supportedNetworks,
        merchantCapabilities: this.options.merchantCapabilities,
        total: {
          label: this.options.total.label,
          amount: this.options.total.amount
        },
        lineItems: this.options.lineItems
      };
      this.session = new window.ApplePaySession(3, request);
      this.session.onvalidatemerchant = this.onValidateMerchant;
      this.session.onpaymentauthorized = this.onPaymentAuthorized;
      this.session.oncancel = this.onCancel;
      this.session.begin();
    },
    async onValidateMerchant(event) {
      const data = { ValidationUrl: event.validationURL };
      const options = {
        url: this.options.paymentSession,
        method: "POST",
        headers: { "content-type": "application/json" },
        data: JSON.stringify(data)
      };

      const session = await axios(options);
      this.session.completeMerchantValidation(session);
    },
    onPaymentAuthorized(event) {
      const paymentData = event.payment;
      this.$emit("onPaymentAuthorized", paymentData);
    },
    onCancel(event) {
      this.session.abort();
      this.$emit("onPaymentCancel", event);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".apple-pay-button";

#{$b} {
  @supports (-webkit-appearance: -apple-pay-button) {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-style: black;
    -apple-pay-button-type: buy;

    & > * {
      display: none;
    }
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
    background-color: black;
    color: white;

    &__logo {
      width: calc(35px * var(--scale));
      height: 100%;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 0 50%;
      margin-left: calc(2px * var(--apple-pay-scale));
      border: none;
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
    }

    &__text {
      font-family: -apple-system;
      font-size: calc(1em * var(--apple-pay-scale));
      font-weight: 300;
      align-self: center;
      margin-right: calc(2px * var(--apple-pay-scale));
    }
  }
}
</style>

<i18n>
{
  "en": {
    "buy_with": "Buy with"
  },
  "ru": {
    "buy_with": "Оплатить с"
  }
}
</i18n>
