<template>
  <div class="tabs-list tabs-list_dropdowns">
    <div
      v-if="isChangedDropdowns"
      class="tabs-list__item tabs-list__item_reset"
      title="Сбросить фильтры"
      @click="resetAll"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.269 329">
        <path
          fill="#131313"
          d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"
        />
      </svg>
      <span>Сбросить всё</span>
    </div>
    <DatePicker
      v-model="date"
      class="tabs-list__item tabs-list__item_dropdown"
      :class="[
        { 'tabs-list__item_selected': isChangedDate },
        { 'mx-datepicker_picked': isPickedDate }
      ]"
      range
      :lang="lang"
      :append-to-body="false"
      :editable="false"
      :clearable="false"
      :confirm="true"
      confirm-text="Выбрать"
      value-type="date"
      format="MM.YYYY"
      @input="applyFilter"
      @pick="pickedDate"
      @confirm="confirmedDate"
      @open="openedDate"
    >
      <div slot="input">
        <template v-if="isChangedDate">
          <template v-if="isRangeDate">
            {{ drawDate($d(date[0], "short"), $d(date[1], "short")) }}
          </template>
          <template v-else>
            {{ $d(date[0], "short") }}
          </template>
        </template>
        <template v-else>
          Дата
        </template>
      </div>
    </DatePicker>
    <custom-select
      v-model="direction"
      :options="options.directionOptions"
      :placeholder="$t('direction_default')"
      @input="applyFilter"
    />
    <custom-select
      v-model="format"
      :options="options.formatOptions"
      :placeholder="$t('format_default')"
      @input="applyFilter"
    />
    <custom-select
      v-model="country"
      :options="options.countryOptions"
      :placeholder="$t('country_default')"
      @input="applyFilter"
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";

export default {
  name: "PulseDropdowns",
  components: {
    DatePicker
  },
  props: {
    options: { type: Object, required: true }
  },
  data() {
    return {
      date: "",
      direction: "",
      format: "",
      country: "",
      fields: {},
      lang: {
        default: "ru",
        formatLocale: {
          firstDayOfWeek: 1
        }
      },
      dropdowns: [],
      isChangedDropdowns: false,
      isRangeDate: true,
      isChangedDate: false,
      isPickedDate: false,
      pickDate: null
    };
  },
  computed: {
    dateOptions() {
      const data = [];

      if (this.date.length > 0) {
        this.date.forEach(item => {
          const day = ("0" + item.getDate()).slice(-2);
          const month = ("0" + (item.getMonth() + 1)).slice(-2);
          const year = item.getFullYear();

          data.push(`${year}-${month}-${day}`);
        });
      }

      return data;
    }
  },
  mounted() {
    this.dropdowns = this.$children.filter(
      comp => comp.$options.name === "CustomSelect"
    );
  },
  methods: {
    drawDate(from, to) {
      if (from == to) {
        return from;
      } else {
        return from + " — " + to;
      }
    },
    pickedDate(date) {
      this.isPickedDate = true;
      this.pickDate = date;
    },
    confirmedDate() {
      this.isPickedDate = false;
      this.isChangedDate = true;

      if (this.date[0] == null) {
        this.isRangeDate = false;

        this.date.forEach((item, i) => {
          this.date[i] = this.pickDate;
        });
        this.applyFilter();
      }
    },
    openedDate() {
      if (this.isChangedDate) {
        this.clearDate();
      }
    },
    clearDate() {
      this.isRangeDate = true;
      this.isChangedDate = false;
      this.isPickedDate = false;
      this.date = "";
      this.applyFilter();
    },
    applyFilter() {
      this.fields = {
        date: this.dateOptions,
        direction: this.direction,
        format: this.format,
        country: this.country
      };

      this.checkFields();
      this.$emit("change", this.fields);
    },
    checkFields() {
      this.isChangedDropdowns = false;

      for (let prop in this.fields) {
        if (this.fields[prop]) {
          this.isChangedDropdowns = true;
        }
      }
    },
    resetAll() {
      this.clearDate();

      this.dropdowns.forEach((item, i) => {
        this.dropdowns[i].reset();
      });
    }
  }
};
</script>

<i18n>
{
  "en": {
    "direction_default": "Direction...",
    "format_default": "Format...",
    "country_default": "Country..."
  },
  "ru": {
    "direction_default": "Направление...",
    "format_default": "Формат...",
    "country_default": "Страна..."
  }
}
</i18n>
