<template>
  <div class="gallery">
    <CoolLightBox
      :items="images"
      :index="index"
      @close="index = null"
    ></CoolLightBox>

    <ul class="gallery-wrapper">
      <li v-for="(image, i) in thumbs" :key="i">
        <a
          :href="image.src"
          class="gallery-item"
          :style="{ backgroundImage: 'url(' + image.src + ')' }"
          @click.prevent="index = i"
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Gallery",
  components: {
    CoolLightBox
  },
  props: {
    initItems: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [
        {
          SRC: ""
        }
      ]
    },
    initThumbs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [
        {
          src: ""
        }
      ]
    }
  },
  data() {
    return {
      items: [],
      thumbs: [],
      images: [],
      index: null
    };
  },
  mounted() {
    this.items = this.initItems;
    this.thumbs = this.initThumbs;

    this.items.forEach(item => {
      this.images.push({
        src: item.SRC
      });
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".gallery";

#{$b} {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -32px -32px;

    @include md-desktop-only {
      margin: 0 0 -32px * $zoom -32px * $zoom;
    }
    @include low-desktop {
      margin: 0 0 -20px -20px;
    }
    @include mobile {
      margin-bottom: -20px;
    }
    > li {
      width: calc(100% / 3);
      padding: 0 0 32px 32px;

      @include md-desktop-only {
        padding: 0 0 32px * $zoom 32px * $zoom;
      }
      @include low-desktop {
        padding: 0 0 20px 20px;
      }
      @include tablet {
        width: calc(100% / 2);
      }
      @include mobile {
        width: 100%;
      }
    }
  }

  &-item {
    display: block;
    background-position: center;
    background-size: cover;
    background-color: $border-gray-lt;
    border-radius: 8px;

    &:before {
      content: "";
      display: block;
      padding-top: 62.5%;
    }
    @include md-desktop-only {
      border-radius: 8px * $zoom;
    }
  }
}
</style>
