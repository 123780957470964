<template>
  <form class="form" @submit.prevent="onSubmit">
    <UserNotifyOptions
      v-for="(item, index) in results.categories"
      :key="index"
      :category="item"
      @onUpdateForm="updateForm"
    ></UserNotifyOptions>
    <button class="button button_base" :disabled="isDisabled" type="submit">
      {{ $t("save") }}
    </button>
  </form>
</template>

<script>
import axios from "axios";
import UserNotifyOptions from "./UserNotifyOptions.vue";

export default {
  name: "UserNotifyForm",
  components: {
    UserNotifyOptions
  },
  props: {
    results: {
      type: Object,
      default() {
        return {};
      }
    },
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: [],
      isDisabled: true
    };
  },
  computed: {
    formattedForm() {
      let data = [];
      for (let prop in this.form) {
        data.push({
          category: this.form[prop].category,
          option: this.form[prop].option
        });
      }
      return data;
    }
  },
  methods: {
    async onSubmit() {
      await axios.post(this.action, this.formattedForm).then(response => {
        if (response.data) {
          this.isDisabled = true;
        }
      });
    },
    updateForm(value) {
      const index = this.form.findIndex(
        item => value.category === item.category
      );

      if (index > -1) {
        this.form[index].option = value.option;
      } else {
        this.form.push(value);
      }

      this.isDisabled = false;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "save": "Save changes"
  },
  "ru": {
    "save": "Сохранить изменения"
  }
}
</i18n>
