<template>
  <div>
    <h2 v-if="$slots.title" class="h1">
      <slot name="title"></slot>{{ activeTabName }}
    </h2>
    <div
      v-if="filterdList.length"
      class="edu-cards__list"
      :class="mod ? `edu-cards__list_${mod}` : null"
    >
      <a
        v-for="(item, i) in filterdList"
        :key="i"
        :href="item.link"
        class="edu-cards__item"
        :class="{ 'edu-cards__item_wide': item.isWide }"
        :style="{ 'background-image': `url('${item.img}')` }"
        :target="item.isNewWindow ? '_blank' : false"
      >
        <span v-if="item.isVideo" class="edu-cards__play"></span>
        <ul v-if="item.tag" class="edu-cards__tags">
          <li>{{ item.tag }}</li>
        </ul>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="edu-cards__title" v-html="item.title"></div>
        <p v-if="item.description" class="edu-cards__text">
          {{ item.description }}
        </p>
        <div class="edu-cards__time edu-cards__time_white">
          <span v-if="item.time">{{ item.time }}</span>
        </div>
      </a>
    </div>
    <div v-else>
      <p>По выбранным параметрам ничего не найдено.</p>
    </div>
    <ShowMoreButton
      v-if="isMore"
      label="Показать еще"
      @showMore="showMore"
    ></ShowMoreButton>
  </div>
</template>

<script>
import ShowMoreButton from "./ShowMoreButton";
export default {
  name: "CardList",
  components: { ShowMoreButton },
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    mod: {
      type: String,
      default: ""
    },
    filterName: {
      type: String,
      default: ""
    },
    isExternalSearch: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => [
        {
          title: "",
          img: "",
          tag: "",
          isVideo: false,
          isWide: false,
          time: "",
          link: "",
          filter: "",
          isNewWindow: false
        }
      ]
    }
  },
  data() {
    return {
      itemsMultiplier: 1,
      isMore: true,
      activeTabName: "",
      activeFilter: "all",
      selectedFilter: "all",
      selectedTabName: "",
      searchValue: ""
    };
  },
  computed: {
    filterdList() {
      let count = 0;
      let list = this.items
        .filter(item => {
          return (
            (this.activeFilter === "all" ||
              item.filter === this.activeFilter) &&
            (!this.searchValue.trim().length ||
              item.title
                .toLowerCase()
                .indexOf(this.searchValue.trim().toLowerCase()) !== -1)
          );
        })
        .filter(item => {
          let show = count < this.maxCount;
          item.isWide ? (count += 2) : count++;
          return show;
        });
      this.checkMore(count);
      return list;
    },
    maxCount() {
      return 6 * this.itemsMultiplier;
    }
  },
  mounted() {
    this.$root.$on("sendSearch", this.addSearchValue);
    this.$root.$on("setActiveFilter", this.setFilter);
    this.$root.$on("selectRandomItem", this.selectRandomItem);
    this.$nextTick(() => {
      this.selectedFilter = this.activeFilter;
      this.selectedTabName = this.activeTabName;
    }, 100);
  },
  methods: {
    addSearchValue(val, searchName) {
      if (this.isExternalSearch) {
        this.searchValue = val;

        if (this.searchValue) {
          this.setFilter("all", "Поиск", this.filterName);
          this.$root.$emit("onSetActiveFilter", "all", this.filterName);
        } else {
          this.setFilter(
            this.selectedFilter,
            this.selectedTabName,
            this.filterName
          );
          this.$root.$emit(
            "onSetActiveFilter",
            this.selectedFilter,
            this.filterName
          );
        }
      } else {
        if (searchName === this.filterName) {
          this.searchValue = val;
        }
      }
    },
    setFilter(val, label, filterName) {
      if (filterName === this.filterName) {
        this.activeTabName = label;
        this.activeFilter = val;
      }
    },
    showMore() {
      this.itemsMultiplier++;
    },
    checkMore(count) {
      this.isMore = count - this.maxCount > 0;
    },
    selectRandomItem(name) {
      if (name === this.filterName) {
        let rand = Math.floor(this.filterdList.length * Math.random());
        let item = this.filterdList[rand];
        if (item.isNewWindow) {
          let win = window.open(item.link, "_blank");
          win.focus();
        } else {
          location.href = item.link;
        }
      }
    }
  }
};
</script>
