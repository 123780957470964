var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"person-slider"},[_c('div',{staticClass:"person-slider__inner"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.activeItems),function(item,key){return _c('div',{key:key,staticClass:"person-slider__slide"},[_c('div',{staticClass:"person-card person-card_clickable",class:{ 'person-card_disable': !item.DETAIL_TEXT.length },on:{"click":function($event){_vm.$modal.show('teachersModal_' + _vm.id);
              _vm.slide = key;}}},[_c('div',{staticClass:"person-card__photo",style:(("background-image:url('" + (_vm.getPersonPhotoUrl(
                  item.PREVIEW_PICTURE
                )) + "');"))}),_c('div',{staticClass:"person-card__name",domProps:{"textContent":_vm._s(item.NAME)}}),_c('div',{staticClass:"person-card__desc",domProps:{"innerHTML":_vm._s(item.PREVIEW_TEXT)}})])])}),0)]),_c('div',{staticClass:"person-slider__controls"},[_c('span',{staticClass:"person-slider__prev"},[_c('svg',{attrs:{"width":"38","height":"26","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.411 25.609c.518.521 1.38.521 1.915 0a1.29 1.29 0 000-1.845l-9.71-9.449h32.043c.747-.001 1.341-.579 1.341-1.306a1.33 1.33 0 00-1.34-1.323H4.615l9.71-9.431a1.312 1.312 0 000-1.864c-.536-.521-1.398-.521-1.915 0L.401 12.077a1.26 1.26 0 000 1.845l12.01 11.687z"}})])]),_c('span',{staticClass:"person-slider__next"},[_c('svg',{attrs:{"width":"38","height":"26","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M25.589.391c-.518-.521-1.38-.521-1.915 0a1.29 1.29 0 000 1.845l9.71 9.449H1.341C.594 11.686 0 12.264 0 12.99a1.33 1.33 0 001.34 1.323h32.044l-9.71 9.431a1.312 1.312 0 000 1.864c.535.521 1.398.521 1.915 0l12.01-11.686a1.26 1.26 0 000-1.845L25.588.391z"}})])])])]),_c('leaf-modal',{attrs:{"active-slide":_vm.slide,"popup":("teachersModal_" + _vm.id)}},_vm._l((_vm.activeItems),function(item,key){return _c('div',{key:key,staticClass:"swiper-slide leaf-modal-slider__slide"},[_c('div',{staticClass:"leaf-modal__item"},[_c('div',{staticClass:"leaf-modal__item__media"},[_c('div',{staticClass:"leaf-modal__item__thumb _g-hidden-mobile",style:(("background-image:url('" + (_vm.getPersonPhotoUrl(
                item.PREVIEW_PICTURE
              )) + "');"))}),(item.EMAIL || item.YOUTUBE)?_c('div',[_c('div',{staticClass:"leaf-modal__title-line"},[_vm._v("Контакты")]),_vm._l((item.EMAIL),function(el,i){return _c('div',{key:i,staticClass:"leaf-modal__item__email"},[_c('div',{staticClass:"leaf-modal__item__email__label"},[_vm._v(" "+_vm._s(el.DESCRIPTION)+" ")]),_c('a',{staticClass:"leaf-modal__item__email__link",attrs:{"href":("" + (el.VALUE))}},[_vm._v(_vm._s(el.VALUE))])])}),_c('div',{staticClass:"leaf-modal__social"},[_c('ul',{staticClass:"leaf-modal__social-list"},[_c('li',[_c('a',{attrs:{"href":("" + (item.YOUTUBE)),"target":"_blank"}},[_c('svg',{attrs:{"viewBox":"-21 -117 682.667 682","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"}})])])])])])],2):_vm._e()]),_c('div',{staticClass:"leaf-modal__item__body"},[_c('div',{staticClass:"leaf-modal__item__thumb _g-visible-mobile",style:(("background-image:url('" + (_vm.getPersonPhotoUrl(
                item.PREVIEW_PICTURE
              )) + "');"))}),_c('div',{staticClass:"leaf-modal__item__head"},[_c('div',{staticClass:"leaf-modal__item__title",domProps:{"textContent":_vm._s(item.NAME)}}),_c('div',{staticClass:"leaf-modal__item__subtitle"},[_c('span',{domProps:{"innerHTML":_vm._s(item.PREVIEW_TEXT)}})])]),_c('collapse-text',{staticClass:"leaf-modal__item__text",attrs:{"text":item.DETAIL_TEXT,"limit":200,"more-class":"leaf-modal__item__text__more","more-text":_vm.$t('learnMoreText'),"less-text":_vm.$t('turnText'),"on-tablet":""}},[_c('div',{domProps:{"innerHTML":_vm._s(item.DETAIL_TEXT)}})])],1),(item.PRESSCENTER)?_c('div',{staticClass:"leaf-modal__item__footer"},[_c('div',[_c('div',{staticClass:"leaf-modal__title-line"},[_vm._v(" упоминания об участнике ")]),_c('div',{staticClass:"leaf-modal__news"},[_c('div',{staticClass:"leaf-modal__news__title"},[_vm._v("Пресс-центр")]),_c('div',{staticClass:"leaf-modal__news-item"},[_c('a',{staticClass:"leaf-modal__news-item__title",attrs:{"href":("" + (item.PRESSCENTER.LINK))}},[_vm._v(_vm._s(item.PRESSCENTER.TITLE))]),_c('div',{staticClass:"leaf-modal__news-item__date"},[_vm._v(" "+_vm._s(item.PRESSCENTER.DATE)+" ")])])])])]):_vm._e()])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }