<template>
  <div class="event-card-speakers">
    <div ref="slider" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(speaker, i) in speakers"
          :key="`event_card_speaker_${i}`"
          class="swiper-slide"
        >
          <event-card-speaker :speaker="speaker" />
        </div>
      </div>

      <div v-if="!isLocked" class="event-card-speakers__arrows">
        <div ref="prev" class="event-card-speakers__prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              d="M6.20562 12.8045C6.46447 13.0652 6.89527 13.0652 7.16316 12.8045C7.42201 12.5526 7.42201 12.1334 7.16316 11.8821L2.30788 7.15757L18.3297 7.15757C18.7031 7.15699 19 6.86812 19 6.5047C19 6.14127 18.7031 5.84301 18.3297 5.84301L2.30788 5.84301L7.16316 1.12727C7.42201 0.866588 7.42201 0.446797 7.16316 0.19551C6.89527 -0.0651711 6.46386 -0.0651711 6.20562 0.19551L0.200923 6.03852C-0.0669714 6.2904 -0.0669714 6.7096 0.200923 6.96089L6.20562 12.8045Z"
            />
          </svg>
        </div>
        <div ref="next" class="event-card-speakers__next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              d="M12.7944 0.195511C12.5355 -0.0651703 12.1047 -0.0651703 11.8368 0.195511C11.578 0.447385 11.578 0.866588 11.8368 1.11788L16.6921 5.84243L0.67034 5.84243C0.296856 5.84301 0 6.13188 0 6.4953C0 6.85873 0.296856 7.15699 0.67034 7.15699L16.6921 7.15699L11.8368 11.8727C11.578 12.1334 11.578 12.5532 11.8368 12.8045C12.1047 13.0652 12.5361 13.0652 12.7944 12.8045L18.7991 6.96148C19.067 6.7096 19.067 6.2904 18.7991 6.03911L12.7944 0.195511Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";

export default {
  name: "EventCardSpeakers",
  props: {
    speakers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      slider: null
    };
  },
  computed: {
    isLocked() {
      return Boolean(this.slider && this.slider.isLocked);
    }
  },
  mounted() {
    this.initSlider();
  },
  methods: {
    initSlider() {
      this.slider = new Swiper(this.$refs.slider, {
        centeredSlides: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        simulateTouch: false,
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        navigation: {
          prevEl: this.$refs.prev,
          nextEl: this.$refs.next,
          disabledClass: "is-disabled"
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/base/_include.scss";

$b: ".event-card-speakers";

#{$b} {
  max-width: 360px;

  @include md-desktop-only {
    @include zoom(max-width, 360px);
  }

  @include low-desktop {
    max-width: 320px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;

    @include md-desktop-only {
      @include zoom(margin-bottom, 40px);
    }
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 1 !important;
    }

    &.swiper-slide-active {
      z-index: 1;
    }
  }

  &__arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96px;
    padding: 0 20px;
    margin: 24px 0 0;

    @include md-desktop-only {
      @include zoom(width, 96px);
      @include zoom(padding, 0 20px);
      @include zoom(margin, 24px 0 0);
    }

    @include low-desktop {
      width: 78px;
      padding: 0 12px;
      margin: 16px 0 0;
    }

    @include tablet {
      width: 66px;
      padding: 0 10px;
      margin: 12px 0 0;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    width: 19px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;

    @include md-desktop-only {
      @include zoom(width, 19px);
      @include zoom(height, 13px);
    }

    @include low-desktop {
      width: 16px;
      height: 11px;
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.5;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }
}
</style>
