<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="form-row">
        <form-input
          id="emailSubscribe"
          v-model="form.email"
          :label="$t('email-label') + ' *'"
          type="email"
          class="form-input_dark"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
          @blur="$v.form.email.$touch()"
        />
      </div>
      <div class="root-form__button">
        <InvisibleRecaptcha
          v-if="reCaptchaUse && reCaptchaKey"
          :sitekey="reCaptchaKey"
          :validate="reCaptchaLoaded"
          :callback="reCaptchaHandler"
          class="button button_medium button_base"
          type="submit"
          :disabled="reCaptchaIsLoaded"
        >
          {{ $t("submit-button") }}
        </InvisibleRecaptcha>
        <button v-else type="submit" class="button button_medium button_base">
          {{ $t("submit-button") }}
        </button>
      </div>
    </form>

    <notify-popup popup="successSubscribe">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              stroke="#18B346"
              stroke-linecap="round"
              stroke-width="2"
              d="M14 17l2.625 3L21 14"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("popup-title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>{{ message }}</p>
        </div>
        <div class="notify-popup__buttons _g-text-center">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('successSubscribe')"
          >
            {{ $t("popup-button") }}
          </button>
        </div>
      </div>
    </notify-popup>

    <notify-popup popup="errorSubscribe">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#FF3939" stroke-width="2" />
            <path
              fill="#FF3939"
              d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">{{ $t("popup-title") }}</div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ message }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('errorSubscribe')"
          >
            {{ $t("popup-button-close") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
  name: "SubscribeForm",
  components: { InvisibleRecaptcha },
  props: {
    rubrics: { type: String, default: "" },
    format: {
      type: String,
      default: ""
    },
    action: {
      type: String,
      default: ""
    },
    reCaptchaKey: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        rubrics: this.rubrics,
        format: this.format,
        email: "",
        resend: false
      },
      message: "",
      reCaptchaToken: "",
      reCaptchaUse: false,
      reCaptchaIsLoaded: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  mounted() {
    this.checkRecaptchaStorage();
  },
  methods: {
    checkRecaptchaStorage() {
      if (localStorage.reCaptchaUse) {
        this.reCaptchaUse = localStorage.reCaptchaUse;
      }
    },
    reCaptchaHandler(token) {
      this.reCaptchaToken = token;
      this.onSubmit();
      this.reCaptchaLoaded();
    },
    reCaptchaLoaded() {
      this.reCaptchaIsLoaded = !this.reCaptchaIsLoaded;
    },
    validate() {
      let flag = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (this.validate()) {
        const data = new FormData();
        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }
        const options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "multipart/form-data" },
          data: data
        };
        const response = await axios(options);
        if (response.data) {
          if (response.data.success) {
            this.resend = true;
            this.form.email = "";

            this.$nextTick(() => {
              this.$v.$reset();
            });

            this.message = response.data.message;
            this.$modal.show("successSubscribe");
          } else {
            this.message = response.data.message;
            this.$modal.show("errorSubscribe");
          }

          // Если введено более 3 раз включаем капчу и запоминаем
          if (response.data.countFill > 3) {
            this.reCaptchaUse = true;
            localStorage.reCaptchaUse = this.reCaptchaUse;
          }
        }
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "email-label": "Enter your e-mail",
    "submit-button": "Subscribe",
    "popup-title": "Subscribe to news",
    "popup-button": "Thanks",
    "popup-button-close": "Close"
  },
  "ru": {
    "email-label": "Введите ваш e-mail",
    "submit-button": "Подписаться",
    "popup-title": "Подписка на новости",
    "popup-button": "Спасибо",
    "popup-button-close": "Закрыть окно"
  }
}
</i18n>
