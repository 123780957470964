<template>
  <div class="swiper-container root-slider__container">
    <div class="root-slider__controls">
      <div class="root-slider__prev">
        <svg
          width="38"
          height="26"
          viewBox="0 0 38 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.5888 0.391022C25.0711 -0.130341 24.2095 -0.130341 23.6737 0.391022C23.156 0.89477 23.156 1.73318 23.6737 2.23575L33.3843 11.6849L1.34068 11.6849C0.593712 11.686 0 12.2638 0 12.9906C0 13.7175 0.593712 14.314 1.34068 14.314L33.3843 14.314L23.6737 23.7455C23.156 24.2668 23.156 25.1064 23.6737 25.609C24.2095 26.1303 25.0723 26.1303 25.5888 25.609L37.5982 13.923C38.1339 13.4192 38.1339 12.5808 37.5982 12.0782L25.5888 0.391022Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="root-slider__next">
        <svg
          width="38"
          height="26"
          viewBox="0 0 38 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.5888 0.391022C25.0711 -0.130341 24.2095 -0.130341 23.6737 0.391022C23.156 0.89477 23.156 1.73318 23.6737 2.23575L33.3843 11.6849L1.34068 11.6849C0.593712 11.686 0 12.2638 0 12.9906C0 13.7175 0.593712 14.314 1.34068 14.314L33.3843 14.314L23.6737 23.7455C23.156 24.2668 23.156 25.1064 23.6737 25.609C24.2095 26.1303 25.0723 26.1303 25.5888 25.609L37.5982 13.923C38.1339 13.4192 38.1339 12.5808 37.5982 12.0782L25.5888 0.391022Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "BannerSlider",
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    this.slider = new Swiper(".root-slider__container", {
      slidesPerView: 1,
      effect: "fade",
      navigation: {
        prevEl: ".root-slider__prev",
        nextEl: ".root-slider__next",
        disabledClass: "_disabled"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      }
    });
  }
};
</script>
