<template>
  <div>
    <div v-if="sections.length" class="tabs tabs_light">
      <div class="tabs-container">
        <div class="tabs-overflow">
          <ul class="tabs-list">
            <li
              :class="{
                'tabs-list__item_active': !sectionIds.length
              }"
              class="tabs-list__item tabs-list__item_mr20"
              @click="switchSection(null)"
            >
              {{ $t("all") }}
            </li>
            <li
              v-for="section in sections"
              :key="section.ID"
              :class="{
                'tabs-list__item_active': sectionIds.indexOf(section.ID) >= 0
              }"
              class="tabs-list__item tabs-list__item_mr20"
              @click="switchSection(section.ID)"
            >
              {{ section.NAME }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="tab"
      class="edu-cards edu-cards_medium"
      :class="{ 'edu-cards_large-first-on-tablet': isLargeFirst }"
    >
      <transition-group class="edu-cards__list" name="card-list" tag="div">
        <a
          v-for="item in activeItems"
          :key="item.ID"
          :href="item.DETAIL_LINK_URL || item.DETAIL_PAGE_URL"
          :target="item.LINK_NEW_WINDOW ? `_blank` : ''"
          :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
          class="edu-cards__item"
        >
          <div
            v-if="!item.EMPTY_REGISTRATION_LAST_DATE"
            class="edu-cards__future-date"
          >
            <div class="edu-cards__future-date__inner">
              <span class="edu-cards__future-date__text">{{
                item.IS_CLOSED ? $t("closed") : $t("start")
              }}</span>
              <span v-if="!item.IS_CLOSED" class="edu-cards__future-date__num">
                <strong>{{ item.REGISTRATION_LAST_DATE.DAY }}</strong>
                {{ item.REGISTRATION_LAST_DATE.MONTH }}
              </span>
            </div>
          </div>
          <ul v-if="showTags" class="edu-cards__tags">
            <li
              v-for="(section, key2) in item.SECTIONS"
              :key="key2"
              v-text="section.NAME"
            />
          </ul>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="edu-cards__title" v-html="item.NAME" />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="item.PLACE" class="edu-cards__place" v-html="item.PLACE" />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="item.PRICE" class="edu-cards__place" v-html="item.PRICE" />
          <div class="edu-cards__buttons">
            <!-- Записаться -->
            <template v-if="!item.IS_CLOSED && !item.HIDE_SIGN_UP">
              <a
                v-if="item.LINK_URL"
                :href="item.LINK_URL"
                :target="`${item.LINK_NEW_WINDOW === 'Y' ? '_blank' : ''}`"
                class="button button_light"
              >
                {{ $t("sign_up") }}
              </a>
              <button
                v-else
                class="button button_light"
                type="button"
                @click.stop.prevent="showRegistrationPopup(item)"
              >
                {{ $t("sign_up") }}
              </button>
            </template>
            <!-- Подробнее -->
            <a
              class="edu-cards__buttons__link "
              :class="{
                'edu-cards__buttons__link_closed': item.IS_CLOSED
              }"
              :href="item.DETAIL_LINK_URL || item.DETAIL_PAGE_URL"
              :target="`${item.DETAIL_LINK_NEW_WINDOW === 'Y' ? '_blank' : ''}`"
            >
              {{ $t("more") }}
            </a>
          </div>
        </a>
      </transition-group>
      <notify-popup
        v-for="(item, key) in activeItems"
        :key="key"
        :popup="`programmRegistrationPopup-${item.ID}`"
        :width="580"
      >
        <programm-registration-form
          url="/local/ajax/programm_registration.php"
          :programm="item.NAME"
          :programm-id="item.ID"
          :legal="!!item.LEGAL_PERSON"
          :id-form="item.ID"
        />
      </notify-popup>
    </div>
    <not-found v-show="!activeItems.length" :text="$t('no_items')" />
    <div
      v-show="nextUrl"
      class="root-news__show-more root-news__show-more_bg"
      @click="loadMore(false)"
    >
      <span>
        {{ $t("show_more") }}
        <svg
          width="13"
          height="19"
          viewBox="0 0 13 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="root-news__show-more-arrow"
        >
          <path
            d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
            fill="#131313"
          ></path>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProgrammsList",
  props: {
    params: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    initPaginator: { type: Object, default: () => ({}) },
    initNextUrl: { type: String, default: "" },
    showSearchForm: { type: Boolean, default: true },
    showTags: { type: Boolean, default: true },
    isLargeFirst: { type: Boolean, default: false }
  },
  data() {
    return {
      searchTerm: "",
      sections: [],
      sectionIds: [],
      sortFields: [
        { name: this.$i18n.t("sorting.desc"), value: "desc" },
        { name: this.$i18n.t("sorting.asc"), value: "asc" }
      ],
      sortBy: "desc",
      moreBtn: null,
      paginator: {},
      activeItems: [],
      nextUrl: "",
      programm: {}
    };
  },
  computed: {
    paginatorText() {
      let shownCount = this.paginator.NavRecordCount;
      if (this.paginator.NavPageNomer !== this.paginator.NavPageCount) {
        shownCount = this.paginator.NavPageNomer * this.paginator.NavPageSize;
      }
      if (this.paginator.NavRecordCount === 0) {
        shownCount = 0;
      }
      return shownCount + "/" + this.paginator.NavRecordCount;
    },
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return this.$i18n.t("search");
      } else {
        return this.$i18n.t("search_journal");
      }
    }
  },
  watch: {
    searchTerm(value) {
      if (!value.trim()) {
        this.loadFirstPage();
      }
    },
    sortBy() {
      this.loadFirstPage();
    },
    sectionIds() {
      this.loadFirstPage();
    }
  },
  mounted() {
    this.sections = this.params.SECTIONS;
    this.activeItems = this.items;
    this.nextUrl = this.initNextUrl;
    this.paginator = this.initPaginator;
    this.getParams();
  },
  methods: {
    getParams() {
      if (!this.params.CURRENT_SECTION_CODE) return;

      this.params.SECTIONS.forEach(section => {
        if (section.CODE === this.params.CURRENT_SECTION_CODE) {
          this.switchSection(section.ID);
        }
      });
    },
    loadFirstPage() {
      const lines = [];
      if (this.type) {
        lines.push(`type=${this.type}`);
      }
      if (this.searchTerm) {
        lines.push("q=" + this.searchTerm);
      }
      if (this.sectionIds.length) {
        lines.push("section=" + this.sectionIds[0]);
      }
      const typesStr = lines.join("&");
      this.nextUrl = `?ajax=Y&PAGEN_${this.paginator.NavNum}=1&sort=${this.sortBy}&${typesStr}`;
      this.loadMore(true);
    },
    async loadMore(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.activeItems = [];
        this.clearItems = false;
      }
      this.activeItems = [...this.activeItems, ...res.data.ITEMS];
    },
    switchSection(id) {
      if (id) {
        // Новая постановка задачи по реализации фильтра не требует множественной фильтрации.
        // Для реализации задачи указываем массив с единственным id.
        this.sectionIds = [id];
        // При этом оставляем возможность вернуть множественную фильтрацию.
        // const idx = this.sectionIds.indexOf(id);
        // if (idx >= 0) {
        //   this.sectionIds.splice(idx, 1);
        // } else {
        //   this.sectionIds.push(id);
        // }
      } else {
        this.sectionIds = [];
      }
    },
    search() {
      this.loadFirstPage();
    },
    clearField() {
      this.searchTerm = "";
    },
    goDetailLinkUrl(item) {
      if (item.LINK_NEW_WINDOW) {
        window.open(item.DETAIL_LINK_URL, "_blank");
      } else {
        location.href = item.DETAIL_LINK_URL || item.DETAIL_PAGE_URL;
      }
    },
    showRegistrationPopup(programm) {
      this.programm = programm;
      this.$modal.show(`programmRegistrationPopup-${programm.ID}`);
    }
  }
};
</script>

<i18n>
{
  "en": {
    "all": "All",
    "start": "Start:",
    "closed": "Closed",
    "sign_up": "Sign up",
    "more": "More",
    "show_more": "Show more",
    "sorting": {
      "desc": "Latest first",
      "asc": "Oldest first"
    },
    "search": "Search",
    "search_journal": "Search logs and reports",
    "no_items": "Nothing found"
  },
  "ru": {
    "all": "Все",
    "start": "Старт:",
    "closed": "Набор закрыт",
    "sign_up": "Записаться",
    "more": "Подробнее",
    "show_more": "Показать еще",
    "sorting": {
      "desc": "Сначала новые",
      "asc": "Сначала старые"
    },
    "search": "Поиск",
    "search_journal": "Поиск по журналам и отчетам",
    "no_items": "Ничего не найдено"
  }
}
</i18n>
