<template>
  <div v-if="closeModal" class="edu-modal-cookie">
    <div class="container">
      <div class="edu-modal-cookie__body">
        <div class="edu-modal-cookie__text">
          <slot v-if="!hideModalInfo" name="text" />
          <slot v-if="!isCertificateAvailable" name="textcertificate" />
        </div>
        <div class="edu-modal-cookie__btn" @click.prevent="closeCookie()">
          <slot v-if="!hideModalInfo" name="button" />
        </div>
        <div class="edu-modal-cookie__btn" @click="closeCookie()">
          <slot v-if="!isCertificateAvailable" name="buttoncertificate" />
        </div>
        <div class="edu-modal-cookie__close">
          <div class="leaf-modal-close" @click="closeCookie">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
            >
              <path
                d="M1.116 25.116a1.25 1.25 0 001.768 1.768l-1.768-1.768zM26.884 2.884a1.25 1.25 0 00-1.768-1.768l1.768 1.768zm-1.768 24a1.25 1.25 0 001.768-1.768l-1.768 1.768zM2.884 1.116a1.25 1.25 0 10-1.768 1.768l1.768-1.768zm0 25.768l12-12-1.768-1.768-12 12 1.768 1.768zm12-12l12-12-1.768-1.768-12 12 1.768 1.768zm-1.768 0l12 12 1.768-1.768-12-12-1.768 1.768zm1.768-1.768l-12-12-1.768 1.768 12 12 1.768-1.768z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalCookie",
  data() {
    return {
      hideModalInfo: false,
      closeModal: true,
      isCertificateAvailable: true
    };
  },
  mounted() {
    this.closeModal = !(localStorage.getItem("cookie-modal") === "true");

    if (
      localStorage.getItem("cookie-modal") === "true" &&
      localStorage.getItem("certificate-modal") !== "true"
    ) {
      this.checkCertificate();
    }
  },
  methods: {
    closeCookie() {
      this.closeModal = !this.closeModal;

      if (localStorage.getItem("cookie-modal") !== "true") {
        this.checkCertificate();
      } else {
        localStorage.setItem("certificate-modal", "true");
      }

      localStorage.setItem("cookie-modal", "true");
    },
    async checkCertificate() {
      try {
        const response = await axios.get("https://www.sberbank.ru/ru/person");

        if (response.status === 200) {
          localStorage.setItem("certificate-modal", "true");
        }
      } catch (error) {
        if (this.isNetworkError(error)) {
          setTimeout(this.showCertificateModal, 3000);
        }
      }
    },
    isNetworkError(error) {
      return !!error.isAxiosError && !error.response;
    },
    showCertificateModal() {
      this.hideModalInfo = true;
      this.closeModal = true;
      this.isCertificateAvailable = false;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";
$b: ".edu-modal-cookie";

#{$b} {
  position: fixed;
  bottom: 35px;
  z-index: 999;
  width: 100%;

  @include tablet-only {
    bottom: 40px;
  }

  @include mobile {
    bottom: 21px;
  }

  &__body {
    display: flex;
    position: relative;
    max-width: 1216px;
    background-color: $white-true;
    padding: 26px 31px 19px;
    border-radius: 15px;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.15);

    @include tablet {
      flex-wrap: wrap;
    }

    @include mobile {
      padding: 26px 20px 20px 18px;
    }
  }

  &__text {
    font-family: "SB Sans Text", Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;

    @include tablet {
      width: 100%;
      margin-bottom: 15px;
    }

    &-link {
      text-decoration: underline;
    }
  }

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .button {
      margin-left: 20px;

      @include tablet {
        margin-left: 0;
      }
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 11px;

    .leaf-modal-close {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
