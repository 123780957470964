<template>
  <div ref="applicationPayment" class="application-payment">
    <template v-if="paymentMethod === 'online'">
      <div class="application-panels">
        <!-- Выбор способа онлайн платежа -->
        <div v-if="!isOnlineDisabled" class="application-panels__column">
          <div class="application-panel">
            <div class="application-panel__title">
              {{ $t("online_title") }}
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="application-panel__text" v-html="$t('online_text')" />
            <form class="form">
              <div class="form-row">
                <custom-select
                  v-model="form.onlineMethod"
                  :options="paymentMethods"
                  :placeholder="$t('payment_default')"
                  is-return-object
                  prop-name-text="name"
                  prop-name-value="code"
                  is-flat-input
                />
                <div
                  v-if="
                    $v.form.onlineMethod.$error &&
                      !$v.form.onlineMethod.required
                  "
                  class="form-input-message is-error"
                >
                  {{ $t("required_online") }}
                </div>
              </div>
            </form>
            <div class="application-panel__offer-block">
              <div class="application-payment__agreement">
                <label class="form-checkbox">
                  <input
                    v-model="form.onlineAgreement"
                    class="form-checkbox__input"
                    :class="{
                      'form-checkbox__input--error':
                        $v.form.onlineAgreement.$error
                    }"
                    type="checkbox"
                    name="data-agreement"
                    @change="$v.form.onlineAgreement.$touch()"
                  />
                  <span class="form-checkbox__text">
                    {{ $t("agreement_text") }}
                    <a
                      :href="
                        $t(
                          `agreement_link.online.${
                            isTesting ? 'digital' : elementCode
                          }`
                        )
                      "
                      target="_blank"
                    >
                      {{ $t("agreement_link_text") }}
                    </a>
                  </span>
                </label>
                <div
                  v-if="$v.form.onlineAgreement.$error"
                  class="form-input-message is-error"
                >
                  {{ $t("agreement_need") }}
                </div>
              </div>
            </div>
            <div class="application-panel__buttons">
              <apple-pay-button
                v-show="
                  form.onlineMethod.code === 'apple_pay' && isApplePayAvailable
                "
                id="applePayButton"
                :options="applePayOptions"
                @onPaymentAuthorized="applePayPayment"
                @onPaymentCancel="applePayCancel"
                @onCanMakePayments="onCanMakePayments"
              />
              <google-pay-button
                v-show="
                  form.onlineMethod.code === 'google_pay' &&
                    isGooglePayAvailable
                "
                id="googlePayButton"
                :options="googlePayOptions"
                @payed="googlePayPayment($event)"
                @cancel="googlePayCancel"
                @onCanMakePayments="onCanMakePayments"
              />
              <button
                v-show="
                  form.onlineMethod.code !== 'apple_pay' &&
                    form.onlineMethod.code !== 'google_pay'
                "
                class="button button_base"
                :class="{ 'is-imitate-disabled': isDisabledPaymentButton }"
                type="button"
                @click.prevent="addPaymentOnline"
              >
                {{ $t("payment_button") }}
              </button>
            </div>
          </div>
        </div>
        <!-- Выбор субъекта (физ. или юр.) -->
        <div class="application-panels__column">
          <div class="application-panel">
            <div class="application-panel__title">
              {{ $t("requisites_title") }}
            </div>
            <div class="application-panel__text">
              {{ $t("requisites_text") }}
            </div>
            <ul class="application-payment__persons">
              <li>
                <div class="radio-input">
                  <input
                    id="person-1"
                    v-model="personType"
                    class="radio-input__input"
                    type="radio"
                    name="person"
                    value="individual"
                  />
                  <label class="radio-input__text" for="person-1">
                    {{ $t("person_individual") }}
                  </label>
                </div>
              </li>
              <li>
                <div class="radio-input">
                  <input
                    id="person-2"
                    v-model="personType"
                    class="radio-input__input"
                    type="radio"
                    name="person"
                    value="entity"
                  />
                  <label class="radio-input__text" for="person-2">
                    {{ $t("person_entity") }}
                  </label>
                </div>
              </li>
            </ul>
            <div v-if="isNeedPersonType" class="form-input-message is-error">
              {{ $t("required_person_type") }}
            </div>
            <div class="application-panel__buttons">
              <button
                class="button button_base"
                type="button"
                :class="{ 'is-imitate-disabled': !personType }"
                @click.prevent="changePaymentMethod('requisites')"
              >
                {{ $t("payment_button") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Оплата по реквизитам -->
    <template v-if="paymentMethod === 'requisites'">
      <!-- Оплата по реквизитам для Юр. лица -->
      <template v-if="personType === 'entity'">
        <div class="application-panel">
          <div class="application-panel__title">
            {{ $t("requisites_entity_title") }}
          </div>
          <form class="form">
            <div class="form-grid form-grid_large">
              <div class="form-grid__col-lg-1-2">
                <div class="form-row">
                  <form-input
                    id="organization"
                    v-model="form.organization"
                    :label="$t('form.organization') + ' *'"
                    :error="$v.form.organization.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.organization.$error &&
                          !$v.form.organization.required
                      },
                      {
                        type: 'error',
                        max: $v.form.organization.$params.maxLength.max,
                        state: !$v.form.organization.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="ogrn"
                    v-model="form.ogrn"
                    :label="$t('form.ogrn') + ' *'"
                    :error="$v.form.ogrn.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state: $v.form.ogrn.$error && !$v.form.ogrn.required
                      },
                      {
                        type: 'error',
                        i18: 'error.numeric',
                        state: !$v.form.ogrn.numeric
                      },
                      {
                        type: 'error',
                        min: $v.form.ogrn.$params.minLength.min,
                        state: !$v.form.ogrn.minLength
                      },
                      {
                        type: 'error',
                        max: $v.form.ogrn.$params.maxLength.max,
                        state: !$v.form.ogrn.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="inn"
                    v-model="form.inn"
                    :label="$t('form.inn') + ' *'"
                    :error="$v.form.inn.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state: $v.form.inn.$error && !$v.form.inn.required
                      },
                      {
                        type: 'error',
                        i18: 'error.numeric',
                        state: !$v.form.inn.numeric
                      },
                      {
                        type: 'error',
                        min: $v.form.inn.$params.minLength.min,
                        state: !$v.form.inn.minLength
                      },
                      {
                        type: 'error',
                        max: $v.form.inn.$params.maxLength.max,
                        state: !$v.form.inn.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="legalFio"
                    v-model="form.legalFio"
                    :label="$t('form.legalFio') + ' *'"
                    :error="$v.form.legalFio.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.legalFio.$error && !$v.form.legalFio.required
                      },
                      {
                        type: 'error',
                        max: $v.form.legalFio.$params.maxLength.max,
                        state: !$v.form.legalFio.maxLength
                      }
                    ]"
                  />
                </div>
              </div>
              <div class="form-grid__col-lg-1-2">
                <div class="form-row">
                  <form-input
                    id="kpp"
                    v-model="form.kpp"
                    :label="$t('form.kpp') + ' *'"
                    :error="$v.form.kpp.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state: $v.form.kpp.$error && !$v.form.kpp.required
                      },
                      {
                        type: 'error',
                        i18: 'error.alpha-num',
                        state: !$v.form.kpp.alphaNum
                      },
                      {
                        type: 'error',
                        min: $v.form.kpp.$params.minLength.min,
                        state: !$v.form.kpp.minLength
                      },
                      {
                        type: 'error',
                        max: $v.form.kpp.$params.maxLength.max,
                        state: !$v.form.kpp.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="legalAddress"
                    v-model="form.legalAddress"
                    :label="$t('form.legalAddress') + ' *'"
                    :error="$v.form.legalAddress.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.legalAddress.$error &&
                          !$v.form.legalAddress.required
                      },
                      {
                        type: 'error',
                        max: $v.form.legalAddress.$params.maxLength.max,
                        state: !$v.form.legalAddress.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="factAddress"
                    v-model="form.factAddress"
                    :label="$t('form.factAddress') + ' *'"
                    :error="$v.form.factAddress.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.factAddress.$error &&
                          !$v.form.factAddress.required
                      },
                      {
                        type: 'error',
                        max: $v.form.factAddress.$params.maxLength.max,
                        state: !$v.form.factAddress.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="legalEmail"
                    v-model="form.legalEmail"
                    :label="$t('form.legalEmail') + ' *'"
                    :error="
                      $v.form.legalEmail.$error || !$v.form.legalEmail.email
                    "
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.legalEmail.$error &&
                          !$v.form.legalEmail.required
                      },
                      {
                        type: 'error',
                        i18: 'error.email.invalid',
                        state: !$v.form.legalEmail.email
                      },
                      {
                        type: 'error',
                        max: $v.form.legalEmail.$params.maxLength.max,
                        state: !$v.form.legalEmail.maxLength
                      }
                    ]"
                  />
                </div>
              </div>
            </div>
          </form>
          <div class="application-panel__buttons">
            <button
              class="button button_base"
              :class="{ 'is-imitate-disabled': !form.requisitesAgreement }"
              type="button"
              @click.prevent="addPaymentBill"
            >
              <template v-if="isShowFinalStep">
                {{ $t("continue") }}
              </template>
              <template v-else>
                {{ $t("view_invoice") }}
              </template>
            </button>
          </div>
          <div class="application-panel__offer-block">
            <div class="application-payment__agreement">
              <label class="form-checkbox">
                <input
                  v-model="form.requisitesAgreement"
                  class="form-checkbox__input"
                  :class="{
                    'form-checkbox__input--error':
                      $v.form.requisitesAgreement.$error
                  }"
                  type="checkbox"
                  name="data-agreement"
                  @change="$v.form.requisitesAgreement.$touch()"
                />
                <span class="form-checkbox__text">
                  {{ $t("agreement_text") }}
                  <a
                    :href="
                      offerLinkLegal !== ''
                        ? offerLinkLegal
                        : $t('agreement_link.' + elementCode + '.entity')
                    "
                    target="_blank"
                  >
                    {{ $t("agreement_link_text") }}
                  </a>
                </span>
              </label>
              <div
                v-if="$v.form.requisitesAgreement.$error"
                class="form-input-message is-error"
              >
                {{ $t("agreement_need") }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- Оплата по реквизитам для Физ. лица -->
      <template v-if="personType === 'individual'">
        <div class="application-panels">
          <div class="application-panels__column">
            <div class="application-panel">
              <div class="application-panel__title">
                {{ $t("requisites_payment_title") }}
              </div>
              <form class="form">
                <div class="form-row">
                  <form-input
                    id="addressRegistration"
                    v-model="form.addressRegistration"
                    :label="$t('form.addressRegistration') + ' *'"
                    :error="$v.form.addressRegistration.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.addressRegistration.$error &&
                          !$v.form.addressRegistration.required
                      },
                      {
                        type: 'error',
                        max: $v.form.addressRegistration.$params.maxLength.max,
                        state: !$v.form.addressRegistration.maxLength
                      }
                    ]"
                  />
                </div>
                <div class="form-row">
                  <form-input
                    id="individualInn"
                    v-model="form.individualInn"
                    :label="$t('form.individualInn') + ' *'"
                    :error="$v.form.individualInn.$error"
                    :messages="[
                      {
                        type: 'error',
                        i18: 'error.required',
                        state:
                          $v.form.individualInn.$error &&
                          !$v.form.individualInn.required
                      },
                      {
                        type: 'error',
                        i18: 'error.numeric',
                        state: !$v.form.individualInn.numeric
                      },
                      {
                        type: 'error',
                        min: $v.form.individualInn.$params.minLength.min,
                        state: !$v.form.individualInn.minLength
                      },
                      {
                        type: 'error',
                        max: $v.form.individualInn.$params.maxLength.max,
                        state: !$v.form.individualInn.maxLength
                      }
                    ]"
                  />
                </div>
              </form>
              <div class="application-panel__buttons">
                <button
                  class="button button_base"
                  :class="{ 'is-imitate-disabled': !form.requisitesAgreement }"
                  type="button"
                  @click.prevent="addPaymentBill"
                >
                  <template v-if="isShowFinalStep">
                    {{ $t("continue") }}
                  </template>
                  <template v-else>
                    {{ $t("view_invoice") }}
                  </template>
                </button>
              </div>
              <div class="application-panel__offer-block">
                <div class="application-payment__agreement">
                  <label class="form-checkbox">
                    <input
                      v-model="form.requisitesAgreement"
                      class="form-checkbox__input"
                      :class="{
                        'form-checkbox__input--error':
                          $v.form.requisitesAgreement.$error
                      }"
                      type="checkbox"
                      name="data-agreement"
                      @change="$v.form.requisitesAgreement.$touch()"
                    />
                    <span class="form-checkbox__text">
                      {{ $t("agreement_text") }}
                      <a
                        :href="
                          offerLinkIndividual !== ''
                            ? offerLinkIndividual
                            : $t(
                                'agreement_link.' + elementCode + '.individual'
                              )
                        "
                        target="_blank"
                      >
                        {{ $t("agreement_link_text") }}
                      </a>
                    </span>
                  </label>
                  <div
                    v-if="$v.form.requisitesAgreement.$error"
                    class="form-input-message is-error"
                  >
                    {{ $t("agreement_need") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- Модалка 'Посмотреть счет' -->
      <application-invoice
        :person-type="personType"
        :payment-data="paymentData"
        :element-code="elementCode"
      />
    </template>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  sameAs,
  numeric,
  alphaNum,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "ApplicationPayment",
  props: {
    applicationId: { type: String, default: "" },
    paymentData: { type: Object, default: () => {} },
    actionAddPaymentOnline: { type: String, default: "" },
    actionAddPaymentBill: { type: String, default: "" },
    initPaymentMethods: { type: Array, default: () => [] },
    initPersonType: { type: String, default: "" },
    elementCode: { type: String, default: "" },
    isShowFinalStep: { type: Boolean, default: false },
    isOnlineDisabled: { type: Boolean, default: false },
    offerLinkIndividual: { type: String, default: "" },
    offerLinkLegal: { type: String, default: "" },
    isTesting: { type: Boolean, default: false }
  },
  data() {
    return {
      paymentMethod: "online",
      tryingSelectMethod: false,
      personType: this.initPersonType,
      form: {
        // Для оплаты онлайн
        onlineMethod: "",
        onlineAgreement: false,
        // Данные юр. лица
        organization: "",
        ogrn: "",
        inn: "",
        kpp: "",
        legalAddress: "",
        factAddress: "",
        legalFio: "",
        legalEmail: "",
        // Платежные реквизиты физ. лица
        addressRegistration: "",
        individualInn: "",
        requisitesAgreement: false
      },
      formSend: false,
      isGooglePayEnable: false,
      isGooglePayAvailable: false,
      googlePayOptions: {
        environment: "TEST",
        buttonColor: "white",
        allowedCardNetworks: [
          "AMEX",
          "DISCOVER",
          "INTERAC",
          "JCB",
          "MASTERCARD",
          "VISA"
        ],
        allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        merchantInfo: {
          merchantName: "Example Merchant",
          merchantId: "12345678901234567890"
        },
        transactionInfo: {
          totalPriceStatus: "FINAL",
          totalPrice: "1.00", // Сюда вставить прайс
          currencyCode: "RUB",
          countryCode: "RU"
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "example",
            gatewayMerchantId: "exampleGatewayMerchantId"
          }
        }
      },
      isApplePayEnable: false,
      isApplePayAvailable: false,
      applePayOptions: {
        merchantId: "merchant.com.example.mystore",
        merchantName: "Example merchant name",
        countryCode: "RU", // код страны магазина
        currencyCode: "RUB", //  код валюты платежа
        total: {
          label: "Your Company Inc.", // название компании
          amount: "1.50" // Итоговая сумма платежа
        },
        lineItems: [
          {
            label: "Пакет программы 1", // Название элемента
            type: "final",
            amount: "1.50" // Стоимость элемента
          }
        ],
        supportedNetworks: ["masterCard", "visa"], // принимаемые типы карт
        merchantCapabilities: ["supports3DS"],
        requiredShippingContactFields: ["phone", "email"], // дополнительные данные плательщика
        paymentSession: "http://localhost:8080/api/ext/apple-pay/paymentSession"
      }
    };
  },
  computed: {
    formRules() {
      let rules;
      if (this.paymentMethod === "online") {
        rules = {
          onlineMethod: {
            required
          },
          onlineAgreement: {
            required,
            sameAs: sameAs(() => true)
          }
        };
      } else if (this.paymentMethod === "requisites") {
        if (this.personType === "individual") {
          rules = {
            addressRegistration: { required, maxLength: maxLength(99) },
            individualInn: {
              required,
              numeric,
              minLength: minLength(12),
              maxLength: maxLength(12)
            },
            requisitesAgreement: {
              required,
              sameAs: sameAs(() => true)
            }
          };
        } else if (this.personType === "entity") {
          rules = {
            organization: { required, maxLength: maxLength(99) },
            ogrn: {
              required,
              numeric,
              minLength: minLength(13),
              maxLength: maxLength(15)
            },
            inn: {
              required,
              numeric,
              minLength: minLength(10),
              maxLength: maxLength(12)
            },
            kpp: {
              required,
              alphaNum,
              minLength: minLength(9),
              maxLength: maxLength(9)
            },
            legalAddress: { required, maxLength: maxLength(99) },
            factAddress: { required, maxLength: maxLength(99) },
            legalFio: { required, maxLength: maxLength(99) },
            legalEmail: { required, email, maxLength: maxLength(99) },
            requisitesAgreement: {
              required,
              sameAs: sameAs(() => true)
            }
          };
        }
      }

      return rules;
    },
    paymentMethods() {
      let result = this.initPaymentMethods
        .filter((item, index) => {
          if (!this.isApplePayAvailable) {
            return item.code !== "apple_pay";
          } else {
            return this.initPaymentMethods[index];
          }
        })
        .filter((item, index) => {
          if (!this.isGooglePayAvailable) {
            return item.code !== "google_pay";
          } else {
            return this.initPaymentMethods[index];
          }
        });

      return result;
    },
    isDisabledPaymentButton() {
      return !(
        this.form.onlineMethod.code !== undefined &&
        this.form.onlineMethod.code !== "" &&
        this.form.onlineAgreement
      );
    },
    isNeedPersonType() {
      return !this.personType && this.tryingSelectMethod;
    }
  },
  validations() {
    return {
      form: this.formRules
    };
  },
  methods: {
    async addPaymentOnline() {
      if (this.isValidate()) {
        const data = new FormData();
        data.append("applicationId", this.applicationId);
        data.append("paymentMethod", this.form.onlineMethod.code);

        let options = {
          url: this.actionAddPaymentOnline,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };

        await axios(options).then(response => {
          switch (response.data.status) {
            case "success":
              window.location.href = response.data.data.paymentLink;
              break;

            case "error":
              this.onError(response.data.errors[0]);
              break;
          }
        });
      }
    },
    changePaymentMethod(methodName) {
      this.tryingSelectMethod = true;

      if (!this.personType) return;

      this.tryingSelectMethod = false;
      this.paymentMethod = methodName;
      this.$emit("onScrollToTop", 300, { offset: -80 });
    },
    async addPaymentBill() {
      if (this.isValidate()) {
        const data = new FormData();
        data.append("applicationId", this.applicationId);
        data.append("userType", this.personType);

        if (this.personType === "entity") {
          // Если было выбрано юридическое лицо
          data.append(
            "paymentInfo",
            JSON.stringify({
              company: this.form.organization,
              ogrn: this.form.ogrn,
              inn: this.form.inn,
              kpp: this.form.kpp,
              legalAddress: this.form.legalAddress,
              factAddress: this.form.factAddress,
              legalFio: this.form.legalFio,
              legalEmail: this.form.legalEmail
            })
          );
        } else if (this.personType === "individual") {
          // Если было выбрано физическое лицо
          data.append(
            "paymentInfo",
            JSON.stringify({
              addressRegistration: this.form.addressRegistration,
              individualInn: this.form.individualInn
            })
          );
        }

        data.append(
          "paymentId",
          this.paymentData ? this.paymentData.number : ""
        );

        let options = {
          url: this.actionAddPaymentBill,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };

        await axios(options).then(response => {
          switch (response.data.status) {
            case "success":
              this.formSend = true;

              // Дополняем данные ссылкой на оферту
              response.data.data.documentLink =
                this.personType === "entity"
                  ? this.$t("agreement_link." + this.elementCode + ".entity")
                  : this.$t(
                      "agreement_link." + this.elementCode + ".individual"
                    );

              this.$nextTick(() => {
                this.onPaymentComplete(this.personType, response.data.data);
              });
              break;

            case "error":
              this.onError(response.data.errors[0]);
              break;
          }
        });
      }
    },
    isValidate() {
      this.$v.$touch();

      return !this.$v.$invalid;
    },
    onError(error) {
      this.$nextTick(() => {
        this.$emit("onErrorPayment", error);
      });
    },
    googlePayPayment(event) {
      // eslint-disable-next-line no-console
      console.log("Google Pay оплата:", event);
    },
    googlePayCancel(event) {
      // eslint-disable-next-line no-console
      console.log("Google Pay прервано:", event);
    },
    applePayPayment(event) {
      // eslint-disable-next-line no-console
      console.log("Apple Pay оплата:", event);
    },
    applePayCancel(event) {
      // eslint-disable-next-line no-console
      console.log("Apple Pay прервано:", event);
    },
    onPaymentComplete(personType, paymentData) {
      this.$emit("onPaymentComplete", personType, paymentData);
    },
    onCanMakePayments(paymentMethodName) {
      switch (paymentMethodName) {
        case "GooglePay":
          this.isGooglePayAvailable = this.isGooglePayEnable;
          break;

        case "ApplePay":
          this.isApplePayAvailable = this.isApplePayEnable;
          break;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-payment";

#{$b} {
  display: block;

  &__panels {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;

    @include md-desktop-only {
      margin-left: -40px * $zoom;
    }

    @include tablet {
      margin-left: -20px;
    }

    & > li {
      padding-left: 40px;
      width: 50%;

      @include md-desktop-only {
        padding-left: 40px * $zoom;
      }

      @include tablet {
        padding-left: 20px;
      }

      @include mobile {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &:only-child {
        @include low-desktop {
          width: 100%;
        }
      }
    }
  }

  &__panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white-true;
    border-radius: 5px;
    padding: 24px 50px 40px;

    @include md-desktop-only {
      border-radius: 5px * $zoom;
      padding: 24px * $zoom 50px * $zoom 40px * $zoom;
    }

    @include low-desktop {
      padding: 24px 32px 36px;
    }

    @include mobile {
      padding: 20px 20px 24px;
    }

    &-title {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.42;
      margin: 0;

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include tablet {
        font-size: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 26px;

        @include md-desktop-only {
          margin-bottom: 26px * $zoom;
        }

        @include tablet {
          margin-bottom: 20px;
        }
      }
    }

    &-text {
      display: block;
      font-size: 16px;
      line-height: 1.375;
      color: $black-true;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }

      @include tablet {
        font-size: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 36px;

        @include md-desktop-only {
          margin-bottom: 36px * $zoom;
        }

        @include tablet {
          margin-bottom: 20px;
        }
      }
    }

    &-offer-block {
      &:not(:first-child) {
        margin-top: 30px;

        @include md-desktop-only {
          margin-top: 30px * $zoom;
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include md-desktop-only {
          margin-bottom: 30px * $zoom;
        }
      }
    }

    &-offer {
      display: block;

      a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        @include md-desktop-only {
          margin-bottom: 10px * $zoom;
        }

        @include tablet {
          margin-bottom: 20px;
        }
      }
    }

    &-buttons {
      &:not(:last-child) {
        margin-top: 30px;

        @include md-desktop-only {
          margin-top: 30px * $zoom;
        }

        @include mobile {
          margin-top: 20px;
        }
      }

      &:last-child {
        margin-top: auto;
      }

      .button {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  &__persons {
    list-style: none;
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 30px;

      @include md-desktop-only {
        margin-bottom: 30px * $zoom;
      }
    }

    & > li {
      &:not(:last-child) {
        margin-bottom: 22px;

        @include md-desktop-only {
          margin-bottom: 22px * $zoom;
        }
      }
    }
  }

  &__agreement {
    .form-checkbox {
      display: inline-block;
    }
  }

  .custom-select {
    margin: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "numeric_error": "Enter a numeric value",
    "required_online": "Select a Payment Method",
    "required_person_type": "You need to choose the type of payment",
    "online_title": "Online payment",
    "online_text": "This type of&nbsp;payment is&nbsp;available for individuals, in&nbsp;the&nbsp;amount of&nbsp;no&nbsp;more than 600 thousand rubles.",
    "agreement_text": "I agree with the terms of",
    "agreement_link": {
      "online": {
        "programs": "/en/legal/general_terms/1/",
        "conferences": "/en/legal/general_terms/3/",
        "courses": "/en/legal/general_terms/1/",
        "events": "/en/legal/general_terms/1/",
        "digital": "/en/legal/contracts_individual/oferta-na-zaklyuchenie-obrazovatelnogo-dogovora-dlya-universitet-2035/"
      },
      "programs": {
        "individual": "/en/legal/contracts_individual/1/",
        "entity": "/en/legal/contracts_individual/planning_and_organization/"
      },
      "conferences": {
        "individual": "/en/legal/contracts_individual/3/",
        "entity": "/en/legal/contracts_individual/4/"
      },
      "courses": {
        "individual": "/en/legal/contracts_individual/4/",
        "entity": "/en/legal/contracts_individual/4/"
      },
      "events": {
        "individual": "/en/legal/contracts_individual/4/",
        "entity": "/en/legal/contracts_individual/4/"
      }
    },
    "agreement_link_text": "the offer agreement",
    "agreement_need": "You must agree to the terms",
    "requisites_title": "Payment on account",
    "requisites_text": "Choosing the payment method \"Payment by invoice\", you will need to enter the details for a legal entity, or registration data for an individual",
    "payment_button": "Pay",
    "payment_default": "Choose a payment method",
    "person_individual": "Individual",
    "person_entity": "Entity",
    "requisites_entity_title": "Legal entity data",
    "requisites_payment_title": "Payment details",
    "view_invoice": "View invoice",
    "continue": "Continue",
    "form": {
      "organization": "Full name of the organization",
      "ogrn": "OGRN",
      "inn": "INN",
      "kpp": "KPP",
      "legalAddress": "Legal address",
      "factAddress": "Address (actual)",
      "addressRegistration": "Registration address",
      "individualInn": "INN",
      "legalFio": "Full name of the representative",
      "legalEmail": "Representative's e-mail"
    }
  },
  "ru": {
    "numeric_error": "Введите числовое значение",
    "required_online": "Выберите способ оплаты",
    "required_person_type": "Необходимо выбрать вид платежа",
    "online_title": "Онлайн-платеж",
    "online_text": "Данный способ платежа доступен для&nbsp;физических лиц, на&nbsp;сумму <nobr>не более 600 тыс. руб.</nobr>",
    "agreement_text": "Я согласен с условиями",
    "agreement_link": {
      "online": {
        "programs": "/legal/contracts_individual/1/",
        "conferences": "/legal/contracts_individual/3/",
        "courses": "/legal/contracts_individual/1/",
        "events": "/legal/contracts_individual/1/",
        "digital": "/legal/contracts_individual/oferta-na-zaklyuchenie-obrazovatelnogo-dogovora-dlya-universitet-2035/"
      },
      "programs": {
        "individual": "/legal/contracts_individual/1/",
        "entity": "/legal/contracts_individual/planning_and_organization/"
      },
      "conferences": {
        "individual": "/legal/contracts_individual/3/",
        "entity": "/legal/contracts_individual/4/"
      },
      "courses": {
        "individual": "/legal/contracts_individual/4/",
        "entity": "/legal/contracts_individual/4/"
      },
      "events": {
        "individual": "/legal/contracts_individual/4/",
        "entity": "/legal/contracts_individual/4/"
      }
    },
    "agreement_link_text": "договора оферты",
    "agreement_need": "Необходимо согласиться с условиями",
    "requisites_title": "Оплата по счету",
    "requisites_text": "Выбирая способ платежа \"Оплата по счёту\", вам будет необходимо ввести реквизиты для юридического лица, или данные регистрации для физического лица",
    "payment_button": "Оплатить",
    "payment_default": "Выберите способ оплаты",
    "person_individual": "Физическое лицо",
    "person_entity": "Юридическое лицо",
    "requisites_entity_title": "Данные юр. лица",
    "requisites_payment_title": "Платежные реквизиты",
    "view_invoice": "Посмотреть счет",
    "continue": "Продолжить",
    "form": {
      "organization": "Полное наименование организации",
      "ogrn": "ОГРН",
      "inn": "ИНН",
      "kpp": "КПП",
      "legalAddress": "Юридический адрес",
      "factAddress": "Адрес (фактический)",
      "addressRegistration": "Адрес регистрации",
      "individualInn": "ИНН",
      "legalFio": "ФИО представителя юридического лица",
      "legalEmail": "E-mail представителя юридического лица"
    }
  }
}
</i18n>
