<template>
  <div class="leaf-modal__item">
    <div class="leaf-modal__item__media">
      <div
        class="leaf-modal__item__thumb _g-hidden-mobile"
        :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
      ></div>
      <div class="leaf-modal__title-line">
        {{ $t("date_and_place") }}
      </div>
      <div class="leaf-modal__item__date">
        {{ item.START_TIME }}
      </div>
      <div class="leaf-modal__item__place">
        <span
          class="leaf-modal__item__place__time"
          v-text="item.START_TIME_STRUCT.TIME"
        />
        <span class="leaf-modal__item__place__name" v-text="item.PLACE" />
      </div>
    </div>
    <div class="leaf-modal__item__body">
      <div
        class="leaf-modal__item__thumb _g-visible-mobile"
        :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
      ></div>
      <div class="leaf-modal__item__head">
        <div class="leaf-modal__item__title" v-text="item.NAME" />
        <div class="leaf-modal__item__subtitle">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="item.PREVIEW_TEXT" />
        </div>
        <ul v-if="item.PRESENSE_TYPE" class="leaf-modal__item__tags">
          <li>
            <div
              class="leaf-modal__item__tags__item"
              v-text="item.PRESENSE_TYPE"
            />
          </li>
        </ul>
      </div>
      <div class="leaf-modal__item__text">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="item.DETAIL_TEXT" />
        <a
          :href="item.DETAIL_PAGE_URL"
          class="button button_dark mt-5"
          type="submit"
        >
          {{ $t("details") }}
        </a>
        <div v-if="item.SCHOOL_STREAM" class="leaf-modal__item__school">
          <a
            :href="
              `/bitrix/services/main/ajax.php?mode=class&c=dalee:virtual.school&action=jwt&stream_id=` +
                item.SCHOOL_STREAM
            "
            target="_blank"
            class="button button_base"
          >
            {{ $t("link_to_school") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeafModalItem",
  props: {
    item: { type: Object, required: true }
  }
};
</script>

<i18n>
{
  "en": {
    "date_and_place": "Dates and times",
    "details": "Details",
    "link_to_school": "Go to training"
  },
  "ru": {
    "date_and_place": "Дата и место проведения",
    "details": "Подробнее",
    "link_to_school": "Перейти к обучению"
  }
}
</i18n>
