<template>
  <div class="tabs">
    <div
      v-if="!isHideNav"
      class="tabs-container"
      :class="{ [`mb-${indent}`]: indent }"
    >
      <div v-dragscroll.x:nochilddrag class="tabs-overflow">
        <ul class="tabs-list">
          <li
            v-for="(item, i) in tabs"
            :id="item.id"
            :key="i"
            :class="{
              'tabs-list__item_active': item.isActive,
              [`tabs-list__item_mr${gutter}`]: gutter
            }"
            class="tabs-list__item"
            @click.prevent="selectTab(i, item)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div v-if="$slots.tabsOnRight" class="tabs-on-right">
        <slot name="tabsOnRight" />
      </div>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    gutter: {
      type: Number,
      default: 0
    },
    indent: {
      type: Number,
      default: 0
    },
    pushUrl: {
      type: Boolean,
      default: false
    },
    baseUrl: {
      type: String,
      default: ""
    },
    currentUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tabs: [],
      hash: "",
      isHideNav: false
    };
  },
  mounted() {
    this.tabs = this.$children;
    this.hash = window.location.hash;

    if (this.pushUrl === true && this.baseUrl.length > 0) {
      let currentIndex = 0;
      if (this.currentUrl.length > 0) {
        currentIndex = this.tabs.findIndex(object => {
          return object.tabUrl === this.currentUrl;
        });
      }
      this.selectTab(currentIndex);
    }

    this.isHideNav = this.tabs.length === 1 && !this.tabs[0].label;

    this.checkHash();
  },
  methods: {
    selectTab(selectedTab, item) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;

      if (this.pushUrl === true && this.baseUrl.length > 0) {
        this.pushTabUrl(this.tabs[selectedTab].tabUrl);
      }

      this.$emit("change", selectedTab, item);
    },
    pushTabUrl(dir) {
      if (history.pushState && dir.length > 0) {
        const url = `${window.location.protocol}//${window.location.host}${this.baseUrl}/`;
        const newUrl = `${url}${dir}/`;
        history.pushState(null, null, newUrl);
      } else {
        console.warn("History API не поддерживается");
      }
    },
    checkHash() {
      this.$nextTick(() => {
        if (this.hash.length > 0) {
          this.tabs.forEach((tab, i) => {
            const nestedAnchor = tab.$el.querySelector(this.hash);

            if (this.hash == "#" + this.tabs[i].id || nestedAnchor) {
              this.tabs.forEach((item, index) => {
                this.tabs[index].isActive = false;
              });
              this.tabs[i].isActive = true;
            }
          });
        }
      });
    }
  }
};
</script>
