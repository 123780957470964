<template>
  <VPopover
    :offset="offset"
    trigger="hover focus"
    :placement="placement"
    class="tooltip"
    hide-on-target-click
    :popover-base-class="[
      'tooltip-popover',
      contentClass,
      { 'is-tooltip-show-arrow': isShowArrow }
    ]"
  >
    <template>
      <slot name="trigger" />
    </template>

    <template slot="popover">
      <slot name="content" />
    </template>
  </VPopover>
</template>

<script>
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";

export default {
  name: "Tooltip",
  directives: {
    VTooltip,
    VClosePopover
  },
  components: {
    VPopover
  },
  props: {
    offset: { type: Number, default: 10 },
    contentClass: { type: String, default: "" },
    placement: { type: String, default: "auto-end" },
    isShowArrow: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".tooltip";
$bg: rgba(19, 19, 19, 0.85);

#{$b} {
  display: inline-block;

  &-outter {
    &:not(:first-child) {
      margin-top: 24px;

      @include md-desktop-only {
        margin-top: 24px * $zoom;
      }
    }
    &:not(:last-child) {
      margin-bottom: 24px;

      @include md-desktop-only {
        margin-bottom: 24px * $zoom;
      }
    }
  }
  .trigger {
    display: inline-block;
  }
  &-circle-icon {
    width: 0.9em;
    height: 0.9em;
    padding: 0.13em;
    border: 1px solid currentColor;
    color: currentColor;
    border-radius: 1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      display: block;

      path {
        fill: currentColor;
      }
    }
  }
  &-popover {
    display: inline-block;

    &[x-placement^="top"] {
      margin-bottom: 5px;
    }
    &[x-placement^="bottom"] {
      margin-top: 5px;
    }
    &[x-placement^="right"] {
      margin-left: 5px;
    }
    &[x-placement^="left"] {
      margin-right: 5px;
    }
  }
  &-content {
    color: $drop-hover;
    font-size: 16px;
    line-height: 1.5;

    @include md-desktop-only {
      font-size: 16px * $zoom;
    }

    &[slot="content"] {
      display: none;
    }

    .is-tooltip-small & {
      font-size: 14px;
      line-height: 1.4;

      @include md-desktop-only {
        font-size: 14px * $zoom;
      }
    }

    &-title {
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.56;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      @include md-desktop-only {
        font-size: 18px * $zoom;

        &:not(:last-child) {
          margin-bottom: 10px * $zoom;
        }
      }
    }
    &-text {
      &:not(:last-child) {
        margin-bottom: 10px;

        @include md-desktop-only {
          margin-bottom: 10px * $zoom;
        }
      }
    }
    &-link {
      font-size: 12px;
      line-height: 20px;
      text-decoration-line: underline;
      color: #999;

      @include md-desktop-only {
        font-size: 12px * $zoom;
        line-height: 20px * $zoom;
      }
    }

    p {
      font: inherit;
      line-height: inherit;
    }
  }
  &-inner {
    width: 280px;
    background: $bg;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    color: $white-true;
    padding: 20px;

    @include md-desktop-only {
      width: 280px * $zoom;
      box-shadow: 0 4px * $zoom 24px * $zoom rgba(0, 0, 0, 0.25);
      border-radius: 8px * $zoom;
      padding: 20px * $zoom;
    }

    .is-tooltip-small & {
      padding: 16px;
    }
  }
  &-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $bg;

    #{$b}-popover[x-placement^="top"] & {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
    [x-placement^="bottom"] & {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
    [x-placement^="right"] & {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
    [x-placement^="left"] & {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
