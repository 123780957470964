<template>
  <div class="application-wrapper">
    <section class="application-section">
      <div v-if="!hideArrow" class="application-arrow-block">
        <div class="container">
          <div class="application-arrow"></div>
        </div>
      </div>
      <div class="container">
        <div
          ref="application"
          class="application"
          :class="{
            application_packages: currentStep === 'packages',
            application_users: currentStep === 'form',
            application_payment: currentStep === 'payment'
          }"
        >
          <div class="application__title _g-visible-low">
            {{ title }}
          </div>
          <div class="application__head">
            <div class="application__head-left">
              <div class="application__title _g-hidden-low">
                {{ title }}
              </div>
              <div class="application__steps-overflow">
                <ul class="application__steps">
                  <li v-for="(item, i) in filteredSteps" :key="i">
                    <div
                      class="application__steps-item"
                      :class="{
                        'is-active': item.isActive,
                        'is-complete': item.isComplete
                      }"
                      @click.prevent="changeStep(item.slug)"
                    >
                      <span>{{ item.title }}</span>
                      <svg
                        width="19"
                        height="14"
                        viewBox="0 0 19 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7944 0.695511C12.5355 0.43483 12.1047 0.43483 11.8368 0.695511C11.578 0.947385 11.578 1.36659 11.8368 1.61788L16.6921 6.34243L0.67034 6.34243C0.296856 6.34301 0 6.63188 0 6.9953C0 7.35873 0.296856 7.65699 0.67034 7.65699L16.6921 7.65699L11.8368 12.3727C11.578 12.6334 11.578 13.0532 11.8368 13.3045C12.1047 13.5652 12.5361 13.5652 12.7944 13.3045L18.7991 7.46148C19.067 7.2096 19.067 6.7904 18.7991 6.53911L12.7944 0.695511Z"
                          fill="#B7BBC1"
                        />
                      </svg>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="application__head-right"
              :class="{ 'application__head-right_testing': isTesting }"
            >
              <template v-if="isTesting">
                <div
                  v-if="testResults.isFinished"
                  class="application__test-results"
                  :class="[
                    { 'is-passed': testResults.isPassed },
                    { 'is-failed': !testResults.isPassed }
                  ]"
                >
                  <div class="application__test-results-icon">
                    <template v-if="testResults.isPassed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xml:space="preserve"
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                      >
                        <path
                          d="M32,3.6C16.6,3.6,4,15.9,3.6,31.3l0,0.7v25.2c0,1.6,1.2,3,2.8,3.2l0,0l0.3,0H32c15.7,0,28.4-12.7,28.4-28.4 S47.7,3.6,32,3.6z M32,0c17.7,0,32,14.3,32,32S49.7,64,32,64H6.8C3.1,64,0,60.9,0,57.2V32C0,14.3,14.3,0,32,0z"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="#18b346"
                        />
                        <path
                          d="M28.2,38l15-14.8c0.7-0.7,1.9-0.7,2.6,0c0.6,0.6,0.7,1.6,0.2,2.3l-0.2,0.2L29.5,41.8 c-0.6,0.6-1.7,0.7-2.4,0.2l-0.2-0.2l-8.7-8.5c-0.7-0.7-0.7-1.8,0-2.5c0.6-0.6,1.7-0.7,2.4-0.2l0.2,0.2L28.2,38z"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="#18b346"
                        />
                      </svg>
                    </template>
                    <template v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xml:space="preserve"
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                      >
                        <path
                          fill="#f1161c"
                          fill-rule="evenodd"
                          d="M0,32C0,14.3,14.3,0,32,0s32,14.3,32,32S49.7,64,32,64S0,49.7,0,32z M60.2,32C60.2,16.4,47.6,3.8,32,3.8 S3.8,16.4,3.8,32S16.4,60.2,32,60.2S60.2,47.6,60.2,32z M23.9,25.7c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2 c0-1.7,1.4-3.2,3.2-3.2C22.5,22.6,23.9,24,23.9,25.7z M46.4,25.7c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2 c0-1.7,1.4-3.2,3.2-3.2C45,22.6,46.4,24,46.4,25.7z M43.7,45.5c-0.5,0.9-1.7,1.3-2.6,0.7c-2.8-1.5-5.8-2.3-9.1-2.3 c-3.3,0-6.4,0.8-9.2,2.3c-0.9,0.5-2.1,0.2-2.6-0.8s-0.2-2.1,0.8-2.6c3.4-1.8,7-2.7,11-2.7c3.9,0,7.6,0.9,10.9,2.7 C43.9,43.4,44.2,44.5,43.7,45.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </template>
                  </div>
                  <div class="application__test-results-content">
                    <div class="application__test-results-title">
                      {{ $t("test_result.title") }}
                    </div>
                    <div class="application__test-results-answers">
                      <span>{{ testResults.correct }}</span>
                      <span> {{ $t("test_result.divider") }} </span>
                      <span>{{ digitalTest.count_questions }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="application__theme-name">
                  {{ elementName }}
                </div>
                <div v-if="selectedPackage" class="application__package">
                  <div v-if="!streamsMode" class="application__package-title">
                    {{ selectedPackage.name }}
                  </div>
                  <!-- eslint-disable vue/no-v-html -->
                  <div class="application__package-cost">
                    <template v-if="checkIsFreeApplication">
                      {{ $t("is_free") }}
                    </template>
                    <template v-else>
                      <!-- Если нет скидки -->
                      <template v-if="!promoCode.discount">
                        <!-- Если 1 участник -->
                        <template v-if="users.length === 1">
                          <span>
                            {{ formatCost(packageCost, true) }}
                          </span>
                        </template>
                        <!-- Если несколько участников -->
                        <template v-else-if="users.length > 1">
                          <span>
                            {{ formatCost(packageCost, false) }}
                          </span>
                          <span>
                            x
                            {{ users.length }}
                          </span>
                          <span>
                            =
                            {{
                              formatCost(
                                getTotalCost(packageCost, users.length),
                                true
                              )
                            }}
                          </span>
                        </template>
                      </template>
                      <!-- Если есть скидка -->
                      <template v-else-if="promoCode.discount">
                        <!-- Если 1 участник -->
                        <template v-if="users.length === 1">
                          <span
                            class="application__package-cost-old"
                            v-html="formatCost(packageCost, false)"
                          />
                          <span>
                            {{ formatCost(getDiscountCost(packageCost), true) }}
                          </span>
                        </template>
                        <!-- Если несколько участников -->
                        <template v-else-if="users.length > 1">
                          <span
                            class="application__package-cost-old"
                            v-html="formatCost(packageCost, false)"
                          />
                          <span>
                            {{ formatCost(getDiscountCost(packageCost)) }}
                            x
                            {{ users.length }}
                          </span>
                          <span>
                            =
                            {{
                              formatCost(
                                getTotalCost(packageCost, users.length),
                                true
                              )
                            }}
                          </span>
                        </template>
                      </template>
                    </template>
                  </div>
                  <!--eslint-enable-->
                  <div
                    v-if="promoCode.value"
                    class="application__package-promocode"
                  >
                    <span class="application__package-promocode-inner">
                      {{ $t("byPromoCode") }}
                      {{ promoCode.value }}

                      <span
                        class="application__package-promocode-reset"
                        :title="$t('resetPromoCode')"
                        @click.prevent="resetPromoCode"
                      >
                        <svg viewBox="0 0 511.76 511.76">
                          <path
                            d="M436.896 74.869c-99.84-99.819-262.208-99.819-362.048 0-99.797 99.819-99.797 262.229 0 362.048 49.92 49.899 115.477 74.837 181.035 74.837s131.093-24.939 181.013-74.837c99.819-99.818 99.819-262.229 0-362.048zm-75.435 256.448c8.341 8.341 8.341 21.824 0 30.165a21.275 21.275 0 01-15.083 6.251 21.277 21.277 0 01-15.083-6.251l-75.413-75.435-75.392 75.413a21.348 21.348 0 01-15.083 6.251 21.277 21.277 0 01-15.083-6.251c-8.341-8.341-8.341-21.845 0-30.165l75.392-75.413-75.413-75.413c-8.341-8.341-8.341-21.845 0-30.165 8.32-8.341 21.824-8.341 30.165 0l75.413 75.413 75.413-75.413c8.341-8.341 21.824-8.341 30.165 0 8.341 8.32 8.341 21.824 0 30.165l-75.413 75.413 75.415 75.435z"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <template v-if="isTesting && currentStep === 'testing'">
            <application-test
              :element-id="elementId"
              :test="digitalTest"
              :digital-extend-time="digitalExtendTime"
              :action-extend-time="actionExtendTime"
              :action-check-test-results="actionCheckTestResults"
              :authorized="isAuthorized"
              :auth-url="authUrl"
              :test-results="testResults"
              :digital-oauth-link="digitalOauthLink"
              :digital-leader-id-exist="digitalLeaderIdExist"
              @onTestingFinish="testingFinished"
              @onCompleteTesting="completeTesting"
            />
          </template>
          <template v-if="currentStep === 'packages'">
            <application-packages
              v-if="!streamsModeShowOnlyAuthorizationControl"
              :init-packages="packages"
              :promo-code="promoCode"
              :is-free-application="isFreeApplication"
              :is-reapplication="isReapplication"
              :is-authorized="isAuthorized"
              :auth-url="authUrl"
              :action-check-package-limits="actionCheckPackageLimits"
              :is-streams-mode="streamsMode"
              @onChangePackage="onChangePackage"
              @onResetPackage="resetPackage"
              @onReapplication="onReapplication"
              @onCheckReapplication="checkReapplication"
            />
            <div v-else class="stream-mode-auth-control">
              <div class="stream-mode-auth-control__info">
                <span class="stream-mode-auth-control__info-title">
                  {{ $t("auth_form.title") }}
                </span>
                <span class="stream-mode-auth-control__info-secondary">
                  {{ $t("auth_form.text") }}
                </span>
              </div>
              <div class="stream-mode-auth-control__buttons">
                <a class="button button_base" :href="authUrl">
                  {{ $t("auth_form.login") }}
                </a>
                <a class="button button_green-border" href="/lk/registration/">
                  {{ $t("auth_form.registration") }}
                </a>
              </div>
            </div>
          </template>
          <template v-if="currentStep === 'form'">
            <application-users
              :application-id="applicationId"
              :element-id="elementId"
              :element-code="elementCode"
              :is-free-application="isFreeApplication"
              :is-another-user="isAnotherUser"
              :is-show-education="isShowEducation"
              :is-show-passport="isShowPassport"
              :is-show-snils="isShowSnils"
              :is-show-big-form="isShowBigForm"
              :selected-package="selectedPackage"
              :dadata-api-key="dadataApiKey"
              :init-users="users"
              :promo-code="promoCode"
              :action-add-application="actionAddApplication"
              :action-add-promo-code="actionAddPromoCode"
              :action-load-excel-users="actionLoadExcelUsers"
              :action-current-user="actionCurrentUser"
              :re-captcha-key="reCaptchaKey"
              :industry-options="industryOptions"
              :education-options="educationOptions"
              :training-options="trainingOptions"
              :is-testing="isTesting"
              @onScrollToTop="scrollToTop"
              @onChangeApplicationId="onChangeApplicationId"
              @onChangeAnotherUser="onChangeAnotherUser"
              @onErrorUsers="onError"
            />
          </template>
          <template v-if="currentStep === 'payment'">
            <application-payment
              :application-id="applicationId"
              :payment-data="paymentData"
              :action-add-payment-online="actionAddPaymentOnline"
              :action-add-payment-bill="actionAddPaymentBill"
              :init-payment-methods="paymentMethods"
              :init-person-type="personType"
              :element-code="elementCode"
              :is-show-final-step="isShowFinalStep"
              :is-online-disabled="isOnlineDisabled"
              :offer-link-individual="offerLinkIndividual"
              :offer-link-legal="offerLinkLegal"
              :is-testing="isTesting"
              @onScrollToTop="scrollToTop"
              @onPaymentComplete="onPaymentComplete"
              @onErrorPayment="onError"
            />
          </template>
          <template v-if="currentStep === 'final'">
            <application-final
              :payment-data="paymentData"
              :init-person-type="personType"
              @onShowInvoice="showInvoice"
              @onDownloadInvoice="downloadInvoice"
            />
          </template>
        </div>
      </div>
    </section>

    <notify-popup popup="reapplicationPopup" is-hidden-close>
      <div
        class="notify-popup__close"
        @click="$modal.hide('reapplicationPopup')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
          <path d="M.017 15.99L15.997.01l.99.99-15.98 15.98z" />
          <path d="M.017 1l.99-.99 15.98 15.98-.99.99z" />
        </svg>
      </div>
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg width="34" height="34" fill="none">
            <path
              fill="#999"
              d="M16.167 9v9.333a1 1 0 102 0V9a1 1 0 00-2 0zM18.552 25.26a1.666 1.666 0 10-2.77-1.852 1.666 1.666 0 002.77 1.851z"
            />
            <circle cx="17" cy="17" r="16" stroke="#999" stroke-width="2" />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("reapplication.title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px">
          <p>
            {{ $t("reapplication.text.start") }}
            <a
              :href="reapplicationLink"
              v-html="$t('reapplication.text.end')"
            ></a>
            <br />
            <br />
            {{ $t("reapplication.text.again") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="selectReapplication"
          >
            {{ $t("reapplication.yes") }}
          </button>
        </div>
      </div>
    </notify-popup>

    <!-- Модалка 'Посмотреть счет' -->
    <application-invoice
      ref="invoice"
      :person-type="personType"
      :payment-data="paymentData"
      :element-code="elementCode"
      :is-show-final-step="isShowFinalStep"
      :offer-link-individual="offerLinkIndividual"
      :offer-link-legal="offerLinkLegal"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Application",
  props: {
    title: { type: String, default: "" },
    stepName: { type: String, default: "" },
    elementId: { type: String, default: "" },
    elementCode: { type: String, default: "" },
    elementName: { type: String, default: "" },
    dadataApiKey: { type: String, default: "" },
    isFreeApplication: { type: Boolean, default: false },
    isShowEducation: { type: Boolean, default: false },
    isShowPassport: { type: Boolean, default: false },
    isShowSnils: { type: Boolean, default: false },
    isOnlineDisabled: { type: Boolean, default: false },
    offerLinkIndividual: { type: String, default: "" },
    offerLinkLegal: { type: String, default: "" },
    isShowBigForm: { type: Boolean, default: false },
    hideArrow: { type: [Boolean, String], default: false },
    authorized: { type: String, default: "" },
    authUrl: { type: String, default: "" },
    packages: { type: Array, default: () => [] },
    paymentMethods: { type: Array, default: () => [] },
    actionAddApplication: { type: String, default: "" },
    actionAddPaymentOnline: { type: String, default: "" },
    actionAddPaymentBill: { type: String, default: "" },
    actionAddPromoCode: { type: String, default: "" },
    actionAddPromoCodeDigital: { type: String, default: "" },
    actionLoadExcelUsers: { type: String, default: "" },
    actionCurrentUser: { type: String, default: "" },
    actionCheckReapplication: { type: String, default: "" },
    actionCheckPackageLimits: { type: String, default: "" },
    reCaptchaKey: { type: String, default: "" },
    industryOptions: { type: Array, default: () => [] },
    educationOptions: { type: Array, default: () => [] },
    trainingOptions: { type: Array, default: () => [] },
    digitalTest: { type: Object, default: () => {} },
    digitalExtendTime: { type: String, default: "" },
    digitalLeaderIdExist: { type: Boolean, default: false },
    digitalOauthLink: { type: String, default: "" },
    digitalPromoCode: { type: String, default: "" },
    digitalResultCountCorrect: { type: String, default: "" },
    digitalResultIsPassed: { type: String, default: "" },
    actionExtendTime: { type: String, default: "" },
    actionCheckTestResults: { type: String, default: "" }
  },
  data() {
    return {
      applicationId: "",
      paymentData: null,
      personType: "",
      users: [
        {
          id: "",
          company: "",
          name: "",
          surname: "",
          middleName: "",
          email: "",
          phone: "",
          position: "",
          city: "",
          educationConfirm: false,
          passportConfirm: false,
          education: "",
          passport: "",
          snils: "",
          typePresence: "Заочно",
          typeArrival: "Корпоративный трансфер",
          carNumber: "",
          carBrand: "",
          industry: "",
          industryOther: "",
          industryEducation: "",
          industryEducationOther: "",
          training: "",
          country: ""
        }
      ],
      steps: [
        {
          title: this.$t("step-testing"),
          slug: "testing",
          isActive: false,
          isComplete: false,
          isSkipped: false
        },
        {
          title: this.stepName,
          slug: "packages",
          isActive: false,
          isComplete: false,
          isSkipped: false
        },
        {
          title: this.$t("step-form"),
          slug: "form",
          isActive: false,
          isComplete: false,
          isSkipped: false
        },
        {
          title: this.$t("step-payment"),
          slug: "payment",
          isActive: false,
          isComplete: false,
          isSkipped: this.checkIsFreeApplication
        },
        {
          title: this.$t("step-final"),
          slug: "final",
          isActive: false,
          isComplete: false,
          isSkipped: true
        }
      ],
      isAuthorized: false,
      isAnotherUser: false,
      currentStep: null,
      selectedPackage: null,
      promoCode: {
        value: "",
        id: "",
        discount: "",
        isApplied: false,
        isDigital: false
      },
      isReapplication: false,
      reapplicationLink: this.$t("lk_link") + this.elementCode,
      reapplicationPack: null,
      streamsMode: false,
      streamsModeCost: null,
      streamsModeShowOnlyAuthorizationControl: false,
      testResults: {
        correct: "",
        isFinished: false,
        isPassed: false
      }
    };
  },
  computed: {
    packageCost() {
      return this.streamsMode && this.streamsModeCost
        ? this.streamsModeCost
        : this.selectedPackage.cost;
    },
    checkIsFreeApplication() {
      if (!this.selectedPackage) return this.isFreeApplication;

      const packageCost = Number(this.packageCost);
      const discount = Number(this.promoCode.discount);
      const isFree =
        this.isFreeApplication || packageCost === 0 || discount === 100;

      return isFree;
    },
    filteredSteps() {
      const result = this.steps.filter(step => {
        if (this.isShowFinalStep && step.slug === "final") {
          return step;
        } else {
          if (!step.isSkipped) {
            return step;
          }
        }
      });

      return result;
    },
    isShowFinalStep() {
      return (
        this.elementCode === "programs" &&
        this.selectedPackage &&
        this.selectedPackage.isShowConnection
      );
    },
    isTesting() {
      return this.digitalTest && Object.keys(this.digitalTest).length;
    }
  },
  created() {
    this.checkPaymentStatus();
    this.isAuthorized = this.authorized === "1";
    this.checkTesting();
  },
  mounted() {
    this.$root.$on("setApplicationPromoCode", this.setPromoCode);
    this.$root.$on("resetApplicationPromoCode", this.resetPromoCode);
    this.$root.$on("onCloseApplicationInvoice", this.redirectToPersonalArea);
    this.$root.$on(
      "onApplicationPaymentSuccessPopup",
      this.onSuccessPaymentPopup
    );
    this.$root.$on("onApplicationConnectionPopup", this.onConnectionPopup);

    const { packages, isAuthorized } = this;
    if (packages.every(p => !!p.idStream)) {
      this.streamsMode = true;
      this.streamsModeCost = packages[0].cost;

      if (packages.length === 1 && !this.digitalTest) {
        if (isAuthorized) {
          this.onChangePackage(packages[0], "packages");
        } else {
          this.streamsModeShowOnlyAuthorizationControl = true;
        }
      }
    }
  },
  methods: {
    checkPaymentStatus() {
      switch (this.getUrlParam("payment")) {
        case "success":
          this.onSuccessPayment();
          break;

        case "fail":
          this.$nextTick(() => {
            this.$modal.show("globalDynamicPopup", {
              icon: "attention",
              title: this.$t("payment.fail.title"),
              text: this.$t("payment.fail.text")
            });

            this.removeUrlParam("payment");
          });
          break;
      }
    },
    getUrlParam(param) {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      return params.get(param);
    },
    removeUrlParam(param) {
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);

      params.delete(param);

      const searchString = decodeURIComponent(params.toString());
      const newUrl =
        window.location.origin +
        window.location.pathname +
        window.location.hash +
        (searchString ? "?" : "") +
        searchString;

      if (history.replaceState) {
        history.replaceState(null, null, newUrl);
      } else {
        // eslint-disable-next-line no-console
        console.warn("History API не поддерживается");
      }
    },
    formatCost(cost, showCurrency) {
      cost = cost.toString().replace(/\s+/g, "");

      if (cost.split(".").length >= 2) {
        cost = Number(cost).toFixed(2);
      }

      cost = cost.replace(/\./g, ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      return showCurrency ? `${cost} ${this.$t("currency")}` : cost;
    },
    getDiscountCost(cost) {
      const trimCost = Number(cost.toString().replace(/\s+/g, ""));

      const discount = Number(this.promoCode.discount);
      const discountCost = (trimCost / 100) * discount;
      const result = Math.ceil(trimCost - discountCost);

      return result;
    },
    getTotalCost(cost, numberUsers) {
      let trimCost = Number(cost.toString().replace(/\s+/g, ""));

      if (this.promoCode.discount) {
        trimCost = this.getDiscountCost(trimCost);
      }

      const result = trimCost * numberUsers;

      return result;
    },
    changeStep(step, skipStep) {
      this.currentStep = step;
      const currentStepIndex = this.steps.findIndex(
        step => step.slug === this.currentStep
      );

      this.steps[currentStepIndex].isComplete = false;
      this.steps[currentStepIndex].isActive = true;
      this.steps.forEach((item, index) => {
        if (currentStepIndex > index) {
          item.isComplete = true;
          item.isActive = false;
        } else {
          if (currentStepIndex !== index) {
            item.isComplete = false;
            item.isActive = false;
          }
        }
      });

      if (skipStep) {
        this.steps.forEach((item, i) => {
          if (item.slug === skipStep) {
            this.steps[i].isSkipped = true;
          }
        });
      } else {
        this.$scrollTo(this.$refs.application, 300, { offset: -80 });
      }
    },
    onChangePackage(pack, skipStep) {
      this.changeStep("form", skipStep);
      this.selectedPackage = pack;
    },
    onChangeApplicationId(applicationId) {
      this.applicationId = applicationId;

      if (this.checkIsFreeApplication) {
        this.onSuccessPayment();
      } else {
        this.changeStep("payment");
      }
    },
    onChangeAnotherUser(state) {
      this.isAnotherUser = state;
    },
    addPaymentBill(personType, paymentData) {
      this.personType = personType;

      // Проверяем наличие номера оплаты
      // если существует запоминаем
      if (this.paymentData) {
        const paymentId = this.paymentData.number;

        this.paymentData = paymentData;
        this.paymentData.number = paymentId;
      } else {
        this.paymentData = paymentData;
      }
    },
    onPaymentComplete(personType, paymentData) {
      this.addPaymentBill(personType, paymentData);

      if (this.isShowFinalStep) {
        this.$modal.show("globalDynamicPopup", {
          icon: "success",
          title: this.$t("connection_popup.title"),
          text: this.$t("connection_popup.text"),
          eventName: "onApplicationConnectionPopup"
        });
      } else {
        this.showInvoice();
      }
    },
    showInvoice() {
      if (this.paymentData && this.personType) {
        this.$modal.show("viewInvoiceModal");
      }
    },
    downloadInvoice() {
      if (this.paymentData && this.personType) {
        this.$modal.show("viewInvoiceModal", { isDownloading: true });
      }
    },
    scrollToTop(delay, settings) {
      this.$nextTick(() => {
        this.$scrollTo(this.$refs.application, delay, settings);
      });
    },
    onError(error) {
      this.$nextTick(() => {
        this.$modal.show("globalDynamicPopup", {
          icon: "error",
          title: this.$t("error.title"),
          text: error.message,
          close: this.$t("error.close")
        });
      });
    },
    onSuccessPayment() {
      this.$nextTick(() => {
        this.$modal.show("globalDynamicPopup", {
          width: 600,
          icon: "success",
          title: this.checkIsFreeApplication
            ? this.$t("payment.free.title")
            : this.$t("payment.success.title"),
          text: this.checkIsFreeApplication
            ? this.$t("payment.free.text")
            : this.$t("payment.success.text"),
          eventName: "onApplicationPaymentSuccessPopup"
        });
      });
    },
    onSuccessPaymentPopup(state) {
      if (state === "close") {
        this.redirectToPersonalArea(this.elementCode);
      }
    },
    onConnectionPopup(state) {
      if (state === "close") {
        this.changeStep("final");
      }
    },
    redirectToPersonalArea(section) {
      window.location.href = this.$t("lk_link") + section;
    },
    setPromoCode(data, promocode, isDigital) {
      this.promoCode.id = data.promoCodeId;
      this.promoCode.value = promocode ? promocode : "";
      this.promoCode.discount = data.discount;
      this.promoCode.isApplied = true;

      if (!isDigital) return;
      this.promoCode.isDigital = true;
    },
    resetPromoCode() {
      this.promoCode.id = "";
      this.promoCode.value = "";
      this.promoCode.discount = "";
      this.promoCode.isApplied = false;
    },
    resetPackage() {
      this.resetPromoCode();
      this.selectedPackage = null;
    },
    goToApplication() {
      this.scrollToTop(300, { offset: -130 });
    },
    onReapplication(pack) {
      this.reapplicationPack = pack;
      this.$modal.show("reapplicationPopup");
    },
    selectReapplication() {
      this.isReapplication = false;
      this.onChangePackage(this.reapplicationPack);
      this.$modal.hide("reapplicationPopup");
    },
    async checkReapplication() {
      const data = new FormData();
      data.append("elementId", this.elementId);

      let options = {
        url: this.actionCheckReapplication,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: data
      };

      await axios(options).then(response => {
        if (response.data.status === "success") {
          if (response.data.data) {
            this.isReapplication = true;
          }
        }
      });
    },
    checkTesting() {
      if (this.isTesting) {
        this.changeStep("testing");

        if (!this.digitalResultCountCorrect) return;

        this.testResults.isFinished = true;
        this.testResults.correct = this.digitalResultCountCorrect;
        this.testResults.isPassed = this.digitalResultIsPassed === "1";
      } else {
        this.changeStep("packages", "testing");
      }
    },
    testingFinished(result) {
      this.testResults.isFinished = true;
      this.testResults.correct = result.correct;
      this.testResults.isPassed = result.isPassed;
    },
    completeTesting(step) {
      this.setDigitalPromoCode();
      this.changeStep(step);
    },
    async setDigitalPromoCode() {
      const data = new FormData();
      data.append("promoCode", this.digitalPromoCode);

      const options = {
        url: this.actionAddPromoCodeDigital,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: data
      };

      const response = await axios(options);

      switch (response.data.status) {
        case "success":
          if (!response.data.data.discount) return;

          this.setPromoCode(response.data.data, "", true);
          break;
        case "error":
          this.$nextTick(() => {
            this.$modal.show("globalDynamicPopup", {
              icon: "attention",
              title: this.$t("attention.title"),
              text: response.data.errors[0].message,
              close: this.$t("attention.close")
            });
          });
          break;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application";

#{$b} {
  display: block;

  &-section {
    position: relative;
    padding: 66px 0;
    background-color: $color-bg-alt;

    @include md-desktop-only {
      padding: 66px * $zoom 0 * $zoom;
    }

    @include mobile {
      padding: 46px 0 60px;
    }
  }

  &-arrow-block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 0;
    line-height: 0;
  }

  &-arrow {
    display: block;
    width: 0;
    height: 0;
    border-left: 33px solid transparent;
    border-right: 33px solid transparent;
    border-top: 33px solid $white-true;
    margin-left: 36px;

    @include md-desktop-only {
      border-left-width: 33px * $zoom;
      border-right-width: 33px * $zoom;
      border-top-width: 33px * $zoom;
      margin-left: 36px * $zoom;
    }

    @include tablet {
      border-left-width: 28px;
      border-right-width: 28px;
      border-top-width: 28px;
      margin-left: 20px;
    }
  }

  &__head {
    display: flex;
    align-items: flex-start;

    @include low-desktop {
      align-items: stretch;
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 32px;

      @include md-desktop-only {
        margin-bottom: 32px * $zoom;
      }

      @include mobile {
        margin-bottom: 28px;
      }
    }

    &-left {
      flex: 1 1 auto;
      padding-right: 20px;

      @include low-desktop {
        order: 1;
        width: 100%;
        padding-right: 0;
      }

      #{$b}_users & {
        margin-bottom: 60px;

        @include md-desktop-only {
          margin-bottom: 60px * $zoom;
        }

        @include low-desktop {
          margin-bottom: 0;
        }
      }
    }

    &-right {
      width: 440px;
      flex-shrink: 0;
      padding-top: 4px;

      @include md-desktop-only {
        width: 440px * $zoom;
        padding-top: 4px * $zoom;
      }

      @include low-desktop {
        order: 0;
        width: 100%;
        margin-bottom: 30px;
      }

      @include mobile {
        margin-bottom: 24px;
      }

      &_testing {
        width: auto;
      }
    }
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.333;
    color: $black-true;
    margin: 0;

    @include md-desktop-only {
      font-size: 36px * $zoom;
    }

    @include low-desktop {
      font-size: 30px;
      line-height: 1.2;
    }

    @include mobile {
      font-size: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 36px;

      @include md-desktop-only {
        margin-bottom: 36px * $zoom;
      }

      @include low-desktop {
        margin-bottom: 16px;
      }
    }
  }

  &__theme-name {
    display: block;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.375;
    color: $black-true;
    margin: 0;

    @include md-desktop-only {
      font-size: 24px * $zoom;
    }

    @include low-desktop {
      font-size: 20px;
      line-height: 1.26;
    }

    @include mobile {
      font-size: 18px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;

      @include md-desktop-only {
        margin-bottom: 8px * $zoom;
      }

      @include low-desktop {
        margin-bottom: 24px;
      }

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  &__package {
    &-title {
      display: block;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.375;
      color: $black-true;
      margin: 0;

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include low-desktop {
        font-size: 20px;
        line-height: 1.26;
      }

      @include mobile {
        font-size: 18px;
      }

      &:not(:last-child) {
        margin-bottom: 7px;

        @include md-desktop-only {
          margin-bottom: 7px * $zoom;
        }
      }
    }

    &-cost {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.42;
      color: $color-base;
      margin: 0;
      white-space: nowrap;

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include low-desktop {
        font-size: 20px;
      }

      @include mobile {
        font-size: 18px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        @include md-desktop-only {
          margin-bottom: 8px * $zoom;
        }
      }
    }

    &-cost-old {
      color: $color-bg-dark;
      text-decoration: line-through;

      &:not(:last-child) {
        margin-right: 6px;

        @include md-desktop-only {
          margin-right: 6px * $zoom;
        }
      }
    }

    &-promocode {
      display: block;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $color-text-light-2;

      @include md-desktop-only {
        font-size: 16px * $zoom;
        line-height: 22px * $zoom;
      }

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        @include md-desktop-only {
          margin-bottom: 8px * $zoom;
        }
      }

      &-inner {
        display: inline-block;
        position: relative;
        padding-right: 30px;

        @include md-desktop-only {
          padding-right: 30px * $zoom;
        }

        @include mobile {
          padding-right: 26px;
        }
      }

      &-reset {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        color: $color-text-dark-2;

        @include md-desktop-only {
          width: 20px * $zoom;
          height: 20px * $zoom;
        }

        @include mobile {
          width: 18px;
          height: 18px;
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: currentColor;
          }
        }
      }
    }
  }

  &__steps-overflow {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;

    @include low-desktop {
      margin-left: #{-$inner-pd-tab};
      margin-right: #{-$inner-pd-tab};
    }

    @include mobile {
      margin-left: #{-$inner-pd-mob};
      margin-right: #{-$inner-pd-mob};
    }

    @media (hover: none) {
      padding-bottom: 10px;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__steps {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @include low-desktop {
      min-width: 100%;
      display: inline-flex;
      padding-left: $inner-pd-tab;
      padding-right: $inner-pd-tab;
    }

    @include mobile {
      padding-left: $inner-pd-mob;
      padding-right: $inner-pd-mob;
    }

    & > li {
      &:not(:last-child) {
        margin-right: 18px;

        @include md-desktop-only {
          margin-right: 18px * $zoom;
        }
      }

      &:last-child {
        & > * {
          svg {
            display: none;
          }
        }
      }
    }

    &-item {
      @include clickable;
      display: inline-flex;
      align-items: center;
      color: #c4c4c4;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: -0.5px;
      white-space: nowrap;

      @include md-desktop-only {
        font-size: 16px * $zoom;
        line-height: 20px * $zoom;
        letter-spacing: -0.5px * $zoom;
      }

      @include tablet {
        font-size: 14px;
      }

      &:not(.is-complete) {
        cursor: default;
        pointer-events: none;
      }

      &.is-active {
        color: $color-bg-dark;
      }

      &.is-complete {
        color: $color-base;
      }

      svg {
        flex-shrink: 0;
        width: 19px;
        height: 19px;

        @include md-desktop-only {
          width: 19px * $zoom;
          height: 19px * $zoom;
        }

        @include low-desktop {
          width: 14px;
          height: 14px;
        }

        &:not(:first-child) {
          margin-left: 14px;

          @include md-desktop-only {
            margin-left: 14px * $zoom;
          }

          @include low-desktop {
            margin-left: 10px;
          }
        }

        path {
          fill: currentColor;
        }
      }
    }
  }

  &-panels {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;

    @include md-desktop-only {
      margin-left: -40px * $zoom;
    }

    @include tablet {
      margin-left: -20px;
    }

    &__column {
      padding-left: 40px;
      width: 50%;

      @include md-desktop-only {
        padding-left: 40px * $zoom;
      }

      @include tablet {
        padding-left: 20px;
      }

      @include mobile {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &:only-child {
        @include low-desktop {
          width: 100%;
        }
      }
    }
  }

  &-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white-true;
    border-radius: 5px;
    padding: 24px 50px 40px;

    @include md-desktop-only {
      border-radius: 5px * $zoom;
      padding: 24px * $zoom 50px * $zoom 40px * $zoom;
    }

    @include low-desktop {
      padding: 24px 32px 36px;
    }

    @include mobile {
      padding: 20px 20px 24px;
    }

    &__title {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.42;
      margin: 0;

      @include md-desktop-only {
        font-size: 24px * $zoom;
      }

      @include tablet {
        font-size: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 26px;

        @include md-desktop-only {
          margin-bottom: 26px * $zoom;
        }

        @include tablet {
          margin-bottom: 20px;
        }
      }
    }

    &__text {
      display: block;
      font-size: 16px;
      line-height: 1.375;
      color: $black-true;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }

      @include tablet {
        font-size: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 36px;

        @include md-desktop-only {
          margin-bottom: 36px * $zoom;
        }

        @include tablet {
          margin-bottom: 20px;
        }
      }

      a {
        color: $color-base;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    &__offer-block {
      &:not(:first-child) {
        margin-top: 30px;

        @include md-desktop-only {
          margin-top: 30px * $zoom;
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include md-desktop-only {
          margin-bottom: 30px * $zoom;
        }
      }
    }

    &__offer {
      display: block;

      a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        @include md-desktop-only {
          margin-bottom: 10px * $zoom;
        }

        @include tablet {
          margin-bottom: 20px;
        }
      }
    }

    &__buttons {
      &:not(:last-child) {
        margin-top: 30px;

        @include md-desktop-only {
          margin-top: 30px * $zoom;
        }

        @include mobile {
          margin-top: 20px;
        }
      }

      &:last-child {
        margin-top: auto;
      }

      .button {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .stream-mode-auth-control {
    width: 100%;
    padding: 45px 50px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background-color: #fff;

    @include tablet {
      display: block;
    }

    @include mobile {
      padding: 24px 20px;
    }

    &__info {
      @include tablet {
        margin-bottom: 20px;
      }
      span {
        display: block;
      }

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        color: #000;

        @include mobile {
          font-size: 20px;
        }
      }
      &-secondary {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000;
      }
    }

    &__buttons {
      display: flex;

      @include mobile {
        display: block;
      }

      .button {
        padding-left: 40px;
        padding-right: 40px;

        @include mobile {
          padding-left: 20px;
          padding-right: 20px;
        }

        &:not(:last-child) {
          margin-right: 40px;

          @include mobile {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &__test-results {
    display: flex;
    align-items: center;

    &-icon {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include md-desktop-only {
        @include zoom(width, 64px);
        @include zoom(height, 64px);
      }

      @include low-desktop {
        width: 52px;
        height: 52px;
      }

      &:not(:last-child) {
        margin-right: 16px;

        @include md-desktop-only {
          @include zoom(margin-right, 16px);
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      display: block;
      line-height: 1.4;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 8px;

        @include md-desktop-only {
          @include zoom(margin-bottom, 8px);
        }

        @include low-desktop {
          margin-bottom: 4px;
        }
      }
    }

    &-answers {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.33;
      color: $color-placeholder;

      @include md-desktop-only {
        @include zoom(font-size, 24px);
      }

      @include low-desktop {
        font-size: 20px;
      }

      & > span {
        &:first-child {
          .is-passed & {
            color: $color-base;
          }
          .is-failed & {
            color: #f1161c;
          }
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "step-testing": "Introductory testing",
    "step-form": "General information",
    "step-payment": "Payment",
    "step-final": "Completion",
    "is_free": "Free",
    "currency": "RUB",
    "error": {
      "title": "Error",
      "close": "Close"
    },
    "attention": {
      "title": "Attention",
      "close": "Proceed"
    },
    "byPromoCode": "By promo code",
    "resetPromoCode": "Reset promo code",
    "payment": {
      "free": {
        "title": "You have successfully registered!",
        "text": "Additional information has been sent to you by e-mail."
      },
      "success": {
        "title": "You have successfully registered!",
        "text": "Additional information has been sent to you by e-mail."
      },
      "fail": {
        "title": "Payment failed",
        "text": "Try again <br> or contact technical support"
      }
    },
    "reapplication": {
      "title": "Application exists",
      "text": {
        "start": "You have already applied. View in your ",
        "end": "personal account",
        "again": "Submit another one?"
      },
      "yes": "Yes"
    },
    "lk_link": "/en/lk/",
    "connection_popup": {
      "title": "You have successfully registered!",
      "text": "Your documents are available <br>inв&nbsp;your <a href='/lk/activity-log/' target='_blank'>personal account</a>"
    },
    "auth_form": {
      "title": "Login or Register",
      "text": "To continue processing",
      "login": "Login",
      "registration": "Registration"
    },
    "test_result": {
      "title": "Test result:",
      "divider": "of"
    }
  },
  "ru": {
    "step-testing": "Входное тестирование",
    "step-form": "Общая информация",
    "step-payment": "Оплата",
    "step-final": "Завершение",
    "currency": "руб",
    "is_free": "Бесплатно",
    "error": {
      "title": "Ошибка",
      "close": "Закрыть"
    },
    "attention": {
      "title": "Внимание",
      "close": "Продолжить"
    },
    "byPromoCode": "По промокоду",
    "resetPromoCode": "Сбросить промокод",
    "payment": {
      "free": {
        "title": "Вы успешно зарегистрировались!",
        "text": "Дополнительная информация направлена Вам на электронную почту."
      },
      "success": {
        "title": "Вы успешно зарегистрировались!",
        "text": "Дополнительная информация направлена Вам на электронную почту."
      },
      "fail": {
        "title": "Оплата не прошла",
        "text": "Попробуйте еще раз <br>или обратитесь в техподдержку"
      }
    },
    "reapplication": {
      "title": "Заявка существует",
      "text": {
        "start": "Вы уже подавали заявку. Посмотреть в ",
        "end": "личном кабинете",
        "again": "Подать еще одну?"
      },
      "yes": "Да"
    },
    "lk_link": "/lk/",
    "connection_popup": {
      "title": "Вы успешно зарегистрировались!",
      "text": "Ваши документы доступны <br>в&nbsp;<a href='/lk/activity-log/' target='_blank'>личном кабинете</a>"
    },
    "auth_form": {
      "title": "Войдите или зарегистрируйтесь",
      "text": "Чтобы продолжить оформление",
      "login": "Войти",
      "registration": "Регистрация"
    },
    "test_result": {
      "title": "Результат тестирования:",
      "divider": "из"
    }
  }
}
</i18n>
