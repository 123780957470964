<template>
  <div class="container">
    <form class="form mb-10" @submit.prevent="onSubmit" @reset="onReset">
      <div class="form-body form-body_width-medium">
        <div class="form-row">
          <form-input
            id="email"
            v-model="form.login"
            :label="$t('login') + ' *'"
            type="email"
            :error="
              $v.form.login.$error ||
                !$v.form.login.email ||
                errors.emailNotFound ||
                errors.incorrectAccess
            "
            :messages="[
              {
                type: 'error',
                i18: 'error.email.invalid',
                state: !$v.form.login.email
              },
              {
                type: 'error',
                i18: 'error.required',
                state: $v.form.login.$error && !$v.form.login.required
              },
              {
                type: 'error',
                i18: 'error.email.not-found',
                state: errors.emailNotFound
              },
              {
                type: 'error',
                text: $t('wrong_data'),
                state: errors.incorrectAccess
              }
            ]"
            @input="onInput"
            @blur="$v.form.login.$touch()"
          />
        </div>
        <div class="form-row">
          <form-input
            id="password"
            v-model="form.password"
            :label="$t('password.input') + ' *'"
            autocomplete="off"
            type="password"
            :error="$v.form.password.$error || errors.incorrectAccess"
            :messages="[
              {
                type: 'error',
                i18: 'error.required',
                state: $v.form.password.$error && !$v.form.password.required
              },
              {
                type: 'error',
                min: $v.form.password.$params.minLength.min,
                state: !$v.form.password.minLength
              },
              {
                type: 'error',
                text: $t('wrong_data'),
                state: errors.incorrectAccess
              }
            ]"
            @input="onInput"
          />
        </div>

        <p class="form-light-text mt-6 mb-6">
          <small>
            {{ $t("required_text") }}
          </small>
        </p>

        <p v-if="errors.general" class="form-input-message is-error mt-6 mb-6">
          {{ errors.general }}
          <br><br>
        </p>

        <div class="form-row">
          <div class="form__horizontal-items">
            <button class="button button_base" type="submit">
              {{ $t("enter") }}
            </button>
            <div class="form-links">
              <a class="form-link" :href="forgotPasswordUrl">
                {{ $t("password.forgot") }}
              </a>
              <a class="form-link" :href="registrationUrl">
                {{ $t("registration.title") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <div class="form__horizontal-items">
          <slot name="social"></slot>
        </div>
      </div>
    </form>

    <notify-popup popup="errorSocialRegPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#FF3939" stroke-width="2" />
            <path
              fill="#FF3939"
              d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("authorization.title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("authorization.text") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('errorSocialRegPopup')"
          >
            {{ $t("authorization.next") }}
          </button>
        </div>
      </div>
    </notify-popup>

    <notify-popup popup="errorRegPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#FF3939" stroke-width="2" />
            <path
              fill="#FF3939"
              d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("registration.title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("registration.email") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('errorRegPopup')"
          >
            {{ $t("registration.next") }}
          </button>
          <a
            v-if="showAgain"
            class="notify-popup__link notify-popup__link_light _g-text-left"
            href="#"
            @click.prevent="onSubmit('resend')"
          >
            {{ $t("registration.send") }}
            <br />
            {{ $t("registration.replay") }}
          </a>
          <p v-else class="_g-text-left">
            {{ $t("registration.again_send") }}
            <br />
            {{ $t("registration.again_replay") }}
          </p>
        </div>
      </div>
    </notify-popup>

    <notify-popup popup="needAgreementMark" :width="660">
      <div class="notify-popup__body _g-text-center">
        <form @submit.prevent="onAgreementSubmit">
          <div class="notify-popup__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="34"
              height="34"
              viewBox="0 0 34 34"
            >
              <circle
                cx="17"
                cy="17"
                r="16"
                stroke="#FF3939"
                stroke-width="2"
              />
              <path
                fill="#FF3939"
                d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
              />
            </svg>
          </div>
          <div class="notify-popup__title">
            {{ $t("agreement_site_data.title") }}
          </div>
          <div class="notify-popup__text" style="max-width: 460px;">
            <p>
              {{ $t("agreement_site_data.text") }}
              <br />
              <br />
            </p>
            <label class="form-agree _g-text-left">
              <input
                v-model="formAgreement.agreementSite"
                class="form-agree__checkbox"
                type="checkbox"
                name="data-agreement-site"
              />
              <span class="form-agree__text">
                {{ $t("agreement_site_data.left") }}
                <a :href="$t('agreement_site_data.link')" target="_blank">
                  {{ $t("agreement_site_data.right") }}
                </a>
              </span>
            </label>
          </div>
          <div class="notify-popup__buttons">
            <button
              :disabled="!formAgreement.agreementSite"
              class="button button_base"
              type="submit"
            >
              {{ $t("agreement_site_data.button") }}
            </button>
          </div>
        </form>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Vuelidate from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
Vue.use(Vuelidate);

export default {
  name: "AuthLoginForm",
  props: {
    action: {
      type: String,
      default: ""
    },
    redirectUrl: {
      type: String,
      required: true
    },
    forgotPasswordUrl: {
      type: String,
      default: ""
    },
    registrationUrl: {
      type: String,
      default: ""
    },
    socServAuthError: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        login: "",
        password: "",
        resend: false
      },
      formAgreement: {
        idUser: "",
        agreementSite: false
      },
      errors: {
        emailNotFound: false,
        incorrectAccess: false,
        general: ''
      },
      showAgain: true,
      dataLayer: false
    };
  },
  validations: {
    form: {
      login: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  mounted() {
    this.showSocServAuthErrorModal();
    this.dataLayer = window.dataLayer;
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    onInput() {
      this.errors.emailNotFound = false;
      this.errors.incorrectAccess = false;
    },
    async onSubmit(type = "default") {
      if (this.validate()) {
        if (type === "resend") {
          this.form.resend = true;
          this.showAgain = false;
        } else {
          this.form.resend = false;
        }

        const data = new FormData();
        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            if (response.data.code > 0) {
              if (
                response.data.success === true &&
                this.form.resend === false
              ) {
                location.href = this.redirectUrl;
                if (this.dataLayer) {
                  this.dataLayer.push({
                    event: "ga_event",
                    event_category: "forms",
                    event_action: "sent",
                    event_label: "login"
                  });
                }
              } else if (response.data.code === 300) {
                this.$modal.show("errorRegPopup");
              } else if (response.data.code === 400) {
                this.errors.emailNotFound = true;
              } else if (response.data.code === 500) {
                this.errors.incorrectAccess = true;
              } else if (response.data.code === 600) {
                this.formAgreement.idUser = response.data.user_id;
                this.$modal.show("needAgreementMark");
              } else if (response?.data?.message !== '') {
                this.errors.general = response.data.message;
              }
            }
          }
        });
      }
    },
    async onAgreementSubmit() {
      if (this.validate()) {
        const data = new FormData();

        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }

        for (let prop in this.formAgreement) {
          data.append(prop, this.formAgreement[prop]);
        }

        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            if (response.data.success === true) {
              location.href = this.redirectUrl;
              if (this.dataLayer) {
                this.dataLayer.push({
                  event: "ga_event",
                  event_category: "forms",
                  event_action: "sent",
                  event_label: "login"
                });
              }
            }
          }
        });
      }
    },
    onReset(e) {
      e.preventDefault();
      this.form.login = "";
      this.form.password = "";
    },
    showSocServAuthErrorModal() {
      if (this.socServAuthError) {
        this.$modal.show("errorSocialRegPopup");
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "login": "Login (E-mail)",
    "wrong_data": "Wrong login or password",
    "password": {
      "input": "Password",
      "length_left": "Password must contain at least",
      "length_right": "characters",
      "forgot": "Forgot your password?"
    },
    "required_text": "Fields marked * are required",
    "enter": "Enter",
    "registration": {
      "title": "Registration",
      "email": "This e-mail is not confirmed",
      "next": "Continue",
      "send": "Send",
      "replay": "letter again",
      "again_send": "Letter has been",
      "again_replay": "sent again!"
    },
    "authorization": {
      "title": "Authorization",
      "text": "You cannot log in through social networks if they are not tied to your personal account",
      "next": "Continue"
    },
    "agreement_site_data": {
      "left": "I am familiar with",
      "right": "user agreement",
      "need": "You must agree to the terms",
      "link": "/legal/obshchie-usloviy/polzovatelskoe-soglashenie-sayta/",
      "title": "We have updated the user agreement",
      "text": "Please read it to continue using the site.",
      "button": "Login"
    }
  },
  "ru": {
    "login": "Логин (E-mail)",
    "wrong_data": "Неверный логин или пароль",
    "password": {
      "input": "Пароль",
      "length_left": "Пароль должен содержать минимум",
      "length_right": "символов",
      "forgot": "Забыли пароль?"
    },
    "required_text": "Поля, отмеченные *, обязательные к заполнению",
    "enter": "Войти",
    "registration": {
      "title": "Регистрация",
      "email": "Данный e-mail не подтвержден",
      "next": "Продолжить",
      "send": "Отправить",
      "replay": "письмо повторно",
      "again_send": "Письмо отправлено",
      "again_replay": "повторно"
    },
    "authorization": {
      "title": "Авторизация",
      "text": "Вы не можете авторизоваться через социальные сети, если они не привязаны в Вашем личном кабинете",
      "next": "Продолжить"
    },
    "agreement_site_data": {
      "left": "Я ознакомлен(а) с",
      "right": "пользовательским соглашением",
      "need": "Необходимо согласиться с условиями",
      "link": "/legal/obshchie-usloviy/polzovatelskoe-soglashenie-sayta/",
      "title": "Мы обновили пользовательское соглашение",
      "text": "Пожалуйста, ознакомьтесь с ним, чтобы дальше пользоваться сайтом.",
      "button": "Авторизоваться"
    }
  }
}
</i18n>
