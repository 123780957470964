<template>
  <div class="accordions">
    <div class="accordions-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordions",
  data() {
    return {
      Accordion: {
        count: 0,
        active: null
      }
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  },
  mounted() {
    this.checkHash();
  },
  methods: {
    checkHash() {
      const hash = window.location.hash.substring(1);

      if (hash) {
        const foundIndex = this.$children.findIndex(accordion => {
          return accordion.id === hash;
        });

        if (foundIndex >= 0) {
          this.Accordion.active = foundIndex;
          setTimeout(() => {
            this.$scrollTo("#" + hash, 300, { offset: -70 });
          }, 300);
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".accordions";

#{$b} {
  &:not(:first-child) {
    margin-top: 24px;

    @include md-desktop-only {
      margin-top: 24px * $zoom;
    }

    @include mobile {
      margin-top: 20px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @include md-desktop-only {
      margin-bottom: 24px * $zoom;
    }

    @include mobile {
      margin-bottom: 20px;
    }
  }
}
</style>
