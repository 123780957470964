<template>
  <div class="root-programs">
    <ul class="root-programs__nav">
      <li
        v-for="(item, i) in tabs"
        :key="i"
        :class="{ _active: item.isActive }"
        @click.prevent="selectTab(i)"
      >
        {{ item.label }}
      </li>
    </ul>
    <template v-if="breakpoint.isMobile">
      <div ref="slider" class="root-programs__card-container">
        <div class="swiper-wrapper">
          <slot />
        </div>
        <div ref="pagination" class="swiper-pagination"></div>
      </div>
    </template>
    <template v-else>
      <div class="root-programs__card-container">
        <slot />
      </div>
    </template>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";

export default {
  name: "ProgramTabs",
  data() {
    return {
      tabs: [],
      slider: null,
      breakpoint: this.getBreakpoint()
    };
  },
  watch: {
    breakpoint: {
      handler() {
        this.initSlider();
      },
      deep: true
    }
  },
  mounted() {
    this.tabs = this.$children;
    this.initSlider();
    window.addEventListener("resize", this.updateBreakpoint);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;
    },
    initSlider() {
      if (this.breakpoint.isMobile) {
        this.$nextTick(() => {
          this.slider = new Swiper(this.$refs.slider, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 20,
            slideClass: "root-programs__tab",
            slideActiveClass: "_active",
            pagination: {
              el: this.$refs.pagination,
              clickable: true
            }
          });
        });
      } else {
        if (this.slider) {
          this.slider.destroy();
        }
      }
    },
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768
      };
    },
    updateBreakpoint() {
      const { isMobile } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
    }
  }
};
</script>
