<template>
  <ul class="glossary-menu">
    <slot />
  </ul>
</template>

<script>
import Stickyfill from "stickyfilljs";

export default {
  name: "GlossaryMenu",
  data() {
    return {
      mainNav: null,
      mainNavTop: null,
      mainNavLinks: null,
      mainSections: null
    };
  },
  mounted() {
    window.addEventListener("load", this.stickyMenu);
    window.addEventListener("load", this.smoothScroll);
  },
  methods: {
    stickyMenu() {
      this.mainNav = document.querySelectorAll(".glossary-menu");
      this.mainNavLinks = document.querySelectorAll(".glossary-menu li a");
      this.mainSections = document.querySelectorAll(".glossary-content");
      this.mainNavTop = this.mainNav[0].offsetTop;

      window.addEventListener("scroll", () => {
        let yOffset = 100;
        let fromTop = window.pageYOffset;

        if (fromTop > this.mainNavTop) {
          this.mainNav[0].classList.add("_sticky");
          Stickyfill.add(document.querySelectorAll("._sticky"));
        } else {
          this.mainNav[0].classList.remove("_sticky");
          Stickyfill.removeAll(document.querySelectorAll("._sticky"));
        }

        this.mainNavLinks.forEach(link => {
          let section = document.querySelector(link.hash);

          if (
            section.offsetTop <= fromTop + yOffset &&
            section.offsetTop + section.offsetHeight > fromTop + yOffset
          ) {
            link.classList.add("_active");
          } else {
            link.classList.remove("_active");
          }
        });
      });
    },
    smoothScroll() {
      this.mainNavLinks.forEach(link => {
        link.addEventListener("click", event => {
          event.preventDefault();
          let yOffset = -100;
          let target = document.querySelector(event.target.hash);
          let y =
            target.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({
            top: y,
            behavior: "smooth"
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".glossary-menu";

#{$b} {
  &._sticky {
    margin: 0 !important;
    position: sticky;
    top: 100px;

    &:before,
    &:after {
      content: "";
      display: table;
    }
    @include md-desktop-only {
      top: 100px * $zoom;
    }
  }
  & > li {
    &:not(:last-child) {
      margin-bottom: 30px;

      @include md-desktop-only {
        margin-bottom: 30px * $zoom;
      }
    }
    & > a {
      display: inline-block;
      font-weight: normal;
      @include font(18px, 1.56);
      color: $color-text-base;

      &:hover {
        text-decoration: underline;
      }
      @include md-desktop-only {
        font-size: 18px * $zoom;
      }
      @include low-desktop {
        @include font(14px, 1.4);
      }
      &._active {
        color: $color-bg-dark;
        text-decoration: none;
        @include font(20px, 1.42, 600);

        @include md-desktop-only {
          font-size: 20px * $zoom;
        }
        @include low-desktop {
          @include font(16px, 1.36);
        }
      }
    }
  }
}
</style>
