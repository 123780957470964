<template>
  <div class="hero-slider">
    <div class="swiper-container">
      <div class="hero-slider__controls">
        <div class="hero-slider__prev">
          <svg
            width="38"
            height="26"
            viewBox="0 0 38 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5888 0.391022C25.0711 -0.130341 24.2095 -0.130341 23.6737 0.391022C23.156 0.89477 23.156 1.73318 23.6737 2.23575L33.3843 11.6849L1.34068 11.6849C0.593712 11.686 0 12.2638 0 12.9906C0 13.7175 0.593712 14.314 1.34068 14.314L33.3843 14.314L23.6737 23.7455C23.156 24.2668 23.156 25.1064 23.6737 25.609C24.2095 26.1303 25.0723 26.1303 25.5888 25.609L37.5982 13.923C38.1339 13.4192 38.1339 12.5808 37.5982 12.0782L25.5888 0.391022Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="hero-slider__next">
          <svg
            width="38"
            height="26"
            viewBox="0 0 38 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5888 0.391022C25.0711 -0.130341 24.2095 -0.130341 23.6737 0.391022C23.156 0.89477 23.156 1.73318 23.6737 2.23575L33.3843 11.6849L1.34068 11.6849C0.593712 11.686 0 12.2638 0 12.9906C0 13.7175 0.593712 14.314 1.34068 14.314L33.3843 14.314L23.6737 23.7455C23.156 24.2668 23.156 25.1064 23.6737 25.609C24.2095 26.1303 25.0723 26.1303 25.5888 25.609L37.5982 13.923C38.1339 13.4192 38.1339 12.5808 37.5982 12.0782L25.5888 0.391022Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="swiper-wrapper">
        <slot />
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "HeroSlider",
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    const slides = this.$el.querySelectorAll(".swiper-slide");
    const loop = slides.length > 1;
    const autoplay = loop ? { delay: 5000 } : false;

    this.slider = new Swiper(this.$el.querySelector(".swiper-container"), {
      slidesPerView: 1,
      loop: loop,
      effect: "fade",
      autoplay: autoplay,
      watchOverflow: true,
      navigation: {
        prevEl: ".hero-slider__prev",
        nextEl: ".hero-slider__next",
        disabledClass: "_disabled"
      },
      pagination: {
        el: this.$el.querySelector(".swiper-pagination"),
        clickable: true
      },
      on: {
        init: function() {
          if (this.realIndex > 1) {
            this.navigation.init();
            this.navigation.nextEl.classList.add("swiper-button-lock");
            this.navigation.prevEl.classList.add("swiper-button-lock");
          }
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".hero-slider";

#{$b} {
  position: relative;
  z-index: 2;
  background-size: cover;
  background-repeat: no-repeat;
  color: $color-bg-dark;

  &#{$b}_light {
    color: $white-true;
    background-color: $color-bg-light;

    .swiper-pagination-bullet {
      border-color: $border-gray;

      &:before {
        background-color: $white-true;
      }
    }

    .swiper-slide {
      &-active {
        position: relative;

        &:before {
          content: "";
          @include full-abs;
          z-index: -1;
          background-color: rgba(26, 38, 27, 0.44);
          background: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 100%
          );
          opacity: 0.5;
        }
      }
    }
  }

  .container {
    box-sizing: border-box;

    // IE fix - flex min-height
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: flex;
      flex-direction: column;
    }
  }

  &__inner {
    position: relative;
  }

  &__content {
    position: relative;
    height: 100%;
    max-width: 650px;
    min-height: 662px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;

    &_medium {
      max-width: 750px;
    }
    &_large {
      max-width: 900px;
    }
    @include low-desktop {
      max-width: 100%;
      padding-left: 80px;
      padding-right: 80px;
    }
    @include tablet {
      min-height: 600px;
    }
    @include mobile {
      min-height: 600px;
      padding: 144px 0 70px;
    }
    #{$b}_centered & {
      justify-content: center;
    }
    #{$b}_medium & {
      min-height: 750px;

      @include tablet {
        min-height: 600px;
      }
      @include mobile {
        min-height: 568px;
      }
    }
  }

  .swiper-slide {
    height: auto;
    position: relative;
    z-index: 0;
    opacity: 0 !important;
    transition: 0.5s;
    transition-property: opacity;

    &-active {
      position: relative;
      z-index: 1;
      opacity: 1 !important;
    }
  }

  &__title {
    @include font(44px, 60px);
    margin-bottom: 30px;
    font-weight: 600;
    transform: translateY(-30px);
    opacity: 0;
    transition: 0.3s;

    @include tablet {
      @include font(36px, 1.44);
    }

    @include mobile {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    nobr {
      @include mobile {
        white-space: normal;
      }
    }
  }

  &__subtitle {
    @include font(24px, 1.42);
    font-weight: 400;
    transform: translateY(-20px);
    opacity: 0;
    transition: 0.3s 0.1s;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    @include mobile {
      font-size: 20px;
      line-height: 28px;
    }

    nobr {
      @include mobile {
        white-space: normal;
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.56;
    transform: translateY(-40px);
    opacity: 0;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    @include md-desktop-only {
      font-size: 18px * $zoom;
    }

    nobr {
      @include mobile {
        white-space: normal;
      }
    }
  }

  &__buttons {
    transform: translateY(-10px);
    opacity: 0;
    transition: 0.3s 0.2s;

    & > * {
      flex-shrink: 0;
    }

    @include mobile {
      margin-top: 25px;
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;

      & > * {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    &_bottom {
      margin: 0;
      position: absolute;
      bottom: 70px;
      right: 0;
      left: 0;
    }

    &_centered {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include mobile {
        align-items: center;

        .button {
          width: 100%;
          max-width: 400px;
        }
      }
    }

    .button {
      padding-left: 40px;
      padding-right: 40px;

      @include md-desktop-only {
        @include zoom(padding-left, 40px);
        @include zoom(padding-right, 40px);
      }

      @include mobile {
        width: 100%;
        padding: 0 16px;
      }
    }

    &.registration_wrapper .button:first-child {
      margin-right: 16px;

      @include mobile {
        margin-right: 0;
      }
    }

    .form-body {
      text-align: start;
    }
  }

  &__date {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 80px;
    background-image: url("/local/templates/main/src/assets/img/eduevents/date-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-purple;

    @include md-desktop-only {
      right: 20px;
      width: 140px;
      height: 140px;
    }

    @include low-desktop {
      display: none;
    }

    &__num {
      display: block;
      @include font(80px, 1, 700);

      @include md-desktop-only {
        font-size: 56px;
      }
    }

    &__month {
      display: block;
      @include font(28px, 1.43, 400);

      @include md-desktop-only {
        font-size: 20px;
      }
    }
  }

  &__link {
    text-decoration: underline;
    font-size: 16px;
    line-height: 24px;

    @include mobile-min {
      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    @include mobile {
      margin: 0 auto;
    }
  }

  &__title,
  &__subtitle,
  &__buttons,
  &__tags,
  &__text {
    .swiper-slide-active & {
      transform: none;
      opacity: 1;
    }
  }

  &__tags {
    display: flex;
    margin-bottom: 30px;
    transform: translateY(-40px);
    opacity: 0;
    transition: 0.3s;
    transition-property: transform, opacity;

    & > li {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 15px;
      color: $white-true;
      font-size: 12px;
      line-height: 1.25;
      background-color: rgba($white-true, 0.12);
      border-radius: 5px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    &_base {
      & > li {
        background-color: $color-base;
        color: $white-true;
      }
    }
  }

  &__controls {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;

    @include md-desktop-only {
      max-width: 1360px * $zoom;
    }
    @include low-desktop {
      max-width: calc(100% - 80px);
    }
    @include tablet {
      display: none;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    position: absolute;
    width: 38px;
    height: 29px;
    transition: 0.3s;
    color: $color-bg-dark;

    &:hover {
      &:not(._disabled) {
        color: $color-base;
      }
    }

    #{$b}_light & {
      color: $white-true;
    }

    &._disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__prev {
    left: 0;
    transform: scaleX(-1);
  }

  &__next {
    right: 0;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    z-index: 2;
    @include clickable;

    @include tablet {
      bottom: 33px;
    }

    @include mobile {
      bottom: 23px;
    }

    &-bullet {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin: 5px;
      border: 1px solid $color-bg-dark;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-bg-dark;
        transition: 0.3s;
      }

      &:only-child {
        display: none;
      }

      &-active {
        &:before {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  &_image-adaptive {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -2;
    object-fit: cover;
  }
}
.vm--block-scroll .swiper-container {
  overflow: visible;
}
</style>
