var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notify-popup__body"},[_c('div',{staticClass:"notify-popup__title _g-text-center"},[_vm._v(" "+_vm._s(_vm.$t("application_title"))+" ")]),(!_vm.form.resend)?_c('form',{staticClass:"form",attrs:{"action":_vm.url},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":_vm.onReset}},[_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"program","label":_vm.$t('program') + ' *',"error":_vm.$v.form.programm.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.programm.$error && !_vm.$v.form.programm.required
          }
        ]},model:{value:(_vm.form.programm),callback:function ($$v) {_vm.$set(_vm.form, "programm", $$v)},expression:"form.programm"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"company","label":_vm.$t('company_name')},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"fio","label":_vm.$t('fio') + ' *',"error":_vm.$v.form.fio.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.fio.$error && !_vm.$v.form.fio.required
          }
        ]},model:{value:(_vm.form.fio),callback:function ($$v) {_vm.$set(_vm.form, "fio", $$v)},expression:"form.fio"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"email","label":_vm.$t('form.email') + ' *',"type":"email","error":_vm.$v.form.email.$error || !_vm.$v.form.email.email,"messages":[
          {
            type: 'error',
            i18: 'error.email.invalid',
            state: !_vm.$v.form.email.email
          },
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.email.$error && !_vm.$v.form.email.required
          }
        ]},on:{"blur":function($event){return _vm.$v.form.email.$touch()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"tel","label":_vm.$t('phone.input') + ' *',"type":"tel","error":_vm.$v.form.phone.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.phone.$error && !_vm.$v.form.phone.required
          },
          {
            type: 'error',
            min: _vm.$v.form.phone.$params.minLength.min,
            state: !_vm.$v.form.phone.minLength
          }
        ]},on:{"blur":function($event){return _vm.$v.form.phone.$touch()}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{staticClass:"form-row"},[_c('label',{staticClass:"form-agree"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.agreement),expression:"form.agreement"}],staticClass:"form-agree__checkbox",class:{
            'form-agree__checkbox--error': _vm.$v.form.agreement.$error
          },attrs:{"type":"checkbox","name":"data-agreement"},domProps:{"checked":Array.isArray(_vm.form.agreement)?_vm._i(_vm.form.agreement,null)>-1:(_vm.form.agreement)},on:{"change":[function($event){var $$a=_vm.form.agreement,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "agreement", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "agreement", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "agreement", $$c)}},function($event){return _vm.$v.form.agreement.$touch()}]}}),_c('span',{staticClass:"form-agree__text"},[_vm._v(" "+_vm._s(_vm.$t("agreement_left"))+" "),_c('a',{attrs:{"href":"/upload/iblock/999/09%20%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BF%D0%BE%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.docx"}},[_vm._v(" "+_vm._s(_vm.$t("agreement_right"))+" ")])])]),(_vm.$v.form.agreement.$error)?_c('div',{staticClass:"form-input-message is-error"},[_vm._v(" "+_vm._s(_vm.$t("agreement_need"))+" ")]):_vm._e()]),_c('div',{staticClass:"mt-14 _g-text-center"},[_c('button',{staticClass:"button button_base",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("application_send"))+" ")])])]):_c('div',[_c('div',{staticClass:"notify-popup__text _g-text-center",staticStyle:{"max-width":"360px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("success_left"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("success_right"))+" ")])]),_c('div',{staticClass:"notify-popup__buttons"},[_c('a',{staticClass:"notify-popup__link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$modal.hide('campusRegistrationPopup')}}},[_vm._v(" "+_vm._s(_vm.$t("success_close"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }