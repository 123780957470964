<template>
  <div class="section-switch">
    <div class="section-switch__overflow-wrapper">
      <div v-dragscroll.x:nochilddrag class="section-switch__overflow">
        <ul class="section-switch__list">
          <li>
            <div
              class="section-switch__button"
              :class="{
                'is-active': !currentSection
              }"
              @click.prevent="changeSection(null)"
            >
              {{ $t("all") }}
            </div>
          </li>
          <li v-for="(section, i) in sections" :key="`section_switch_${i}`">
            <div
              class="section-switch__button"
              :class="{
                'is-active': section.code === currentCectionCode
              }"
              @click.prevent="changeSection(section)"
            >
              {{ section.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionSwitch",
  props: {
    currentSection: {
      type: Object,
      default: () => {}
    },
    sections: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    currentCectionCode() {
      return this.currentSection?.code ?? null;
    }
  },
  methods: {
    changeSection(section) {
      this.$emit("change-section", section);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".section-switch";

#{$b} {
  &__overflow-wrapper {
    position: relative;
    display: flow-root;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: $inner-pd-tab;

      @include md-desktop-only {
        @include zoom(width, $inner-pd-tab);
      }

      @include mobile {
        width: $inner-pd-mob;
      }
    }

    &::before {
      left: #{-$inner-pd-tab};
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );

      @include md-desktop-only {
        @include zoom(left, #{-$inner-pd-tab});
      }

      @include mobile {
        left: #{-$inner-pd-mob};
      }

      ._dark & {
        background: linear-gradient(
          270deg,
          rgba($color-bg-dark, 0.01) 0%,
          $color-bg-dark 100%
        );
      }
      ._g-bg-alt &,
      .section._g-bg-gray & {
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          $drop-hover 100%
        );
      }
    }

    &::after {
      right: #{-$inner-pd-tab};
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );

      @include md-desktop-only {
        @include zoom(right, #{-$inner-pd-tab});
      }

      @include mobile {
        right: #{-$inner-pd-mob};
      }

      ._dark & {
        background: linear-gradient(
          90deg,
          rgba($color-bg-dark, 0.01) 0%,
          $color-bg-dark 100%
        );
      }
      ._g-bg-alt &,
      .section._g-bg-gray & {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          $drop-hover 100%
        );
      }
    }
  }

  &__overflow {
    display: flow-root;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 12px $inner-pd-tab;
    margin: -12px #{-$inner-pd-tab};

    @include md-desktop-only {
      @include zoom(padding, 12px $inner-pd-tab);
      @include zoom(margin, -12px #{-$inner-pd-tab});
    }

    @include mobile {
      padding: 12px $inner-pd-mob;
      margin: -12px #{-$inner-pd-mob};
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list {
    min-width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;

    & > li {
      &:not(:last-child) {
        margin-right: 20px;

        @include md-desktop-only {
          @include zoom(margin-right, 20px);
        }
      }
    }
  }

  &__button {
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
    background: $white-true;
    color: $color-text-base;
    font-size: 16px;
    line-height: 1.25;
    white-space: nowrap;
    box-shadow: 0px 2px 12px #eaecee;
    border-radius: 8px;
    appearance: none;
    transition: 0.3s ease;
    transition-property: color, background-color, box-shadow, border-color;

    @include md-desktop-only {
      @include zoom(height, 48px);
      @include zoom(padding, 14px 20px);
      @include zoom(font-size, 16px);
      @include zoom(box-shadow, 0px 2px 12px #eaecee);
      @include zoom(border-radius, 8px);
    }

    &.is-active {
      background: $color-bg-alt;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "all": "All"
  },
  "ru": {
    "all": "Все"
  }
}
</i18n>
