<template>
  <div class="program-structure-tabs">
    <div class="program-structure-tabs__row">
      <div class="program-structure-tabs__overflow">
        <ul class="program-structure-tabs__nav">
          <li
            v-for="(item, i) in tabs"
            :key="i"
            :class="{ _active: item.isActive }"
            @click.prevent="selectTab(i)"
          >
            <div class="program-structure-tabs__nav__name">{{ item.name }}</div>
            <div class="program-structure-tabs__nav__desc">
              {{ item.desc }} <strong>{{ item.date }}</strong>
            </div>
          </li>
        </ul>
      </div>
      <div class="program-structure-tabs__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramStructureTabs",
  data() {
    return {
      tabs: []
    };
  },
  mounted() {
    this.tabs = this.$children;

    if (!this.tabs.some(this.isSelected)) {
      this.tabs[0].isActive = true;
    }
  },
  methods: {
    isSelected(item) {
      return item.selected;
    },
    selectTab(selectedTab) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".program-structure-tabs";

#{$b} {
  display: block;

  &__overflow {
    @include tablet {
      min-width: 100%;
      -ms-overflow-style: none;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      box-sizing: border-box;
      padding: 0 40px 30px;
      margin: 0 -40px;
      border-bottom: 1px solid $border-gray-lt;
      margin-bottom: 30px;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }

  &__row {
    display: flex;

    @include tablet {
      display: block;
    }

    #{$b}__overflow {
      @include tablet-min {
        width: 33.3333%;
      }
    }

    #{$b}__content {
      @include tablet-min {
        width: 66.6667%;
        padding-left: 32px;
      }

      @include md-desktop-only {
        padding-left: 32px * $zoom;
      }
    }
  }

  &__nav {
    list-style: none;
    padding-left: 0;
    margin: 0;

    @include tablet {
      display: inline-flex;
      min-width: 100%;
    }

    > li {
      cursor: pointer;

      @include tablet-min {
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $border-gray-lt;

        &:first-child {
          border-top: 1px solid $border-gray-lt;
        }
      }

      @include md-desktop-only {
        padding-top: 30px * $zoom;
        padding-bottom: 30px * $zoom;
      }

      @include tablet {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      &._active {
        cursor: default;

        #{$b}__nav__name {
          color: $color-bg-dark;
        }
      }
    }

    &__name {
      display: block;
      @include font(18px, 1.56, 600);
      color: $color-base;
      margin-bottom: 6px;

      ._g-bg-light-blue & {
        color: $color-light-blue-2;
      }

      @include md-desktop-only {
        font-size: 18px * $zoom;
        margin-bottom: 6px * $zoom;
      }
    }

    &__desc {
      display: block;
      font-style: italic;
      color: $black-true;

      @include tablet-only {
        display: inline;
      }

      > strong {
        font-weight: 600;

        @include tablet {
          white-space: nowrap;
          display: block;
          margin-top: 6px;
        }
      }
    }
  }

  &__content {
    line-height: 1.5;

    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    p,
    ul,
    ol,
    table {
      &:not(:last-child) {
        margin-bottom: 20px;

        @include md-desktop-only {
          margin-bottom: 20px * $zoom;
        }
      }
    }

    * + h2,
    * + .h2,
    * + h3,
    * + .h3,
    * + h4,
    * + .h4 {
      margin-top: 30px;

      @include md-desktop-only {
        margin-top: 30px * $zoom;
      }
    }

    ul,
    ol {
      > li:not(:last-child) {
        margin-bottom: 16px;

        @include md-desktop-only {
          margin-bottom: 16px * $zoom;
        }
      }
    }

    .accordion-item {
      @include tablet {
        &:first-child {
          padding-top: 0;
          border-top: 0;
        }
      }
    }
  }
}
</style>
