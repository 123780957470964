<template>
  <div class="container">
    <form class="form mb-10" action="">
      <div class="form-row">
        <div class="form-status-item">
          <div class="form-status-item__icon">
            <template v-if="arResult.confirm === 'Y'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 34 34"
              >
                <circle
                  cx="17"
                  cy="17"
                  r="16"
                  stroke="#18B346"
                  stroke-width="2"
                />
                <path
                  stroke="#18B346"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M14 17l2.625 3L21 14"
                />
              </svg>
            </template>
            <template v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 34 34"
              >
                <circle
                  cx="17"
                  cy="17"
                  r="16"
                  stroke="#FF3939"
                  stroke-width="2"
                />
                <path
                  fill="#FF3939"
                  d="M16.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
                />
              </svg>
            </template>
          </div>
          <div class="form-status-item__text" v-text="confirmText"></div>
        </div>
      </div>

      <div v-if="arResult.confirm === 'N'" class="form-row">
        <a
          v-if="showAgainButton"
          href="#"
          class="form-link"
          @click.prevent="send"
        >
          {{ $t("repeat") }}
        </a>
        <p v-else>
          {{ showAgainText }}
        </p>
      </div>

      <div class="form-row">
        <a :href="buttonUrlMain" class="button button_dark mr-5" type="submit">
          {{ $t("to_main") }}
        </a>
        <a :href="buttonUrlLk" class="button button_base">
          {{ $t("to_lk") }}
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ConfirmEmail",
  props: {
    action: {
      type: String,
      default: ""
    },
    arResult: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        email: "",
        confirm: ""
      }
    },
    buttonUrlMain: {
      type: String,
      default: ""
    },
    buttonUrlLk: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAgainButton: true,
      showAgainText: ""
    };
  },
  computed: {
    confirmText() {
      return this.arResult.confirm === "Y"
        ? this.$i18n.t("email_success")
        : this.$i18n.t("email_error");
    }
  },
  methods: {
    async send() {
      this.showAgainButton = false;

      const data = new FormData();
      data.append("id", this.arResult.id);
      data.append("code", this.arResult.code);
      let options = {
        url: this.action,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: data
      };
      await axios(options).then(response => {
        if (response.data.code) {
          switch (response.data.code) {
            case 200:
              this.showAgainText = this.$i18n.t("show_again_success");
              break;
            case 300:
              this.showAgainText = this.$i18n.t("show_again_code");
              break;
            case 400:
              this.showAgainText = this.$i18n.t("show_again_user_confirm");
              break;
            case 500:
              this.showAgainText = this.$i18n.t("show_again_user_not_found");
              break;
            default:
              this.showAgainText = this.$i18n.t("show_again_error");
          }
        } else {
          this.showAgainText = this.$i18n.t("show_again_error");
        }
      });
    }
  }
};
</script>

<i18n>
  {
    "en": {
      "repeat": "Resend confirmation email?",
      "to_main": "To main",
      "to_lk": "To personal cabinet",
      "email_success": "Email confirmed",
      "email_error": "This e-mail is not confirmed",
      "show_again_success": "Email has been resent!",
      "show_again_code": "Invalid confirmation code",
      "show_again_user_confirm": "User is already activated",
      "show_again_user_not_found": "User is not found",
      "show_again_error": "Failed to resend email. Try later."
    },
    "ru": {
      "repeat": "Отправить письмо с подтверждением повторно?",
      "to_main": "На главную",
      "to_lk": "В личный кабинет",
      "email_success": "E-mail подтвержден",
      "email_error": "Данный e-mail не подтвержден",
      "show_again_success": "Письмо повторно отправлено!",
      "show_again_code": "Неверный код подтверждения",
      "show_again_user_confirm": "Пользователь уже активирован",
      "show_again_user_not_found": "Пользователь не найден",
      "show_again_error": "Не удалось повторно отправить письмо. Попробуйте позже."
    }
  }
</i18n>
