<template>
  <div>
    <!-- search -->
    <section class="section section_edu-search">
      <div class="container">
        <edutech-search-form
          v-model.trim="searchTerm"
          :suggest-list="suggestList"
          @search="search"
          @reset="reset"
        />
      </div>
    </section>
    <!-- /search -->

    <!-- search-content -->
    <div v-show="!showPageContent">
      <!-- Мероприятия -->
      <div
        v-if="searchData.events && searchData.events.length"
        class="section section_zb"
      >
        <div class="container">
          <div
            class="edu-main-title edu-main-title_nm edu-main-title_column-tablet"
          >
            <main-title :value="$t('events')" />
            <div class="edu-main-title__container">
              <div class="edu-main-title__label">
                {{ $t("seminars_and_workshops") }}
              </div>
              <a :href="$t('all_events_link')" class="edu-main-title__link">
                {{ $t("all_events") }}
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="edu-main-title__arrow"
                >
                  <path
                    d="M12.7944 0.195511C12.5355 -0.0651703 12.1047 -0.0651703 11.8368 0.195511C11.578 0.447385 11.578 0.866588 11.8368 1.11788L16.6921 5.84243L0.67034 5.84243C0.296856 5.84301 0 6.13188 0 6.4953C0 6.85873 0.296856 7.15699 0.67034 7.15699L16.6921 7.15699L11.8368 11.8727C11.578 12.1334 11.578 12.5532 11.8368 12.8045C12.1047 13.0652 12.5361 13.0652 12.7944 12.8045L18.7991 6.96148C19.067 6.7096 19.067 6.2904 18.7991 6.03911L12.7944 0.195511Z"
                    fill="#131313"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <edutech-index-events :items="searchData.events" />
        </div>
      </div>

      <!-- Журналы -->
      <section
        v-if="searchData.journals && searchData.journals.length"
        class="section section_zb"
      >
        <div class="container">
          <div
            class="edu-main-title edu-main-title_nm edu-main-title_column-tablet"
          >
            <main-title value="Журналы и отчеты" />
            <div class="edu-main-title__container">
              <div class="edu-main-title__label">Журналы и отчеты</div>
              <a href="/edutech-club/journals/" class="edu-main-title__link">
                Перейти в раздел
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="edu-main-title__arrow"
                >
                  <path
                    d="M12.7944 0.195511C12.5355 -0.0651703 12.1047 -0.0651703 11.8368 0.195511C11.578 0.447385 11.578 0.866588 11.8368 1.11788L16.6921 5.84243L0.67034 5.84243C0.296856 5.84301 0 6.13188 0 6.4953C0 6.85873 0.296856 7.15699 0.67034 7.15699L16.6921 7.15699L11.8368 11.8727C11.578 12.1334 11.578 12.5532 11.8368 12.8045C12.1047 13.0652 12.5361 13.0652 12.7944 12.8045L18.7991 6.96148C19.067 6.7096 19.067 6.2904 18.7991 6.03911L12.7944 0.195511Z"
                    fill="#131313"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="_g-bg-gray">
          <div class="container">
            <div class="edu-cards edu-cards_outer-bg">
              <edutech-index-journals :items="searchData.journals" />
            </div>
          </div>
        </div>
      </section>

      <!-- Пульс -->
      <section
        v-if="searchData.pulse && searchData.pulse.length"
        class="section section_zb"
      >
        <div class="container">
          <div
            class="edu-main-title edu-main-title_nm edu-main-title_column-tablet"
          >
            <main-title value="Пульс EduTech" />
            <div class="edu-main-title__container">
              <div class="edu-main-title__label">Пульс EduTech</div>
              <a href="/edutech-club/market/" class="edu-main-title__link">
                перейти в раздел
                <svg
                  class="edu-main-title__arrow"
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7944 0.195511C12.5355 -0.0651703 12.1047 -0.0651703 11.8368 0.195511C11.578 0.447385 11.578 0.866588 11.8368 1.11788L16.6921 5.84243L0.67034 5.84243C0.296856 5.84301 0 6.13188 0 6.4953C0 6.85873 0.296856 7.15699 0.67034 7.15699L16.6921 7.15699L11.8368 11.8727C11.578 12.1334 11.578 12.5532 11.8368 12.8045C12.1047 13.0652 12.5361 13.0652 12.7944 12.8045L18.7991 6.96148C19.067 6.7096 19.067 6.2904 18.7991 6.03911L12.7944 0.195511Z"
                    fill="#131313"
                  />
                </svg>
              </a>
            </div>
          </div>
          <edutech-index-pulse :items="searchData.pulse" />
        </div>
      </section>

      <!-- Инструменты -->
      <section
        v-if="searchData.tools && searchData.tools.length"
        class="section section_zb"
      >
        <div class="container">
          <div
            class="edu-main-title edu-main-title_nm edu-main-title_column-tablet"
          >
            <main-title
              class="_multiple-rows"
              value="Инструменты разработки <br> обучающих решений"
            ></main-title>
            <div class="edu-main-title__container">
              <div class="edu-main-title__label"></div>
              <a href="/edutech-club/tools/" class="edu-main-title__link">
                перейти в раздел
                <svg
                  class="edu-main-title__arrow"
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7944 0.195511C12.5355 -0.0651703 12.1047 -0.0651703 11.8368 0.195511C11.578 0.447385 11.578 0.866588 11.8368 1.11788L16.6921 5.84243L0.67034 5.84243C0.296856 5.84301 0 6.13188 0 6.4953C0 6.85873 0.296856 7.15699 0.67034 7.15699L16.6921 7.15699L11.8368 11.8727C11.578 12.1334 11.578 12.5532 11.8368 12.8045C12.1047 13.0652 12.5361 13.0652 12.7944 12.8045L18.7991 6.96148C19.067 6.7096 19.067 6.2904 18.7991 6.03911L12.7944 0.195511Z"
                    fill="#131313"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="_g-bg-gray">
          <div class="container">
            <edutech-index-tools :items="searchData.tools" />
          </div>
        </div>
      </section>

      <!-- Глоссарий -->
      <section
        v-if="searchData.glossary && searchData.glossary.length"
        class="section section_zb"
      >
        <div class="container">
          <div
            class="edu-main-title edu-main-title_nm edu-main-title_column-tablet"
          >
            <main-title
              class="_multiple-rows"
              value="Словарь-справочник по <br>корпоративному обучению"
            />
            <div
              class="edu-main-title__container edu-main-title__container_small"
            >
              <div class="edu-main-title__label"></div>
              <a
                href="/edutech-club/glossary/"
                class="edu-main-title__link mt-8"
              >
                Перейти в раздел
                <svg
                  class="edu-main-title__arrow"
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7944 0.195511C12.5355 -0.0651703 12.1047 -0.0651703 11.8368 0.195511C11.578 0.447385 11.578 0.866588 11.8368 1.11788L16.6921 5.84243L0.67034 5.84243C0.296856 5.84301 0 6.13188 0 6.4953C0 6.85873 0.296856 7.15699 0.67034 7.15699L16.6921 7.15699L11.8368 11.8727C11.578 12.1334 11.578 12.5532 11.8368 12.8045C12.1047 13.0652 12.5361 13.0652 12.7944 12.8045L18.7991 6.96148C19.067 6.7096 19.067 6.2904 18.7991 6.03911L12.7944 0.195511Z"
                    fill="#131313"
                  />
                </svg>
              </a>
            </div>
          </div>
          <!-- -->
          <edutech-index-glossary :items="searchData.glossary" />
        </div>
      </section>

      <!-- Блок пусто -->
      <section v-show="isEmptySearchResult" class="section">
        <div class="container">
          <not-found :text="$t('not_found')"></not-found>
        </div>
      </section>
    </div>
    <!-- /search-content -->

    <!-- page content -->
    <div v-show="showPageContent">
      <slot />
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EdutechIndex",
  props: {
    suggestList: {
      type: Array,
      default: () => []
    },
    isEnglish: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerm: "",
      searchData: {},
      showPageContent: true
    };
  },
  computed: {
    isEmptySearchResult() {
      const entities = ["events", "journals", "pulse", "tools", "glossary"];
      const itemsCount = entities.reduce((acc, entity) => {
        if (typeof this.searchData[entity] !== "undefined") {
          return acc + this.searchData[entity].length;
        }
        return acc;
      }, 0);
      return !itemsCount;
    }
  },
  watch: {
    searchTerm(value) {
      if (!value) {
        this.reset();
      }
    }
  },
  methods: {
    async search() {
      const params = {
        q: this.searchTerm,
        isEnglish: this.isEnglish
      };
      const enPrefix = this.isEnglish ? "/en" : "";
      const res = await axios.post(
        enPrefix + "/local/ajax/edutech_index.php",
        params
      );
      if (res.data.success) {
        this.searchData = res.data.data;
        this.showPageContent = false;
      }
    },
    reset() {
      this.searchTerm = "";
      this.showPageContent = true;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "events": "Events",
    "seminars_and_workshops": "Seminars and workshops",
    "all_events": "All events",
    "all_events_link": "/en/edutech-club/events/",
    "not_found": "Nothing found"
  },
  "ru": {
    "events": "Мероприятия",
    "seminars_and_workshops": "Семинары и воркшопы",
    "all_events": "Все мероприятия",
    "all_events_link": "/edutech-club/events/",
    "not_found": "Ничего не найдено"
  }
}
</i18n>
