<template>
  <div
    :id="id"
    ref="accordion"
    class="accordion-item"
    :class="{ 'accordion-item_active': visible }"
  >
    <div class="accordion-item__title" @click.prevent="open">
      <span class="accordion-item__title__text">{{ name }}</span>
      <span class="accordion-item__title__arrow">
        <svg viewBox="0 0 11 11">
          <path
            d="M5.5 8.9L.3 3.7c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l3.8 3.8 3.8-3.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4L5.5 8.9z"
          />
        </svg>
      </span>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible" class="accordion-item__collapse">
        <div class="accordion-item__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AccordionItem",
  props: {
    id: { type: String, default: "" },
    name: { type: String, default: "" },
    selected: { type: Boolean, default: false }
  },
  inject: ["Accordion"],
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },
  mounted() {
    if (this.selected) {
      this.Accordion.active = this.index;
    }
  },
  created() {
    this.index = this.Accordion.count++;
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".accordion-item";

#{$b} {
  display: block;
  border-bottom: 1px solid $border-gray-lt;

  &:first-child {
    border-top: 1px solid $border-gray-lt;
  }

  &__title {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    @include font(20px, 1.4, 600);
    color: $color-base;
    padding: 14px 0;

    @include md-desktop-only {
      @include zoom(padding, 14px 0);
      @include zoom(font-size, 20px);
    }

    @include tablet {
      font-size: 18px;
    }

    .accordions_alt-black & {
      font-size: 24px;
      color: $black-true;
      padding: 32px 0;

      @include md-desktop-only {
        @include zoom(font-size, 24px);
        @include zoom(padding, 32px 0);
      }

      @include tablet {
        font-size: 20px;
        padding: 24px 0;
      }

      @include mobile {
        font-size: 18px;
        padding: 16px 0;
      }
    }

    &__text {
      margin-right: 20px;
      white-space: normal;

      @include md-desktop-only {
        margin-right: 20px * $zoom;
      }
    }

    &__arrow {
      width: 9px;
      height: 9px;
      color: inherit;
      display: inline-block;
      font-size: 0;
      transition: 0.2s ease;
      transition-property: transform, color;
      margin-left: auto;

      @include md-desktop-only {
        width: 9px * $zoom;
        height: 9px * $zoom;
      }

      #{$b}_active & {
        transform: rotateX(180deg);
      }

      .accordions_alt-black & {
        width: 18px;
        height: 18px;

        @include md-desktop-only {
          @include zoom(width, 18px);
          @include zoom(height, 18px);
        }

        @include tablet {
          width: 14px;
          height: 14px;
        }

        @include mobile {
          width: 12px;
          height: 12px;
        }
      }

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: currentColor;
        }
      }
    }
  }

  &__collapse {
    &.accordion-enter-active,
    &.accordion-leave-active {
      will-change: height, opacity;
      transition: height 0.2s ease, opacity 0.2s ease;
      overflow: hidden;
    }

    &.accordion-enter,
    &.accordion-leave-to {
      height: 0 !important;
      opacity: 0;
    }
  }

  &__content {
    @include font(16px, 1.5);
    padding: 0 0 14px;

    @include md-desktop-only {
      @include zoom(padding, 0 0 14px);
      @include zoom(font-size, 16px);
    }

    .accordions_alt-black & {
      padding: 0 0 32px;

      @include md-desktop-only {
        @include zoom(padding, 0 0 32px);
      }

      @include tablet {
        padding: 0 0 24px;
      }

      @include mobile {
        padding: 0 0 16px;
      }
    }

    p {
      @include font(inherit, inherit);
    }
  }
}
</style>
