<template>
  <div :id="id" />
</template>

<script>
export default {
  name: "GooglePayButton",
  props: {
    id: {
      type: String,
      required: false,
      default: "googlePayButton"
    },
    options: {
      type: Object,
      required: true,
      default: () => {
        return {
          environment: "TEST",
          buttonColor: "white",
          allowedCardNetworks: ["MASTERCARD", "VISA"],
          allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          merchantInfo: {
            merchantName: "Example Merchant",
            merchantId: "12345678901234567890"
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: "1.00", // Сюда вставить прайс
            currencyCode: "RUB",
            countryCode: "RU"
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "example",
              gatewayMerchantId: "exampleGatewayMerchantId"
            }
          }
        };
      }
    }
  },
  data() {
    return {
      baseRequest: {
        apiVersion: 2,
        apiVersionMinor: 0
      },
      baseCardPaymentMethod: {
        type: "CARD",
        parameters: {
          allowedAuthMethods: this.options.allowedCardAuthMethods,
          allowedCardNetworks: this.options.allowedCardNetworks
        }
      },
      cardPaymentMethod: null,
      paymentDataRequest: {
        baseRequest: this.options.baseRequest,
        allowedPaymentMethods: null,
        transactionInfo: this.options.transactionInfo,
        merchantInfo: this.options.merchantInfo
      },
      paymentsClient: null
    };
  },
  async mounted() {
    const varsSet = await this.assignVars();
    if (varsSet) this.injectGooglePayScript();
  },
  methods: {
    async assignVars() {
      Object.assign(this, this.options);
      return await this.$nextTick();
    },
    injectGooglePayScript() {
      if (!this.paymentsClient) {
        // load google pay script
        const googlePayScript = document.createElement("script");
        googlePayScript.setAttribute(
          "src",
          "https://pay.google.com/gp/p/js/pay.js"
        );
        googlePayScript.setAttribute("async", true);
        googlePayScript.setAttribute("defer", true);
        googlePayScript.onload = () => this.onGooglePayLoaded();
        document.head.appendChild(googlePayScript);
      }
    },
    async initPaymentsVars() {
      this.cardPaymentMethod = Object.assign({}, this.baseCardPaymentMethod, {
        tokenizationSpecification: this.tokenizationSpecification
      });
      return await this.$nextTick();
    },
    getGoogleIsReadyToPayRequest() {
      return Object.assign({}, this.baseRequest, {
        allowedPaymentMethods: [this.baseCardPaymentMethod]
      });
    },
    getGooglePaymentsClient() {
      if (this.paymentsClient === null) {
        // eslint-disable-next-line
        this.paymentsClient = new google.payments.api.PaymentsClient({
          environment: this.environment
        });
      }
      return this.paymentsClient;
    },
    addGooglePayButton() {
      const button = this.paymentsClient.createButton({
        onClick: () => this.googlePayButtonClick(),
        buttonColor: this.buttonColor
      });
      document.getElementById(this.id).appendChild(button);
    },
    async onGooglePayLoaded() {
      const varsSet = await this.initPaymentsVars();
      if (varsSet) {
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient
          .isReadyToPay(this.getGoogleIsReadyToPayRequest())
          .then(response => {
            if (response.result) {
              this.addGooglePayButton();
              this.$nextTick(() => {
                this.$emit("onCanMakePayments", "GooglePay");
              });
            }
          })
          .catch(err => {
            // show error
            console.error(err);
          });
      }
    },
    getGooglePaymentDataRequest() {
      const paymentDataRequest = Object.assign({}, this.baseRequest);
      paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod];
      paymentDataRequest.transactionInfo = this.transactionInfo;
      paymentDataRequest.merchantInfo = {
        merchantId: this.merchantInfo.merchantId,
        merchantName: this.merchantInfo.merchantName
      };
      return paymentDataRequest;
    },
    googlePayButtonClick() {
      const paymentDataRequest = this.getGooglePaymentDataRequest();
      paymentDataRequest.transactionInfo = this.transactionInfo;
      const paymentsClient = this.getGooglePaymentsClient();
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(paymentData => {
          // handle the response
          this.$emit("payed", paymentData);
        })
        .catch(err => {
          // show error
          console.error(err);
          if (err.statusCode === "CANCELED") this.$emit("cancel");
        });
    }
  }
};
</script>

<style></style>
