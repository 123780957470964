export default function(cb) {
  if (document.readyState !== "complete") {
    window.addEventListener(
      "load",
      () => {
        cb();
      },
      { once: true }
    );
  } else {
    cb();
  }
}
