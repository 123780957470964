<template>
  <div
    v-click-outside="hideDropdown"
    class="language-switch"
    :class="{ 'language-switch_opened': showDropdown }"
    @mouseover.stop="openDropdown"
    @mouseleave.stop="hideDropdown"
  >
    <div class="language-switch__current">
      {{ value }}
    </div>
    <transition name="language-switch-slide">
      <div v-show="showDropdown" class="language-switch__dropdown">
        <ul class="language-switch__options">
          <li
            v-for="(option, i) in options"
            :key="i"
            class="language-switch__option"
          >
            <a :href="option.src">{{ option.text }}</a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "LanguageSwitch",
  directives: {
    ClickOutside
  },
  props: {
    value: {
      default: "",
      type: String
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDropdown: false
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    openDropdown() {
      this.showDropdown = true;
    },
    hideDropdown() {
      this.showDropdown = false;
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".language-switch";

#{$b} {
  color: $color-text-base;
  margin-left: auto;
  position: relative;
  z-index: 5;
  @include font(14px, 18px);

  @include md-desktop-only {
    @include font((14px * $zoom), (18px * $zoom));
  }

  @include tablet {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 6px;
    background: url("/local/templates/main/src/assets/img/select-arrow-black.svg")
      no-repeat center / cover;
    transition: transform ease 0.3s;

    @include md-desktop-only {
      top: 8px * $zoom;
      width: 10px * $zoom;
      height: 6px * $zoom;
    }
  }

  ._g-body-light & {
    &::after {
      background-image: url("/local/templates/main/src/assets/img/select-arrow-white.svg");
    }
  }

  .header_fixed & {
    &::after {
      background-image: url("/local/templates/main/src/assets/img/select-arrow-black.svg");
    }
  }

  &_opened {
    &::after {
      transform: rotateX(180deg);
    }
  }

  &-slide-enter-active {
    transition: all 0.3s ease;
  }

  &-slide-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  &-slide-enter,
  &-slide-leave-to {
    transform: translateY(4px);
    opacity: 0;
  }

  &__current {
    @include font(inherit, inherit);
    width: 38px;
    padding-bottom: 3px;
    padding-right: 15px;
    border-bottom: 1px solid rgba($white-true, 0);
    cursor: pointer;
    color: $color-bg-dark;

    &:hover {
      border-bottom-color: $color-bg-dark;
    }

    @include md-desktop-only {
      width: 38px * $zoom;
      padding-bottom: 3px * $zoom;
      padding-right: 15px * $zoom;
    }

    ._g-body-light & {
      color: $white-true;

      &:hover {
        border-bottom-color: $white-true;
      }
    }

    .header_fixed & {
      color: $color-bg-dark;

      &:hover {
        border-bottom-color: $color-bg-dark;
      }
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: -7px;
    width: calc(100% + 14px);
    padding-top: 4px;

    @include md-desktop-only {
      left: -7px * $zoom;
      width: calc(100% + #{14px * $zoom});
      padding-top: 4px * $zoom;
    }

    #{$b}_opened & {
      display: block;
    }
  }

  &__options {
    padding: 5px 0;
    background: $white-true;
    box-shadow: 0 2px 16px rgba($black-true, 0.1);
    border-radius: 8px;
    max-height: 120px;
    overflow: auto;

    @include md-desktop-only {
      padding: 5px * $zoom 0;
      box-shadow: 0 2px 16px * $zoom rgba($black-true, 0.1);
      border-radius: 8px * $zoom;
      max-height: 120px * $zoom;
    }
  }

  &__option {
    & > a {
      cursor: pointer;
      display: block;
      position: relative;
      padding: 3px 21px 3px 8px;
      color: #666;
      @include font(14px, 18px);

      &:hover {
        background: #f5f5f5;
      }

      @include md-desktop-only {
        padding: 3px * $zoom 21px * $zoom 3px * $zoom 8px * $zoom;
        @include font((14px * $zoom), (18px * $zoom));
      }
    }

    &_current {
      & > a {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 8px;
          width: 8px;
          height: 6px;
          background: url("/local/templates/main/src/assets/img/icon-mark.svg")
            no-repeat center / cover;

          @include md-desktop-only {
            right: 8px * $zoom;
            width: 8px * $zoom;
            height: 6px * $zoom;
          }
        }
      }
    }
  }
}
</style>
