<template>
  <div class="person-slider">
    <div class="person-slider__inner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <slot />
        </div>
      </div>
      <div class="person-slider__controls">
        <span class="person-slider__prev">
          <svg
            width="38"
            height="26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.411 25.609c.518.521 1.38.521 1.915 0a1.29 1.29 0 000-1.845l-9.71-9.449h32.043c.747-.001 1.341-.579 1.341-1.306a1.33 1.33 0 00-1.34-1.323H4.615l9.71-9.431a1.312 1.312 0 000-1.864c-.536-.521-1.398-.521-1.915 0L.401 12.077a1.26 1.26 0 000 1.845l12.01 11.687z"
            />
          </svg>
        </span>
        <span class="person-slider__next">
          <svg
            width="38"
            height="26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.589.391c-.518-.521-1.38-.521-1.915 0a1.29 1.29 0 000 1.845l9.71 9.449H1.341C.594 11.686 0 12.264 0 12.99a1.33 1.33 0 001.34 1.323h32.044l-9.71 9.431a1.312 1.312 0 000 1.864c.535.521 1.398.521 1.915 0l12.01-11.686a1.26 1.26 0 000-1.845L25.588.391z"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "PersonSlider",
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    const $slider = this.$el;
    const container = $slider.querySelector(".swiper-container");

    this.slider = new Swiper(container, {
      slidesPerView: "auto",
      spaceBetween: 20,
      autoplay: {
        delay: 5000
      },
      observer: true,
      observeParents: true,
      slideClass: "person-slider__slide",
      navigation: {
        prevEl: $slider.querySelector(".person-slider__prev"),
        nextEl: $slider.querySelector(".person-slider__next"),
        disabledClass: "_disabled"
      },
      breakpoints: {
        768: {
          spaceBetween: 28
        },
        1024: {
          spaceBetween: 30
        },
        1280: {
          spaceBetween: 24
        },
        1620: {
          spaceBetween: 30
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".person-slider";

#{$b} {
  &__inner {
    position: relative;

    #{$b}_indent-control & {
      padding-bottom: 70px;

      @include md-desktop-only {
        padding-bottom: 70px * $zoom;
      }
      @include tablet {
        padding-bottom: 0;
      }
    }
  }

  .swiper-container {
    .container & {
      @include tablet {
        margin-left: -$inner-pd-tab;
        margin-right: -$inner-pd-tab;
        padding-left: $inner-pd-tab;
        padding-right: $inner-pd-tab;
      }
      @include mobile {
        margin-left: -$inner-pd-mob;
        margin-right: -$inner-pd-mob;
        padding-left: $inner-pd-mob;
        padding-right: $inner-pd-mob;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -70px;
    right: 0;
    user-select: none;

    @include md-desktop-only {
      bottom: -70px * $zoom;
    }
    @include tablet {
      display: none;
    }
    #{$b}_indent-control & {
      bottom: 0;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    display: inline-block;
    width: 38px;
    height: 26px;
    outline: none;
    color: $black-true;

    &:hover {
      &:not(._disabled) {
        color: $color-base;
      }
    }

    &._disabled {
      cursor: default;
      opacity: 0.35;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__prev {
    margin-right: 18px;
  }
}
</style>
