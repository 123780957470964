<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="legal-menu"
    :class="{ 'is-menu-open': isOpen, 'is-initialized': isInit }"
  >
    <div class="legal-menu__toggle" @click.prevent="toggleDropdown">
      <span>{{ $t("toggle-title") }}</span>
      <svg viewBox="0 0 11.4 6.7">
        <path
          d="M5.7 6.7L.2 1.2C-.1.9-.1.5.2.2s.7-.3 1 0l4.5 4.5L10.2.2c.3-.3.7-.3 1 0s.3.7 0 1L5.7 6.7z"
        />
      </svg>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="isOpen || (!breakpoint.isMobile && !breakpoint.isTablet)"
        class="legal-menu__collapse"
      >
        <div v-for="(menu, key) in items" :key="key" class="legal-menu__group">
          <h5 class="legal-menu__group-title" v-html="menu.name"></h5>
          <ul class="legal-menu__list">
            <li v-for="(item, index) in menu.items" :key="index">
              <a
                :class="{ 'is-active': item.active }"
                :href="item.src"
                v-html="item.name"
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: "LegalMenu",
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      isInit: false,
      isOpen: false,
      breakpoint: this.getBreakpoint()
    };
  },
  mounted() {
    this.isInit = true;
    window.addEventListener("resize", this.updateBreakpoint);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768,
        isTablet: innerWidth < 1024
      };
    },
    updateBreakpoint() {
      const { isMobile, isTablet } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
      this.breakpoint.isTablet = isTablet;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".legal-menu";

#{$b} {
  &__toggle {
    display: none;

    @include tablet {
      cursor: pointer;
      user-select: none;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #313130;
      border-radius: 50px;
      padding: 0 20px;
      color: $color-bg-dark;
      font-weight: 400;
    }

    svg {
      width: 12px;
      height: 12px;
      transition: 0.2s ease transform;

      &:not(:first-child) {
        margin-left: 12px;
      }

      .is-menu-open & {
        transform: rotateX(180deg);
      }

      path {
        fill: currentColor;
      }
    }
  }

  &__collapse {
    &:not(:first-child) {
      @include tablet {
        margin-top: 20px;
      }
    }

    #{$b}:not(.is-initialized) & {
      display: none;
    }
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 30px;

      @include md-desktop-only {
        margin-bottom: 30px * $zoom;
      }

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      color: #131313;

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }

      @include tablet {
        font-size: 15px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        @include md-desktop-only {
          margin-bottom: 10px * $zoom;
        }
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: 6px;

        @include md-desktop-only {
          margin-bottom: 6px * $zoom;
        }
      }

      & > a {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        color: #454545;

        &.is-active {
          font-weight: bold;
        }

        @include md-desktop-only {
          font-size: 14px * $zoom2;
        }
      }
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "toggle-title": "List of documents"
  },
  "ru": {
    "toggle-title": "Список документов"
  }
}
</i18n>
