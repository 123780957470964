<template>
  <div class="conference-tabs">
    <div class="conference-tabs__head">
      <ul class="conference-tabs-list">
        <li
          v-for="(tab, i) in tabs"
          :id="tab.id"
          :key="i"
          class="conference-tabs-list__item"
          :class="{ 'conference-tabs-list__item_active': tab.isActive }"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="conference-tabs-list__item-name"
            @click.prevent="selectTab(i, true)"
            v-html="tab.name"
          />
          <transition
            name="accordion"
            @enter="accordionStart"
            @after-enter="accordionEnd"
            @before-leave="accordionStart"
            @after-leave="accordionEnd"
          >
            <div
              v-show="tab.isActive"
              class="conference-tabs-list__item-description"
              v-html="tab.description"
            />
          </transition>
        </li>
      </ul>

      <div class="conference-tabs-media">
        <template v-for="(tab, i) in videoTabs">
          <div v-if="tab.isActive" :key="i" class="conference-tabs-video">
            <video-player
              controls
              :poster="tab.poster"
              :src="tab.video"
              is-show-button
            />
          </div>
        </template>
      </div>
    </div>
    <div class="conference-tabs-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ConferenceTabs",
  data() {
    return {
      tabs: []
    };
  },
  computed: {
    videoTabs() {
      return this.tabs.filter(tab => tab.video);
    }
  },
  mounted() {
    this.tabs = this.$children.filter(
      tab => tab.$vnode.componentOptions.tag === "conference-tab"
    );

    this.checkHash();
    this.checkActiveTab();
  },
  methods: {
    selectTab(index, changeHash) {
      const hash = this.tabs[index].id;

      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[index].isActive = true;

      if (changeHash) {
        this.changeHash(hash);
      }

      this.$emit("change", index);
    },
    changeHash(hash) {
      if (!hash.length) return;

      if (history.replaceState) {
        history.replaceState(null, null, `#${hash}`);
      } else {
        location.hash = `#${hash}`;
      }
    },
    checkHash() {
      const hash = window.location.hash.substring(1);

      if (hash) {
        const foundIndex = this.tabs.findIndex(tab => {
          return tab.id === hash;
        });

        if (foundIndex >= 0) {
          this.selectTab(foundIndex, true);
          setTimeout(() => {
            this.$scrollTo("#" + hash, 300, { offset: -70 });
          }, 300);
        }
      }
    },
    checkActiveTab() {
      const hasActiveTab = this.tabs.some(tab => {
        return tab.isActive;
      });

      if (!hasActiveTab) {
        this.selectTab(0, false);
      }
    },
    accordionStart(el) {
      el.style.height = el.scrollHeight + "px";
    },
    accordionEnd(el) {
      el.style.height = "";
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".conference-tabs";

#{$b} {
  display: block;

  &__head {
    display: flex;
    flex-wrap: wrap;

    @include mobile {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 40px;

      @include md-desktop-only {
        margin-bottom: 40px * $zoom;
      }

      @include mobile {
        margin-bottom: 30px;
      }
    }
  }

  &-list {
    width: 44%;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-right: 30px;

    @include md-desktop-only {
      padding-right: 30px * $zoom;
    }

    @include mobile {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }

    &__item {
      max-width: 420px;
      display: block;

      @include md-desktop-only {
        max-width: 420px * $zoom;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @include md-desktop-only {
          margin-bottom: 20px * $zoom;
        }

        @include tablet {
          margin-bottom: 16px;
        }
      }

      &-name {
        display: inline-block;
        cursor: pointer;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $color-base;
        border-bottom: 1px dashed currentColor;
        transition: color 0.3s ease;

        @include md-desktop-only {
          font-size: 20px * $zoom;
        }

        @include low-desktop {
          font-size: 18px;
        }

        @include tablet {
          font-size: 16px;
        }

        &:hover {
          color: $color-bg-dark;
        }

        #{$b}-list__item_active & {
          font-weight: 600;
          color: $color-bg-dark;
          border-bottom-color: transparent;
          margin-bottom: 10px;

          @include md-desktop-only {
            margin-bottom: 10px * $zoom;
          }
        }
      }

      &-description {
        display: block;
        font-size: 16px;
        line-height: 1.5;
        padding-left: 20px;
        border-left: 2px solid $color-base;

        @include md-desktop-only {
          font-size: 16px * $zoom;
          padding-left: 20px * $zoom;
        }

        @include tablet {
          font-size: 14px;
        }
      }
    }
  }

  &-media {
    width: 56%;

    @include mobile {
      width: 100%;
    }
  }

  &-video {
    display: block;
    overflow: hidden;
    border-radius: 8px;

    &:not(:first-child) {
      margin-top: 24px;

      @include md-desktop-only {
        margin-top: 24px * $zoom;
      }

      @include mobile {
        margin-top: 20px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @include md-desktop-only {
        margin-bottom: 24px * $zoom;
      }

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.2s ease, opacity 0.2s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }
}
</style>
