<template>
  <div class="endless-carousel">
    <div class="endless-carousel__container swiper-container">
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>

    <div class="endless-carousel__nav">
      <div class="endless-carousel__count"></div>
      <div class="endless-carousel__arrows">
        <span class="endless-carousel__prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              fill="#131313"
              d="M6.206 12.805c.258.26.69.26.957 0a.645.645 0 000-.923L2.308 7.158H18.33c.373-.001.67-.29.67-.653a.665.665 0 00-.67-.662H2.308l4.855-4.716a.656.656 0 000-.931.684.684 0 00-.957 0L.2 6.039a.63.63 0 000 .922l6.005 5.844z"
            />
          </svg>
        </span>
        <span class="endless-carousel__next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="19"
            height="13"
            viewBox="0 0 19 13"
          >
            <path
              fill="#131313"
              d="M12.794.196a.684.684 0 00-.957 0 .645.645 0 000 .922l4.855 4.724H.67c-.373.001-.67.29-.67.653 0 .364.297.662.67.662h16.022l-4.855 4.716a.656.656 0 000 .932c.268.26.7.26.957 0L18.8 6.96a.63.63 0 000-.922L12.794.196z"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "EndlessCarousel",
  props: {
    infinite: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    const $slider = this.$el;
    const $container = $slider.querySelector(".endless-carousel__container");
    const $counter = $slider.querySelector(".endless-carousel__count");
    let $slides = $slider.querySelectorAll(".endless-carousel__slide");
    let $loop = this.infinite;
    let $index = null;

    this.slider = new Swiper($container, {
      loop: $loop,
      loopedSlides: $slides.length,
      slidesPerView: "auto",
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      slideClass: "endless-carousel__slide",
      navigation: {
        prevEl: $slider.querySelector(".endless-carousel__prev"),
        nextEl: $slider.querySelector(".endless-carousel__next"),
        disabledClass: "_disabled"
      },
      on: {
        init: function() {
          if ($loop) {
            $index = this.realIndex;
          } else {
            $index = this.activeIndex;
          }

          $counter.innerHTML =
            '<span class="endless-carousel__count__active">' +
            ($index + 1) +
            "</span>" +
            '<span class="endless-carousel__count__divider">/</span>' +
            '<span class="endless-carousel__count__total">' +
            $slides.length +
            "</span>";
        },
        slideChange: function() {
          if ($loop) {
            $index = this.realIndex;
          } else {
            $index = this.activeIndex;
          }

          $counter.innerHTML =
            '<span class="endless-carousel__count__active">' +
            ($index + 1) +
            "</span>" +
            '<span class="endless-carousel__count__divider">/</span>' +
            '<span class="endless-carousel__count__total">' +
            $slides.length +
            "</span>";
        }
      },
      breakpoints: {
        768: {
          spaceBetween: 30
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".endless-carousel";

#{$b} {
  &__container {
    width: 100%;
    max-width: 592px;
    overflow: visible;
    margin: 0 0 30px;

    @include md-desktop-only {
      max-width: 592px * $zoom;
      margin: 0 0 30px * $zoom;
    }

    @include tablet {
      max-width: 508px;
      margin: 0;
    }

    @include mobile {
      max-width: 240px;
    }
  }

  &__slide {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }

  &__item {
    display: block;
    text-decoration: none;

    &__thumb {
      width: 100%;
      display: block;
      background: $white-true;
      overflow: hidden;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &:before {
        content: "";
        display: block;
        padding-top: 69%;
      }

      @include md-desktop-only {
        border-radius: 8px * $zoom;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
  }

  &__count {
    @include font(16px, 1.5, 400);
    color: $color-bg-dark;

    @include md-desktop-only {
      font-size: 16px * $zoom;
    }

    @include tablet {
      display: none;
    }

    ._dark & {
      color: $white-true;
    }

    &__active {
      font-weight: 600;
    }

    &__divider {
      margin: 0 6px;

      @include md-desktop-only {
        margin: 0 6px * $zoom;
      }
    }
  }

  &__arrows {
    display: flex;
    align-items: center;

    @include tablet {
      display: none;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    width: 19px;
    height: 13px;
    outline: none;
    color: $color-bg-dark;

    @include md-desktop-only {
      width: 19px * $zoom;
      height: 13px * $zoom;
    }

    ._dark & {
      color: $white-true;
    }

    &:hover {
      &:not(._disabled) {
        color: $color-base;
      }
    }

    &._disabled {
      cursor: default;
      opacity: 0.3;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__prev {
    margin-right: 18px;

    @include md-desktop-only {
      margin-right: 18px * $zoom;
    }
  }
}
</style>
