<template>
  <li
    v-click-outside="closeDropdown"
    class="tabs-list__item tabs-list__item_mr20 tabs-list__item_dropdown"
    :class="{ 'tabs-list__item_active': activeTab === tabName, _open: isOpen }"
  >
    <span class="tabs-list__item_toggle" @click="isOpen = !isOpen">
      {{ tabLabel }}
    </span>
    <ul v-if="isOpen" class="tabs-dropdown">
      <li
        v-for="(item, i) in items"
        :key="i"
        :class="{ _current: item.name === activeTab }"
        @click="selectFilter(item.name, item.label)"
      >
        {{ item.label }}
      </li>
    </ul>
  </li>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "TabsFilterDropdown",
  directives: {
    ClickOutside
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: ""
    },
    parentLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tabName: "",
      tabLabel: "",
      isOpen: false
    };
  },
  mounted() {
    let activeTab = this.activeTab;
    let activeItem = this.$props.items.filter(function(item) {
      return item.name === activeTab;
    });
    if (activeItem.length) {
      this.tabLabel = this.parentLabel ? this.parentLabel : activeItem[0].label;
      this.tabName = activeTab;
    } else {
      this.tabLabel = this.parentLabel
        ? this.parentLabel
        : this.$props.items[0].label;
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    selectFilter(val, label) {
      this.tabName = val;
      this.tabLabel = this.parentLabel ? this.parentLabel : label;
      this.$emit("filterChange", val, label);
      this.isOpen = false;
    }
  }
};
</script>
