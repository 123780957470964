<template>
  <div
    class="form-input"
    :class="{
      'form-input_error': error,
      'form-input_filled': isFilled,
      'form-input_focused': isFocused,
      'form-input_tooltip': labelTooltip,
      'form-input_datepicker': type === 'date',
      'form-input_phone': type === 'tel',
      'form-input_search': isDadata,
      'form-input_textarea': component === 'textarea'
    }"
    @mouseleave="mouseleave"
    @mouseover="mouseover"
    @keydown.esc="close"
  >
    <label class="form-input__field" :for="id">
      <div class="form-input__label">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="label"></span>
        <tooltip
          v-if="labelTooltip"
          content-class="is-tooltip-small"
          placement="bottom-center"
          is-show-arrow
        >
          <div slot="trigger" class="tooltip-circle-icon">
            <svg xml:space="preserve" viewBox="0 0 51.4 72.3">
              <path
                d="M31.5 53.9H19c0-1.8-.1-2.9-.1-3.3 0-4 .7-7.3 2-10 1.3-2.6 4-5.5 8-8.8 4-3.2 6.4-5.4 7.2-6.4 1.2-1.6 1.8-3.3 1.8-5.3 0-2.7-1.1-5-3.2-6.9-2.1-1.9-5-2.8-8.6-2.8-3.5 0-6.4 1-8.7 3-2.3 2-4 5-4.8 9.1L0 21c.3-5.8 2.8-10.8 7.4-14.8C12 2 18.1 0 25.6 0c7.9 0 14.1 2.1 18.8 6.2 4.6 4.1 7 8.9 7 14.4 0 3-.9 5.9-2.6 8.6-1.7 2.7-5.4 6.4-11 11-2.9 2.4-4.7 4.4-5.4 5.8s-1 4.1-.9 7.9zM19 72.3V58.6h13.7v13.7H19z"
              />
            </svg>
          </div>
          <div slot="content" class="tooltip-content">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="labelTooltip"></p>
          </div>
        </tooltip>
      </div>
      <component
        :is="component"
        :id="id"
        ref="input"
        :class="{
          'form-input__input': type !== 'date' && component !== 'textarea',
          'form-input__textarea': component === 'textarea'
        }"
        :name="name"
        :type="inputType"
        :value="value"
        v-bind="currentProps"
        :placeholder="label"
        :required="required"
        :readonly="readonly"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
      />
      <div
        v-if="isPassword"
        class="form-input__eye"
        :class="{ 'is-active': isPasswordShow }"
        @click="togglePasswordInput"
      ></div>
    </label>
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="description" class="form-input__desc" v-html="description" />
    <div
      v-if="suggestions.length && suggestionsOpened"
      class="form-input__suggestions"
    >
      <div
        v-for="(suggestion, index) in suggestions"
        :key="index"
        class="form-input__suggestion"
        @click="setSuggestion(suggestion.value, index)"
      >
        <span v-html="highLight(suggestion.value, value)"></span>
      </div>
    </div>
    <div
      v-for="(message, i) in getMessages"
      :key="i"
      class="form-input-message"
      :class="{ 'is-error': message.type === 'error' }"
    >
      <span v-if="message.i18" v-html="$t(message.i18)" />
      <span v-if="message.text" v-html="message.text" />
      <span
        v-if="message.min"
        v-html="
          $t('error.min-length.left') +
            message.min +
            $t('error.min-length.right')
        "
      />
      <span
        v-if="message.max"
        v-html="
          $t('error.max-length.left') +
            message.max +
            $t('error.max-length.right')
        "
      />
    </div>
    <!--eslint-enable-->
  </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";

export default {
  name: "FormInput",
  components: {
    DatePicker
  },
  props: {
    id: { type: String, default: "" },
    tag: { type: String, default: "input" },
    type: { type: String, default: "text" },
    label: { type: String, default: "" },
    value: { type: [String, Date], default: "" },
    required: { type: Boolean, default: false },
    messages: { type: [Array, Object], default: () => [] },
    name: { type: String, default: "" },
    rows: { type: String, default: "1" },
    readonly: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    isCapitalize: { type: Boolean, default: false },
    description: { type: String, default: "" },
    isDadata: { type: Boolean, default: false },
    dadataToken: { type: String, default: "" },
    dadataType: { type: String, default: "" },
    dadataParts: { type: Array, default: () => [] },
    labelTooltip: { type: String, default: "" }
  },
  data() {
    return {
      url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/",
      isFocused: false,
      suggestions: [],
      suggestionsOpened: false,
      isMouseOver: false,
      inputType: this.type,
      isPassword: false,
      isPasswordShow: false
    };
  },
  computed: {
    component() {
      let result;

      switch (this.type) {
        case "tel":
          result = "international-phone-mask";
          break;

        case "date":
          result = "DatePicker";
          break;

        default:
          result = this.tag;
          break;
      }

      return result;
    },
    currentProps() {
      let result;

      if (this.type === "date") {
        result = Object.assign(
          {
            valueType: "date",
            lang: this.lang,
            appendToBody: false,
            disabledDate: this.disabledRange,
            format: "DD.MM.YYYY",
            inputClass: "form-input__input"
          },
          this.$attrs
        );
      } else if (this.component === "textarea") {
        result = Object.assign(
          {
            rows: this.rows
          },
          this.$attrs
        );
      } else {
        result = this.$attrs;
      }

      return result;
    },
    getMessages() {
      let result = this.messages.filter(item => item.state);

      return result;
    },
    isFilled() {
      const result = this.value ? this.value.toString().length > 0 : false;

      return result;
    },
    lang() {
      return document.querySelector("html").getAttribute("lang");
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (this.type === "date" || this.type === "tel" || !this.$refs.input)
        return;

      if (newValue && newValue !== oldValue) {
        if (this.isCapitalize) {
          const capitalized = newValue[0].toUpperCase() + newValue.slice(1);

          this.$refs.input.value = capitalized;
        } else {
          this.$refs.input.value = newValue;
        }
      } else {
        this.$refs.input.value = "";
      }
    }
  },
  created() {
    this.isPassword = this.type === "password";
  },
  methods: {
    disabledRange(date) {
      var today = new Date();

      return (
        date < new Date(1900, 0, 1) ||
        date > new Date(today.getFullYear(), today.getMonth(), today.getDate())
      );
    },
    onFocus(event) {
      this.isFocused = true;

      if (event && event.target) {
        this.$emit("focus", event.target.value);
      } else {
        this.$emit("focus", event);
      }
    },
    onBlur(event) {
      this.isFocused = false;

      if (event && event.target) {
        this.$emit("blur", event.target.value);
      } else {
        this.$emit("blur", event);
      }
    },
    onInput(event) {
      if (event && event.target) {
        this.$emit("input", event.target.value);
      } else {
        this.$emit("input", event);
      }

      if (this.isDadata) {
        this.inputDadata(event);
      }
    },
    // eslint-disable-next-line func-names
    inputDadata: debounce(function(event) {
      if (this.isDadata && typeof event !== "string") {
        if (event.target.value.length) {
          this.suggestionsOpened = true;
        }
        this.getDadata(event.target.value);
        this.$emit("input", event.target.value);
      }
    }, 300),
    onChange(event) {
      if (event && event.target) {
        this.$emit("change", event.target.value);
      } else {
        this.$emit("change", event);
      }
    },
    async getDadata(value) {
      await axios({
        url: `${this.url}${this.dadataType}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${this.dadataToken}`
        },
        data: {
          query: value,
          parts: this.dadataParts
        }
      })
        .then(({ data: { suggestions = [] } }) => {
          this.suggestions = suggestions;
        })
        .catch(event => {
          // eslint-disable-next-line no-console
          console.error(event);
        });
    },
    setSuggestion(value, index) {
      const sug = this.suggestions[index];
      const data = this.suggestions[index].data;
      this.$refs.input.value = sug.value;
      this.suggestions = [];
      this.$refs.input.focus();

      const payload = {
        Address: this.val,
        Zip: data.postal_code,
        Country: data.country,
        Region: data.region,
        District: data.city_district,
        City: data.city,
        Settlement: data.settlement,
        Street: data.street,
        Building1: data.house,
        Building2: data.block,
        Building3: "",
        AptOffice: data.flat,
        CodeKladr: data.kladr_id
      };

      this.suggestionsOpened = false;
      this.$emit("input", sug.value);
      this.$emit("changeDatata", value, payload);
    },
    highLight(words, query) {
      let sWords = this.$sanitize(words);
      const sQuery = this.$sanitize(query);

      if (sWords && sQuery) {
        var highlight = [sQuery.trim(), sQuery.toLowerCase().trim()];
        sWords = " " + sWords;
        return sWords.replace(
          new RegExp("(.)(" + highlight.join("|") + ")(.)", "ig"),
          '$1<span class="suggestion-highlight">$2</span>$3'
        );
      } else return sWords;
    },
    togglePasswordInput() {
      if (!this.isPassword) return;

      this.isPasswordShow = this.inputType !== "text";

      this.inputType = this.inputType === "text" ? "password" : "text";
    },
    close() {
      if (this.isDadata) {
        this.suggestionsOpened = false;
      }
    },
    mouseover() {
      this.isMouseOver = true;
    },
    mouseleave() {
      this.isMouseOver = false;
      setTimeout(() => {
        if (!this.isMouseOver) {
          if (this.isDadata) {
            this.suggestionsOpened = false;
          }
        }
      }, 500);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

// For datepicker
$default-color: $color-text-base;
$primary-color: $color-base;
$popup-z-index: 100;

@import "~vue2-datepicker/scss/index.scss";

$b: ".form-input";

#{$b} {
  position: relative;
  font-size: $font-size-base;
  line-height: 1.25;

  @include md-desktop-only {
    font-size: $font-size-base * $zoom;
  }

  &__field {
    display: block;
    width: 100%;
    position: relative;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: $color-placeholder;
    transition: 0.3s ease;
    transition-property: font-size, top, left, color;

    #{$b}_datepicker & {
      left: 30px;

      @include md-desktop-only {
        left: 30px * $zoom;
      }
    }

    #{$b}_textarea & {
      top: 20px;

      @include md-desktop-only {
        top: 20px * $zoom;
      }
    }

    #{$b}_filled &,
    #{$b}_focused & {
      top: 0;
      left: 0;
      font-size: 12px;
      z-index: 1;
    }

    #{$b}_tooltip & {
      display: flex;
      align-items: center;
    }

    #{$b}_tooltip:hover & {
      z-index: 1;
    }

    & > span {
      &:not(:first-child) {
        margin-left: 0.4em;
      }

      &:not(:last-child) {
        margin-right: 0.4em;
      }
    }

    .tooltip {
      line-height: 0;

      &-circle-icon {
        line-height: 1;
      }
    }
  }

  &__input {
    height: 40px;
    position: relative;
    display: block;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    border-radius: 0;
    font-size: inherit;
    line-height: inherit;
    border-bottom: 1px solid $color-placeholder;
    color: $color-bg-dark;

    @include md-desktop-only {
      height: 40px * $zoom;
    }

    @include placeholder {
      color: transparent;
      color: rgba(255, 255, 255, 0%);
    }

    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    #{$b}_datepicker & {
      padding-left: 30px;

      @include md-desktop-only {
        padding-left: 30px * $zoom;
      }
    }

    #{$b}_error & {
      border-color: $color-error !important;
    }

    #{$b}_dark & {
      color: $white-true;
    }

    #{$b}_variable & {
      padding-left: 24px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkuODg0OTkgMS4yOTE3NUwxLjQxMjExIDkuNzY0MTNMNC4yMzY0IDEyLjU4ODRMMTIuNzA4OCA0LjExNTU0TDkuODg0OTkgMS4yOTE3NVoiIGZpbGw9IiNDQ0NFRDEiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuNzA1OTQ5IDEwLjQ3TDMuNTMwMjQgMTMuMjk0M0wwIDE0LjAwMDJMMC43MDU5NDkgMTAuNDdaIiBmaWxsPSIjQ0NDRUQxIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC41OTkzIDAuNTc1NTI3QzExLjM3OTcgLTAuMTk0ODI2IDEyLjYzODMgLTAuMTkxODMxIDEzLjQxNDYgMC41ODQ1MTNDMTQuMTk0IDEuMzYzODUgMTQuMTk0IDIuNjI5NDcgMTMuNDE0NiAzLjQwODgxTDEwLjU5MDMgMC41ODQ1MTNMMTAuNTk5MyAwLjU3NTUyN1oiIGZpbGw9IiNDQ0NFRDEiLz48L3N2Zz4=");
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center left;

      @include md-desktop-only {
        padding-left: 24px * $zoom;
        background-size: 14px * $zoom;
      }
    }

    #{$b}_phone & {
      padding-right: 50px;
    }
  }

  &__textarea {
    display: block;
    min-height: 40px;
    max-width: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: inherit;
    line-height: 19px;
    padding: 0 0 10px;
    border-radius: 0;
    border-top: 10px solid transparent;
    border-bottom: 1px solid $color-placeholder;
    color: $color-bg-dark;
    outline: none;
    resize: vertical;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include md-desktop-only {
      @include zoom(min-height, 40px);
      @include zoom(padding, 0 0 10px);
      @include zoom(line-height, 19px);
      @include zoom(border-top-width, 10px);
    }

    @include mobile {
      min-height: 100px;
    }

    &::-webkit-scrollbar {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      display: none;
    }

    @include placeholder {
      color: transparent;
    }

    #{$b}_dark & {
      color: $white-true;
    }
  }

  &__eye {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    height: 26px;
    width: 26px;
    cursor: pointer;
    background: url("/local/templates/main/src/assets/img/lk/eye-close.svg")
      center / contain no-repeat;

    &.active {
      background-image: url("/local/templates/main/src/assets/img/lk/eye-open.svg");
    }
  }

  &__phone-wrapper {
    position: relative;
  }

  &__flag {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: scale(0.5) translateY(-50%) !important;
    margin: -14px;

    @include md-desktop-only {
      right: 10px * $zoom;
    }
  }

  &__desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.29;
    color: #292929;
    margin: 14px 0;

    @include md-desktop-only {
      font-size: 14px * $zoom;
      margin: 14px * $zoom 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-message {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 12px;

      @include md-desktop-only {
        margin-top: 12px * $zoom;
      }
    }
    &:not(:last-child) {
      margin-bottom: 12px;

      @include md-desktop-only {
        margin-bottom: 12px * $zoom;
      }
    }

    &:before {
      content: "";
      flex-shrink: 0;
      align-self: flex-start;
      display: inline-block;
      vertical-align: middle;
      width: 26px;
      height: 26px;
      background-size: 25px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 16px;

      @include md-desktop-only {
        width: 26px * $zoom;
        height: 26px * $zoom;
        background-size: 25px * $zoom;
        margin-right: 16px * $zoom;
      }
    }

    &.is-error {
      color: $color-error;

      &:before {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI2IDI2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGMzkzOTt9PC9zdHlsZT48Zz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTMsMjZDNS44LDI2LDAsMjAuMiwwLDEzQzAsNS44LDUuOCwwLDEzLDBjNy4yLDAsMTMsNS44LDEzLDEzQzI2LDIwLjIsMjAuMiwyNiwxMywyNnogTTEzLDEuNUM2LjcsMS41LDEuNSw2LjcsMS41LDEzYzAsNi4zLDUuMiwxMS41LDExLjUsMTEuNWM2LjMsMCwxMS41LTUuMiwxMS41LTExLjVDMjQuNSw2LjcsMTkuMywxLjUsMTMsMS41eiIvPjwvZz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTIsMTguM2MwLDAuNywwLjQsMS4zLDEuMywxLjNjMSwwLDEuNC0wLjYsMS40LTEuM1MxNC4zLDE3LDEzLjQsMTdDMTIuNCwxNywxMiwxNy41LDEyLDE4LjN6IE0xMi40LDE1LjFoMS45bDAuMS04LjRoLTJMMTIuNCwxNS4xeiIvPjwvc3ZnPg==");
      }
    }
  }

  &__suggestions {
    position: absolute;
    z-index: 10;
    top: calc(100% + 8px);
    left: -8px;
    width: calc(100% + 16px);
    background: $white-true;
    box-shadow: 0 2px 16px rgba($black-true, 0.1);
    border-radius: 8px;
    max-height: 220px;
    overflow: auto;

    &:not(:empty) {
      padding: 8px 0;
    }
    @include md-desktop-only {
      top: calc(100% + #{8px * $zoom});
      left: -8px * $zoom;
      width: calc(100% + #{16px * $zoom});
      box-shadow: 0 2px * $zoom 16px * $zoom rgba($black-true, 0.1);
      border-radius: 8px * $zoom;
      max-height: 220px * $zoom;

      &:not(:empty) {
        padding: 8px * $zoom 0;
      }
    }
  }
  &__suggestion {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 12px 24px 12px 28px;
    color: #666;
    @include font(14px, 1.29);
    transition: 0.3s ease;
    transition-property: background, color, border-color, opacity, box-shadow;

    @include md-desktop-only {
      padding: 12px * $zoom 24px * $zoom 12px * $zoom 28px * $zoom;
      font-size: 14px * $zoom;
    }

    &:hover {
      background: #f5f5f5;
    }

    .suggestion-highlight {
      background-color: #ffff00;
    }
  }

  .mx-datepicker {
    display: block;
    width: 100%;
  }

  .mx-datepicker-popup {
    left: 0 !important;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.15);

    @include md-desktop-only {
      border-radius: 4px * $zoom;
      box-shadow: 0 10px * $zoom 30px * $zoom -5px * $zoom rgba(0, 0, 0, 0.15);
    }
  }

  .mx-icon-calendar,
  .mx-icon-clear {
    right: auto;
    left: 0;
    color: $color-text-light-2;
  }
}
</style>

<i18n>
{
  "en": {
    "error": {
      "required": "Required field",
      "numeric": "Enter a numeric value",
      "alpha-num": "Only numeric and alphabetic values are allowed",
      "snils": {
        "numeric": "Enter a numeric value",
        "checkSum": "Not valid number",
        "length": "The field must contain 11 digits"
      },
      "phone": {
        "invalid": "Number format +(country code)(999)999-99-99"
      },
      "password": {
        "invalid": "Wrong password",
        "incorrect": "Make sure that the password is filled in correctly",
        "mismatch": "Password mismatch"
      },
      "email": {
        "invalid": "Invalid e-mail",
        "exist": "E-mail is already in use",
        "not-found": "User with this e-mail was not found"
      },
      "min-length": {
        "left": "The field must contain at least ",
        "right": " characters"
      },
      "max-length": {
        "left": "The field must contain a maximum of ",
        "right": " characters"
      }
    }
  },
  "ru": {
    "error": {
      "required": "Поле обязательно для заполнения",
      "numeric": "Введите числовое значение",
      "alpha-num": "Допускаются только цифровые и буквенные значения",
      "snils": {
        "numeric": "Введите числовое значение",
        "checkSum": "Неправильно введен СНИЛС",
        "length": "СНИЛС должен состоять из 11 цифр"
      },
      "phone": {
        "invalid": "Формат номера +(код страны)(999)999-99-99"
      },
      "password": {
        "invalid": "Неверный пароль",
        "incorrect": "Проверьте правильность заполнения пароля",
        "mismatch": "Пароли не совпадают"
      },
      "email": {
        "invalid": "Некорректный e-mail",
        "exist": "E-mail уже используется",
        "not-found": "Пользователь с таким e-mail не найден"
      },
      "min-length": {
        "left": "Поле должно содержать минимум ",
        "right": " символов"
      },
      "max-length": {
        "left": "Поле должно содержать максимум ",
        "right": " символов"
      }
    }
  }
}
</i18n>
