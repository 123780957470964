<template>
  <div class="teacher-slider">
    <div class="teacher-slider__inner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, key) in filteredItems"
            :key="key"
            :class="[
              'teacher-slider__slide',
              { '_g-visible-tablet': key >= itemsPerPage * page }
            ]"
          >
            <div
              class="person-card"
              :class="{ 'person-card_disable': item.DETAIL_TEXT.length == 0 }"
              @click="
                $modal.show('teachersModal_' + id);
                slide = key;
              "
            >
              <div
                class="person-card__photo"
                :style="`background-image:url(${item.PREVIEW_PICTURE.SRC});`"
              ></div>
              <div class="person-card__name" v-text="item.NAME"></div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="person-card__desc" v-html="item.PREVIEW_TEXT"></div>
            </div>
          </div>
        </div>
      </div>

      <arrow-more-link
        v-show="showMoreLink"
        :value="$t('show-more')"
        @load-more="showMore"
      ></arrow-more-link>
    </div>

    <leaf-modal :active-slide="slide" :popup="`teachersModal_${id}`">
      <div
        v-for="(item, key) in filteredItems"
        :key="key"
        class="swiper-slide leaf-modal-slider__slide"
      >
        <div class="leaf-modal__item">
          <div class="leaf-modal__item__media">
            <div
              class="leaf-modal__item__thumb _g-hidden-mobile"
              :style="`background-image:url(${item.PREVIEW_PICTURE.SRC});`"
            ></div>
            <div v-if="item.EMAIL || item.YOUTUBE">
              <div class="leaf-modal__title-line">
                {{ $t("contacts") }}
              </div>
              <div
                v-for="(el, i) in item.EMAIL"
                :key="i"
                class="leaf-modal__item__email"
              >
                <div class="leaf-modal__item__email__label">
                  {{ el.DESCRIPTION }}
                </div>
                <a
                  class="leaf-modal__item__email__link"
                  :href="`mailto:${el.VALUE}`"
                  >{{ el.VALUE }}</a
                >
              </div>
              <div class="leaf-modal__social">
                <ul class="leaf-modal__social-list">
                  <li v-if="item.YOUTUBE">
                    <a :href="`${item.YOUTUBE}`" target="_blank">
                      <svg
                        viewBox="-21 -117 682.667 682"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="leaf-modal__item__body">
            <div
              class="leaf-modal__item__thumb _g-visible-mobile"
              :style="`background-image:url(${item.PREVIEW_PICTURE.SRC});`"
            ></div>
            <div class="leaf-modal__item__head">
              <div class="leaf-modal__item__title" v-text="item.NAME"></div>
              <div class="leaf-modal__item__subtitle">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="item.PREVIEW_TEXT"></span>
              </div>
            </div>
            <collapse-text
              class="leaf-modal__item__text"
              :text="item.DETAIL_TEXT"
              :limit="200"
              more-class="leaf-modal__item__text__more"
              :more-text="$t('more-text')"
              :less-text="$t('less-text')"
              on-tablet
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="item.DETAIL_TEXT"></div>
            </collapse-text>
          </div>
          <div v-if="item.PRESSCENTER" class="leaf-modal__item__footer">
            <div>
              <div class="leaf-modal__title-line">
                {{ $t("mention") }}
              </div>
              <div class="leaf-modal__news">
                <div class="leaf-modal__news__title">
                  {{ $t("press-center") }}
                </div>
                <div class="leaf-modal__news-item">
                  <a
                    :href="`${item.PRESSCENTER.LINK}`"
                    class="leaf-modal__news-item__title"
                    >{{ item.PRESSCENTER.TITLE }}</a
                  >
                  <div class="leaf-modal__news-item__date">
                    {{ item.PRESSCENTER.DATE }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </leaf-modal>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "TeacherSlider",
  props: {
    items: {
      type: Array,
      default: () => {}
    },
    paginator: {
      type: Object,
      default: () => {}
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    itemsPerPage: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      id: null,
      slider: null,
      slide: 0,
      swiper: undefined,
      init: false,
      activeItems: [],
      page: 1
    };
  },
  computed: {
    filteredItems() {
      return this.activeItems;
    },
    contactsPresent(item) {
      return item.EMAIL || item.FACEBOOK || item.INSTAGRAM || item.YOUTUBE;
    },
    visibleItem(key) {
      return key < this.itemsPerPage * this.page;
    },
    showMoreLink() {
      return this.items.length > this.itemsPerPage * this.page;
    }
  },
  mounted() {
    this.id = this._uid;
    this.nextUrl = this.initNextUrl;
    this.activeItems = this.items;
    this.slider = this.$el.querySelector(".swiper-container");

    window.addEventListener("load", this.swiperMode);
    window.addEventListener("resize", this.swiperMode);
  },
  beforeDestroy() {
    window.removeEventListener("load", this.swiperMode);
    window.removeEventListener("resize", this.swiperMode);
  },
  methods: {
    showMore() {
      this.page += 1;
    },
    swiperInit() {
      this.swiper = new Swiper(this.slider, {
        slidesPerView: "auto",
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        slideClass: "teacher-slider__slide",
        breakpoints: {
          768: {
            slidesPerView: "auto",
            spaceBetween: 28
          },
          1025: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        },
        on: {
          beforeDestroy: function() {}
        }
      });
    },
    swiperDestroy() {
      this.swiper.destroy({
        deleteInstance: true,
        cleanStyles: true
      });
    },
    swiperMode() {
      let mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
      let tablet = window.matchMedia(
        "(min-width: 769px) and (max-width: 1023px)"
      );
      let desktop = window.matchMedia("(min-width: 1024px)");

      if (mobile.matches) {
        if (!this.init) {
          this.init = true;
          this.swiperInit();
        }
      } else if (tablet.matches) {
        if (!this.init) {
          this.init = true;
          this.swiperInit();
        }
      } else if (desktop.matches) {
        if (this.swiper != undefined) {
          this.swiperDestroy();
          this.$destroy();
        }
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "show-more": "Show more",
    "more-text": "Show more",
    "less-text": "Show less",
    "contacts": "Contacts",
    "mention": "Member mentions",
    "press-center": "Press center"
  },
  "ru": {
    "show-more": "Показать еще",
    "more-text": "Читать далее",
    "less-text": "Свернуть",
    "contacts": "Контакты",
    "mention": "Упоминания об участнике",
    "press-center": "Пресс-центр"
  }
}
</i18n>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".teacher-slider";

#{$b} {
  &__inner {
    .swiper-container {
      &:not(.swiper-container-initialized) {
        .swiper-wrapper {
          width: auto;
          flex-wrap: wrap;
          margin-left: -30px;
          box-sizing: border-box;

          @include md-desktop-only {
            margin-left: -30px * $zoom;
          }
        }
        #{$b}__slide {
          width: 25%;
          padding-left: 30px;
          margin-bottom: 44px;

          @include md-desktop-only {
            padding-left: 30px * $zoom;
            margin-bottom: 44px * $zoom;
          }
        }
      }
    }
  }

  &__slide {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s, visibility 1s;

    &._g-visible-tablet {
      @include tablet-min {
        display: block !important;
        opacity: 0;
        visibility: hidden;
        height: 0;
        margin: 0 !important;
      }
    }

    .person-card {
      cursor: pointer;
    }
  }

  .arrow-more-link {
    @include tablet {
      display: none !important;
    }
  }
}
</style>
