<template>
  <div class="event-card" :style="`background-image: url('${event.picture}')`">
    <a
      :href="event.url"
      :target="`${event.is_target_blank ? '_blank' : ''}`"
      :class="[
        event.speakers ? 'event-card__link-half' : 'event-card__link-full'
      ]"
    />
    <event-card-speakers v-if="event.speakers" :speakers="event.speakers" />

    <div class="event-card__content">
      <div v-if="isFutureEvent" class="event-card__labels-wrapper">
        <ul class="event-card__labels">
          <li v-if="event.format">
            <div class="event-card__label is-green">
              {{ event.format }}
            </div>
          </li>

          <li v-if="event.participation">
            <div class="event-card__label is-dark">
              {{ event.participation }}
            </div>
          </li>
        </ul>
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div class="event-card__title" v-html="$sanitize(event.title)" />

      <div class="event-card__info-row">
        <div
          v-if="
            isHasDate(event) || isHasVideo(event) || event.custom_time !== ''
          "
          class="event-card__info-col"
        >
          <div
            v-if="isHasDate(event)"
            class="event-card__label is-purple is-large-text"
          >
            <template v-if="isToday">
              {{ $t("today") }}
            </template>
            <template v-else-if="event.start_time.interval">
              {{ event.start_time.interval }}
            </template>
            <template v-else>
              {{ event.start_time.day }} {{ event.start_time.month }}
            </template>
          </div>

          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="event.custom_time !== ''"
            class="event-card__label is-purple is-large-text"
            v-html="event.custom_time"
          ></div>
        </div>
        <div
          v-if="isHasDate(event) || isHasPlace(event) || isHasVideo(event)"
          class="event-card__info-col"
        >
          <ul class="event-card__divider-list">
            <li
              v-if="isFutureEvent && isHasDate(event) && event.start_time.time"
            >
              <div class="event-card__time">
                {{ event.start_time.time }}
              </div>
            </li>
            <li v-if="isHasPlace(event)">
              <!-- eslint-disable vue/no-v-html -->
              <div class="event-card__place" v-html="event.place"></div>
            </li>
            <li v-if="isPastEvent && isHasVideo(event)">
              <div class="event-card__time">
                {{ event.video.video_length }}
              </div>
            </li>
            <li v-if="isPastEvent && isHasVideo(event)">
              <div class="event-card__play-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                >
                  <path
                    d="M12 7L-6.52533e-07 13.9282L-4.68497e-08 0.0717964L12 7Z"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isFutureEvent() {
      return this.event.status === "future";
    },
    isPastEvent() {
      return this.event.status === "past";
    },
    isToday() {
      if (!this.event.start_time) {
        return false;
      }

      const splitDate = this.event.start_time.full_date.split(".");
      const eventDay = new Date(
        `${splitDate[1]}.${splitDate[0]}.${splitDate[2]}`
      );
      const today = new Date();

      return (
        eventDay.getDate() == today.getDate() &&
        eventDay.getMonth() == today.getMonth() &&
        eventDay.getFullYear() == today.getFullYear()
      );
    }
  },
  methods: {
    isHasDate(event) {
      return Boolean(Object.keys(event?.start_time).length);
    },
    isHasVideo(event) {
      return Boolean(event?.video?.url || event?.video?.video_length);
    },
    isHasPlace(event) {
      return Boolean(event?.place);
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/base/_include.scss";

$b: ".event-card";

#{$b} {
  height: 100%;
  min-height: 480px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 48px 40px 30px;
  background-color: $color-text-light-4;
  color: $white-true;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include md-desktop-only {
    @include zoom(min-height, 480px);
    @include zoom(border-radius, 8px);
    @include zoom(padding, 48px 40px 30px);
  }

  @include low-desktop {
    min-height: 400px;
    padding: 32px;
  }

  @include tablet {
    min-height: 380px;
    padding: 32px 24px 24px;
  }

  &::before {
    content: "";
    @include full-abs();
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: -1;
    pointer-events: none;
  }

  &__content {
    margin-top: auto;
  }

  &__labels-wrapper {
    display: flow-root;

    &:not(:last-child) {
      margin-bottom: 20px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 20px);
      }

      @include low-desktop {
        margin-bottom: 16px;
      }
    }
  }

  &__labels {
    list-style: none;
    padding: 0;
    margin: 0 0 -8px -8px;
    display: flex;
    flex-wrap: wrap;

    @include md-desktop-only {
      @include zoom(margin, 0 0 -8px -8px);
    }

    & > li {
      padding: 0 0 8px 8px;

      @include md-desktop-only {
        @include zoom(padding, 0 0 8px 8px);
      }
    }
  }

  &__label {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.333;
    white-space: nowrap;

    @include md-desktop-only {
      @include zoom(height, 32px);
      @include zoom(padding, 0 16px);
      @include zoom(border-radius, 4px);
      @include zoom(font-size, 12px);
    }

    @include low-desktop {
      height: 24px;
      padding: 0 12px;
      font-size: 10px;
    }

    &.is-large-text {
      font-size: 16px;

      @include md-desktop-only {
        @include zoom(font-size, 16px);
      }

      @include low-desktop {
        font-size: 12px;
      }
    }

    &.is-green {
      background-color: $color-base;
      color: $white-true;
    }

    &.is-light {
      background-color: $white-true;
      color: $black-true;
    }

    &.is-dark {
      background-color: rgba(0, 0, 0, 0.6);
      color: $white-true;
    }

    &.is-purple {
      background-color: $color-purple-blue;
      color: $white-true;
    }
  }

  &__title {
    display: block;
    text-decoration: none;
    max-width: 420px;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.3;
    color: inherit;
    margin: 0;

    @include md-desktop-only {
      @include zoom(max-width, 420px);
      @include zoom(font-size, 26px);
    }

    @include low-desktop {
      max-width: 340px;
      font-size: 22px;
    }

    @include tablet {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 54px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 54px);
      }

      @include low-desktop {
        margin-bottom: 36px;
      }

      @include tablet {
        margin-bottom: 32px;
      }
    }
  }

  &__info-row {
    display: flex;
    align-items: center;

    @include tablet {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__info-col {
    &:not(:last-child) {
      margin-right: 24px;

      @include md-desktop-only {
        @include zoom(margin-right, 24px);
      }

      @include low-desktop {
        margin-right: 16px;
      }

      @include tablet {
        margin: 0 0 12px;
      }
    }

    &:nth-child(2) {
      min-width: 0;
    }
  }

  &__divider-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;

    @include md-desktop-only {
      @include zoom(font-size, 16px);
    }

    @include low-desktop {
      font-size: 14px;
    }

    & > li:last-child {
      min-width: 0;

      .event-card__place {
        word-break: break-all;
      }
    }

    & > li {
      position: relative;

      &:not(:last-child) {
        padding-right: 16px;
        margin-right: 16px;

        @include md-desktop-only {
          @include zoom(padding-right, 16px);
          @include zoom(margin-right, 16px);
        }

        @include low-desktop {
          padding-right: 12px;
          margin-right: 12px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          height: 12px;
          width: 1px;
          background-color: currentColor;

          @include md-desktop-only {
            @include zoom(height, 12px);
          }

          @include low-desktop {
            height: 10px;
          }
        }
      }
    }
  }

  &__play-icon {
    width: 12px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include md-desktop-only {
      @include zoom(width, 12px);
      @include zoom(height, 14px);
    }

    @include low-desktop {
      width: 10px;
      height: 12px;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__link {
    &-full,
    &-half {
      position: absolute;
      width: 100%;
      left: 0;
      border-radius: 8px;
    }

    &-full {
      height: 100%;
      top: 0;
    }

    &-half {
      height: 50%;
      bottom: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "today": "Today",
    "watch_recording": "Watch the recording"
  },
  "ru": {
    "today": "Сегодня",
    "watch_recording": "Смотреть в записи"
  }
}
</i18n>
