<template>
  <div>
    <div v-if="showSearchForm" class="edu-search">
      <div class="edu-search__input">
        <form
          class="search-field search-field_medium"
          autocomplete="off"
          @submit.prevent="search"
        >
          <div class="search-field__inner">
            <button class="search-field__button" type="submit">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <path
                  d="M17.78 16.722l-4.328-4.328A7.588 7.588 0 007.586 0a7.586 7.586 0 100 15.171 7.545 7.545 0 004.805-1.715l4.327 4.324a.75.75 0 101.062-1.058zm-10.194-3.06a6.084 6.084 0 01-6.08-6.076c0-3.35 2.726-6.08 6.08-6.08a6.09 6.09 0 016.08 6.08c0 3.35-2.73 6.076-6.08 6.076z"
                  fill="#C4C7CC"
                />
              </svg>
            </button>
            <div class="search-field__input">
              <input
                v-model.trim="searchTerm"
                :placeholder="placeholderValue"
                @keyup="throttledSubmit"
              />
            </div>
            <button
              v-if="searchTerm.length > 0"
              class="search-field__clear"
              type="submit"
              @click.prevent="clearField()"
            >
              <svg
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM1.35355 11.3536L6.35355 6.35355L5.64645 5.64645L0.646447 10.6464L1.35355 11.3536ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355ZM5.64645 6.35355L10.6464 11.3536L11.3536 10.6464L6.35355 5.64645L5.64645 6.35355ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
                  fill="#C4C4C4"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="enableControls" class="tabs tabs_light">
      <div
        class="tabs-container"
        :class="{ 'tabs-container_wrap': wrapSortingDropdown }"
      >
        <div class="tabs-overflow">
          <ul class="tabs-list">
            <li
              :class="{
                'tabs-list__item_active': !sectionIds.length
              }"
              class="tabs-list__item tabs-list__item_mr20"
              @click="switchSection(null)"
            >
              {{ $t("all") }}
            </li>
            <li
              v-for="(section, key) in sections"
              :key="key"
              :class="{
                'tabs-list__item_active': sectionIds.indexOf(section.ID) >= 0
              }"
              class="tabs-list__item tabs-list__item_mr20"
              @click="switchSection(section.ID), setAnchor(section.CODE)"
            >
              {{ section.NAME }}
            </li>
          </ul>
        </div>
        <sorting-dropdown
          v-model="sortBy"
          :options="sortFields"
          :is-upper-search="showSearchForm ? true : false"
        ></sorting-dropdown>
      </div>
    </div>
    <div class="edu-cards" :class="{ 'edu-cards_overflow': isOverflow }">
      <transition-group class="edu-cards__list" name="card-list" tag="div">
        <card-item v-for="item in activeItems" :key="item.ID" :item="item" />
      </transition-group>
      <div
        v-if="enableControls"
        class="root-news__show-more root-news__show-more_count"
      >
        <div class="root-news__show-more-counter">
          {{ $t("events") }} {{ paginatorText }}
        </div>
        <span v-show="nextUrl" @click="loadMore">
          {{ $t("show_more") }}
          <svg
            width="13"
            height="19"
            viewBox="0 0 13 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="root-news__show-more-arrow"
          >
            <path
              d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
              fill="#131313"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { throttle } from "lodash";

export default {
  name: "EduCardsList",
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => {}
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    isOverflow: { type: Boolean, default: false },
    showSearchForm: { type: Boolean, default: true },
    enableControls: { type: Boolean, default: true },
    wrapSortingDropdown: { type: Boolean, default: false }
  },
  data() {
    return {
      searchTerm: "",
      sections: [],
      sectionIds: [],
      sortFields: [
        { name: this.$i18n.t("sorting"), value: "" },
        { name: this.$i18n.t("sorting_future"), value: "asc" },
        { name: this.$i18n.t("sorting_past"), value: "desc" }
      ],
      sortBy: "",
      moreBtn: null,
      paginator: {},
      activeItems: [],
      nextUrl: ""
    };
  },
  computed: {
    paginatorText() {
      let shownCount = this.paginator.NavRecordCount;
      if (this.paginator.NavPageNomer !== this.paginator.NavPageCount) {
        shownCount = this.paginator.NavPageNomer * this.paginator.NavPageSize;
      }
      if (this.paginator.NavRecordCount === 0) {
        shownCount = 0;
      }
      return shownCount + "/" + this.paginator.NavRecordCount;
    },
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return this.$i18n.t("search");
      } else {
        return this.$i18n.t("search_events");
      }
    },
    throttledSubmit() {
      return throttle(this.search, 1000);
    }
  },
  watch: {
    searchTerm(value) {
      if (!value.trim()) {
        this.loadFirstPage();
      }
    },
    sortBy() {
      this.loadFirstPage();
    },
    sectionIds() {
      this.loadFirstPage();
    }
  },
  mounted() {
    this.sections = this.params.SECTIONS;
    this.activeItems = this.items;
    this.nextUrl = this.initNextUrl;
    this.paginator = this.initPaginator;
    this.setSectionIdByAnchor();
  },
  methods: {
    loadFirstPage() {
      const lines = [];
      if (this.sectionIds.length) {
        const line = this.sectionIds.map(id => {
          return `type[]=${id}`;
        });
        lines.push(line);
      }
      if (this.searchTerm) {
        lines.push("q=" + this.searchTerm);
      }
      const typesStr = lines.join("&");
      this.nextUrl = `?ajax=Y&PAGEN_${this.paginator.NavNum}=1&sort=${this.sortBy}&${typesStr}`;
      this.activeItems = [];
      this.loadMore();
    },
    async loadMore() {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      this.activeItems = [...this.activeItems, ...res.data.ITEMS];
    },
    setAnchor(code) {
      if (code) {
        window.location = `#${code}`;
      }
    },
    setSectionIdByAnchor() {
      const hash = window.location.hash.substring(1);

      if (hash) {
        const id = this.params.SECTIONS.find(item => item.CODE === hash)["ID"];

        if (id) {
          this.sectionIds.push(id);
        }
      }
    },
    switchSection(id) {
      if (id) {
        //window.location = `#${code}`;
        // Новая постановка задачи по реализации фильтра не требует множественной фильтрации.
        // Для реализации задачи указываем массив с единственным id.
        this.sectionIds = [id];
        // При этом оставляем возможность вернуть множественную фильтрацию.
        // const idx = this.sectionIds.indexOf(id);
        // if (idx >= 0) {
        //   this.sectionIds.splice(idx, 1);
        // } else {
        //   this.sectionIds.push(id);
        // }
      } else {
        this.sectionIds = [];
      }
    },
    clearField() {
      this.searchTerm = "";
    },
    search() {
      this.loadFirstPage();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "all": "All",
    "events": "Events",
    "show_more": "show more",
    "sorting": "Sort",
    "sorting_future": "Upcoming",
    "sorting_past": "Past",
    "search": "Search",
    "search_events": "Search by events"
  },
  "ru": {
    "all": "Все",
    "events": "Мероприятия",
    "show_more": "показать еще",
    "sorting": "Сортировать",
    "sorting_future": "Предстоящие",
    "sorting_past": "Прошедшие",
    "search": "Поиск",
    "search_events": "Поиск по мероприятиям"
  }
}
</i18n>
