<template>
  <a
    class="button button_base button_wide"
    :href="data.src"
    @click.prevent="log"
    >Скачать</a
  >
</template>

<script>
import axios from "axios";

export default {
  name: "JournalsUserLog",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    async log() {
      await axios
        .post(this.data.url, {
          id: this.data.id,
          type: this.data.type
        })
        .then((window.location.href = this.data.src));
    }
  }
};
</script>
