<template>
  <div>
    <notify-popup popup="suggestTerm">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__title">{{ $t("title") }}</div>
        <form class="form">
          <div class="form-row">
            <form-input
              id="new_term"
              v-model="form.term"
              tag="textarea"
              rows="4"
              :label="$t('term')"
              :error="$v.form.term.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state: $v.form.term.$error && !$v.form.term.required
                }
              ]"
            />
          </div>
          <div
            v-if="resend"
            class="notify-popup__text"
            style="max-width: 360px;"
          >
            <p>
              {{ $t("success.text") }}
            </p>
          </div>
          <div class="notify-popup__buttons">
            <button
              v-if="!resend"
              class="button button_base"
              @click.prevent="onSubmit"
            >
              {{ $t("success.send") }}
            </button>
            <button
              v-else
              class="button button_dark"
              @click.prevent="$modal.hide('suggestTerm')"
            >
              {{ $t("success.close") }}
            </button>
          </div>
        </form>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "SuggestTerm",
  props: {
    action: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        term: ""
      },
      resend: false
    };
  },
  validations: {
    form: {
      term: {
        required
      }
    }
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (this.validate()) {
        const res = await axios.post(this.url, { term: this.form.term });
        if (!res.data.success) {
          console.error(res.data.message);
        }
        this.form.term = "";
        this.$v.$reset();
        this.resend = true;
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "title": "Suggest term",
    "term": "Your term",
    "success": {
      "text": "Thank you! A letter with further instructions has been sent to your E-mail",
      "send": "Send",
      "close": "Close"
    }
  },
  "ru": {
    "title": "Предложить термин",
    "term": "Ваш термин",
    "success": {
      "text": "Спасибо! На ваш E-mail отправлено письмо с дальнейшими инструкциями",
      "send": "Отправить",
      "close": "Закрыть"
    }
  }
}
</i18n>
