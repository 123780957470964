<template>
  <div class="person-slider">
    <div class="person-slider__inner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, key) in activeItems"
            :key="key"
            class="person-slider__slide"
          >
            <div
              class="person-card person-card_clickable"
              :class="{ 'person-card_disable': !item.DETAIL_TEXT.length }"
              @click="
                $modal.show('teachersModal_' + id);
                slide = key;
              "
            >
              <div
                class="person-card__photo"
                :style="
                  `background-image:url('${getPersonPhotoUrl(
                    item.PREVIEW_PICTURE
                  )}');`
                "
              ></div>
              <div class="person-card__name" v-text="item.NAME"></div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="person-card__desc" v-html="item.PREVIEW_TEXT"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="person-slider__controls">
        <span class="person-slider__prev">
          <svg
            width="38"
            height="26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.411 25.609c.518.521 1.38.521 1.915 0a1.29 1.29 0 000-1.845l-9.71-9.449h32.043c.747-.001 1.341-.579 1.341-1.306a1.33 1.33 0 00-1.34-1.323H4.615l9.71-9.431a1.312 1.312 0 000-1.864c-.536-.521-1.398-.521-1.915 0L.401 12.077a1.26 1.26 0 000 1.845l12.01 11.687z"
            />
          </svg>
        </span>
        <span class="person-slider__next">
          <svg
            width="38"
            height="26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.589.391c-.518-.521-1.38-.521-1.915 0a1.29 1.29 0 000 1.845l9.71 9.449H1.341C.594 11.686 0 12.264 0 12.99a1.33 1.33 0 001.34 1.323h32.044l-9.71 9.431a1.312 1.312 0 000 1.864c.535.521 1.398.521 1.915 0l12.01-11.686a1.26 1.26 0 000-1.845L25.588.391z"
            />
          </svg>
        </span>
      </div>
    </div>

    <leaf-modal :active-slide="slide" :popup="`teachersModal_${id}`">
      <div
        v-for="(item, key) in activeItems"
        :key="key"
        class="swiper-slide leaf-modal-slider__slide"
      >
        <div class="leaf-modal__item">
          <div class="leaf-modal__item__media">
            <div
              class="leaf-modal__item__thumb _g-hidden-mobile"
              :style="
                `background-image:url('${getPersonPhotoUrl(
                  item.PREVIEW_PICTURE
                )}');`
              "
            ></div>
            <div v-if="item.EMAIL || item.YOUTUBE">
              <div class="leaf-modal__title-line">Контакты</div>
              <div
                v-for="(el, i) in item.EMAIL"
                :key="i"
                class="leaf-modal__item__email"
              >
                <div class="leaf-modal__item__email__label">
                  {{ el.DESCRIPTION }}
                </div>
                <a
                  class="leaf-modal__item__email__link"
                  :href="`${el.VALUE}`"
                  >{{ el.VALUE }}</a
                >
              </div>
              <div class="leaf-modal__social">
                <ul class="leaf-modal__social-list">
                  <li>
                    <a :href="`${item.YOUTUBE}`" target="_blank">
                      <svg
                        viewBox="-21 -117 682.667 682"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="leaf-modal__item__body">
            <div
              class="leaf-modal__item__thumb _g-visible-mobile"
              :style="
                `background-image:url('${getPersonPhotoUrl(
                  item.PREVIEW_PICTURE
                )}');`
              "
            ></div>
            <div class="leaf-modal__item__head">
              <div class="leaf-modal__item__title" v-text="item.NAME"></div>
              <div class="leaf-modal__item__subtitle">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="item.PREVIEW_TEXT"></span>
              </div>
            </div>
            <collapse-text
              class="leaf-modal__item__text"
              :text="item.DETAIL_TEXT"
              :limit="200"
              more-class="leaf-modal__item__text__more"
              :more-text="$t('learnMoreText')"
              :less-text="$t('turnText')"
              on-tablet
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="item.DETAIL_TEXT"></div>
            </collapse-text>
          </div>
          <div v-if="item.PRESSCENTER" class="leaf-modal__item__footer">
            <div>
              <div class="leaf-modal__title-line">
                упоминания об участнике
              </div>
              <div class="leaf-modal__news">
                <div class="leaf-modal__news__title">Пресс-центр</div>
                <div class="leaf-modal__news-item">
                  <a
                    :href="`${item.PRESSCENTER.LINK}`"
                    class="leaf-modal__news-item__title"
                    >{{ item.PRESSCENTER.TITLE }}</a
                  >
                  <div class="leaf-modal__news-item__date">
                    {{ item.PRESSCENTER.DATE }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </leaf-modal>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "PersonSliderClickable",
  props: {
    items: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      id: null,
      slider: null,
      slide: 0,
      swiper: undefined,
      activeItems: []
    };
  },
  mounted() {
    this.id = this._uid;
    this.activeItems = this.items;
    this.slider = this.$el.querySelector(".swiper-container");
    this.$root.$on("openPersonSlide", this.openPersonSlide);

    setTimeout(() => {
      this.swiperInit();
    });
  },
  methods: {
    swiperInit() {
      this.swiper = new Swiper(this.slider, {
        slidesPerView: "auto",
        spaceBetween: 20,
        autoplay: {
          delay: 5000
        },
        observer: true,
        observeSlideChildren: true,
        slideClass: "person-slider__slide",
        navigation: {
          prevEl: this.$el.querySelector(".person-slider__prev"),
          nextEl: this.$el.querySelector(".person-slider__next"),
          disabledClass: "_disabled"
        },
        breakpoints: {
          768: {
            spaceBetween: 28
          },
          1024: {
            spaceBetween: 30
          }
        }
      });
    },
    getPersonPhotoUrl(pictureInfo) {
      if (pictureInfo) {
        return pictureInfo.SRC;
      }
    },
    openPersonSlide(personId) {
      if (!this.activeItems.length) return;

      const foundIndex = this.activeItems.findIndex(person => {
        return person.ID == personId && person.DETAIL_TEXT.length;
      });

      if (foundIndex < 0) return;

      this.slide = foundIndex;
      this.$modal.show(`teachersModal_${this.id}`);
    }
  }
};
</script>

<i18n>
{
  "en": {
    "learnMoreText": "Learn more",
    "turnText": "Turn"
  },
  "ru": {
    "learnMoreText": "Читать далее",
    "turnText": "Свернуть"
  }
}
</i18n>
