<template>
  <div class="application-promocode">
    <div class="application-promocode__inner">
      <div class="application-promocode__title">{{ $t("title") }}</div>
      <form autocomplete="off" novalidate @submit.prevent="onSubmit">
        <div class="application-promocode__field">
          <form-input
            id="promoCode"
            v-model="form.promoCode"
            :label="$t('form.promoCode')"
            :readonly="promoCode.isApplied"
            :error="$v.form.promoCode.$error"
            :messages="[
              {
                type: 'error',
                text: $t('required'),
                state: $v.form.promoCode.$error && !$v.form.promoCode.required
              }
            ]"
          />
          <template v-if="!promoCode.isApplied && reCaptchaUse && reCaptchaKey">
            <InvisibleRecaptcha
              :sitekey="reCaptchaKey"
              :validate="reCaptchaLoaded"
              :callback="reCaptchaHandler"
              class="button button_dark"
              type="submit"
              :disabled="reCaptchaIsLoaded"
            >
              <span v-if="!promoCode.isApplied">{{ $t("form.submit") }}</span>
            </InvisibleRecaptcha>
          </template>
          <template v-else>
            <button class="button button_dark" type="submit">
              <span v-if="!promoCode.isApplied">{{ $t("form.submit") }}</span>
              <span v-else>{{ $t("form.reset") }}</span>
            </button>
          </template>
        </div>
      </form>
    </div>

    <notify-popup v-if="promoCode.isApplied" popup="successPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              stroke="#18B346"
              stroke-linecap="round"
              stroke-width="2"
              d="M14 17l2.625 3L21 14"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("success.title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("success.text") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('successPopup')"
          >
            {{ $t("success.close") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
  name: "ApplicationPromoCode",
  components: { InvisibleRecaptcha },
  props: {
    actionAddPromoCode: {
      type: String,
      default: ""
    },
    promoCode: {
      type: Object,
      default: () => {}
    },
    packageId: {
      type: String,
      default: ""
    },
    reCaptchaKey: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        promoCode: this.promoCode.value,
        packageId: this.packageId
      },
      reCaptchaToken: "",
      reCaptchaUse: false,
      reCaptchaIsLoaded: false
    };
  },
  validations: {
    form: {
      promoCode: {
        required
      }
    }
  },
  watch: {
    "promoCode.value"() {
      // Для реактивности примитивного значения
      this.form.promoCode = this.promoCode.value;

      // Сбрасываем vuelidate, если произошел сброс вне компонента
      if (!this.form.promoCode) {
        this.$v.$reset();
      }
    }
  },
  mounted() {
    if (localStorage.reCaptchaUse) {
      this.reCaptchaUse = localStorage.reCaptchaUse;
    }
    this.$root.$on("onResetApplicationPromoCode", this.reset);
  },
  methods: {
    reCaptchaHandler(token) {
      this.reCaptchaToken = token;
      this.onSubmit();
      this.reCaptchaLoaded();
    },
    reCaptchaLoaded() {
      this.reCaptchaIsLoaded = !this.reCaptchaIsLoaded;
    },
    isValidate() {
      this.$v.$touch();

      return !this.$v.$invalid;
    },
    async onSubmit() {
      if (this.isValidate()) {
        if (this.promoCode.isApplied) {
          this.reset();
        } else {
          const data = new FormData();
          for (let prop in this.form) {
            data.append(prop, this.form[prop]);
          }
          const options = {
            url: this.actionAddPromoCode,
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: data
          };

          const response = await axios(options);

          switch (response.data.status) {
            case "success":
              this.$root.$emit(
                "setApplicationPromoCode",
                response.data.data,
                this.form.promoCode
              );
              this.$nextTick(() => {
                this.$modal.show("successPopup");
              });
              break;
            case "error":
              this.onError(response.data.errors[0]);
              break;
          }

          // Если введено более 3 раз включаем капчу и запоминаем
          if (response.data.data && response.data.data.countError > 3) {
            this.reCaptchaUse = true;
            localStorage.reCaptchaUse = this.reCaptchaUse;
          }
        }
      }
    },
    reset() {
      this.$root.$emit("resetApplicationPromoCode");
    },
    onError(error) {
      this.$nextTick(() => {
        this.$emit("onErrorPromoCode", error);
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-promocode";

#{$b} {
  display: block;

  &__inner {
    display: flex;
    align-items: flex-start;

    @include low-desktop {
      align-items: flex-start;
    }

    @include mobile {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.42;
    color: $black-true;
    margin: 0;

    @include md-desktop-only {
      font-size: 24px * $zoom;
    }

    @include mobile {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @include md-desktop-only {
        margin-right: 30px * $zoom;
      }

      @include mobile {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  &__field {
    display: flex;
    align-items: flex-start;

    @include low-desktop {
      align-items: flex-start;
    }

    @include mobile {
      flex-direction: column;
      align-items: stretch;
    }

    .form-input {
      &:not(:last-child) {
        margin-right: 30px;

        @include md-desktop-only {
          margin-right: 30px * $zoom;
        }

        @include mobile {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }

      &__input {
        width: 200px;
        min-width: 100%;
        padding: 9px 0 8px;

        @include md-desktop-only {
          width: 200px * $zoom;
          padding: 9px * $zoom 0 8px * $zoom;
        }

        &:read-only {
          cursor: default;
          color: $color-base;
        }
      }
    }

    .button {
      min-width: 170px;

      @include md-desktop-only {
        min-width: 170px * $zoom;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Have a promo code?",
    "form": {
      "promoCode": "Enter promo code",
      "submit": "Apply",
      "reset": "Сбросить"
    },
    "required": "Enter promo code",
    "success": {
      "title": "Thank you",
      "text": "Promo code has been successfully applied",
      "close": "Close"
    }
  },
  "ru": {
    "title": "Есть промокод?",
    "form": {
      "promoCode": "Введите промокод",
      "submit": "Применить",
      "reset": "Сбросить"
    },
    "required": "Введите промокод",
    "success": {
      "title": "Спасибо",
      "text": "Промокод успешно применен",
      "close": "Закрыть"
    }
  }
}
</i18n>
