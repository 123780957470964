<template>
  <div v-show="!isDownloading" class="application-invoice">
    <notify-popup
      popup="viewInvoiceModal"
      :width="1240"
      is-hidden-close
      @onBeforeOpen="onBeforeOpen"
      @onOpened="onOpened"
      @onClose="onClose"
    >
      <div class="application-invoice__inner">
        <div class="application-invoice__head">
          <div class="application-invoice__head-left">
            <div class="application-invoice__title">
              {{ $t("modal_title") }}
            </div>
          </div>
          <div class="application-invoice__head-right">
            <button
              class="button button_base"
              type="button"
              @click.prevent="generateReport"
            >
              {{ $t("download_invoice") }}
            </button>
            <div
              class="application-invoice__close"
              @click="$modal.hide('viewInvoiceModal')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                <path d="M.017 15.99L15.997.01l.99.99-15.98 15.98z" />
                <path d="M.017 1l.99-.99 15.98 15.98-.99.99z" />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="paymentData" class="application-invoice__body">
          <div class="application-invoice__overflow">
            <div
              ref="document"
              class="application-invoice__content"
              :class="{ _printing: isPrinting }"
            >
              <div class="application-invoice__number-name">
                № {{ paymentData.number }}
              </div>

              <table>
                <tbody>
                  <tr>
                    <td width="606px" rowspan="2" colspan="2">
                      ПАО СБЕРБАНК г. Москва
                      <br /><small>Банк получателя</small>
                    </td>
                    <td width="140">БИК</td>
                    <td width="345" class="cell-noborder-bottom">
                      044525225
                    </td>
                  </tr>
                  <tr>
                    <td width="140">Сч. №</td>
                    <td width="345" class="cell-noborder-top">
                      30101810400000000225
                    </td>
                  </tr>
                  <tr>
                    <td width="303px">ИНН 7736128605</td>
                    <td width="303px">КПП 773601001</td>
                    <td width="140" rowspan="2">Сч. №</td>
                    <td width="345" rowspan="2">
                      40703810738120000517
                    </td>
                  </tr>
                  <tr>
                    <td width="606px" colspan="2">
                      АНО ДПО "Корпоративный университет Сбербанка"
                      <br /><small>Получатель</small>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="application-invoice__number-title">
                Счет на оплату No {{ paymentData.number }} от
                {{ paymentData.date }}
              </div>

              <table class="noborder">
                <tbody>
                  <tr>
                    <td width="105">
                      Поставщик
                      <br />(Исполнитель):
                    </td>
                    <td>
                      Автономная некоммерческая организация дополнительного
                      профессионального образования «Корпоративный университет
                      Сбербанка», ИНН 7736128605, КПП 773601001, 117997, г.
                      Москва, ул. Вавилова, д. 19
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="noborder">
                <tbody>
                  <tr>
                    <td width="105">
                      Покупатель
                      <br />(Заказчик):
                    </td>
                    <!-- eslint-disable vue/no-v-html -->
                    <td v-html="sanitize(paymentData.clientInfo)" />
                  </tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td width="40">№</td>
                    <td>Товары (работы, услуги)</td>
                    <td width="65">Кол-во</td>
                    <td width="55">Ед.</td>
                    <td width="100">Цена</td>
                    <td width="100">Сумма</td>
                  </tr>
                  <tr>
                    <td>
                      1
                    </td>
                    <!-- eslint-disable vue/no-v-html -->
                    <td v-html="sanitize(paymentData.products.name)" />
                    <td>
                      {{ paymentData.products.count }}
                    </td>
                    <td>
                      {{ paymentData.products.units }}
                    </td>
                    <td>
                      {{ paymentData.products.price }}
                    </td>
                    <td>
                      {{ paymentData.products.priceAll }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td class="cell-noborder" align="right">Итого:</td>
                    <td width="100" class="cell-noborder" align="right">
                      {{ paymentData.payment.price }}
                    </td>
                  </tr>
                  <tr>
                    <td class="cell-noborder" align="right">
                      В том числе НДС:
                    </td>
                    <td width="100" class="cell-noborder" align="right">
                      {{ priceVat }} {{ $t("currency") }}
                    </td>
                  </tr>
                  <tr>
                    <td class="cell-noborder" align="right">Всего к оплате:</td>
                    <td width="100" class="cell-noborder" align="right">
                      {{ paymentData.payment.priceAll }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                Всего наименований 1, на сумму
                {{ paymentData.payment.priceAll }}
              </p>
              <p>{{ paymentData.payment.priceString }}</p>

              <hr />

              <div class="application-invoice__employees">
                <div class="application-invoice__employee">
                  <div class="application-invoice__employee-info">
                    <span class="application-invoice__employee-status"
                      >Бухгалтер</span
                    >
                    <span class="application-invoice__employee-signature">
                      <span
                        class="application-invoice__employee-signature-line"
                      >
                        <div class="application-invoice__employee-confirm-doc">
                          Приказ №864-1П от 09.06.2020
                        </div>
                      </span>
                      <div class="application-invoice__employee-signature-img">
                        <img
                          src="/local/templates/main/src/assets/img/application/signature-vinokurova.png"
                          alt="Подпись"
                        />
                      </div>
                    </span>
                    <span class="application-invoice__employee-stamp">
                      <span class="application-invoice__employee-stamp-name">
                        Винокурова Ю. А.
                      </span>
                      <div class="application-invoice__employee-stamp-img">
                        <img
                          src="/local/templates/main/src/assets/img/application/stamp-vinokurova.png"
                          alt="Печать"
                        />
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <hr />

              <p>
                Оплачивая настоящий счет вы&nbsp;соглашаетесь с&nbsp;условиями
                оферты
                <a :href="offerLink" target="_blank">
                  {{ offerLink }}
                </a>
                <br />
                и&nbsp;Пользовательским соглашением сайта
                <a
                  href="/legal/obshchie-usloviy/polzovatelskoe-soglashenie-sayta/"
                  target="_blank"
                >
                  https://sberuniversity.ru/legal/obshchie-usloviy/polzovatelskoe-soglashenie-sayta/
                </a>
              </p>

              <p>
                Обратите внимание! Пожалуйста, при&nbsp;оплате указывайте
                назначение платежа.
              </p>
            </div>
          </div>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "ApplicationInvoice",
  props: {
    personType: { type: String, default: "" },
    elementCode: { type: String, default: "" },
    paymentData: { type: Object, default: () => {} },
    isShowFinalStep: { type: Boolean, default: false },
    offerLinkIndividual: { type: String, default: "" },
    offerLinkLegal: { type: String, default: "" }
  },
  data() {
    return {
      isPrinting: false,
      isDownloading: false,
      worker: null,
      htmlToPdfOptions: {
        filename: "Счёт на оплату.pdf",
        margin: [11.9, 16.94, 11.9, 5.3], // [top, left, bottom, right] in mm
        image: {
          type: "jpeg",
          quality: 1
        },
        enableLinks: true,
        html2canvas: {
          dpi: 150,
          scale: 2,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: {
          orientation: "portrait",
          unit: "mm",
          format: "a4",
          compressPDF: false
        }
      }
    };
  },
  computed: {
    priceVat() {
      const vatPercent = {
        events: 20,
        conferences: 20,
        courses: 20,
        programs: 0,
        default: 0
      };

      return this.elementCode
        ? this.getVatPrice(
            this.paymentData.payment.price,
            vatPercent[this.elementCode]
          )
        : vatPercent.default;
    },
    offerLink() {
      const start = "https://sberuniversity.ru";
      let link = this.paymentData.documentLink;

      if (this.personType === "entity" && this.offerLinkLegal !== "") {
        link = this.offerLinkLegal;
      }

      if (this.personType === "individual" && this.offerLinkIndividual !== "") {
        link = this.offerLinkIndividual;
      }

      return start + link;
    }
  },
  methods: {
    getVatPrice(price, vatPercent) {
      const cleanedPrice = Number(price.replace(/\.|([^\d.-])/g, "") / 100);
      if (cleanedPrice <= 1) {
        return 0;
      }

      const vatPrice = ((cleanedPrice / 120) * vatPercent).toFixed(2);
      return vatPrice
        .toString()
        .replace(/\s+/g, "")
        .replace(/[.]/g, ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    generateReport() {
      this.isPrinting = true;
      this.worker = html2pdf();
      this.worker
        .set(this.htmlToPdfOptions)
        .from(this.$refs.document)
        .toContainer()
        .toCanvas()
        .toImg()
        .toPdf()
        .save();

      this.worker.then(() => {
        this.isPrinting = false;

        if (this.isDownloading) {
          this.$modal.hide("viewInvoiceModal");
          this.isDownloading = false;
        }
      });
    },
    onBeforeOpen(event) {
      if (event && event.params && event.params.isDownloading) {
        this.isDownloading = true;
      }
    },
    onOpened() {
      if (this.isDownloading) {
        this.generateReport();
      }
    },
    onClose() {
      if (!this.isShowFinalStep) {
        this.$root.$emit("onCloseApplicationInvoice", this.elementCode);
      }
    },
    sanitize(html) {
      return this.$sanitize(html);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-invoice";

#{$b} {
  &__inner {
    padding: 40px 50px 50px;
    font-family: Arial, sans-serif;

    @include mobile {
      padding: 24px 20px 30px;
    }
  }

  &__content {
    min-width: 1000px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.43;

    &._printing {
      min-width: auto;
    }

    small {
      font-size: 12px;
      line-height: 1.25;
    }

    p {
      font-size: inherit;
      line-height: inherit;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    hr {
      border: 0;
      border-bottom: 1px solid $black-true;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      border: 1px solid $black-true;

      &:not(:first-child) {
        margin-top: 16px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      td,
      th {
        border: 1px solid $black-true;
        padding: 2px 3px;
        vertical-align: top;

        &.cell-noborder {
          border: 0;
        }

        &.cell-noborder-top {
          border-top: 0;
        }

        &.cell-noborder-bottom {
          border-bottom: 0;
        }
      }

      &.noborder {
        border: 0;

        td,
        th {
          border: 0;
          padding: 0;
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
    }

    &:not(:last-child) {
      margin-bottom: 50px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-left {
      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include mobile {
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
      }

      & > * {
        &:not(:last-child) {
          margin-right: 60px;

          @include mobile {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }

    .button {
      @include mobile {
        width: 100%;
      }
    }
  }

  &__overflow {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: $black-true;
    transition: color 0.2s ease;

    &:hover {
      color: $color-text-dark-2;
    }

    @include mobile {
      position: absolute;
      top: 32px;
      right: 20px;
      width: 24px;
      height: 24px;
    }

    svg {
      flex-shrink: 0;
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.3333;
    color: $black-true;
    margin: 0;

    @include mobile {
      font-size: 30px;
    }
  }

  &__number-name {
    width: 64%;
    display: block;
    background-color: #ffc107;
    font-size: 14px;
    line-height: 20px;
    color: $black-true;
    text-align: right;
    padding: 0 10px;
    margin-left: auto;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__number-title {
    display: block;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: $black-true;
    padding-bottom: 18px;
    border-bottom: 1px solid $black-true;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__employees {
    display: flex;
    justify-content: space-between;
  }

  &__employee {
    position: relative;
    z-index: 0;
    height: 170px;
    padding-right: 112px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    ._printing & {
      height: 140px;
      padding-right: 48px;
    }

    &-info {
      white-space: nowrap;

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    &-status {
      display: inline-block;
      padding-right: 4px;
    }

    &-signature {
      position: relative;
      z-index: 0;
    }

    &-signature-line {
      position: relative;
      display: inline-block;
      width: 210px;
      border-bottom: 1px solid $black-true;

      ._printing & {
        width: 120px;
      }
    }

    &-signature-img {
      width: 118px;
      height: 88px;
      position: absolute;
      bottom: -22px;
      left: 46px;

      ._printing & {
        width: 80px;
        height: 60px;
        bottom: -12px;
        left: 24px;
      }

      img {
        max-height: 100%;
      }
    }

    &-stamp-img {
      width: 177px;
      height: 168px;
      position: absolute;
      bottom: 16px;
      right: 0;
      z-index: -1;

      ._printing & {
        width: 140px;
        height: 135px;
        bottom: 13px;
      }

      img {
        max-height: 100%;
      }
    }

    &-confirm-doc {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      margin-top: 10px;
      font-size: 12px;

      ._printing & {
        font-size: 10px;
        margin-top: 8px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "modal_title": "Invoice",
    "download_invoice": "Download invoice",
    "currency": "rub."
  },
  "ru": {
    "modal_title": "Счет",
    "download_invoice": "Скачать счет",
    "currency": "руб."
  }
}
</i18n>
