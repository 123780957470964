<template>
  <div v-if="!isNoTab" v-show="isActive" ref="tab" class="user-tabs-panel">
    <slot v-if="!isMobile" />
  </div>
</template>

<script>
export default {
  name: "UserTab",
  props: {
    label: { type: String, default: "" },
    selected: { type: Boolean, default: false },
    isNoTab: { type: Boolean, default: false },
    tabUrl: { type: String, default: "" }
  },
  data() {
    return {
      isActive: false,
      isMobile: false
    };
  },
  mounted() {
    this.isActive = this.selected;

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1024;
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".user-tabs-panel";

#{$b} {
  padding-left: 100px;

  @include md-desktop-only {
    padding-left: 100px * $zoom;
  }

  @include low-desktop {
    padding-left: 50px;
  }

  @include tablet {
    padding-left: 0;
  }

  &_large {
    padding-left: 40px;

    @include md-desktop-only {
      padding-left: 40px * $zoom;
    }

    @include low-desktop {
      padding-left: 30px;
    }

    @include tablet {
      padding-left: 0;
    }
  }
}
</style>
