<template>
  <div>
    <div class="container">
      <div class="edu-search">
        <form class="search-field search-field_medium" @submit.prevent="search">
          <div class="search-field__inner">
            <button class="search-field__button" type="submit">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <path
                  d="M17.78 16.722l-4.328-4.328A7.588 7.588 0 007.586 0a7.586 7.586 0 100 15.171 7.545 7.545 0 004.805-1.715l4.327 4.324a.75.75 0 101.062-1.058zm-10.194-3.06a6.084 6.084 0 01-6.08-6.076c0-3.35 2.726-6.08 6.08-6.08a6.09 6.09 0 016.08 6.08c0 3.35-2.73 6.076-6.08 6.076z"
                  fill="#C4C7CC"
                />
              </svg>
            </button>
            <div class="search-field__input">
              <input
                v-model.trim="searchTerm"
                name="q"
                :placeholder="placeholderValue"
                @keyup="throttledSubmit"
              />
            </div>
            <button
              v-if="searchTerm.length > 0"
              class="search-field__clear"
              type="submit"
              @click.prevent="searchTerm = ''"
            >
              <svg
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM1.35355 11.3536L6.35355 6.35355L5.64645 5.64645L0.646447 10.6464L1.35355 11.3536ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355ZM5.64645 6.35355L10.6464 11.3536L11.3536 10.6464L6.35355 5.64645L5.64645 6.35355ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
                  fill="#C4C4C4"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
      <div class="tabs tabs_light">
        <div class="tabs-container">
          <ul class="tabs-list tabs-list_dropdowns">
            <custom-select
              v-if="params.DROPDOWNS.GROUP"
              v-model="group"
              :options="params.DROPDOWNS.GROUP.ITEMS"
              :placeholder="`${params.DROPDOWNS.GROUP.NAME}...`"
              prop-name-text="NAME"
              prop-name-value="ID"
            />
            <custom-select
              v-if="params.DROPDOWNS.TYPE"
              v-model="type"
              :options="params.DROPDOWNS.TYPE.ITEMS"
              :placeholder="`${params.DROPDOWNS.TYPE.NAME}...`"
              prop-name-text="NAME"
              prop-name-value="ID"
            />
            <custom-select
              v-if="params.DROPDOWNS.IS_RUSSIAN"
              v-model="isRussian"
              :options="params.DROPDOWNS.IS_RUSSIAN.ITEMS"
              :placeholder="`${params.DROPDOWNS.IS_RUSSIAN.NAME}...`"
              prop-name-text="NAME"
              prop-name-value="ID"
            />
            <custom-select
              v-if="params.DROPDOWNS.PAID"
              v-model="paid"
              :options="params.DROPDOWNS.PAID.ITEMS"
              :placeholder="`${params.DROPDOWNS.PAID.NAME}...`"
              prop-name-text="NAME"
              prop-name-value="ID"
            />
            <custom-select
              v-if="params.DROPDOWNS.SUBSCRIPTION"
              v-model="subscription"
              :options="params.DROPDOWNS.SUBSCRIPTION.ITEMS"
              :placeholder="`${params.DROPDOWNS.SUBSCRIPTION.NAME}...`"
              prop-name-text="NAME"
              prop-name-value="ID"
            />
          </ul>
        </div>
      </div>
    </div>
    <div class="_g-bg-gray">
      <div class="container">
        <div class="root-news__outer-bg">
          <transition-group
            class="root-news root-news_no-pd"
            name="card-list"
            tag="div"
          >
            <a
              v-for="item in activeItems"
              :key="item.ID"
              :href="item.DETAIL_PAGE_URL"
              class="root-news__item"
            >
              <div
                :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
                class="root-news__img"
              >
                <div class="root-news__img-content">
                  <p>
                    {{ item.PREVIEW_TEXT }}
                  </p>
                </div>
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="root-news__title h3" v-html="item.NAME"></div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="root-news__date para" v-html="item.GROUP"></div>
            </a>
          </transition-group>
          <div
            v-show="nextUrl"
            class="root-news__show-more root-news__show-more_bg _alt"
            @click="loadMore"
          >
            <span>
              показать еще
              <svg
                width="13"
                height="19"
                viewBox="0 0 13 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="root-news__show-more-arrow"
              >
                <path
                  d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
                  fill="#131313"
                ></path>
              </svg>
            </span>
          </div>
          <not-found v-show="!activeItems.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { throttle } from "lodash";

export default {
  name: "ToolsList",
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => {}
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    initNextUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      searchTerm: "",
      moreBtn: null,
      paginator: {},
      activeItems: [],
      nextUrl: "",
      group: "",
      type: "",
      paid: "",
      isRussian: "",
      subscription: ""
    };
  },
  computed: {
    paginatorText() {
      let shownCount = this.paginator.NavRecordCount;
      if (this.paginator.NavPageNomer !== this.paginator.NavPageCount) {
        shownCount = this.paginator.NavPageNomer * this.paginator.NavPageSize;
      }
      if (this.paginator.NavRecordCount === 0) {
        shownCount = 0;
      }
      return shownCount + "/" + this.paginator.NavRecordCount;
    },
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return "Поиск";
      } else {
        return "Поиск по программам и приложениям";
      }
    },
    throttledSubmit() {
      return throttle(this.search, 1000);
    }
  },
  watch: {
    searchTerm(value) {
      if (!value.trim()) {
        this.loadFirstPage();
      }
    },
    group() {
      this.loadFirstPage();
    },
    type() {
      this.loadFirstPage();
    },
    paid() {
      this.loadFirstPage();
    },
    isRussian() {
      this.loadFirstPage();
    },
    subscription() {
      this.loadFirstPage();
    }
  },
  mounted() {
    this.activeItems = this.items;
    this.paginator = this.initPaginator;
    this.nextUrl = this.initNextUrl;
  },
  methods: {
    loadFirstPage() {
      const lines = [];
      if (this.group) {
        lines.push(`group=${this.group}`);
      }
      if (this.type) {
        lines.push(`type=${this.type}`);
      }
      if (this.paid) {
        lines.push(`paid=${this.paid}`);
      }
      if (this.isRussian) {
        lines.push(`isRussian=${this.isRussian}`);
      }
      if (this.subscription) {
        lines.push(`subscription=${this.subscription}`);
      }
      if (this.searchTerm) {
        lines.push("q=" + this.searchTerm);
      }
      const typesStr = lines.join("&");
      this.nextUrl = `?ajax=Y&PAGEN_${this.paginator.NavNum}=1&sort=${this.sortBy}&${typesStr}`;
      this.activeItems = [];
      this.loadMore();
    },
    async loadMore() {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      this.activeItems = [...this.activeItems, ...res.data.ITEMS];
    },
    search() {
      this.loadFirstPage();
    }
  }
};
</script>
