<template>
  <div class="swiper-container root-grid">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "TourSlider",
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    this.slider = new Swiper(".root-grid", {
      slidesPerView: "auto",
      spaceBetween: 20,
      slideClass: "root-grid__item",
      breakpoints: {
        768: {
          //spaceBetween: 30
        }
      }
    });
  }
};
</script>
