export const useUrlParams = {
  data() {
    return {
      urlParams: null
    };
  },
  created() {
    this.urlParams = new URLSearchParams(window.location.search.substring(1));
  },
  methods: {
    hasUrlParam(param) {
      return this.urlParams.has(param);
    },
    setUrlParam(param, value) {
      this.urlParams.set(param, value);
      this.updateUrl();
    },
    getUrlParam(param) {
      return this.urlParams.get(param);
    },
    removeUrlParam(param) {
      this.urlParams.delete(param);
      this.updateUrl();
    },
    updateUrl(hash) {
      const searchString = decodeURIComponent(this.urlParams.toString());
      const searchParams = searchString ? `?${searchString}` : "";
      const hashString =
        hash === undefined
          ? location.hash
          : hash === ""
          ? hash
          : hash.split("#")[1]
          ? hash
          : `#${hash}`;
      const newUrl = `${location.origin}${location.pathname}${searchParams}${hashString}`;

      if (history.replaceState) {
        history.replaceState(null, null, newUrl);
      } else {
        // eslint-disable-next-line no-console
        console.warn("History API не поддерживается");
      }
    },
    updateHash(hash) {
      this.updateUrl(hash);
    }
  }
};
