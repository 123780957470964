<template>
  <div class="root-news root-news_alt-pd">
    <a
      v-for="(item, key) in items"
      :key="key"
      :href="item.DETAIL_PAGE_URL"
      class="root-news__item"
    >
      <div
        :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
        class="root-news__img"
      >
        <div class="root-news__img-content">
          <p>
            {{ item.PREVIEW_TEXT }}
          </p>
        </div>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="root-news__title h3" v-html="item.NAME"></div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="root-news__date para" v-html="item.GROUP"></div>
    </a>
  </div>
</template>

<script>
export default {
  name: "EdutechIndexTools",
  props: {
    items: { type: Array, default: () => [] }
  }
};
</script>
