<template>
  <div class="application-test">
    <template v-if="isShowIntro">
      <div class="application-test-intro">
        <div class="application-test-intro__row">
          <div class="application-test-intro__left">
            <div class="application-test-text">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <h3 v-html="test.title" />
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="test.description" />
            </div>
            <div class="application-test-intro__actions _g-hidden-tablet">
              <button
                v-if="digitalLeaderIdExist"
                class="button button_base"
                @click.prevent="checkAuth"
              >
                <template v-if="!state.isStarted">
                  {{ $t("button_start") }}
                </template>
                <template v-else>
                  {{ $t("button_continue") }}
                </template>
              </button>
              <a
                v-else
                class="button button_green-border-dark"
                :href="digitalOauthLink"
                target="_self"
              >
                {{ $t("button_leader") }}
              </a>
            </div>
          </div>
          <div class="application-test-intro__right">
            <ul class="application-test-features">
              <li>
                <div class="application-test-feature">
                  <div class="application-test-feature__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xml:space="preserve"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#9c9fa6"
                        fill-rule="evenodd"
                        d="M22.3,0.1C10,0.9,0,12.3,0,24.6L0,41c0,4.8,2.2,7,5,7c4.8,0,12.2,0,18.5,0C35.8,48,47,38,47.9,25.8	C49,11.2,36.8-1,22.3,0.1z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill="#fff"
                        d="M31.3,15.3c0.7-0.7,1.8-1,2.6-0.5c1.1,0.7,1.3,2.1,0.6,3L34.4,18L21.3,32.7c-0.8,0.8-2,0.9-2.9,0.2l-0.1-0.1	l-5.7-5.7c-0.7-0.7-0.9-1.8-0.3-2.7c0.7-1.1,2.1-1.2,3-0.4l0.1,0.1l4.2,4.2L31.3,15.3z"
                      />
                    </svg>
                  </div>
                  <div class="application-test-feature__content">
                    <div class="application-test-feature__title">
                      {{ test.count_questions }} вопросов
                    </div>
                    <div class="application-test-feature__desc">
                      одна попытка на прохождение теста
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="application-test-feature">
                  <div class="application-test-feature__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xml:space="preserve"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#9c9fa6"
                        fill-rule="evenodd"
                        d="M48,24c0,13.3-10.7,24-24,24S0,37.3,0,24S10.7,0,24,0S48,10.7,48,24z M21,14c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2	v10h8c1.1,0,2,0.9,2,2s-0.9,2-2,2H23h0c-0.1,0-0.1,0-0.2,0c-1-0.1-1.8-0.9-1.8-2l0,0V14z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="application-test-feature__content">
                    <div class="application-test-feature__title">
                      {{ test.time }} минут
                    </div>
                    <div class="application-test-feature__desc">
                      время на тест
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="application-test-intro__actions _g-visible-tablet">
              <button
                v-if="digitalLeaderIdExist"
                class="button button_base"
                @click.prevent="checkAuth"
              >
                <template v-if="!state.isStarted">
                  {{ $t("button_start") }}
                </template>
                <template v-else>
                  {{ $t("button_continue") }}
                </template>
              </button>
              <a
                v-else
                class="button button_green-border-dark"
                :href="digitalOauthLink"
                target="_self"
              >
                {{ $t("button_leader") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <template v-else-if="!state.isFinished">
      <div class="application-test-testing">
        <div class="application-test-question">
          <div
            class="application-test-question__question"
            v-html="currentQuestion.question"
          />
          <template v-if="isRadio(currentQuestion)">
            <div class="application-test-answers-wrapper">
              <ul class="application-test-answers">
                <li v-for="answer in currentQuestion.answers" :key="answer.id">
                  <div class="application-test-radio">
                    <input
                      :id="`radio_${answer.id}`"
                      v-model="state.form[currentQuestion.id]"
                      type="radio"
                      :name="`radio_${currentQuestion.id}`"
                      :value="answer.id"
                    />
                    <label :for="`radio_${answer.id}`">
                      {{ answer.text }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <template v-if="isCheckbox(currentQuestion)">
            <div class="application-test-text">
              <p>
                {{ $t("checkbox_desc") }}
              </p>
            </div>
            <div class="application-test-answers-wrapper">
              <ul class="application-test-answers">
                <li v-for="answer in currentQuestion.answers" :key="answer.id">
                  <div class="application-test-checkbox">
                    <input
                      :id="`checkbox_${answer.id}`"
                      v-model="state.form[currentQuestion.id]"
                      type="checkbox"
                      :name="`checkbox_${currentQuestion.id}`"
                      :value="answer.id"
                    />
                    <label :for="`checkbox_${answer.id}`">
                      {{ answer.text }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <template v-if="isText(currentQuestion)">
            <div class="application-test-text">
              <p>
                {{ $t("input_desc") }}
              </p>
            </div>
            <div class="application-test-answers-wrapper">
              <ul class="application-test-answers">
                <li>
                  <div class="application-test-input">
                    <label
                      v-if="currentQuestion.text"
                      :for="`input_${currentQuestion.id}`"
                    >
                      {{ currentQuestion.text }}
                    </label>
                    <input
                      :id="`input_${currentQuestion.id}`"
                      v-model="state.form[currentQuestion.id]"
                      type="text"
                      :name="`input_${currentQuestion.id}`"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <template v-if="isMatch(currentQuestion)">
            <div class="application-test-text">
              <p>
                {{ $t("match_desc") }}
              </p>
            </div>
            <div class="application-test-match">
              <div class="application-test-match__row-wrapper _g-hidden-mobile">
                <div class="application-test-match__row">
                  <div class="application-test-match__left">
                    <div class="application-test-match__title">
                      {{ $t("match_prompt_title") }}
                    </div>
                  </div>
                  <div class="application-test-match__right">
                    <div class="application-test-match__title">
                      {{ $t("match_answer_title") }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(prompt, i) in currentQuestion.matches.left"
                :key="`prompt_${i}`"
                class="application-test-match__row-wrapper application-test-match__row-wrapper_question"
              >
                <div class="application-test-match__row">
                  <div class="application-test-match__left">
                    <div class="application-test-match__prompt">
                      {{ prompt.value }}
                    </div>
                  </div>
                  <div class="application-test-match__right">
                    <div class="application-test-match__answer">
                      <custom-select
                        v-model="state.form[currentQuestion.id][prompt.key]"
                        :options="
                          getMatchOptions(
                            currentQuestion.id,
                            currentQuestion.matches.right
                          )
                        "
                        placeholder="Выберите"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="application-test-bottom">
          <div class="application-test-bottom__left">
            <ul class="application-test-info">
              <li>
                <div class="application-test-timer">
                  <span class="application-test-timer__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        fill="#9C9FA6"
                        fill-rule="evenodd"
                        d="M9.0013 0.666504C4.3988 0.666504 0.667969 4.39734 0.667969 8.99984C0.667969 13.6023 4.3988 17.3332 9.0013 17.3332C13.6038 17.3332 17.3346 13.6023 17.3346 8.99984C17.3346 4.39734 13.6038 0.666504 9.0013 0.666504ZM9.0013 2.33317C12.6771 2.33317 15.668 5.324 15.668 8.99984C15.668 12.6757 12.6771 15.6665 9.0013 15.6665C5.32547 15.6665 2.33464 12.6757 2.33464 8.99984C2.33464 5.324 5.32547 2.33317 9.0013 2.33317ZM7.7513 5.24984C7.7513 4.78984 8.12464 4.4165 8.58464 4.4165C9.04463 4.4165 9.41797 4.78984 9.41797 5.24984V8.9265L12.7396 10.7715C13.1413 10.9948 13.2871 11.5023 13.063 11.9048C12.8396 12.3065 12.3321 12.4523 11.9296 12.2282L8.17964 10.1448C7.91547 9.99817 7.7513 9.719 7.7513 9.4165V5.24984Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span class="application-test-timer__time">
                    {{ state.remainingTime.minutes }} :
                    {{ state.remainingTime.seconds }}
                  </span>
                </div>
              </li>
              <li>
                <div class="application-test-question-counter">
                  <span class="application-test-question-counter__title">
                    {{ $t("question_counter.title") }}
                  </span>
                  <span>
                    <strong>{{ state.currentStep }}</strong>
                    {{ $t("question_counter.divider") }}
                    {{ totalQuestions }}
                  </span>
                </div>
              </li>
              <li v-if="state.currentStep < totalQuestions">
                <div
                  class="application-test-early-finish"
                  @click.prevent="$modal.show('earlyFinishModal')"
                >
                  {{ $t("finish_btn") }}
                </div>
              </li>
            </ul>
          </div>
          <div class="application-test-bottom__right">
            <ul class="application-test-bottom__buttons">
              <li v-if="state.currentStep > 1" @click.prevent="prevStep">
                <button class="button button_icon button_gray">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xml:space="preserve"
                    width="13.4"
                    height="13.4"
                    viewBox="0 0 13.4 13.4"
                  >
                    <path
                      fill="#131313"
                      fill-rule="evenodd"
                      d="M7,12.2L2.4,7.4h10.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H2.4L7,1.2c0.3-0.3,0.3-0.7,0-1	c-0.3-0.3-0.7-0.3-1,0l-5.8,6c-0.3,0.3-0.3,0.7,0,1l5.8,6c0.3,0.3,0.7,0.3,1,0C7.3,12.9,7.3,12.4,7,12.2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </li>
              <li v-if="!isFinishStep">
                <button class="button button_base" @click.prevent="nextStep">
                  {{ $t("next_btn") }}
                </button>
              </li>
              <li v-if="isFinishStep">
                <button class="button button_base" @click.prevent="finishTest">
                  {{ $t("finish_btn") }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <notify-popup
        popup="earlyFinishModal"
        :width="560"
        class="notify-popup_early"
      >
        <div class="notify-popup__body _g-text-center">
          <div class="notify-popup__icon">
            <svg width="34" height="34" fill="none">
              <circle
                cx="17"
                cy="17"
                r="16"
                stroke="#FF3939"
                stroke-width="2"
              />
              <path
                fill="#FF3939"
                d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
              />
            </svg>
          </div>
          <div class="notify-popup__title notify-popup__title_medium">
            {{ $t("early_finish.title") }}
          </div>
          <div
            class="notify-popup__text notify-popup__text_small"
            style="max-width: 400px;"
          >
            <p v-html="$t('early_finish.text')" />
          </div>
          <div class="notify-popup__buttons">
            <button class="button button_dark" @click.prevent="finishTest">
              {{ $t("early_finish.yes") }}
            </button>
            <button
              class="button button_base"
              @click.prevent="$modal.hide('earlyFinishModal')"
            >
              {{ $t("early_finish.no") }}
            </button>
          </div>
        </div>
      </notify-popup>
    </template>
    <template v-if="state.isFinished">
      <div class="application-test-result">
        <div class="application-test-result__inner">
          <template v-if="testResults.isPassed">
            <div class="application-test-result__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xml:space="preserve"
                width="120"
                height="120"
                viewBox="0 0 120 120"
              >
                <circle cx="60" cy="60" r="60" fill="#fff" />
                <path
                  fill="#18b346"
                  fill-rule="evenodd"
                  d="M106.3,86.7c3.3,0,6-2.7,6-6V26.3c0-3.3-2.7-6-6-6H64c-3.3,0-6,2.7-6,6v54.4c0,3.3,2.7,6,6,6H106.3z M103.3,32.3H67.1v6h36.2V32.3z M67.1,47.4h36.2v6H67.1V47.4z M91.2,70.4l6.9-6.9c1.2-1.2,3-1.2,4.2,0c1.2,1.2,1.2,3,0,4.2l-9.1,9.1	c-0.6,0.6-1.5,0.9-2.1,0.9c-0.6,0-1.5-0.3-2.1-0.9l-6-6c-1.2-1.2-1.2-3,0-4.2c1.2-1.2,3-1.2,4.2,0L91.2,70.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="application-test-result__title">
              {{ $t("test_result.success.title") }}
            </div>
            <div class="application-test-result__text">
              <p class="application-test-text">
                {{ $t("test_result.success.text") }}
              </p>
            </div>
            <div class="application-test-result__buttons">
              <button
                class="button button_base"
                @click.prevent="completeTesting"
              >
                {{ $t("test_result.success.btn") }}
              </button>
            </div>
          </template>
          <template v-else>
            <div class="application-test-result__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xml:space="preserve"
                width="120"
                height="120"
                viewBox="0 0 120 120"
              >
                <circle cx="60" cy="60" r="60" fill="#fff" />
                <path
                  fill="#9c9fa6"
                  fill-rule="evenodd"
                  d="M106.4,33.4c-0.5-12-10.3-21.5-22.4-21.5c-12.4,0-22.4,10-22.4,22.4v9.2c-5.2,2.1-8.7,7.5-8.1,13.5l2.6,23.6	c0.7,6.6,6.3,11.6,13,11.6H99c6.7,0,12.2-5,13-11.6l2.6-23.6c0.6-6-2.9-11.4-8.1-13.5v-9.2L106.4,33.4z M98.4,42.5v-8.2	c0-7.9-6.4-14.4-14.4-14.4c-7.7,0-14,6-14.3,13.6l0,0.8v8.2H98.4z M84,60.9c2.1,0,3.8,1.5,4,3.5l0,0.5V71c0,2.2-1.8,4-4,4	c-2.1,0-3.8-1.5-4-3.5l0-0.5v-6.1C80,62.7,81.8,60.9,84,60.9z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="application-test-result__title">
              {{ $t("test_result.fail.title") }}
            </div>
            <div class="application-test-result__text">
              <p class="application-test-text">
                {{ $t("test_result.fail.text") }}
              </p>
            </div>
            <div class="application-test-result__buttons">
              <a href="/learning/" class="button button_base">
                {{ $t("test_result.fail.btn") }}
              </a>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import genFormData from "../utils/genFormData";

export default {
  name: "ApplicationTest",
  props: {
    elementId: { type: String, default: "" },
    test: { type: Object, default: () => {} },
    digitalExtendTime: { type: String, default: "" },
    actionExtendTime: { type: String, default: "" },
    actionCheckTestResults: { type: String, default: "" },
    authUrl: { type: String, default: "" },
    testResults: {
      type: Object,
      default: () => ({ correct: "", isFinished: false, isPassed: false })
    },
    authorized: { type: Boolean, default: false },
    digitalLeaderIdExist: { type: Boolean, default: false },
    digitalOauthLink: { type: String, default: "" }
  },
  data() {
    return {
      state: {
        isStarted: false,
        isPaused: false,
        isFinished: this.testResults.isFinished,
        currentStep: 1,
        remainingTime: {
          minutes: 0,
          seconds: 0
        },
        form: {}
      },
      isAuthorized: this.authorized,
      timerInterval: null,
      extendInterval: null
    };
  },
  computed: {
    currentQuestion() {
      const question = Object.values(this.test.questions)
        .sort((a, b) => a.sort - b.sort)
        .filter((item, index) => index + 1 === this.state.currentStep)[0];

      return this.test.questions[question.id];
    },
    totalQuestions() {
      return this.test.count_questions;
    },
    isFinishStep() {
      return this.state.currentStep === this.totalQuestions;
    },
    isShowIntro() {
      return (
        !this.state.isFinished &&
        (!this.state.isStarted || !this.isAuthorized || this.state.isPaused)
      );
    }
  },
  mounted() {
    this.state.form = this.getForm();
    this.checkSavedState();
    if (this.state.isStarted) {
      this.startTesting();
    }
  },
  methods: {
    checkSavedState() {
      if (this.testResults.correct) {
        localStorage.removeItem(`test_state_${this.elementId}`);
      } else {
        const savedState = JSON.parse(
          localStorage.getItem(`test_state_${this.elementId}`)
        );

        for (const prop in savedState) {
          if (Object.hasOwnProperty.call(this.state, prop)) {
            this.state[prop] = savedState[prop];
          }
        }
      }
    },
    saveState() {
      localStorage.setItem(
        `test_state_${this.elementId}`,
        JSON.stringify(this.state)
      );
    },
    getForm() {
      let form = {};

      Object.values(this.test.questions).forEach(question => {
        switch (question.type) {
          case "many":
            form[question.id] = [];
            break;

          case "match":
            form[question.id] = {};
            question.matches.left.forEach(item => {
              form[question.id][item.key] = "";
            });
            break;

          default:
            form[question.id] = "";
            break;
        }
      });

      return form;
    },
    isRadio(question) {
      return question.type === "one";
    },
    isCheckbox(question) {
      return question.type === "many";
    },
    isText(question) {
      return question.type === "text";
    },
    isMatch(question) {
      return question.type === "match";
    },
    getMatchOptions(questionId, answers) {
      return answers.map(answer => ({
        text: answer.value,
        value: answer.key,
        isPicked: Object.values(this.state.form[questionId]).includes(
          answer.key
        )
      }));
    },
    getRemainingTime() {
      const minutes = parseInt(this.state.remainingTime.minutes, 10);
      const seconds = parseInt(this.state.remainingTime.seconds, 10);
      return minutes && seconds ? minutes * 60 + seconds : this.test.time * 60;
    },
    startTesting() {
      if (!this.isAuthorized || this.state.isFinished) return;

      this.state.isPaused = false;

      if (this.state.isStarted) {
        this.startTimer(this.getRemainingTime());
      } else {
        this.state.isStarted = true;
        this.startTimer(this.test.time * 60);
        this.saveState();
      }

      this.watchExtendTime();
    },
    pauseTesting() {
      this.state.isPaused = true;
      this.stopTimer();
      this.saveState();
    },
    finishTest() {
      this.$modal.hide("earlyFinishModal");
      this.sendTestResult();
    },
    watchExtendTime() {
      if (this.extendInterval) return;
      const extendTime = parseInt(this.digitalExtendTime, 10) * 1000;
      // const extendTime = parseInt(5, 10) * 1000;

      this.extendInterval = setInterval(() => {
        this.checkAuth();
      }, extendTime);
    },
    startTimer(duration) {
      if (this.timerInterval) return;

      let timer = duration;
      let minutes;
      let seconds;

      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.state.remainingTime.minutes = minutes;
        this.state.remainingTime.seconds = seconds;

        if (--timer < 0) {
          timer = duration;
          this.finishTest();
          this.showTimeOverModal();
        }

        if (this.testResults.isFinished) return;
        this.saveState();
      }, 1000);
    },
    stopTimer() {
      if (!this.timerInterval) return;
      clearTimeout(this.timerInterval);
    },
    async sendTestResult() {
      const formData = new FormData();
      const data = {
        elementId: this.elementId,
        results: Object.entries(this.state.form).map(item => ({
          question_id: item[0],
          answers: item[1]
        }))
      };

      genFormData(formData, data, "");

      const options = {
        url: this.actionCheckTestResults,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: formData
      };

      const response = await axios(options);

      if (response.data.status === "success") {
        this.state.isFinished = true;
        this.stopTimer();
        this.saveState();
        this.$emit("onTestingFinish", {
          correct: response.data.data.correct,
          isPassed: response.data.data.is_passed
        });
      }
    },
    async checkAuth() {
      const options = {
        url: this.actionExtendTime,
        method: "GET"
      };

      const redirectUrl = new URL(
        `${window.location.protocol}//${window.location.host}${this.authUrl}`
      );

      await axios(options)
        .then(response => {
          if (response.data.status === "success" && response.data.data) {
            this.isAuthorized = response.data.data;
            this.startTesting();
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.isAuthorized = false;
            this.pauseTesting();

            if (this.timerInterval) {
              this.$modal.show("globalDynamicPopup", {
                icon: "error",
                title: this.$t("error.need_auth.title"),
                text: `<p>${
                  error.response.data.errors[0].message
                }</p><p><a href="${redirectUrl}">${this.$t(
                  "error.need_auth.text"
                )}</a></p>`
              });
            } else {
              window.location.href = redirectUrl;
            }
          }
        });
    },
    prevStep() {
      if (this.state.currentStep - 1 < 0) return;
      this.state.currentStep = this.state.currentStep - 1;
    },
    nextStep() {
      if (this.state.currentStep + 1 > this.totalQuestions) return;
      this.state.currentStep = this.state.currentStep + 1;
    },
    completeTesting() {
      this.$emit("onCompleteTesting", "packages");
    },
    showTimeOverModal() {
      this.$modal.show("globalDynamicPopup", {
        icon: "attention",
        title: this.$t("time_over.title"),
        text: this.$t("time_over.text")
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".application-test";

#{$b} {
  background-color: $white-true;
  padding: 44px 50px;
  border-radius: 5px;
  border: 1px solid $color-line;

  @include md-desktop-only {
    @include zoom(padding, 44px 50px);
    @include zoom(border-radius, 5px);
  }

  @include tablet {
    padding: 36px 30px;
  }

  @include mobile {
    padding: 32px 20px;
    margin: 0px -20px;
    border-radius: 0;
    border: 0;
  }

  &:not(:first-child) {
    margin-top: 96px;

    @include md-desktop-only {
      @include zoom(margin-top, 96px);
    }

    @include low-desktop {
      margin-top: 50px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 70px;

    @include md-desktop-only {
      @include zoom(margin-bottom, 70px);
    }

    @include low-desktop {
      margin-bottom: 50px;
    }
  }

  &-intro {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 0 -40px;

      @include md-desktop-only {
        @include zoom(margin, 0 0 0 -40px);
      }

      & > * {
        padding-left: 40px;

        @include md-desktop-only {
          @include zoom(padding-left, 40px);
        }
      }
    }

    &__left {
      width: 55%;

      @include tablet {
        width: 100%;
      }

      &:not(:last-child) {
        @include tablet {
          margin-bottom: 32px;
        }
      }
    }

    &__right {
      width: 45%;

      @include tablet {
        width: 100%;
      }
    }

    &__actions {
      &:not(:first-child) {
        margin-top: 28px;

        @include md-desktop-only {
          @include zoom(margin-top, 28px);
        }
      }

      .button {
        min-width: 180px;
        padding: 0 40px;

        @include md-desktop-only {
          @include zoom(min-width, 180px);
          @include zoom(padding, 0 40px);
        }

        @include tablet {
          width: 100%;
        }
      }
    }
  }

  &-features {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: 12px;

        @include md-desktop-only {
          @include zoom(margin-bottom, 12px);
        }
      }
    }
  }

  &-feature {
    display: flex;
    padding: 28px 32px;
    background: $color-bg-light;
    border-radius: 5px;

    @include md-desktop-only {
      @include zoom(padding, 28px 32px);
      @include zoom(border-radius, 5px);
    }

    @include mobile {
      padding: 15px 20px;
    }

    &__icon {
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 20px;

        @include md-desktop-only {
          @include zoom(margin-right, 20px);
        }

        @include mobile {
          margin-right: 16px;
        }
      }
    }

    &__content {
      flex: 1 1 auto;
    }

    &__title {
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.56;
      color: $color-bg-dark;
      margin: 0;

      @include md-desktop-only {
        @include zoom(font-size, 18px);
      }

      @include mobile {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    &__desc {
      font-weight: 400;
      line-height: 1.5;
      color: $color-text-light-2;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  &-text {
    font-weight: 400;
    line-height: 1.62;
    color: $color-text-light-2;

    h3,
    .h3 {
      max-width: 610px;
      display: block;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.33;
      color: $color-bg-dark;
      margin: 0;

      @include md-desktop-only {
        @include zoom(max-width, 610px);
        @include zoom(font-size, 24px);
      }

      @include mobile {
        font-size: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;

        @include md-desktop-only {
          @include zoom(margin-bottom, 12px);
        }
      }
    }

    p {
      font: inherit;
      line-height: inherit;
    }
  }

  &-buttons {
    .button {
      user-select: none;
      padding: 0 40px;

      @include md-desktop-only {
        @include zoom(padding, 0 40px);
      }
    }
  }

  &-testing {
    background: $color-bg-light;
    border-radius: 5px;
    padding: 40px 50px;

    @include md-desktop-only {
      @include zoom(border-radius, 5px);
      @include zoom(padding, 40px 50px);
    }

    @include tablet {
      padding: 40px 30px;
    }

    @include mobile {
      padding: 20px;
    }
  }

  &-question {
    &__question {
      display: block;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.44;
      margin: 0;

      @include md-desktop-only {
        @include zoom(font-size, 18px);
      }

      &:not(:last-child) {
        margin-bottom: 24px;

        @include md-desktop-only {
          @include zoom(margin-bottom, 24px);
        }
      }

      p {
        font: inherit;
        line-height: inherit;

        &:not(:last-child) {
          margin-bottom: 16px;

          @include md-desktop-only {
            @include zoom(margin-bottom, 16px);
          }
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        &:not(:first-child) {
          margin-top: 16px;

          @include md-desktop-only {
            @include zoom(margin-top, 16px);
          }
        }

        &:not(:last-child) {
          margin-bottom: 16px;

          @include md-desktop-only {
            @include zoom(margin-bottom, 16px);
          }
        }

        th {
          font-weight: 600;
        }

        th,
        td {
          border: 1px solid #9c9fa6;
          text-align: left;
          padding: 6px 10px;
          font-size: 16px;

          @include md-desktop-only {
            @include zoom(padding, 6px 10px);
            @include zoom(font-size, 16px);
          }
        }
      }

      ul,
      ol {
        padding-left: 30px;

        @include md-desktop-only {
          @include zoom(padding-left, 30px);
        }

        &:not(:last-child) {
          margin-bottom: 16px;

          @include md-desktop-only {
            @include zoom(margin-bottom, 16px);
          }
        }

        li {
          &:not(:last-child) {
            margin-bottom: 12px;

            @include md-desktop-only {
              @include zoom(margin-bottom, 12px);
            }
          }
        }
      }

      ul {
        list-style: disc;
      }
    }
  }

  &-answers-wrapper,
  &-match {
    &:not(:first-child) {
      margin-top: 24px;

      @include md-desktop-only {
        @include zoom(margin-top, 24px);
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 24px);
      }
    }
  }

  &-answers {
    list-style: none;
    padding: 0;
    margin: 0 0 -16px -16px;
    display: flex;
    flex-wrap: wrap;

    @include md-desktop-only {
      @include zoom(margin, 0 0 -16px -16px);
    }

    & > * {
      width: 50%;
      padding: 0 0 16px 16px;

      @include md-desktop-only {
        @include zoom(padding, 0 0 16px 16px);
      }

      @include tablet {
        width: 100%;
      }
    }
  }

  &-radio,
  &-checkbox {
    height: 100%;
    position: relative;
    z-index: 0;

    & > input {
      position: absolute;
      top: 12px;
      left: 16px;
      height: 28px;
      width: 28px;
      overflow: hidden;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: $color-bg-light;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border: 1px solid #d0d1d5;
      border-radius: 5px;
      transition: 0.3s ease-in-out;
      transition-property: background-color, border;

      @include md-desktop-only {
        @include zoom(top, 12px);
        @include zoom(left, 16px);
        @include zoom(height, 28px);
        @include zoom(width, 28px);
        @include zoom(border-radius, 5px);
      }

      @include tablet {
        top: 16px;
        height: 16px;
        width: 16px;
        border-radius: 2px;
      }

      &:checked {
        background-color: $color-base;
        border-color: $color-base;

        & + label {
          border-color: $color-base;
        }
      }

      &:not(:checked) {
        &:hover + label {
          background-color: $color-line;
        }
        & + label {
          &:hover {
            background-color: $color-line;
          }
        }
      }

      &:not([disabled]) {
        cursor: pointer;

        & + label {
          cursor: pointer;
        }
      }
    }

    & > label {
      height: 100%;
      min-height: 52px;
      display: block;
      background-color: $white-true;
      padding: 12px 16px 12px 54px;
      border: 1px solid transparent;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.62;
      transition: 0.3s ease;
      transition-property: background-color;

      @include md-desktop-only {
        @include zoom(min-height, 52px);
        @include zoom(padding, 12px 16px 12px 54px);
        @include zoom(border-radius, 8px);
        @include zoom(font-size, 16px);
      }

      @include tablet {
        min-height: 48px;
        padding: 12px 16px 12px 42px;
        line-height: 1.36;
      }
    }
  }

  &-radio {
    & > input {
      border-radius: 50%;
      background-size: 12px;

      @include md-desktop-only {
        @include zoom(background-size, 12px);
      }

      @include tablet {
        background-size: 6px;
      }

      &:checked {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Ccircle fill='%23fff' cx='8' cy='8' r='8' style=''/%3E%3C/svg%3E");
      }
    }
  }

  &-checkbox {
    & > input {
      background-size: 10px;

      @include md-desktop-only {
        @include zoom(background-size, 10px);
      }

      @include tablet {
        background-size: 8px;
      }

      &:checked {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M9.58836 0.305471C10.0946 0.749184 10.1402 1.51355 9.69021 2.01273L4.65884 7.59411C4.40634 7.87421 4.03641 8.02305 3.65727 7.99709C3.27812 7.97113 2.93261 7.77331 2.72179 7.46149L0.206103 3.74057C-0.169606 3.18486 -0.01732 2.43405 0.546245 2.06358C1.10981 1.6931 1.87124 1.84327 2.24695 2.39897L3.88124 4.81623L7.85697 0.405898C8.30696 -0.0932804 9.08213 -0.138243 9.58836 0.305471Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }

  &-input {
    display: block;

    input {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 52px;
      border: 1px solid $color-line;
      border-radius: 8px;
      background: $white-true;
      padding: 0 16px;
      transition: 0.3s ease;
      transition-property: border-color, background-color, color;

      @include md-desktop-only {
        @include zoom(height, 52px);
        @include zoom(border-radius, 8px);
        @include zoom(padding, 0 16px);
      }

      @include tablet {
        height: 48px;
      }

      &:focus {
        border-color: $color-placeholder;
      }
    }
  }

  &-match {
    display: block;

    &__title {
      display: block;
      font-weight: 600;
    }

    &__row-wrapper {
      &:not(:last-child) {
        margin-bottom: 24px;

        @include md-desktop-only {
          @include zoom(margin-bottom, 24px);
        }

        @include tablet {
          margin-bottom: 16px;
        }
      }

      &_question {
        &:not(:last-child) {
          padding-bottom: 24px;
          border-bottom: 2px dashed $color-line;

          @include md-desktop-only {
            @include zoom(padding-bottom, 24px);
            border-bottom-width: 1px;
          }

          @include tablet {
            padding-bottom: 16px;
            border-bottom-width: 1px;
          }
        }
      }
    }

    &__row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -24px;

      @include md-desktop-only {
        @include zoom(margin-left, -24px);
      }

      @include tablet {
        margin-left: -16px;
      }
    }

    &__left {
      @include mobile {
        margin-bottom: 12px;
      }
    }

    &__left,
    &__right {
      width: 50%;
      padding-left: 24px;

      @include md-desktop-only {
        @include zoom(padding-left, 24px);
      }

      @include tablet {
        padding-left: 16px;
      }

      @include mobile {
        width: 100%;
      }
    }

    &__prompt {
      height: 52px;
      display: flex;
      align-items: center;
      padding: 12px 16px;
      border: 0;
      background: $white-true;
      border-radius: 8px;
      color: #484848;
      font-weight: 500;

      @include md-desktop-only {
        @include zoom(height, 52px);
        @include zoom(padding, 12px 16px);
        @include zoom(border-radius, 8px);
      }
    }

    .custom-select {
      &_changed {
        background-color: $white-true !important;
      }
    }

    .tabs-list__item {
      height: 52px;
      border: 1px solid $color-line;
      margin: 0;
      padding-left: 16px;

      @include md-desktop-only {
        @include zoom(height, 52px);
        @include zoom(padding-left, 16px);
      }
    }

    .tabs-dropdown li {
      padding-left: 16px;

      @include md-desktop-only {
        @include zoom(padding-left, 16px);
      }
    }
  }

  &-bottom {
    display: flex;
    align-items: center;

    @include tablet {
      flex-direction: column;
    }

    &:not(:first-child) {
      margin-top: 40px;
      padding-top: 24px;
      border-top: 1px solid $color-line;

      @include md-desktop-only {
        @include zoom(margin-top, 40px);
        @include zoom(padding-top, 24px);
      }

      @include mobile {
        margin-top: 24px;
        padding-top: 20px;
      }
    }

    &__left {
      flex: 1 1 auto;

      @include tablet {
        width: 100%;
        margin-bottom: 24px;
      }
    }

    &__right {
      flex-shrink: 0;

      @include tablet {
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      & > * {
        &:not(:last-child) {
          margin-right: 12px;

          @include md-desktop-only {
            @include zoom(margin-right, 12px);
          }
        }

        &:first-child {
          .button {
            @include mobile {
              min-width: 90px;
            }
          }
        }

        &:last-child {
          @include mobile {
            flex: 1 1 auto;

            .button {
              width: 100%;
              display: flex;
            }
          }
        }
      }

      .button {
        padding: 0 40px;

        @include md-desktop-only {
          @include zoom(padding, 0 40px);
        }

        @include mobile {
          padding: 0 20px;
        }
      }
    }
  }

  &-info {
    display: flex;
    align-items: center;
    line-height: 1.25;

    @include mobile {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    & > * {
      position: relative;

      &:not(:last-child) {
        padding-right: 32px;
        margin-right: 32px;

        @include md-desktop-only {
          @include zoom(padding-right, 32px);
          @include zoom(margin-right, 32px);
        }

        @include tablet {
          padding-right: 24px;
          margin-right: 24px;
        }

        @include mobile {
          padding: 0;
          margin: 0;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 1px;
          height: 20px;
          background-color: $color-line;

          @include md-desktop-only {
            @include zoom(height, 20px);
          }

          @include mobile {
            display: none;
          }
        }
      }

      &:nth-child(3) {
        @include mobile {
          width: 100%;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }

  &-timer {
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      color: $color-placeholder;

      @include md-desktop-only {
        @include zoom(width, 16px);
        @include zoom(height, 16px);
      }

      &:not(:last-child) {
        margin-right: 10px;

        @include md-desktop-only {
          @include zoom(margin-right, 10px);
        }
      }

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: currentColor;
        }
      }
    }

    &__time {
      white-space: nowrap;
    }
  }

  &-question-counter {
    display: flex;
    align-items: center;

    &__title {
      color: $color-placeholder;

      &:not(:last-child) {
        margin-right: 8px;

        @include md-desktop-only {
          @include zoom(margin-right, 8px);
        }
      }
    }

    strong {
      font-weight: 600;
    }
  }

  &-early-finish {
    cursor: pointer;
    display: inline-block;
    text-decoration-line: underline;
    color: $color-placeholder;
    transition: color 0.3s ease;

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      border: 1px solid $color-line;
      border-radius: 4px;
      color: $color-bg-dark;
      text-decoration: none;
    }

    &:hover,
    &:focus {
      color: $color-bg-dark;
    }
  }

  &-result {
    background: $color-bg-light;
    border-radius: 5px;
    padding: 40px 50px;
    text-align: center;

    @include md-desktop-only {
      @include zoom(border-radius, 5px);
      @include zoom(padding, 40px 50px);
    }

    @include tablet {
      padding: 40px 30px;
    }

    @include mobile {
      padding: 20px;
    }

    &__inner {
      max-width: 466px;
      margin: 0 auto;

      @include md-desktop-only {
        @include zoom(max-width, 466px);
      }
    }

    &__icon {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 20px;

      @include md-desktop-only {
        @include zoom(width, 120px);
        @include zoom(height, 120px);
        @include zoom(margin, 0 auto 20px);
      }

      & > svg {
        height: 100%;
        width: 100%;
      }
    }

    &__title {
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.44;
      margin: 0;

      @include md-desktop-only {
        @include zoom(font-size, 18px);
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &__text {
      &:not(:last-child) {
        margin-bottom: 24px;

        @include md-desktop-only {
          @include zoom(margin-bottom, 24px);
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      .button {
        padding: 0 40px;

        @include md-desktop-only {
          @include zoom(padding, 0 40px);
        }

        @include mobile {
          display: flex;
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "button_start": "Start the test",
    "button_continue": "Continue the test",
    "button_leader": "Log in with Leader ID",
    "checkbox_desc": "Select several options",
    "input_desc": "Enter the answer in the field below",
    "match_desc": "Match the statement with the answer",
    "match_prompt_title": "Prompts",
    "match_answer_title": "Answers",
    "question_counter": {
      "title": "Question",
      "divider": "of"
    },
    "next_btn": "Next",
    "finish_btn": "Finish test",
    "early_finish": {
      "title": "Are you sure you want to finish the test?",
      "text": "If you skip the&nbsp;remaining questions, the&nbsp;test will be&nbsp;considered failed. <br/>Retake the&nbsp;test is&nbsp;not&nbsp;possible!",
      "yes": "Yes, complete the test",
      "no": "No, I changed my mind"
    },
    "test_result": {
      "success": {
        "title": "You have successfully passed the test!",
        "text": "You can proceed to filling out the application",
        "btn": "Go over"
      },
      "fail": {
        "title": "The test is not passed",
        "text": "To get a discount, you had to be tested for a positive result",
        "btn": "Return to the catalog"
      }
    },
    "time_over": {
      "title": "The time for the test has run out",
      "text": "Your results have been sent"
    },
    "error": {
      "need_auth": {
        "title": "Session expired",
        "text": "Log in"
      }
    }
  },
  "ru": {
    "button_start": "Начать тест",
    "button_continue": "Продолжить тест",
    "button_leader": "Войти с Leader ID",
    "checkbox_desc": "Выберите несколько вариантов",
    "input_desc": "Введите ответ в поле ниже",
    "match_desc": "Сопоставьте утверждение с ответом",
    "match_prompt_title": "Утверждения",
    "match_answer_title": "Ответы",
    "question_counter": {
      "title": "Вопрос",
      "divider": "из"
    },
    "next_btn": "Далее",
    "finish_btn": "Завершить тест",
    "early_finish": {
      "title": "Вы уверены, что хотите закончить тест?",
      "text": "Если пропустить оставшиеся вопросы, то&nbsp;тест будет считаться не&nbsp;пройденным. <br/>Пересдача теста невозможна!",
      "yes": "Да, завершить тест",
      "no": "Нет, я передумал(а)"
    },
    "test_result": {
      "success": {
        "title": "Вы успешно прошли тестирование!",
        "text": "Вы можете перейти к заполнению заявки",
        "btn": "Перейти"
      },
      "fail": {
        "title": "Тест не сдан",
        "text": "Для получения скидки, Вам необходимо было пройти тестирование на положительный результат",
        "btn": "Вернуться в каталог"
      }
    },
    "time_over": {
      "title": "Время на выполнение теста вышло",
      "text": "Ваши результаты отправлены"
    },
    "error": {
      "need_auth": {
        "title": "Сессия истекла",
        "text": "Войти"
      }
    }
  }
}
</i18n>
