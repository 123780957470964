<template>
  <form class="form" @change="onUpdateForm">
    <div class="form-body form-body_width-medium">
      <div class="form-row">
        <form-input
          id="phone"
          v-model="form.PERSONAL_MOBILE"
          :label="$t('phone')"
          type="tel"
          :error="$v.form.PERSONAL_MOBILE.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state:
                $v.form.PERSONAL_MOBILE.$error &&
                !$v.form.PERSONAL_MOBILE.required
            },
            {
              type: 'error',
              min: $v.form.PERSONAL_MOBILE.$params.minLength.min,
              state: !$v.form.PERSONAL_MOBILE.minLength
            }
          ]"
          @blur="$v.form.PERSONAL_MOBILE.$touch()"
        />
      </div>
      <div class="form-row">
        <form-input
          id="email"
          v-model="form.EMAIL"
          label="E-mail"
          type="email"
          readonly
        />
      </div>
      <div class="form-row">
        <form-input
          id="newEmail"
          v-model="form.NEW_EMAIL"
          :label="$t('new_email')"
          type="email"
          :error="$v.form.NEW_EMAIL.$error || emailExist"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.NEW_EMAIL.email
            },
            {
              type: 'error',
              i18: 'error.email.exist',
              state: emailExist
            }
          ]"
          @change="$v.form.NEW_EMAIL.$touch()"
          @input="clearEmailExist"
        />
      </div>
      <div class="form-row">
        <form-input
          v-if="!hideDadata"
          id="country"
          v-model="form.UF_COUNTRY"
          is-dadata
          dadata-type="country"
          :dadata-token="dadataApiKey"
          :label="$t('country')"
        />
        <form-input
          v-else
          id="countryText"
          v-model="form.UF_COUNTRY"
          :label="$t('country')"
        />
      </div>
      <div class="form-row">
        <form-input
          v-if="!hideDadata"
          id="city"
          v-model="form.PERSONAL_CITY"
          is-dadata
          dadata-type="address"
          :dadata-token="dadataApiKey"
          :label="$t('city')"
        />
        <form-input
          v-else
          id="cityText"
          v-model="form.PERSONAL_CITY"
          :label="$t('city')"
        />
      </div>
      <slot name="social"></slot>

      <button
        class="button button_base mt-15"
        type="submit"
        :disabled="isDisabled"
        @click.prevent="onSubmit"
      >
        {{ $t("save") }}
      </button>
    </div>
    <notify-popup popup="successEdit" :width="580">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__title">
          {{ $t("edit_success_title") }}
        </div>
        <div class="notify-popup__text">
          <p>
            {{ $t("edit_success_text") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('successEdit')"
          >
            {{ $t("edit_success_close") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </form>
</template>

<script>
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "UserEditFormContact",
  props: {
    arResult: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {
        return {
          LOGIN: "",
          UF_COUNTRY: "",
          PERSONAL_CITY: "",
          PERSONAL_MOBILE: ""
        };
      }
    },
    action: {
      type: String,
      default: ""
    },
    hideDadata: {
      type: Boolean,
      default: false
    },
    dadataApiKey: {
      type: String,
      default: ""
    },
    dadataCountryUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        EMAIL: this.arResult.LOGIN,
        NEW_EMAIL: "",
        UF_COUNTRY: this.arResult.UF_COUNTRY,
        PERSONAL_CITY: this.arResult.PERSONAL_CITY,
        PERSONAL_MOBILE: this.arResult.PERSONAL_MOBILE
      },
      isDisabled: true,
      emailExist: false
    };
  },
  validations: {
    form: {
      PERSONAL_MOBILE: {
        required,
        minLength: minLength(10)
      },
      NEW_EMAIL: {
        email
      }
    }
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    onUpdateForm() {
      this.isDisabled = false;
    },
    async onSubmit() {
      this.emailExist = false;
      if (this.validate()) {
        const data = new FormData();
        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "multipart/form-data" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            if (response.data.code > 0) {
              if (response.data.code === 200) {
                this.isDisabled = true;
                if (this.form.NEW_EMAIL.length > 0) {
                  this.form.EMAIL = this.form.NEW_EMAIL;
                  this.form.NEW_EMAIL = "";
                }
                this.$modal.show("successEdit");
              } else if (response.data.code === 300) {
                this.isDisabled = true;
                this.emailExist = true;
              }
            }
          }
        });
      }
    },
    clearEmailExist() {
      this.emailExist = false;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "phone": "Telephone number",
    "new_email": "New E-mail",
    "country": "Country",
    "city": "City",
    "required_field": "Required field",
    "save": "Save changes",
    "edit_success_title": "Changes saved",
    "edit_success_text": "Contact information successfully changed",
    "edit_success_close": "Close"
  },
  "ru": {
    "phone": "Телефон",
    "new_email": "Новый E-mail",
    "country": "Страна",
    "city": "Город или населенный пункт",
    "required_field": "Поле обязательно для заполнения",
    "save": "Сохранить изменения",
    "edit_success_title": "Изменения сохранены",
    "edit_success_text": "Контактная информация успешно изменена",
    "edit_success_close": "Закрыть"
  }
}
</i18n>
