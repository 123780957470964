<template>
  <div class="user-tabs">
    <aside class="user-tabs__sidebar">
      <nav class="user-tabs__nav">
        <ul class="user-tabs-list">
          <li
            v-for="(item, i) in tabs"
            :key="i"
            :class="{ 'user-tabs-list__title': item.isNoTab }"
          >
            <div
              v-scroll-to="{ el: `#${item.tabUrl}`, offset: -140 }"
              :class="{
                'user-tabs-list__item_active': item.isActive && !item.isNoTab,
                'user-tabs-list__item_large': item.isNoTab,
                [`user-tabs-list__item_mr${gutter}`]: gutter
              }"
              class="user-tabs-list__item"
              @click.prevent="selectTab(i)"
            >
              {{ item.label }}
            </div>

            <div
              v-if="item.$refs.tab && item.isMobile"
              v-show="item.isActive"
              :id="item.tabUrl"
              class="user-tabs-accordion"
            >
              <Render :vnode="item.$slots.default"></Render>
            </div>
          </li>
        </ul>
      </nav>
    </aside>
    <main class="user-tabs__main">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: "UserTabs",
  components: {
    Render: {
      functional: true,
      render: (h, ctx) => ctx.props.vnode
    }
  },
  props: {
    gutter: {
      type: Number,
      default: 0
    },
    pushUrl: {
      type: Boolean,
      default: false
    },
    baseUrl: {
      type: String,
      default: ""
    },
    currentUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tabs: []
    };
  },
  mounted() {
    this.tabs = this.$children.filter(comp => comp.$options.name === "UserTab");

    if (this.pushUrl === true && this.baseUrl.length > 0) {
      let currentIndex = 1;
      if (this.currentUrl.length > 0) {
        currentIndex = this.tabs.findIndex(object => {
          return object.tabUrl === this.currentUrl;
        });
      }
      this.selectTab(currentIndex);
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;

      if (this.pushUrl === true && this.baseUrl.length > 0) {
        this.pushTabUrl(this.tabs[selectedTab].tabUrl);
      }

      this.$emit("change", selectedTab);
    },
    pushTabUrl(dir) {
      if (history.pushState && dir.length > 0) {
        const url = `${window.location.protocol}//${window.location.host}${this.baseUrl}/`;
        const newUrl = `${url}${dir}/`;
        history.pushState(null, null, newUrl);
      } else {
        console.warn("History API не поддерживается");
      }
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".user-tabs";

#{$b} {
  display: flex;

  &__sidebar {
    width: 380px;
    padding-right: 20px;
    box-sizing: border-box;

    @include md-desktop-only {
      width: 380px * $zoom;
      padding-right: 20px * $zoom;
    }

    @include low-desktop {
      width: 270px;
    }

    @include tablet {
      width: 100%;
      padding: 0;
    }
  }

  &__main {
    width: calc(100% - 380px);
    border-left: 1px solid #ebebeb;
    box-sizing: border-box;

    @include md-desktop-only {
      width: calc(100% - #{380px * $zoom});
    }

    @include low-desktop {
      width: calc(100% - 270px);
    }

    @include tablet {
      display: none;
    }
  }

  &-list {
    list-style: none;
    padding-left: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: 25px;

        @include md-desktop-only {
          margin-bottom: 25px * $zoom;
        }

        @include mobile {
          margin-bottom: 20px;
        }
      }

      &#{$b}-list__title {
        pointer-events: none;

        &:not(:first-child) {
          margin-top: 70px;

          @include md-desktop-only {
            margin-top: 70px * $zoom;
          }

          @include mobile {
            margin-top: 50px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 32px;

          @include md-desktop-only {
            margin-bottom: 32px * $zoom;
          }
        }
      }
    }

    &__item {
      display: block;
      @include font(16px, 1.25, 400);
      color: $color-bg-dark;
      padding-left: 16px;

      @include md-desktop-only {
        font-size: 16px * $zoom;
        padding-left: 16px * $zoom;
      }
      &:not(&_large) {
        cursor: pointer;

        @include tablet {
          display: inline-block;
          text-align: left;
          padding: 12px 20px;
          border-radius: $border-radius * 2;
          background: $white-true;
          box-shadow: 0 2px 12px #eaecee;

          &#{$b}-list__item_active {
            color: #707070;
            font-weight: 400;
            background: $color-bg-alt;
          }
        }

        @include mobile {
          display: block;
        }
      }

      &_active {
        font-weight: 600;
      }

      &_large {
        @include font(18px, 1.33, 400);
        padding-left: 0;

        @include md-desktop-only {
          font-size: 18px * $zoom;
        }
      }
    }
  }

  &-accordion {
    padding: 36px 0;

    @include md-desktop-only {
      padding: 36px * $zoom 0;
    }

    @include tablet-min {
      display: none;
    }
  }

  .edu-cards__item {
    width: calc(99.99% / 2 - 32px);

    @include md-desktop-only {
      width: calc(99.99% / 2 - (#{32px * $zoom}));
    }

    @include mobile {
      width: 100%;
    }
  }
}
</style>
