<template>
  <div @click="log">
    <slot />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EventLog",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    async log() {
      await axios.post(this.url, {
        id: this.item.ID,
        dateTime: this.item.DATETIME
      });
    }
  }
};
</script>
