<template>
  <a class="file-link" :class="'file-link_type-' + extension" href="#">
    <svg class="file-link__icon" fill="none" viewBox="0 0 22 30">
      <template v-if="extension === 'pdf'">
        <path
          stroke="#B31818"
          stroke-width="1.4"
          d="M.7 1.875C.7 1.215 1.226.7 1.833.7h12.83l5.352.06a1.3 1.3 0 011.285 1.3v26.065c0 .66-.526 1.175-1.133 1.175H1.833C1.226 29.3.7 28.785.7 28.125V1.875zM4 17.3h14M4 21.3h9M14 13.3h4"
        />
        <path
          fill="#fff"
          stroke="#B31818"
          stroke-width="1.4"
          d="M4.7 6.7h6.6v6.6H4.7V6.7z"
        />
      </template>
      <template v-if="extension === 'rar'">
        <path
          stroke="#B37518"
          stroke-width="1.4"
          d="M.7 1.875C.7 1.215 1.226.7 1.833.7h12.83l5.352.06a1.3 1.3 0 011.285 1.3v26.065c0 .66-.526 1.175-1.133 1.175H1.833C1.226 29.3.7 28.785.7 28.125V1.875z"
        />
        <path
          fill="#B37518"
          d="M4.785 18v-1.652h.609L6.458 18h1.267l-1.197-1.778c.609-.266.805-.742.805-1.54 0-1.085-.49-1.617-2.03-1.617-.56 0-1.057.028-1.666.056V18h1.148zm-.028-2.555v-1.337c.182-.021.322-.028.525-.028.686 0 .868.196.868.665 0 .49-.196.7-.854.7h-.539zM12.338 18h1.176L11.8 13.1h-1.358L8.72 18H9.86l.336-1.029h1.792l.35 1.029zm-1.246-3.717l.581 1.743h-1.162l.581-1.743zM16.077 18v-1.652h.609L17.75 18h1.267l-1.197-1.778c.609-.266.805-.742.805-1.54 0-1.085-.49-1.617-2.03-1.617-.56 0-1.057.028-1.666.056V18h1.148zm-.028-2.555v-1.337c.182-.021.322-.028.525-.028.686 0 .868.196.868.665 0 .49-.196.7-.854.7h-.54z"
        />
      </template>
      <template v-if="extension === 'doc' || extension === 'docx'">
        <path
          stroke="#1869B3"
          stroke-width="1.4"
          d="M.7 1.875C.7 1.215 1.226.7 1.833.7h12.54L21.3 7.785v20.34c0 .66-.526 1.175-1.133 1.175H1.833C1.226 29.3.7 28.785.7 28.125V1.875z"
        />
        <path
          fill="#1869B3"
          fill-rule="evenodd"
          d="M14.187 1.643L12.966.415v5.684a2 2 0 002 2h5.625l-1.34-1.35h-4.065a1 1 0 01-1-1V1.642z"
          clip-rule="evenodd"
        />
        <path
          stroke="#1869B3"
          stroke-width="1.4"
          d="M4 13.3h14M4 17.3h14M4 21.3h9"
        />
      </template>
      <template v-if="extension === 'ref'">
        <path
          fill="#000000"
          d="M20.2 30H1.8c-1 0-1.8-.8-1.8-1.9V1.9C0 .8.8 0 1.8 0h12.8l5.4.1c1.1 0 2 .9 2 2v26.1c0 1-.8 1.8-1.8 1.8zM14.7 1.4H1.8c-.2 0-.4.2-.4.5v26.3c0 .3.2.5.4.5h18.3c.2 0 .4-.2.4-.5V2.1c0-.3-.3-.6-.6-.6l-5.2-.1z"
        />
        <path
          fill="#000000"
          d="M9.5 18.5l-1.9 1.9c-.8.8-2 .8-2.8 0-.8-.8-.8-2 0-2.8l3.7-3.7c.8-.8 2-.8 2.8 0 .3.3.7.3.9 0 .3-.3.3-.7 0-.9-1.3-1.3-3.4-1.3-4.6 0l-3.7 3.7c-1.3 1.3-1.3 3.4 0 4.6 1.3 1.3 3.4 1.3 4.6 0l1.9-1.9c.3-.3.3-.7 0-.9s-.7-.3-.9 0z"
        />
        <path
          fill="#000000"
          d="M17.7 7.4c-1.3-1.3-3.4-1.3-4.6 0l-2.2 2.2c-.3.3-.3.7 0 .9s.7.3.9 0L14 8.3c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-4.1 4.1c-.8.8-2 .8-2.8 0-.2-.2-.6-.2-.9 0-.3.3-.3.7 0 .9 1.3 1.3 3.4 1.3 4.6 0l4.1-4.1c1.3-1.2 1.3-3.3 0-4.6z"
        />
      </template>
    </svg>
    <span class="file-link__text">
      {{ text }}
      <span v-if="extension === 'ref'" class="file-link__arrow">
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7944 0.695511C12.5355 0.43483 12.1047 0.43483 11.8368 0.695511C11.578 0.947385 11.578 1.36659 11.8368 1.61788L16.6921 6.34243L0.67034 6.34243C0.296856 6.34301 0 6.63188 0 6.9953C0 7.35873 0.296856 7.65699 0.67034 7.65699L16.6921 7.65699L11.8368 12.3727C11.578 12.6334 11.578 13.0532 11.8368 13.3045C12.1047 13.5652 12.5361 13.5652 12.7944 13.3045L18.7991 7.46148C19.067 7.2096 19.067 6.7904 18.7991 6.53911L12.7944 0.695511Z"
            fill="#000000"
          />
        </svg>
      </span>
      <span v-else class="file-link__extension">({{ extension }})</span>
    </span>
  </a>
</template>

<script>
export default {
  name: "FileLink",
  props: {
    text: {
      type: String,
      default: ""
    },
    extension: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";
$b: ".file-link";

#{$b} {
  display: block;
  position: relative;
  padding: 4px 0 4px 38px;
  font-family: "SB Sans Text", Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: $color-bg-dark;

  &:hover,
  &:focus {
    #{$b}__arrow {
      margin-left: 8px;
    }
  }

  @include md-desktop-only {
    padding: 4px * $zoom 0 4px * $zoom 38px * $zoom;
    font-size: 16px * $zoom;

    &:hover,
    &:focus {
      #{$b}__arrow {
        margin-left: 8px * $zoom;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 1px;
    left: 0;
    width: 22px;
    height: 30px;

    @include md-desktop-only {
      width: 22px * $zoom;
      height: 30px * $zoom;
    }
  }
  &__extension {
    display: inline-block;
    text-transform: uppercase;
    margin-left: 4px;

    @include md-desktop-only {
      margin-left: 4px * $zoom;
    }
    #{$b}_type-pdf & {
      color: #b31818;
    }
    #{$b}_type-rar & {
      color: #b37518;
    }
    #{$b}_type-doc & {
      color: #1869b3;
    }
  }

  &__arrow {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    margin-left: 4px;
    line-height: 1;
    transition: margin-left 0.2s ease;

    @include md-desktop-only {
      margin-left: 4px * $zoom;
    }
  }
}
</style>
