<template>
  <div class="form-wrapper">
    <form v-if="!formHide" class="form" novalidate @submit.prevent="onSubmit">
      <template v-if="isShowParams">
        <div
          v-for="(rubric, i) in rubrics"
          :key="`subscribe_param_${i}`"
          class="form-row"
        >
          <label class="form-checkbox">
            <input
              v-model="rubrics[i].isChecked"
              class="form-checkbox__input"
              type="checkbox"
              name="another"
            />
            <span class="form-checkbox__text">
              {{ rubric.name }}
            </span>
          </label>
        </div>
        <div v-if="isShowRubricsError" class="form-row">
          <div class="form-input-message is-error">
            <span>{{ $t("rubrics-error") }}</span>
          </div>
        </div>
      </template>
      <div class="form-row form-row_name">
        <form-input
          id="event-name"
          v-model="form.name"
          :label="$t('name-label') + ' *'"
          class="form-input_dark"
          :error="$v.form.name.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.name.$error && !$v.form.name.required
            }
          ]"
        />
      </div>
      <div class="form-row form-row_lastname">
        <form-input
          id="event-lastname"
          v-model="form.lastname"
          :label="$t('lastname-label') + ' *'"
          class="form-input_dark"
          :error="$v.form.lastname.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.lastname.$error && !$v.form.lastname.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="event-email"
          v-model="form.email"
          :label="$t('email-label') + ' *'"
          type="email"
          class="form-input_dark"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
          @blur="$v.form.email.$touch()"
        />
      </div>
      <div class="root-form__button">
        <button
          :class="buttonClasses"
          type="submit"
          :disabled="!isHasSelectedRubrics || $v.$invalid"
          @click.prevent="onSubmit"
        >
          {{ $t("submit-button") }}
        </button>
      </div>
    </form>
    <p v-else>
      {{ $t("popup-success") }}
    </p>

    <notify-popup popup="successSubscribe">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              stroke="#18B346"
              stroke-linecap="round"
              stroke-width="2"
              d="M14 17l2.625 3L21 14"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("popup-title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("popup-success") }}
          </p>
        </div>
        <div class="notify-popup__buttons _g-text-center">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('successSubscribe')"
          >
            {{ $t("popup-button") }}
          </button>
        </div>
      </div>
    </notify-popup>

    <notify-popup popup="errorSubscribe">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#FF3939" stroke-width="2" />
            <path
              fill="#FF3939"
              d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">{{ $t("popup-title") }}</div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p v-if="errorText">
            {{ errorText }}
          </p>
          <p v-else>
            {{ $t("popup-error") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('errorSubscribe')"
          >
            {{ $t("popup-button-close") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "SubscribeEventForm",
  props: {
    action: {
      type: String,
      default: ""
    },
    elementId: {
      type: String,
      default: ""
    },
    elementName: {
      type: String,
      default: ""
    },
    displayRubrics: {
      type: Array,
      default: () => []
    },
    buttonClasses: {
      type: String,
      default: "button button_medium button_base"
    }
  },
  data() {
    return {
      form: {
        element_id: this.elementId,
        element_name: this.elementName,
        rname: "",
        lastname: "",
        email: ""
      },
      formHide: false,
      formBlock: false,
      errorText: "",
      rubrics: this.displayRubrics.map(param => ({
        id: param.id,
        name: param.name,
        isChecked: false
      })),
      isShowRubricsError: false
    };
  },
  computed: {
    isShowParams() {
      return Boolean(this.displayRubrics.length);
    },
    isHasSelectedRubrics() {
      return this.rubrics.some(rubric => rubric.isChecked);
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validate() {
      let flag = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (!this.isHasSelectedRubrics) {
        this.isShowRubricsError = true;
        return;
      }

      if (this.validate()) {
        if (this.formBlock) {
          return;
        }

        this.isShowRubricsError = false;
        this.formBlock = true;

        const data = {
          ...this.form,
          ...(this.isShowParams && {
            rubrics: this.rubrics
              .filter(param => param.isChecked)
              .flatMap(param => param.id)
          })
        };
        const res = await axios.post(this.action, data);

        if (res.data.success) {
          this.form.email = "";

          this.$nextTick(() => {
            this.$v.$reset();
          });

          this.formHide = true;
          this.$modal.show("successSubscribe");
          this.errorText = "";
        } else {
          if (res.data.message) {
            this.errorText = this.$t("popup-error-duplicate");
          } else {
            this.errorText = this.$t("form-text-error");
          }
          this.formBlock = false;
          this.$modal.show("errorSubscribe");
        }
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "email-label": "Enter your e-mail",
    "name-label": "Your name",
    "lastname-label": "Your last name",
    "submit-button": "Subscribe",
    "popup-title": "Subscribe to news",
    "popup-button": "Thanks",
    "popup-button-close": "Close",
    "popup-success": "You have successfully subscribed to the news of the event",
    "popup-error": "An error has occurred. Try later",
    "popup-error-duplicate": "You have already subscribed to our newsletter",
    "form-text-error": "An error has occurred. Try again later or contact your administrator.",
    "rubrics-error": "Select at least one category"
  },
  "ru": {
    "email-label": "Введите ваш e-mail",
    "name-label": "Ваше имя",
    "lastname-label": "Ваша фамилия",
    "submit-button": "Подписаться",
    "popup-title": "Подписка на новости",
    "popup-button": "Спасибо",
    "popup-button-close": "Закрыть окно",
    "popup-success": "Вы успешно подписаны на новости мероприятия",
    "popup-error": "Произошла ошибка. Попробуйте позже",
    "popup-error-duplicate": "Вы уже подписались на нашу рассылку",
    "form-text-error": "Произошла ошибка. Попробуйте позже или обратитесь к администратору.",
    "rubrics-error": "Выберите хотя бы одну рубрику"
  }
}
</i18n>
