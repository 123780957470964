<template>
  <div class="persons-number">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="persons-number__item"
      :class="{ _plus: isPlus }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PersonsNumber",
  props: {
    value: {
      type: Number,
      default: 1
    },
    isPlus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.items = this.value;
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".persons-number";

#{$b} {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include mobile {
    justify-content: flex-start;
  }

  &_col-2 {
    min-width: 61px;

    @include md-desktop-only {
      min-width: 61px * $zoom;
    }
  }

  &_col-3 {
    min-width: 94px;

    @include md-desktop-only {
      min-width: 94px * $zoom;
    }
  }

  &__item {
    display: block;
    width: 28px;
    height: 34px;
    background-image: url("/local/templates/main/src/assets/img/campus/weekend/icon-person.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:not(:last-child) {
      margin-right: 5px;
    }

    @include md-desktop-only {
      width: 28px * $zoom;
      height: 34px * $zoom;

      &:not(:last-child) {
        margin-right: 5px * $zoom;
      }
    }

    &._plus {
      width: 46px;
      background-image: url("/local/templates/main/src/assets/img/campus/weekend/icon-person-plus.svg");

      @include md-desktop-only {
        width: 46px * $zoom;
      }
    }
  }
}
</style>
