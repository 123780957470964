<template>
  <form class="form-body" @change="onUpdateForm">
    <div class="form-row">
      <custom-select
        id="company-branch"
        v-model="form.UF_WAY_OF_COMPANY"
        :options="wayOfCompanyOptions"
        :placeholder="$t('industry')"
        :error="$v.form.UF_WAY_OF_COMPANY.$error"
        prop-name-text="text"
        prop-name-value="id"
        is-flat-input
      />
      <div
        v-if="
          $v.form.UF_WAY_OF_COMPANY.$error &&
            !$v.form.UF_WAY_OF_COMPANY.required
        "
        class="form-input-message is-error"
      >
        {{ $t("required_field") }}
      </div>
    </div>

    <div v-if="isSelectedOther" class="form-row">
      <form-input
        id="workNotes"
        v-model="form.WORK_NOTES"
        :label="$t('other') + ' *'"
        :error="$v.form.WORK_NOTES.$error"
        :messages="[
          {
            type: 'error',
            i18: 'error.required',
            state: $v.form.WORK_NOTES.$error && !$v.form.WORK_NOTES.required
          }
        ]"
      />
    </div>

    <div v-if="isSelectedEducation" class="form-row">
      <custom-select
        id="education"
        v-model="form.UF_EDUCATION"
        :options="educationOptions"
        :placeholder="$t('education')"
        :error="$v.form.UF_EDUCATION.$error"
        prop-name-text="text"
        prop-name-value="id"
        is-flat-input
      />
      <div
        v-if="$v.form.UF_EDUCATION.$error && !$v.form.UF_EDUCATION.required"
        class="form-input-message is-error"
      >
        {{ $t("required_field") }}
      </div>
    </div>

    <div v-if="isSelectedEducationOther" class="form-row">
      <form-input
        id="personalNotes"
        v-model="form.PERSONAL_NOTES"
        :label="$t('other') + ' *'"
        :error="$v.form.PERSONAL_NOTES.$error"
        :messages="[
          {
            type: 'error',
            i18: 'error.required',
            state:
              $v.form.PERSONAL_NOTES.$error && !$v.form.PERSONAL_NOTES.required
          }
        ]"
      />
    </div>

    <div class="form-row">
      <form-input
        v-if="!hideDadata"
        id="organizationName"
        v-model="form.WORK_COMPANY"
        is-dadata
        dadata-type="party"
        :dadata-token="dadataApiKey"
        :label="$t('organization')"
        :error="$v.form.WORK_COMPANY.$error"
        :messages="[
          {
            type: 'error',
            i18: 'error.required',
            state: $v.form.WORK_COMPANY.$error && !$v.form.WORK_COMPANY.required
          }
        ]"
      />
      <form-input
        v-else
        id="organizationNameText"
        v-model="form.WORK_COMPANY"
        :label="$t('organization')"
        :error="$v.form.WORK_COMPANY.$error"
        :messages="[
          {
            type: 'error',
            i18: 'error.required',
            state: $v.form.WORK_COMPANY.$error && !$v.form.WORK_COMPANY.required
          }
        ]"
      />
    </div>

    <div class="form-row">
      <form-input
        id="position"
        v-model="form.WORK_POSITION"
        :label="$t('position')"
        :error="$v.form.WORK_POSITION.$error"
        :messages="[
          {
            type: 'error',
            i18: 'error.required',
            state:
              $v.form.WORK_POSITION.$error && !$v.form.WORK_POSITION.required
          }
        ]"
      />
    </div>

    <div class="form-row">
      <custom-select
        id="job-level"
        v-model="form.UF_POSITION_LEVEL"
        :options="positionLevelOptions"
        :placeholder="$t('level')"
        :error="$v.form.UF_POSITION_LEVEL.$error"
        prop-name-text="text"
        prop-name-value="id"
        is-flat-input
      />
      <div
        v-if="
          $v.form.UF_POSITION_LEVEL.$error &&
            !$v.form.UF_POSITION_LEVEL.required
        "
        class="form-input-message is-error"
      >
        {{ $t("required_field") }}
      </div>
    </div>

    <div class="mt-15 _g-text-center">
      <button
        class="button button_base"
        type="submit"
        :disabled="isDisabled"
        @click.prevent="onSubmit"
      >
        <span v-if="buttonText">
          {{ buttonText }}
        </span>
        <span v-else>
          {{ $t("save") }}
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: "UserEditFormProfessional",
  props: {
    arResult: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {
        return {
          WORK_COMPANY: "",
          WORK_POSITION: "",
          UF_POSITION_LEVEL: "",
          UF_WAY_OF_COMPANY: "",
          UF_EDUCATION: "",
          WORK_NOTES: "",
          PERSONAL_NOTES: ""
        };
      }
    },
    action: {
      type: String,
      default: ""
    },
    isInline: {
      type: String,
      default: ""
    },
    hideDadata: {
      type: Boolean,
      default: false
    },
    dadataApiKey: {
      type: String,
      default: ""
    },
    dadataCompanyUrl: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        WORK_COMPANY: this.arResult.WORK_COMPANY,
        WORK_POSITION: this.arResult.WORK_POSITION,
        UF_POSITION_LEVEL: "",
        UF_WAY_OF_COMPANY: "",
        UF_EDUCATION: "",
        WORK_NOTES: this.arResult.WORK_NOTES,
        PERSONAL_NOTES: this.arResult.PERSONAL_NOTES
      },
      isDisabled: false
    };
  },
  computed: {
    isSelectedOther() {
      return this.form.UF_WAY_OF_COMPANY === "15";
    },
    isSelectedEducation() {
      return this.form.UF_WAY_OF_COMPANY === "9";
    },
    isSelectedEducationOther() {
      return this.isSelectedEducation && this.form.UF_EDUCATION === "33";
    }
  },
  validations: {
    form: {
      UF_WAY_OF_COMPANY: {
        required
      },
      WORK_COMPANY: {
        required
      },
      WORK_POSITION: {
        required
      },
      UF_POSITION_LEVEL: {
        required
      },
      WORK_NOTES: {
        required: requiredIf(function() {
          return this.isSelectedOther;
        })
      },
      UF_EDUCATION: {
        required: requiredIf(function() {
          return this.isSelectedEducation;
        })
      },
      PERSONAL_NOTES: {
        required: requiredIf(function() {
          return this.isSelectedEducationOther;
        })
      }
    }
  },
  created() {
    this.wayOfCompanyOptions = JSON.parse(
      JSON.stringify(this.arResult.UF_WAY_OF_COMPANY)
    );
    this.positionLevelOptions = JSON.parse(
      JSON.stringify(this.arResult.UF_POSITION_LEVEL)
    );
    this.educationOptions = JSON.parse(
      JSON.stringify(this.arResult.UF_EDUCATION)
    );

    this.form.UF_WAY_OF_COMPANY = this.getIdByChecked(
      this.arResult.UF_WAY_OF_COMPANY
    );
    this.form.UF_POSITION_LEVEL = this.getIdByChecked(
      this.arResult.UF_POSITION_LEVEL
    );
    this.form.UF_EDUCATION = this.getIdByChecked(this.arResult.UF_EDUCATION);
  },
  methods: {
    getIdByChecked(options) {
      if (!options) return;

      let result = options.find(option => option.checked);

      return result && result.id ? result.id : "";
    },
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (this.validate()) {
        const data = new FormData();
        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "multipart/form-data" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            this.isDisabled = true;
            if (response.data.success) {
              this.$emit("update-success", true);
            }
          }
        });
      }
    },
    // setWayOfCompany(value) {
    //   this.wayOfCompany = value;
    //   this.form.UF_WAY_OF_COMPANY = value.id;
    //   this.onUpdateForm();
    // },
    // setPositionLevel(value) {
    //   this.positionLevel = value;
    //   this.form.UF_POSITION_LEVEL = value.id;
    //   this.onUpdateForm();
    // },
    // setEducation(value) {
    //   this.education = value;
    //   this.form.UF_EDUCATION = value.id;
    //   this.onUpdateForm();
    // },
    onUpdateForm() {
      this.isDisabled = false;
    },
    getDadataSuggestion(suggestion) {
      this.form.WORK_COMPANY = suggestion.value;
      this.onUpdateForm();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "industry": "Industry",
    "education": "Institution",
    "other": "Other",
    "organization": "Organization *",
    "position": "Position *",
    "level": "Level of your position *",
    "save": "Save changes",
    "required_field": "Required field"
  },
  "ru": {
    "industry": "Отрасль *",
    "education": "Учреждение",
    "other": "Другое",
    "organization": "Название организации *",
    "position": "Должность *",
    "level": "Уровень должности *",
    "save": "Сохранить изменения",
    "required_field": "Поле обязательно для заполнения"
  }
}
</i18n>
