<template>
  <div class="show-more-line">
    <div class="show-more-line__button" @click.prevent="showMore">
      <span>
        {{ $t("show_more") }}
      </span>
      <svg
        width="13"
        height="19"
        viewBox="0 0 13 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowMoreLine",
  methods: {
    showMore() {
      this.$emit("show-more");
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".show-more-line";

#{$b} {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before,
  &::after {
    content: "";
    height: 1px;
    background-color: $border-gray-lt;
    flex: 1 1 auto;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1.25;
    text-transform: uppercase;
    color: inherit;
    margin: 0 24px;

    @include md-desktop-only {
      @include zoom(font-size, 16px);
    }

    svg {
      width: 13px;
      height: 19px;

      &:not(:first-child) {
        margin-left: 12px;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "show_more": "Show more"
  },
  "ru": {
    "show_more": "Показать еще"
  }
}
</i18n>
