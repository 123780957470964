<template>
  <div v-show="isActive || breakpoint.isMobile" class="root-programs__tab">
    <div class="root-programs__card" :style="`background-image:url(${bg})`">
      <div class="root-programs__card__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramTab",
  props: {
    label: { type: String, default: "" },
    selected: { type: Boolean, default: false },
    bg: {
      type: String,
      default: "/local/templates/main/src/assets/img/root/program1.jpg"
    }
  },
  data() {
    return {
      isActive: false,
      breakpoint: this.getBreakpoint()
    };
  },
  mounted() {
    this.isActive = this.selected;
    window.addEventListener("resize", this.updateBreakpoint);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768
      };
    },
    updateBreakpoint() {
      const { isMobile } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
    }
  }
};
</script>
