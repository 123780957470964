<template>
  <div class="form-wrapper">
    <form
      v-if="!formHide"
      class="form"
      novalidate
      :action="url"
      @submit.prevent="submit"
    >
      <label v-if="form.field" class="_g-hidden">
        <input id="field" v-model="form.field" type="text" />
      </label>
      <div v-if="!isLearningForm" class="form-row">
        <vue-select
          id="topic"
          :value-input="selectedTopic"
          :placeholder="$t('topic-label')"
          class="select_dark"
          :class="{ 'select--error': $v.form.topic.$error }"
          event-tag="onTopic"
          :select-options="dropdowns"
          @onTopic="setTopic"
        />
        <div
          v-if="$v.form.topic.$error && !$v.form.topic.required"
          class="select__error"
        >
          {{ $t("topic-error") }}
        </div>
      </div>
      <div v-if="!isLearningForm && toggleOtherField" class="form-row">
        <form-input
          id="other"
          v-model="form.other"
          :label="$t('other-label') + ' *'"
          class="form-input_dark"
          :error="$v.form.other.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.other.$error && !$v.form.other.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="name"
          v-model="form.fio"
          :label="$t('name-label') + ' *'"
          class="form-input_dark"
          :error="$v.form.fio.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.fio.$error && !$v.form.fio.required
            }
          ]"
        />
      </div>
      <div v-if="isLearningForm" class="form-row">
        <form-input
          id="tel"
          v-model="form.phone"
          :label="$t('phone.input') + ' *'"
          type="tel"
          class="form-input_dark"
          :error="$v.form.phone.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.phone.$error && !$v.form.phone.required
            },
            {
              type: 'error',
              min: $v.form.phone.$params.minLength.min,
              state: !$v.form.phone.minLength
            }
          ]"
          @blur="$v.form.phone.$touch()"
        />
      </div>
      <div class="form-row">
        <form-input
          id="email"
          v-model="form.email"
          :label="$t('email-label') + ' *'"
          type="email"
          class="form-input_dark"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
          @blur="$v.form.email.$touch()"
        />
      </div>
      <div v-if="isLearningForm" class="form-row">
        <form-input
          id="comment"
          v-model="form.comment"
          tag="textarea"
          rows="1"
          class="form-input_dark"
          :label="$t('comment-label')"
        />
      </div>
      <div class="root-form__button">
        <InvisibleRecaptcha
          v-if="reCaptchaUse && reCaptchaKey"
          :sitekey="reCaptchaKey"
          :validate="reCaptchaLoaded"
          :callback="reCaptchaHandler"
          class="button button_medium button_light"
          type="submit"
          :disabled="reCaptchaIsLoaded"
        >
          {{ $t("submit-button") }}
        </InvisibleRecaptcha>
        <button v-else type="submit" class="button button_medium button_light">
          {{ $t("submit-button") }}
        </button>
        <div v-if="errorText" class="form-error">
          <p>
            {{ errorText }}
          </p>
        </div>
      </div>
    </form>
    <p v-else>
      {{ $t("form-text-finish") }}
    </p>
    <notify-popup popup="successFeedback">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              stroke="#18B346"
              stroke-linecap="round"
              stroke-width="2"
              d="M14 17l2.625 3L21 14"
            />
          </svg>
        </div>
        <div v-if="!disableTitle" class="notify-popup__title">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="$t('popup-title')"></span>
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="$t('popup-text')"></p>
        </div>
        <div class="notify-popup__buttons">
          <button
            class="button button_dark"
            @click.prevent="$modal.hide('successFeedback')"
          >
            {{ $t("popup-button") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, minLength } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
  name: "FeedbackForm",
  components: { InvisibleRecaptcha },
  props: {
    url: {
      type: String,
      default: ""
    },
    dropdowns: {
      type: Array,
      default: () => {}
    },
    disableTitle: {
      default: false,
      type: Boolean
    },
    isLearningForm: {
      type: Boolean,
      default: false
    },
    programmId: {
      type: String,
      default: ""
    },
    reCaptchaKey: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        topic: "",
        fio: "",
        field: "",
        email: "",
        other: "",
        phone: "",
        comment: "",
        programmId: this.programmId
      },
      selectedTopic: {},
      reCaptchaToken: "",
      reCaptchaUse: false,
      reCaptchaIsLoaded: false,
      formHide: false,
      formBlock: false,
      errorText: ""
    };
  },
  computed: {
    toggleOtherField() {
      return this.form.topic === "Другое";
    },
    // eslint-disable-next-line vue/return-in-computed-property
    clearOtherField() {
      if (this.toggleOtherField === false) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.other = "";
      }
    }
  },
  validations: {
    form: {
      topic: {
        required: requiredIf(function() {
          return !this.isLearningForm;
        })
      },
      fio: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required: requiredIf(function() {
          return this.isLearningForm;
        }),
        minLength: minLength(10)
      },
      other: {
        required: requiredIf(function() {
          return this.toggleOtherField;
        })
      }
    }
  },
  mounted() {
    this.checkRecaptchaStorage();
  },
  methods: {
    checkRecaptchaStorage() {
      if (localStorage.reCaptchaUse) {
        this.reCaptchaUse = localStorage.reCaptchaUse;
      }
    },
    reCaptchaHandler(token) {
      this.reCaptchaToken = token;
      this.submit();
      this.reCaptchaLoaded();
    },
    reCaptchaLoaded() {
      this.reCaptchaIsLoaded = !this.reCaptchaIsLoaded;
    },
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    setTopic(value) {
      this.selectedTopic = value;
      this.form.topic = value.text;
    },
    async submit() {
      if (this.validate()) {
        if (this.formBlock) {
          return;
        }

        this.formBlock = true;
        const response = await axios.post(this.url, this.form);

        if (response.data.success) {
          this.$modal.show("successFeedback");
          this.onReset();

          this.formHide = true;
        } else {
          this.errorText = this.$t("form-text-error");
          this.formBlock = false;
        }

        // Если введено более 3 раз включаем капчу и запоминаем
        if (response.data && response.data.countFill > 3) {
          this.reCaptchaUse = true;
          localStorage.reCaptchaUse = this.reCaptchaUse;
        }
      }
    },
    onReset() {
      this.form.topic = "";
      this.form.fio = "";
      this.form.field = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.other = "";
      this.form.comment = "";
      this.selectedTopic = {};
      this.$nextTick(() => {
        this.$v.$reset();
      });
    }
  }
};
</script>

<i18n>
{
  "en": {
    "topic-error": "Select the subject of your appeal",
    "topic-label": "Subject",
    "error-email": "Invalid e-mail",
    "submit-button": "Submit application",
    "other-label": "Enter text",
    "name-label": "What is your name?",
    "phone": {
      "input": "Phone",
      "length_left": "Phone must contain at least",
      "length_right": "characters"
    },
    "email-label": "Email address for communication",
    "comment-label": "Comment",
    "popup-title": "Application <br>for the program",
    "popup-text": "Thank you <br>Your application is accepted. <br>Our manager will contact you <br>in the near future!",
    "popup-button": "Close",
    "form-text-finish": "Your application is accepted!",
    "form-text-error": "An error has occurred. Try again later or contact your administrator."
  },
  "ru": {
    "topic-error": "Выберите тему обращения",
    "topic-label": "Тема обращения",
    "error-email": "Некорректный e-mail",
    "submit-button": "Оставить заявку",
    "other-label": "Введите текст",
    "name-label": "Как Вас зовут?",
    "phone": {
      "input": "Телефон",
      "length_left": "Телефон должен содержать минимум",
      "length_right": "символов"
    },
    "email-label": "E-mail для связи",
    "comment-label": "Комментарий",
    "popup-title": "Заявка на программу",
    "popup-text": "Спасибо <br>Ваша заявка принята. <br>Наш менеджер свяжется с&nbsp;Вами <br>в&nbsp;ближайшее время!",
    "popup-button": "Продолжить",
    "form-text-finish": "Ваша заявка принята!",
    "form-text-error": "Произошла ошибка. Попробуйте позже или обратитесь к администратору."
  }
}
</i18n>
