export default function genFormData(formData, data, previousKey) {
  if (data instanceof File) {
    formData.append(previousKey, data);
  } else if (data instanceof Object) {
    let keys = Object.keys(data);
    for (let k = 0; k < keys.length; ++k) {
      let key = keys[k];
      let value = data[key];
      if (previousKey != "") {
        key = previousKey + "[" + key + "]";
      }
      if (
        !(value instanceof File) &&
        value instanceof Object &&
        !Array.isArray(value)
      ) {
        formData.append(key + "[]", []);
        genFormData(formData, value, key);
      } else {
        if (Array.isArray(value)) {
          formData.append(key + "[]", []);
          for (let a = 0; a < value.length; ++a) {
            genFormData(formData, value[a], key + "[" + a + "]");
          }
        } else {
          formData.append(key, value);
        }
      }
    }
  } else {
    formData.append(previousKey, data);
  }
}
