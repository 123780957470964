<template>
  <div class="root-news">
    <a
      v-for="(item, key) in items"
      :key="key"
      :href="item.DETAIL_PAGE_URL"
      class="root-news__item"
    >
      <div
        :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
        class="root-news__img"
      >
        <div class="root-news__img-date">
          <p>{{ item.ACTIVE_FROM_STRUCT.DAY }}</p>
          <p>{{ item.ACTIVE_FROM_STRUCT.MONTH }}</p>
        </div>
      </div>
      <div class="root-news__title h3">
        {{ item.NAME }}
      </div>
      <div class="root-news__date para">
        {{ item.PLACE }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "EdutechIndexPulse",
  props: {
    items: { type: Array, default: () => [] }
  }
};
</script>
