var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dropdown",staticClass:"custom-select tabs-list__item tabs-list__item_dropdown",class:[
    { 'custom-select_opened': _vm.isOpen },
    { 'custom-select_changed': _vm.isChanged },
    { 'custom-select_flat-input': _vm.isFlatInput },
    { 'custom-select_filled': _vm.isFilled },
    { 'custom-select_focused': _vm.isFocused }
  ],attrs:{"tabindex":"0"},on:{"click":_vm.open,"focus":_vm.onFocus,"blur":_vm.onBlur,"mouseleave":_vm.mouseleave,"mouseover":_vm.mouseover,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}}},[(_vm.isFlatInput ? true : !_vm.selectedOption)?_c('div',{staticClass:"custom-select__label"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.isFilled)?_c('div',{staticClass:"custom-select__text"},[_vm._v(" "+_vm._s(_vm.selectedOption)+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChanged),expression:"isChanged"}],staticClass:"custom-select__reset",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.reset($event)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 11 11","fill":"none"}},[_c('path',{attrs:{"d":"M1 10L10 1M1 1L10 10","stroke":"#33363A"}})])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"tabs-dropdown"},_vm._l((_vm.options),function(option,key){return _c('li',{key:key,staticClass:"custom-select__item",class:[
        { _current: option[_vm.propNameValue] === _vm.value },
        { _selected: key === _vm.hoverIndex },
        { _picked: option.isPicked }
      ],on:{"click":function($event){return _vm.changeValue(option)},"mouseover":function($event){_vm.hoverIndex = key}}},[_vm._v(" "+_vm._s(option[_vm.propNameText])+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }