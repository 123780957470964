<template>
  <div class="edutech-index-empty">
    Ничего не найдено
  </div>
</template>

<script>
export default {
  name: "EdutechIndexEmpty",
  props: {
    items: { type: Array, default: () => [] }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";
.edutech-index-empty {
  border: solid 1px red;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: red;
  background: #fffaf5;

  @include md-desktop-only {
    padding: 20px * $zoom;
    border-radius: 8px * $zoom;
  }
}
</style>
