<template>
  <div class="root-news">
    <a
      v-for="(item, key) in items"
      :key="key"
      :href="item.DETAIL_PAGE_URL"
      class="root-news__item"
    >
      <div
        :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
        class="root-news__img"
      />
      <div class="root-news__title h3">
        {{ item.NAME }}
      </div>
      <div class="root-news__date para">
        {{ item.DISPLAY_ACTIVE_FROM }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "EdutechIndexGlossary",
  props: {
    items: { type: Array, default: () => [] }
  }
};
</script>
