<template>
  <div class="edu-cards__list">
    <a
      v-for="(item, key) in items"
      :key="key"
      :href="item.DETAIL_PAGE_URL"
      class="edu-cards__item"
      :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
    >
      <ul class="edu-cards__tags">
        <li v-for="(section, key2) in item.SECTIONS" :key="key2">
          {{ section.NAME }}
        </li>
      </ul>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="edu-cards__title" v-html="item.NAME"></div>
    </a>
  </div>
</template>

<script>
export default {
  name: "EdutechIndexJournals",
  props: {
    items: { type: Array, default: () => [] }
  }
};
</script>
