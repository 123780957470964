<template>
  <div
    :class="[
      'certificate-check',
      {
        'is-checking': isChecking,
        'is-available': isAvailable && !isChecking,
        'is-anavailable': !isAvailable && !isChecking
      }
    ]"
  >
    <template v-if="isChecking">
      <div class="certificate-check-panel">
        <div class="certificate-check-panel__img">
          <img src="../assets/img/certificates/certificate-checking.svg" />
        </div>
        <div class="certificate-check-panel__content">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="certificate-check-panel__title"
            v-html="$t('checking.title')"
          />
          <div class="certificate-check-panel__text">
            <span v-html="$t('checking.text')" />
            <span class="certificate-check-panel__dots">
              <span class="one">.</span>
              <span class="two">.</span>
              <span class="three">.</span>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isAvailable">
      <div class="certificate-check-panel">
        <div class="certificate-check-panel__img">
          <img src="../assets/img/certificates/certificate-available.svg" />
        </div>
        <div class="certificate-check-panel__content">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="certificate-check-panel__title"
            v-html="$t('available.title')"
          />
          <div
            class="certificate-check-panel__text"
            v-html="$t('available.text')"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="certificate-check-panel">
        <div class="certificate-check-panel__img">
          <img src="../assets/img/certificates/certificate-unavailable.svg" />
        </div>
        <div class="certificate-check-panel__content">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="certificate-check-panel__title"
            v-html="$t('unavailable.title')"
          />
          <div
            class="certificate-check-panel__text"
            v-html="$t('unavailable.text')"
          />
        </div>
      </div>

      <div class="certificate-check-installs">
        <!-- eslint-disable no-v-html -->
        <h3 v-html="$t('installs.title')" />

        <div class="grid-wrapper">
          <div class="grid grid_gap-32">
            <div
              v-for="(item, i) in installs"
              :key="`certificate_check_install_${i}`"
              class="grid-col-sm-1-2"
            >
              <div class="certificate-check-install">
                <div class="certificate-check-install__media">
                  <img
                    :src="
                      `/local/templates/main/src/assets/img/${item.img.src}`
                    "
                    :width="item.img.width"
                    :height="item.img.height"
                  />
                </div>
                <div class="certificate-check-install__content">
                  <div
                    class="certificate-check-install__text"
                    v-html="item.text"
                  />
                  <div class="certificate-check-install__buttons">
                    <a
                      :href="item.button.href"
                      target="_blank"
                      class="button button_base button_small"
                      v-html="item.button.text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CertificateCheck",
  data() {
    return {
      isAvailable: false,
      isChecking: false,
      installs: [
        {
          img: {
            src: "certificates/gosuslugi-logo.png",
            width: 101.5,
            height: 111
          },
          text: this.$t("installs.gosuslugi.text"),
          button: {
            text: this.$t("installs.gosuslugi.button"),
            href: "https://www.gosuslugi.ru/crt"
          }
        },
        {
          img: {
            src: "certificates/yandex-logo.png",
            width: 110.5,
            height: 113.5
          },
          text: this.$t("installs.yandex.text"),
          button: {
            text: this.$t("installs.yandex.button"),
            href: "https://browser.yandex.ru/"
          }
        }
      ]
    };
  },
  mounted() {
    this.checkCertificate();
  },
  methods: {
    async checkCertificate() {
      try {
        this.isChecking = true;

        const response = await axios.get("https://www.sberbank.ru/ru/person");

        if (response.status === 200) {
          this.isAvailable = true;
        }
      } catch (error) {
        if (this.isNetworkError(error)) {
          this.isAvailable = false;
          return;
        }
        throw Error(error);
      } finally {
        this.isChecking = false;
      }
    },
    isNetworkError(error) {
      return !!error.isAxiosError && !error.response;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".certificate-check";

#{$b} {
  &-panel {
    @include zoom-laptop(min-height, 225px);
    background-color: $white-true;
    @include zoom-laptop(border-radius, 8px);
    @include zoom-laptop(padding, 20px 60px);
    display: flex;
    align-items: center;
    line-height: 1.62;
    color: #33363a;

    @include tablet {
      min-height: 200px;
      padding: 32px;
    }

    @include mobile {
      flex-direction: column;
      align-items: stretch;
      padding: 20px 16px 32px;
    }

    &:not(:last-child) {
      @include zoom-laptop(margin-bottom, 56px);
    }

    &__img {
      flex: 1 0 auto;
      align-self: flex-start;
      display: flex;
      justify-content: center;
      @include zoom-laptop(margin-right, 46px);
      width: 50%;

      @include tablet {
        width: 40%;
        margin-right: 24px;
      }

      @include mobile {
        width: auto;
        margin: 0 0 16px;
      }

      #{$b}.is-checking & {
        @include zoom-laptop(height, 166px);

        @include tablet {
          height: 138px;
        }
      }

      #{$b}.is-available & {
        @include zoom-laptop(height, 184px);

        @include tablet {
          height: 150px;
        }
      }

      #{$b}.is-anavailable & {
        @include zoom-laptop(height, 221px);
        @include zoom-laptop(margin-top, -42px);

        @include tablet {
          height: 168px;
        }

        @include mobile {
          margin-top: -38px;
        }
      }

      & > img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
        align-self: center;
      }
    }

    &__content {
      flex: 1 1 auto;
      @include zoom-laptop(max-width, 520px);
    }

    &__title {
      display: block;
      font-weight: 600;
      @include zoom-laptop(font-size, 28px);
      line-height: 1.43;
      color: $black-true;
      @include zoom-laptop(margin, 0 0 16px);

      @include tablet {
        font-size: 24px;
      }

      @include mobile {
        margin-bottom: 12px;
      }
    }

    &__text {
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__dots {
      display: inline;

      & > * {
        opacity: 0;
        animation: dots 1.3s infinite;

        &.one {
          animation-delay: 0s;
        }

        &.two {
          animation-delay: 0.2s;
        }

        &.three {
          animation-delay: 0.3s;
        }
      }
    }
  }

  &-install {
    height: 100%;
    @include zoom-laptop(min-height, 225px);
    display: flex;
    position: relative;
    background-color: $white-true;
    @include zoom-laptop(border-radius, 8px);
    @include zoom-laptop(padding, 32px 24px 40px 30px);
    display: flex;
    line-height: 1.62;
    color: #33363a;

    @include tablet {
      flex-direction: column;
      min-height: auto;
    }

    @include mobile {
      padding: 16px 16px 24px;
    }

    &__media {
      flex-shrink: 0;
      @include zoom-laptop(margin-right, 32px);

      @include tablet {
        margin: 0 0 16px;
      }

      @include mobile {
        height: 90px;
      }

      & > img {
        width: auto;
        max-height: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    &__text {
      &:not(:last-child) {
        @include zoom-laptop(margin-bottom, 10px);

        @include tablet {
          margin-bottom: 20px;
        }
      }
    }

    &__buttons {
      margin-top: auto;

      .button {
        @include tablet {
          width: 100%;
        }
      }
    }
  }

  @keyframes dots {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>

<i18n>
  {
    "en": {
      "checking": {
        "title": "The certificate is being checked",
        "text": "Please, wait"
      },
      "available": {
        "title": "Certificates installed",
        "text": "Your data is secure"
      },
      "unavailable": {
        "title": "Certificates not found",
        "text": "Install <a href='https://browser.yandex.ru/' target='_blank'>Yandex Browser</a> or <a href='https://www.gosuslugi.ru/crt' target='_blank'>certificates</a> per device"
      },
      "installs": {
        "title": "How to protect your data",
        "gosuslugi": {
          "text": "Install the certificates and use the browser you are used to. The instructions on Public Services will help you",
          "button": "Install certificates"
        },
        "yandex": {
          "text": "Download and use Yandex.Browser with support for Russian certificates",
          "button": "Download the browser"
        }
      }
    },
    "ru": {
      "checking": {
        "title": "Идет проверка наличия сертификата",
        "text": "Пожалуйста, подождите"
      },
      "available": {
        "title": "Сертификаты установлены",
        "text": "Ваши данные надежно защищены"
      },
      "unavailable": {
        "title": "Сертификаты не&nbsp;найдены",
        "text": "Установите <a href='https://browser.yandex.ru/' target='_blank'>Яндекс Браузер</a> или <a href='https://www.gosuslugi.ru/crt' target='_blank'>сертификаты</a> на устройство"
      },
      "installs": {
        "title": "Как защитить ваши данные",
        "gosuslugi": {
          "text": "Установите сертификаты и&nbsp;используйте браузер, к&nbsp;которому привыкли. Вам поможет инструкция на&nbsp;Госуслугах",
          "button": "Установить сертификаты"
        },
        "yandex": {
          "text": "Скачайте и&nbsp;используйте Яндекс.Браузер с&nbsp;поддержкой российских сертификатов",
          "button": "Скачать браузер"
        }
      }
    }
  }
</i18n>
