<template>
  <div class="not-found">
    <div class="not-found__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    text: {
      type: String,
      default: "Ничего не найдено"
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".not-found";

#{$b} {
  display: block;

  &__text {
    @include font(16px, 1.5, 400);
    font-style: normal;
    color: $color-text-dark-3;

    @include md-desktop-only {
      font-size: 16px * $zoom;
    }
  }
}
</style>
