<template>
  <ul class="tabs-list tabs-list_scrollable">
    <template v-for="(item, i) in items">
      <li
        v-if="!item.dropdown"
        :key="i"
        class="tabs-list__item tabs-list__item_mr20"
        :class="{ 'tabs-list__item_active': item.name === activeTab }"
        @click="selectFilter(item.name, item.label)"
      >
        {{ item.label }}
      </li>
      <TabsFilterDropdown
        v-else
        :key="i"
        :items="item.dropdown"
        :active-tab="activeTab"
        :parent-label="item.label"
        @filterChange="selectFilter"
      ></TabsFilterDropdown>
    </template>
    <li
      v-if="randomItemAction"
      class="tabs-list__item tabs-list__item_mr20"
      @click="selectRandomItem"
    >
      Случайный материал
    </li>
  </ul>
</template>

<script>
import TabsFilterDropdown from "./TabsFilterDropdown";
import axios from "axios";

export default {
  name: "TabsFilter",
  components: { TabsFilterDropdown },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    select: {
      type: Object,
      default: null
    },
    startFilteredSelect: {
      type: String,
      default: ""
    },
    filterFor: {
      type: String,
      default: ""
    },
    randomItemAction: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeTab: this.startFilteredSelect
        ? this.startFilteredSelect
        : this.select
        ? this.select.name
        : this.$props.items[0].name,
      currentUrl: new URL(window.location.href)
    };
  },
  mounted() {
    this.$nextTick(function() {
      if (this.currentUrl.searchParams.get("section")) {
        let currentId = this.getUrlParams().section;
        this.items.map(item => {
          if (item.dropdown) {
            item.dropdown.map(dropdownItem => {
              if (dropdownItem.name === currentId) {
                this.selectFilter(dropdownItem.name, dropdownItem.label);
              }
            });
          }
        });
      } else {
        let name = this.select ? this.select.name : this.$props.items[0].name;
        let label = this.select
          ? this.select.label
          : this.$props.items[0].label;
        this.selectFilter(name, label, false);
      }
    });
    this.$root.$on("onSetActiveFilter", this.onSetActiveFilter);
  },
  methods: {
    selectFilter(val, label, withChangeUrl = true) {
      this.activeTab = val;
      this.$root.$emit("setActiveFilter", val, label, this.filterFor);
      if (withChangeUrl) {
        this.setUrlParam(val);
      }
    },
    onSetActiveFilter(val, filterName) {
      if (this.filterFor === filterName) {
        this.activeTab = val;
      }
    },
    async selectRandomItem() {
      await axios(this.randomItemAction).then(response => {
        if (response.data.success) {
          let win = window.open(response.data.link, "_blank");
          win.focus();
        }
      });
    },
    setUrlParam(idItem) {
      let updateUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?section=${idItem}`;
      window.history.pushState({ path: updateUrl }, "", updateUrl);
    },
    getUrlParams() {
      return {
        section: this.currentUrl.searchParams.get("section")
      };
    }
  }
};
</script>
