var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('form',{staticClass:"form",attrs:{"action":_vm.url},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":_vm.onReset}},[_c('div',{staticClass:"form-row"},[_c('form-input',{staticClass:"form-input_dark",attrs:{"id":"theme","tag":"textarea","rows":"2","label":_vm.$t('theme') + ' *',"error":_vm.$v.form.topic.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.topic.$error && !_vm.$v.form.topic.required
          }
        ]},model:{value:(_vm.form.topic),callback:function ($$v) {_vm.$set(_vm.form, "topic", $$v)},expression:"form.topic"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{staticClass:"form-input_dark",attrs:{"id":"email","label":_vm.$t('email') + ' *',"type":"email","error":_vm.$v.form.email.$error || !_vm.$v.form.email.email,"messages":[
          {
            type: 'error',
            i18: 'error.email.invalid',
            state: !_vm.$v.form.email.email
          },
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.email.$error && !_vm.$v.form.email.required
          }
        ]},on:{"blur":function($event){return _vm.$v.form.email.$touch()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{staticClass:"form-input_dark",attrs:{"id":"fio","label":_vm.$t('full_name') + ' *',"error":_vm.$v.form.fio.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.fio.$error && !_vm.$v.form.fio.required
          }
        ]},model:{value:(_vm.form.fio),callback:function ($$v) {_vm.$set(_vm.form, "fio", $$v)},expression:"form.fio"}})],1),_c('div',{staticClass:"root-form__button"},[(_vm.reCaptchaUse && _vm.reCaptchaKey)?_c('InvisibleRecaptcha',{staticClass:"button button_medium button_light",attrs:{"sitekey":_vm.reCaptchaKey,"validate":_vm.reCaptchaLoaded,"callback":_vm.reCaptchaHandler,"type":"submit","disabled":_vm.reCaptchaIsLoaded}},[_vm._v(" "+_vm._s(_vm.$t("topic_suggest"))+" ")]):_c('button',{staticClass:"button button_medium button_light",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("topic_suggest"))+" ")])],1)]),_c('notify-popup',{attrs:{"popup":"successPropposalPopup"}},[_c('div',{staticClass:"notify-popup__body _g-text-center"},[_c('div',{staticClass:"notify-popup__title"},[_vm._v(" "+_vm._s(_vm.$t("topic_suggest"))+" ")]),_c('div',{staticClass:"notify-popup__text",staticStyle:{"max-width":"360px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("success_left"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("success_right"))+" ")])]),_c('div',{staticClass:"notify-popup__buttons _g-text-center"},[_c('button',{staticClass:"button button_dark",on:{"click":function($event){$event.preventDefault();return _vm.$modal.hide('successPropposalPopup')}}},[_vm._v(" "+_vm._s(_vm.$t("close_window"))+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }