<template>
  <div class="events">
    <div class="events__sections">
      <section-switch
        :sections="sections"
        :current-section="currentSection"
        @change-section="changeSection"
      />
    </div>
    <div class="events__sorting">
      <custom-select
        v-model="sortBy"
        :placeholder="$t('sorting')"
        :options="sortFields"
        @input="changeSorting"
      />
    </div>
    <events-list v-show="filteredEvents.length" :events="filteredEvents" />
    <not-found
      v-show="!filteredEvents.length && !isLoading"
      :text="$t('not_found')"
    ></not-found>
    <show-more-line v-if="isShowLoadMore" @show-more="loadMore" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Events",
  props: {
    events: {
      type: Array,
      default: () => []
    },
    sections: {
      type: Array,
      default: () => []
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    initNextUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentSection: null,
      sortBy: "",
      sortFields: [
        { text: this.$i18n.t("sorting_future"), value: "asc" },
        { text: this.$i18n.t("sorting_past"), value: "desc" }
      ],
      paginator: this.initPaginator,
      filteredEvents: this.events,
      nextUrl: this.initNextUrl,
      isLoading: false
    };
  },
  computed: {
    isShowLoadMore() {
      return Boolean(this.nextUrl && !this.isLoading);
    }
  },
  mounted() {
    this.setSectionIdByAnchor();
    this.sortBy = "";
  },
  methods: {
    changeSorting(value) {
      this.sortBy = value;
      this.loadFirstPage();
    },
    changeSection(section) {
      window.location = section?.code ? `#${section.code}` : `#all`;
      this.currentSection = section;
      this.loadFirstPage();
    },
    loadFirstPage() {
      let sortLink = this.sortBy ? `&sort=${this.sortBy}` : ``;
      let sectionLink = this.currentSection
        ? `&type[]=${this.currentSection.id}`
        : "";
      this.nextUrl =
        "?ajax=Y&PAGEN_" +
        this.paginator?.NavNum +
        "=1" +
        sortLink +
        sectionLink;
      this.filteredEvents = [];
      this.loadMore();
    },
    async loadMore() {
      this.isLoading = true;
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res?.data?.NEXT_URL;
      this.paginator = res?.data?.PAGINATOR;
      this.filteredEvents = [...this.filteredEvents, ...res.data.ITEMS];
      this.isLoading = false;
    },
    setSectionIdByAnchor() {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const findSection = this.sections.find(item => item.code === hash);
        if (findSection) {
          this.currentSection = findSection;
          this.$nextTick(() => {
            this.$scrollTo(".events", 0, { offset: -320 });
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/base/_include.scss";

$b: ".events";

#{$b} {
  display: block;

  &__sections {
    &:not(:last-child) {
      margin-bottom: 48px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 48px);
      }

      @include tablet {
        margin-bottom: 32px;
      }
    }
  }

  &__sorting {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 60px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 60px);
      }

      @include tablet {
        margin-bottom: 40px;
      }
    }

    .custom-select {
      min-width: 200px;
      margin: 0;

      @include md-desktop-only {
        @include zoom(min-width, 200px);
      }

      @include mobile {
        width: 100%;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "sorting": "Sort",
    "sorting_future": "Upcoming",
    "sorting_past": "Past",
    "not_found": "Nothing found"
  },
  "ru": {
    "sorting": "Сортировать",
    "sorting_future": "Предстоящие",
    "sorting_past": "Прошедшие",
    "not_found": "Ничего не найдено"
  }
}
</i18n>
