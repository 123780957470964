<template>
  <transition name="fade">
    <div v-show="isActive" class="conference-tab">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "ConferenceTab",
  props: {
    id: { type: String, default: "" },
    name: { type: String, default: "" },
    description: { type: String, default: "" },
    selected: { type: Boolean, default: false },
    video: { type: String, default: "" },
    poster: { type: String, default: "" }
  },
  data() {
    return {
      isActive: false
    };
  },
  mounted() {
    this.isActive = this.selected;
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".conference-tab";

#{$b} {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
