<template>
  <div class="event-video-block">
    <component
      :is="'script'"
      v-if="customScript"
      :src="customScript"
      @load="onCustomScriptLoad"
    ></component>
    <template v-if="!isAuthorized">
      <a
        :href="authUrl"
        class="edu-review__video"
        :style="`background-image:url(${poster});`"
      >
        <div class="edu-review__video__content">
          <div class="edu-review__video-disclaimer">
            {{ $t("need_auth") }}
          </div>
          <div class="edu-review__video-play">
            <svg fill="none" viewBox="0 0 72 72">
              <circle cx="36" cy="36" r="36" fill="#18B346" />
              <path
                fill="#fff"
                d="M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"
              />
            </svg>
          </div>
          <h3 class="edu-review__video-title">
            {{ title }}
          </h3>
          <p v-if="isStream" class="edu-review__video-text">
            {{ $t("live") }}
          </p>
        </div>
      </a>
    </template>
    <template v-else>
      <div
        class="edu-review__video"
        :class="{
          'edu-review__video_custom': isCustomScriptLoaded,
          'edu-review__video_vk': isVideoVK,
          'edu-review__video_vk-played': isVkVideoPlayed
        }"
        :style="`background-image:url(${poster});`"
        @click="onClickVideo"
      >
        <div v-show="isShowVideoContent" class="edu-review__video__content">
          <div v-if="isShowPlayBtn" class="edu-review__video-play">
            <svg fill="none" viewBox="0 0 72 72">
              <circle cx="36" cy="36" r="36" fill="#18B346" />
              <path
                fill="#fff"
                d="M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"
              />
            </svg>
          </div>
          <h3 class="edu-review__video-title">
            {{ title }}
          </h3>
          <p v-if="isStream" class="edu-review__video-text">
            {{ $t("live") }}
          </p>
        </div>
        <template v-if="isRegistered || status != 'Anounce'">
          <event-log v-if="videoUrl" :url="eventLogUrl" :item="eventLogData">
            <video-player :src="videoUrl" :poster="poster"></video-player>
          </event-log>
          <event-log
            v-if="(isStream || isVideoCustom) && isCustomScriptLoaded"
            :url="eventLogUrl"
            :item="eventLogData"
          >
            <slot name="customPlayer" />
          </event-log>
          <event-log v-if="isVideoVK" :url="eventLogUrl" :item="eventLogData">
            <slot name="VKPlayer" />
          </event-log>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "EventVideoBlock",
  props: {
    isAuthorized: {
      type: Boolean,
      default: false
    },
    isRegistered: {
      type: Boolean,
      default: false
    },
    arResult: {
      type: Object,
      default: () => {}
    },
    authUrl: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    eventLogData: {
      type: Object,
      default: () => {}
    },
    eventLogUrl: {
      type: String,
      required: true,
      default: ""
    },
    customScript: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      status: "",
      isCustomScriptLoaded: false,
      isVkVideoPlayed: false
    };
  },
  computed: {
    isStream() {
      return (
        this.arResult.STREAM != undefined &&
        this.arResult.STREAM.VALUE_XML_ID == "Y"
      );
    },
    videoUrl() {
      return this.arResult.VIDEO_URL != undefined
        ? this.arResult.VIDEO_URL.VALUE
        : false;
    },
    isVideoCustom() {
      return !!this.$slots.customPlayer;
    },
    isVideoVK() {
      return !!this.$slots.VKPlayer;
    },
    isShowPlayBtn() {
      return (
        this.videoUrl || this.isCustomScriptLoaded || !this.isVkVideoPlayed
      );
    },
    isShowVideoContent() {
      return !this.isRegistered || !this.isCustomScriptLoaded;
    },
    poster() {
      return this.arResult.VIDEO_PICTURE
        ? this.arResult.VIDEO_PICTURE.FILE_VALUE.SRC
        : "";
    }
  },
  mounted() {
    this.status = this.arResult.STATUS.VALUE_XML_ID;
  },
  methods: {
    onClickVideo() {
      if (this.status == "Anounce" && !this.isRegistered) {
        this.$root.$emit("openRegistrationPopup");
      } else if (this.isVideoVK) {
        this.isVkVideoPlayed = true;
      }
    },
    onCustomScriptLoad() {
      this.isCustomScriptLoaded = true;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "need_auth": "Available only to authorized users",
    "live": "Live stream"
  },
  "ru": {
    "need_auth": "Доступно только авторизованным пользователям",
    "live": "Прямая трансляция"
  }
}
</i18n>
