<template>
  <div class="anchors-line">
    <div class="anchors-line__overflow">
      <div class="container">
        <ul class="anchors-line__list">
          <li v-for="(item, i) in items" :key="i">
            <a
              v-scroll-to="{ el: item.link, offset: -70 }"
              class="anchors-line__list__item"
              :class="{ _active: item.active }"
              href="#"
              @click="toggleActive(i)"
            >
              {{ item.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnchorsLine",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggleActive(toggleActive) {
      this.items.forEach((item, i) => {
        this.items[i].active = false;
      });

      this.items[toggleActive].active = true;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".anchors-line";

#{$b} {
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    display: block;
    background-color: $border-gray-lt;
    min-width: 100%;
    height: 1px;
    position: relative;
    top: -2px;
    z-index: -1;

    @include md-desktop-only {
      top: -2px * $zoom;
    }
  }
  &__overflow {
    overflow: auto;
    scrollbar-width: none;
    padding-bottom: 2px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @include md-desktop-only {
      padding-bottom: 2px * $zoom;
    }
  }
  &__list {
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-start;
    min-width: 100%;
    padding-right: 20px;

    @include md-desktop-only {
      padding-right: 20px * $zoom;
    }
    & > li {
      &:not(:last-child) {
        margin-right: 50px;

        @include md-desktop-only {
          margin-right: 50px * $zoom;
        }
      }
    }
    &__item {
      display: block;
      margin-bottom: -2px;
      padding: 32px 0 15px;
      @include font(22px, 1.42, 400);
      color: $color-text-dark-3;
      border-bottom: 3px solid transparent;
      white-space: nowrap;

      @include low-desktop {
        font-size: 20px;
      }
      @include md-desktop-only {
        margin-bottom: -2px * $zoom;
        padding: 32px * $zoom 0 15px * $zoom;
        font-size: 22px * $zoom;
        border-bottom: 3px * $zoom solid transparent;
      }
      @include tablet {
        @include font(18px, 1.56);
        padding: 40px 0 20px;
      }
      &._active {
        font-weight: 600;
        border-bottom-color: $color-bg-dark;
        color: $color-bg-dark;
      }
    }
  }
}
</style>
