<template>
  <form
    class="personal-area__info-form form"
    @change="onUpdateForm"
    @submit.prevent="onSubmit"
  >
    <div class="form-body form-body_width-medium">
      <div class="personal-area__info-form__media">
        <div class="form-row">
          <avatar-upload
            :photo="arResult.PERSONAL_PHOTO"
            @updateAvatar="onUpdateAvatar"
          ></avatar-upload>
        </div>
        <div class="form-row _g-visible-tablet">
          <template v-if="arResult.QR.length > 0">
            <div class="personal-area__qr-code">
              <div
                class="personal-area__qr-code__qr"
                :style="
                  `background-image: url('${arResult.QR}'); margin: -12px;`
                "
              ></div>
              <div class="personal-area__qr-code__label">
                {{ $t("qr_title") }}
              </div>
            </div>
          </template>
          <p class="form-light-text mt-4">
            <small>
              {{ $t("qr_description") }}
            </small>
          </p>
        </div>
      </div>
      <div class="form-row">
        <form-input
          id="surname"
          v-model="form.LAST_NAME"
          :label="$t('surname') + ' *'"
          is-capitalize
          :error="$v.form.LAST_NAME.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.LAST_NAME.$error && !$v.form.LAST_NAME.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="name"
          v-model="form.NAME"
          :label="$t('name') + ' *'"
          is-capitalize
          :error="$v.form.NAME.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.NAME.$error && !$v.form.NAME.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="middleName"
          v-model="form.SECOND_NAME"
          :label="$t('middle_name') + ' *'"
          is-capitalize
        />
      </div>
      <div class="form-row _g-hidden-tablet">
        <template v-if="arResult.QR.length > 0">
          <div class="personal-area__qr-code">
            <div
              class="personal-area__qr-code__qr"
              :style="`background-image: url('${arResult.QR}'); margin: -12px;`"
            ></div>
            <div class="personal-area__qr-code__label">
              {{ $t("qr_title") }}
            </div>
          </div>
        </template>
        <p class="form-light-text mt-4">
          <small>
            {{ $t("qr_description") }}
          </small>
        </p>
      </div>
      <div class="form-row">
        <label class="radio-input radio-input_inline">
          <input
            v-model="form.PERSONAL_GENDER"
            class="radio-input__input"
            type="radio"
            name="radio3"
            value="M"
            checked=""
          />
          <span class="radio-input__text">
            {{ $t("man") }}
          </span>
        </label>
        <label class="radio-input radio-input_inline">
          <input
            v-model="form.PERSONAL_GENDER"
            class="radio-input__input"
            type="radio"
            name="radio3"
            value="F"
          />
          <span class="radio-input__text">
            {{ $t("woman") }}
          </span>
        </label>
      </div>
      <div class="form-row">
        <form-input
          id="date"
          v-model="parseDate"
          :label="$t('birthday')"
          type="date"
          @change="onUpdateForm"
        />
      </div>
      <div class="form-row">
        <form-input
          id="snils"
          v-model="form.SNILS"
          :label="$t('snils')"
          :error="$v.form.SNILS.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.snils.numeric',
              state: !$v.form.SNILS.numeric
            },
            {
              type: 'error',
              i18: 'error.snils.checkSum',
              state: !$v.form.SNILS.checkSum
            },
            {
              type: 'error',
              min: $v.form.SNILS.$params.minLength.min,
              state: !$v.form.SNILS.minLength
            },
            {
              type: 'error',
              max: $v.form.SNILS.$params.maxLength.max,
              state: !$v.form.SNILS.maxLength
            }
          ]"
        />
      </div>
      <div class="form-row form-row_large-indent">
        <file-uploader
          id="education"
          ref="education"
          :title="$t('education.title')"
          :description="$t('education.description')"
          extensions="pdf"
          accept=".pdf"
          :file-url="form.EDUCATION"
          is-show-confirm-status
          :is-file-confirmed="form.EDUCATION_CONFIRM"
          :size="5"
          @onAddFile="addFile('EDUCATION', ...arguments)"
          @onRemoveFile="removeFile('EDUCATION', ...arguments)"
        />
      </div>
      <div class="form-row">
        <file-uploader
          id="passport"
          ref="passport"
          :title="$t('passport.title')"
          :description="$t('passport.description')"
          extensions="pdf"
          accept=".pdf"
          :file-url="form.PASSPORT"
          is-show-confirm-status
          :is-file-confirmed="form.PASSPORT_CONFIRM"
          :size="5"
          @onAddFile="addFile('PASSPORT', ...arguments)"
          @onRemoveFile="removeFile('PASSPORT', ...arguments)"
        />
      </div>

      <div v-if="error" class="form-row">
        <div class="form-input-message is-error">
          {{ error }}
        </div>
      </div>

      <button
        class="button button_base mt-15"
        :class="{
          button_loading: isButtonLoading
        }"
        type="submit"
        :disabled="isDisabled"
      >
        {{ $t("save") }}
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import {
  required,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { checkSum } from "../utils/validators";

export default {
  name: "UserEditFormMain",
  props: {
    arResult: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {
        return {
          NAME: "",
          LAST_NAME: "",
          SECOND_NAME: "",
          SNILS: "",
          QR: "",
          PERSONAL_PHOTO: "",
          PERSONAL_GENDER: "",
          ID: 0,
          EDUCATION: "",
          EDUCATION_CONFIRM: false,
          PASSPORT: "",
          PASSPORT_CONFIRM: false
        };
      }
    },
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        NAME: this.arResult.NAME,
        LAST_NAME: this.arResult.LAST_NAME,
        SECOND_NAME: this.arResult.SECOND_NAME,
        SNILS: this.arResult.SNILS,
        PERSONAL_PHOTO: "",
        PERSONAL_GENDER: this.arResult.PERSONAL_GENDER,
        EDUCATION: this.arResult.EDUCATION,
        EDUCATION_CONFIRM: this.arResult.EDUCATION_CONFIRM,
        UPDATE_EDUCATION: false,
        PASSPORT: this.arResult.PASSPORT,
        PASSPORT_CONFIRM: this.arResult.PASSPORT_CONFIRM,
        UPDATE_PASSPORT: false
      },
      birthDay: this.arResult.PERSONAL_BIRTHDAY,
      isDisabled: true,
      isButtonLoading: false,
      error: ""
    };
  },
  computed: {
    parseDate: {
      get() {
        if (!isNaN(parseInt(this.birthDay))) {
          let date = this.birthDay ? this.birthDay.split(".").reverse() : null;
          return date ? new Date(date.join("-")) : null;
        } else {
          return this.birthDay;
        }
      },
      set(newValue) {
        this.birthDay = newValue;
        this.form.PERSONAL_BIRTHDAY = newValue
          ? new Date(newValue).toLocaleString("ru").split(",")[0]
          : "";
      }
    }
  },
  validations: {
    form: {
      NAME: { required },
      LAST_NAME: { required },
      SNILS: {
        numeric,
        checkSum,
        minLength: minLength(11),
        maxLength: maxLength(11)
      }
    }
  },
  methods: {
    isValidate() {
      this.$v.$touch();

      return !this.$v.$invalid;
    },
    onUpdateAvatar(file) {
      this.form.PERSONAL_PHOTO = file;
    },
    onUpdateForm() {
      this.isDisabled = false;
    },
    addFile(propName, file, isExternalFile) {
      if (!isExternalFile) {
        this.form[`UPDATE_${propName}`] = true;
      }

      this.form[propName] = file.file;
    },
    removeFile(propName, file, isExternalFile) {
      this.error = "";

      if (isExternalFile) {
        this.form[`UPDATE_${propName}`] = true;
        this.onUpdateForm();
      }

      this.form[propName] = "";
    },
    async onSubmit() {
      if (this.isValidate()) {
        if (this.isButtonLoading) {
          return;
        }
        this.error = "";

        const data = new FormData();
        for (const prop in this.form) {
          data.append(prop, this.form[prop]);
        }

        this.isButtonLoading = true;
        const options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "multipart/form-data" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            this.isDisabled = true;

            if (response.data.success) {
              for (const prop in this.form) {
                if (this.form[prop] instanceof File) {
                  const uploader = this.$refs[prop.toLowerCase()];

                  if (uploader && !uploader.isFileConfirmed) {
                    uploader.isFileOnConfirmation = true;
                  }
                }
              }
            } else if (response.data.message) {
              this.error = response.data.message;
            }
          }
          this.isButtonLoading = false;
        });
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "qr_title": "Your QR code *",
    "qr_description": "* A QR code is needed for registration at the event",
    "surname": "Surname",
    "name": "Name",
    "middle_name": "Middle name",
    "man": "Male",
    "woman": "Female",
    "birthday": "Birthday",
    "save": "Save changes",
    "snils": "The Insurance Number of Individual account",
    "education": {
      "title": "Upload your higher education diploma",
      "description": "PDF (Maximum size 5MB)"
    },
    "passport": {
      "title": "Upload scanned passport (pages 2, 3 and 8)",
      "description": "PDF (Maximum size 5MB)"
    }
  },
  "ru": {
    "qr_title": "Ваш QR-код *",
    "qr_description": "* QR код нужен для регистрации на мероприятия",
    "surname": "Фамилия",
    "name": "Имя",
    "middle_name": "Отчество",
    "man": "Мужчина",
    "woman": "Женщина",
    "birthday": "Дата рождения",
    "save": "Сохранить изменения",
    "snils": "СНИЛС",
    "education": {
      "title": "Загрузите диплом о высшем образовании",
      "description": "PDF (Максимальный размер 5МБ)"
    },
    "passport": {
      "title": "Загрузите скан паспорта (2, 3 и 8 страницы)",
      "description": "PDF (Максимальный размер 5МБ)"
    }
  }
}
</i18n>
