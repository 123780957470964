var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notify-popup__body"},[_c('div',{staticClass:"notify-popup__title _g-text-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.form.resend)?_c('form',{staticClass:"form",attrs:{"action":_vm.url},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":_vm.onReset}},[_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"topic","label":_vm.$t('theme') + ' *',"error":_vm.$v.form.topic.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.topic.$error && !_vm.$v.form.topic.required
          }
        ]},model:{value:(_vm.form.topic),callback:function ($$v) {_vm.$set(_vm.form, "topic", $$v)},expression:"form.topic"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"fio","label":_vm.$t('fio') + ' *',"error":_vm.$v.form.fio.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.fio.$error && !_vm.$v.form.fio.required
          }
        ]},model:{value:(_vm.form.fio),callback:function ($$v) {_vm.$set(_vm.form, "fio", $$v)},expression:"form.fio"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"email","label":"E-mail *","type":"email","error":_vm.$v.form.email.$error || !_vm.$v.form.email.email,"messages":[
          {
            type: 'error',
            i18: 'error.email.invalid',
            state: !_vm.$v.form.email.email
          },
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.email.$error && !_vm.$v.form.email.required
          }
        ]},on:{"blur":function($event){return _vm.$v.form.email.$touch()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"tel","label":_vm.$t('phone.input') + ' *',"type":"tel","error":_vm.$v.form.phone.$error,"messages":[
          {
            type: 'error',
            i18: 'error.required',
            state: _vm.$v.form.phone.$error && !_vm.$v.form.phone.required
          },
          {
            type: 'error',
            min: _vm.$v.form.phone.$params.minLength.min,
            state: !_vm.$v.form.phone.minLength
          }
        ]},on:{"blur":function($event){return _vm.$v.form.phone.$touch()}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{staticClass:"form-row"},[_c('form-input',{attrs:{"id":"message","label":_vm.$t('comment'),"tag":"textarea"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_c('div',{staticClass:"mt-14 _g-text-center"},[_c('button',{staticClass:"button button_base",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("send"))+" ")])])]):_c('div',[_c('div',{staticClass:"notify-popup__text _g-text-center",staticStyle:{"max-width":"360px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("success_left"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("success_right"))+" ")])]),_c('div',{staticClass:"notify-popup__buttons"},[_c('a',{staticClass:"notify-popup__link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$modal.hide('eventApplicationPopup')}}},[_vm._v(" "+_vm._s(_vm.$t("close_window"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }