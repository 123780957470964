<template>
  <notify-popup popup="sberqApplicationPopup">
    <div class="notify-popup__body">
      <div class="notify-popup__title _g-text-center">
        {{ $t("title") }}
      </div>
      <template v-if="!isFormSent">
        <form class="form" @submit.prevent="onSubmit">
          <div class="form-row">
            <form-input
              :id="`companyName_${_uid}`"
              v-model="form.companyName"
              :label="$t('company_name') + ' *'"
              :error="$v.form.companyName.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state:
                    $v.form.companyName.$error && !$v.form.companyName.required
                }
              ]"
            />
          </div>
          <div class="form-row">
            <form-input
              :id="`name_${_uid}`"
              v-model="form.name"
              :label="$t('name') + ' *'"
              :error="$v.form.name.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state: $v.form.name.$error && !$v.form.name.required
                }
              ]"
            />
          </div>
          <div class="form-row">
            <form-input
              :id="`surname_${_uid}`"
              v-model="form.surname"
              :label="$t('surname') + ' *'"
              :error="$v.form.surname.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state: $v.form.surname.$error && !$v.form.surname.required
                }
              ]"
            />
          </div>
          <div class="form-row">
            <form-input
              :id="`patronymic_${_uid}`"
              v-model="form.patronymic"
              :label="$t('patronymic')"
            />
          </div>
          <div class="form-row">
            <form-input
              :id="`email_${_uid}`"
              v-model="form.email"
              type="email"
              :label="$t('email') + ' *'"
              :error="$v.form.email.$error || !$v.form.email.email"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.email.invalid',
                  state: !$v.form.email.email
                },
                {
                  type: 'error',
                  i18: 'error.required',
                  state: $v.form.email.$error && !$v.form.email.required
                }
              ]"
            />
          </div>
          <div class="form-row">
            <form-input
              :id="`phone_${_uid}`"
              v-model="form.phone"
              :label="$t('phone') + ' *'"
              type="tel"
              :error="$v.form.phone.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state: $v.form.phone.$error && !$v.form.phone.required
                },
                {
                  type: 'error',
                  min: $v.form.phone.$params.minLength.min,
                  state: !$v.form.phone.minLength
                }
              ]"
              @blur="$v.form.phone.$touch()"
            />
          </div>
          <div class="form-row">
            <label class="form-agree">
              <input
                v-model="form.agreement"
                class="form-agree__checkbox"
                :class="{
                  'form-agree__checkbox--error': $v.form.agreement.$error
                }"
                type="checkbox"
                name="data-agreement"
                @change="$v.form.agreement.$touch()"
              />
              <span class="form-agree__text">
                {{ $t("agree_left") }}
                <a
                  href="/upload/iblock/999/09%20%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BF%D0%BE%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.docx"
                >
                  {{ $t("agree_right") }}
                </a>
              </span>
            </label>
            <div
              v-if="$v.form.agreement.$error"
              class="form-input-message is-error"
            >
              {{ $t("required_agree") }}
            </div>
          </div>
          <div class="mt-14 _g-text-center">
            <button
              type="submit"
              class="button button_base"
              :disabled="isFormLoading"
            >
              {{ $t("send_application") }}
            </button>
          </div>
        </form>
      </template>
      <template v-else>
        <div class="notify-popup__text _g-text-center">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="$t('success_text')" />
        </div>

        <div class="notify-popup__buttons">
          <a
            class="notify-popup__link"
            href="#"
            @click.prevent="$modal.hide('sberqApplicationPopup')"
          >
            {{ $t("close") }}
          </a>
        </div>
      </template>
    </div>
  </notify-popup>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "SberqApplicationPopup",
  props: {
    action: { type: String, required: true },
    title: { type: String, default: "" }
  },
  data() {
    return {
      isFormSent: false,
      isFormLoading: false,
      form: {
        personType: "",
        companyName: "",
        name: "",
        surname: "",
        patronymic: "",
        email: "",
        phone: "",
        agreement: false
      }
    };
  },
  validations: {
    form: {
      companyName: {
        required
      },
      name: {
        required
      },
      surname: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minLength: minLength(10)
      },
      agreement: {
        required,
        sameAs: sameAs(() => true)
      }
    }
  },
  methods: {
    show(personType) {
      this.reset();
      this.form.personType = personType;
      this.showPopup();
    },
    showPopup() {
      this.$modal.show("sberqApplicationPopup");
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async onSubmit() {
      if (this.isValid()) {
        this.isFormLoading = true;

        const data = new FormData();

        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }

        if (window.BX) {
          data.append("sessid", window.BX.bitrix_sessid());
        }

        const options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };

        const response = await axios(options);

        switch (response.data.success) {
          case true:
            this.isFormSent = true;
            this.isFormLoading = false;

            break;
        }
      }
    },
    reset() {
      this.isFormSent = false;

      for (let prop in this.form) {
        if (typeof this.form[prop] === "boolean") {
          this.form[prop] = false;
        } else {
          this.form[prop] = "";
        }
      }

      this.$v.$reset();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "title": "Submit an application",
    "company_name": "Company name",
    "name": "Name",
    "surname": "Surname",
    "patronymic": "Patronymic (not necessary)",
    "email": "E-mail",
    "phone": "Phone",
    "agree_left": "I consent to",
    "agree_right": "the processing of my personal data",
    "required_agree": "You must agree to the terms",
    "send_application": "Submit",
    "success_text": "Thank! Your application has been sent. <br> The manager will contact you shortly.",
    "close": "Close a window"
  },
  "ru": {
    "title": "Оставить заявку",
    "company_name": "Название компании",
    "name": "Имя",
    "surname": "Фамилия",
    "patronymic": "Отчество (не обязательно)",
    "email": "E-mail для связи",
    "phone": "Телефон",
    "agree_left": "Я согласен(а) на",
    "agree_right": "обработку персональных данных",
    "required_agree": "Необходимо согласиться с условиями",
    "send_application": "Подать заявку",
    "success_text": "Спасибо! Ваша заявка отправлена. <br> Менеджер свяжется с&nbsp;Вами в&nbsp;течение 24&nbsp;часов.",
    "close": "Закрыть окно"
  }
}
</i18n>
