<template>
  <div>
    <div class="tabs tabs_light">
      <div class="tabs-container">
        <div class="tabs-overflow">
          <ul class="tabs-list">
            <li
              v-for="(section, key) in sections"
              :key="key"
              :class="{ 'tabs-list__item_active': section.ID === sectionId }"
              class="tabs-list__item tabs-list__item_mr20"
              @click="switchSection(section.ID)"
            >
              {{ section.NAME }}
            </li>
          </ul>
        </div>
        <sorting-dropdown
          v-model="sortBy"
          :options="sortFields"
        ></sorting-dropdown>
      </div>
    </div>
    <div class="root-news">
      <a
        v-for="(item, key) in items"
        :key="key"
        :href="item.DETAIL_PAGE_URL"
        class="root-news__item root-news__item_shadow"
        target="_blank"
      >
        <div
          class="root-news__img"
          :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
          :title="item.NAME"
        >
          <ul class="root-news__tags root-news__tags_right">
            <li>
              <div
                class="root-news__tag"
                :style="{ 'background-color': item.SECTION.UF_TAG_COLOR }"
              >
                {{ item.SECTION.NAME }}
              </div>
            </li>
          </ul>
        </div>
        <div class="root-news__title h3">
          {{ item.NAME }}
        </div>
        <div class="root-news__date para">
          {{ item.DISPLAY_ACTIVE_FROM.toLowerCase() }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "RootNews",
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sectionId: null,
      sortFields: [
        { name: this.$i18n.t("sorting.desc"), value: "desc" },
        { name: this.$i18n.t("sorting.asc"), value: "asc" }
      ],
      sortBy: "desc"
    };
  },
  computed: {
    sections() {
      return [{ ID: null, NAME: this.$i18n.t("all") }, ...this.params.SECTIONS];
    },
    items() {
      const vm = this;
      return vm.params.ITEMS.sort((a, b) => {
        let dateA = new Date(a.ACTIVE_FROM.replace(/-/g, "/")),
          dateB = new Date(b.ACTIVE_FROM.replace(/-/g, "/"));

        if (dateA === dateB) return 0;
        if (this.sortBy === "asc") {
          return dateA > dateB ? 1 : -1;
        } else {
          return dateA < dateB ? 1 : -1;
        }
      }).filter((item, index) => {
        if (this.sectionId) {
          return this.sectionId == item.SECTION.ID;
        } else {
          return index < vm.params.MAX_NEWS ? item : false;
        }
      });
    }
  },
  methods: {
    switchSection(id) {
      this.sectionId = id;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "all": "All",
    "sorting": {
      "desc": "Lastest first",
      "asc": "Oldest first"
    }
  },
  "ru": {
    "all": "Все",
    "sorting": {
      "desc": "Сначала новые",
      "asc": "Сначала старые"
    }
  }
}
</i18n>
