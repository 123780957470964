<template>
  <div class="notify-popup__body">
    <div class="notify-popup__title _g-text-center">
      {{ title }}
    </div>

    <form
      v-if="!form.resend"
      class="form"
      :action="url"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <div class="form-row">
        <form-input
          id="topic"
          v-model="form.topic"
          :label="$t('theme') + ' *'"
          :error="$v.form.topic.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.topic.$error && !$v.form.topic.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="fio"
          v-model="form.fio"
          :label="$t('fio') + ' *'"
          :error="$v.form.fio.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.fio.$error && !$v.form.fio.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="email"
          v-model="form.email"
          label="E-mail *"
          type="email"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
          @blur="$v.form.email.$touch()"
        />
      </div>
      <div class="form-row">
        <form-input
          id="tel"
          v-model="form.phone"
          :label="$t('phone.input') + ' *'"
          type="tel"
          :error="$v.form.phone.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.phone.$error && !$v.form.phone.required
            },
            {
              type: 'error',
              min: $v.form.phone.$params.minLength.min,
              state: !$v.form.phone.minLength
            }
          ]"
          @blur="$v.form.phone.$touch()"
        />
      </div>
      <div class="form-row">
        <form-input
          id="message"
          v-model="form.message"
          :label="$t('comment')"
          tag="textarea"
        />
      </div>
      <div class="mt-14 _g-text-center">
        <button type="submit" class="button button_base">
          {{ $t("send") }}
        </button>
      </div>
    </form>

    <div v-else>
      <div class="notify-popup__text _g-text-center" style="max-width: 360px;">
        <p>
          {{ $t("success_left") }}
          <br />
          {{ $t("success_right") }}
        </p>
      </div>

      <div class="notify-popup__buttons">
        <a
          class="notify-popup__link"
          href="#"
          @click.prevent="$modal.hide('eventApplicationPopup')"
        >
          {{ $t("close_window") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "EventApplicationForm",
  props: {
    title: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      required: true
    },
    userData: {
      type: [Array, Object],
      required: true
    },
    programmId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        topic: "",
        fio: "",
        email: "",
        phone: "",
        message: "",
        resend: false
      }
    };
  },
  mounted() {
    this.setData();
  },
  validations: {
    form: {
      topic: {
        required
      },
      fio: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minLength: minLength(10)
      }
    }
  },
  methods: {
    setData() {
      if (this.userData) {
        this.form.fio = this.userData.FCS;
        this.form.email = this.userData.EMAIL;
        this.form.phone = this.userData.PHONE;
        this.form.programmId = this.programmId;
      }
    },
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async onSubmit() {
      if (this.validate()) {
        await axios.post(this.url, this.form).then(response => {
          if (response.data.success) {
            this.form.resend = true;
          }
        });
      }
    },
    onReset(e) {
      e.preventDefault();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "theme": "The theme of the event",
    "fio": "Full name",
    "invalid_email": "Invalid e-mail address",
    "phone": {
      "input": "Phone",
      "length_left": "Phone must contain at least",
      "length_right": "characters"
    },
    "comment": "Comment",
    "send": "Send",
    "success_left": "Thanks! Your request has been sent.",
    "success_right": "If necessary, the Manager will contact you.",
    "close_window": "Close the window"
  },
  "ru": {
    "theme": "Тема мероприятия",
    "fio": "ФИО",
    "invalid_email": "Некорректный e-mail",
    "phone": {
      "input": "Телефон",
      "length_left": "Телефон должен содержать минимум",
      "length_right": "символов"
    },
    "comment": "Комментарий",
    "send": "Отправить",
    "success_left": "Спасибо! Ваш запрос отправлен.",
    "success_right": "При необходимости менеджер свяжется с Вами.",
    "close_window": "Закрыть окно"
  }
}
</i18n>
