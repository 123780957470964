<template>
  <div :class="wrapperClass">
    <TheMask
      :id="id"
      ref="phone"
      :mask="mask"
      :value="value"
      :tokens="plusTokens"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="inputClass"
      masked
      type="tel"
      @input="onInput"
      @focus.native="onFocus"
      @blur.native="onBlur"
    />
    <CountryFlag
      v-if="country && showFlag"
      :country="country"
      :class="flagClass"
      size="normal"
    />
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CountryFlag from "vue-country-flag";
import { allCountries } from "country-telephone-data";

export default {
  name: "InternationalPhoneMask",
  components: {
    TheMask,
    CountryFlag
  },
  props: {
    id: { type: String, default: "" },
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    showFlag: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    flagClass: { type: String, default: "form-input__flag" },
    inputClass: { type: String, default: "form-input__input" },
    wrapperClass: { type: String, default: "form-input__phone-wrapper" }
  },
  data() {
    return {
      countryCode: "",
      mask: "*############",
      defaultMask: "*############",
      country: "",
      isValid: false,
      plusTokens: {
        "#": {
          pattern: /\d/
        },
        "*": {
          pattern: /\d|\+/
        }
      }
    };
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.updateMaskData(newValue);

          this.$nextTick(() => {
            setTimeout(this.setFocusToEnd.bind(this), 0);
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    onFocus(value) {
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.$emit("blur", value);
    },
    setFocusToEnd() {
      if (!this.$refs.phone) return;

      const length = this.$refs.phone.$el.value.length;
      this.$refs.phone.$el.setSelectionRange(length, length);
    },
    updateMaskData(value) {
      let phoneInfo = parsePhoneNumberFromString(value);

      if (phoneInfo) {
        if (!phoneInfo.country) {
          switch (phoneInfo.countryCallingCode) {
            case "44": {
              phoneInfo = { country: "GB" };
              break;
            }
            case "1": {
              if (value.length > 4) phoneInfo = { country: "US" };
              break;
            }
          }
        }
      }

      const computedMask = this.getMaskByNumber(phoneInfo);
      const computedCountry =
        phoneInfo && phoneInfo.country ? phoneInfo.country.toLowerCase() : "";

      if (computedMask && computedMask.mask) {
        this.mask = computedMask.mask;
        this.country = computedCountry;
      } else {
        this.mask = this.defaultMask;
        this.country = "";
      }
    },
    addCountryCodeToMask(mask, code) {
      const codeLength = code.length;
      let temporyMask = mask;

      for (let i = 0; i < codeLength; i++)
        temporyMask = temporyMask.replace(/#/, code[i]);

      return temporyMask;
    },
    getMaskByNumber(phoneInfo) {
      const country = phoneInfo ? phoneInfo.country : "";

      if (!country) return null;

      const filteredCountries = allCountries.filter(countryObj => {
        return countryObj.iso2.toUpperCase() === country.toUpperCase();
      });

      if (!filteredCountries[0]) return null;

      const mask = filteredCountries[0].format
        ? filteredCountries[0].format.replace(/\./g, "#")
        : null;

      return {
        mask: this.addCountryCodeToMask(mask, filteredCountries[0].dialCode),
        countryCode: `+${filteredCountries[0].dialCode}`
      };
    }
  }
};
</script>
