<template>
  <div class="application-final">
    <div class="application-panels">
      <div class="application-panels__column">
        <div class="application-panel">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="application-panel__title"
            v-html="$t('accession.title')"
          />
          <div class="application-panel__text" v-html="$t('accession.text')" />
          <!-- eslint-enable -->
          <div class="application-panel__buttons">
            <a
              v-if="paymentData && paymentData.programConnectionLink"
              class="button button_base"
              target="_blank"
              :href="paymentData.programConnectionLink"
            >
              {{ $t("download") }}
            </a>
          </div>
        </div>
      </div>
      <div class="application-panels__column">
        <div class="application-panel">
          <div class="application-panel__title">
            Счет на оплату
          </div>
          <div class="application-panel__text">
            <a href="#" @click.prevent="showInvoice">Посмотреть</a> счет
          </div>
          <div class="application-panel__buttons">
            <button
              class="button button_base"
              type="button"
              @click.prevent="downloadInvoice"
            >
              {{ $t("download") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationFinal",
  props: {
    paymentData: { type: Object, default: () => {} },
    initPersonType: { type: String, default: "" }
  },
  data() {
    return {
      personType: this.initPersonType
    };
  },
  methods: {
    showInvoice() {
      this.$emit("onShowInvoice");
    },
    downloadInvoice() {
      this.$emit("onDownloadInvoice");
    }
  }
};
</script>

<i18n>
{
  "en": {
    "download": "Dowload",
    "accession": {
      "title": "Application for&nbsp;accession",
      "text": "To&nbsp;confirm the&nbsp;fact of&nbsp;joining the&nbsp;program, download and send a&nbsp;scan of&nbsp;the&nbsp;completed application&nbsp;to: <a href='mailto:requisitions@sberuniversity.ru'>requisitions@sberuniversity.ru</a>"
    }
  },
  "ru": {
    "download": "Скачать",
    "accession": {
      "title": "Заявление о&nbsp;присоединении",
      "text": "Для подтверждения факта присоединения к&nbsp;программе скачайте и&nbsp;пришлите скан заполненного заявления по&nbsp;адресу: <a href='mailto:requisitions@sberuniversity.ru'>requisitions@sberuniversity.ru</a>"
    }
  }
}
</i18n>
