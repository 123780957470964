<template>
  <div class="activity-log">
    <div class="activity-log-list">
      <div
        v-for="(item, key) in itemsLocal"
        :key="key"
        class="activity-log-item"
        :class="{ 'activity-log-item_payment': item.paymentBill }"
      >
        <div class="activity-log-item__row">
          <div class="activity-log-item__info">
            <div class="activity-log-item__title">
              {{ item.title }}
            </div>
            <div class="activity-log-item__subtitle">
              {{ item.subtitle }}
            </div>
            <div class="activity-log-item__date">
              {{ item.datetime }}
            </div>
          </div>
          <div class="activity-log-item__media">
            <a
              v-if="item.image"
              :href="item.link"
              target="_blank"
              class="activity-log-item__picture"
            >
              <img :src="item.image" :alt="item.name" />
            </a>
          </div>
          <div class="activity-log-item__content">
            <a
              :href="item.link"
              target="_blank"
              class="activity-log-item__name"
            >
              {{ item.name }}
            </a>
            <a
              v-if="item.school && item.school_stream_id"
              :href="
                `/bitrix/services/main/ajax.php?mode=class&c=dalee:virtual.school&action=jwt&stream_id=` +
                  item.school_stream_id
              "
              target="_blank"
              class="activity-log-item__link"
            >
              {{ $t("link_to_school") }}
            </a>
          </div>
          <div v-if="item.paymentId" class="activity-log-item__payment-info">
            <div class="activity-log-item__status">
              <div class="activity-log-item__status-title">
                {{ $t("status") }}
              </div>
              <div
                class="activity-log-item__status-value"
                :class="item.paymentClass"
              >
                {{ item.paymentStatus }}
              </div>
            </div>
            <div class="activity-log-item__docs">
              <ul class="activity-log-item__docs-list">
                <li v-if="item.paymentBill">
                  <a
                    href="#"
                    @click.prevent="
                      openInvoice(
                        item.paymentId,
                        item.personType,
                        item.sectionCode,
                        getOfferLink(item)
                      )
                    "
                  >
                    {{ $t("view_invoice") }}
                  </a>
                </li>
                <li>
                  <a :href="getOfferLink(item)" target="_blank">
                    {{ $t("view_offer") }}
                  </a>
                </li>
                <li v-if="item.programConnectionLink">
                  <a
                    :href="item.programConnectionLink"
                    target="_blank"
                    @click="showAccessionPopup"
                  >
                    {{ $t("download_accession") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <not-found v-if="!itemsLocal.length" :text="$t('no_activities')" />
    </div>
    <arrow-more-link
      v-show="paginatorLocal.next !== 1 && itemsLocal.length"
      :value="$t('show_more')"
      @load-more="loadMore"
    />
    <application-invoice
      :person-type="personType"
      :payment-data="paymentData"
      :element-code="elementCode"
      :offer-link-individual="offerLinkIndividual"
      :offer-link-legal="offerLinkLegal"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserLog",
  props: {
    items: { type: Array, default: () => [] },
    paginator: { type: Object, default: () => {} },
    sess: { type: String, default: "" },
    signedParameters: { type: String, default: "" }
  },
  data() {
    return {
      itemsLocal: [],
      paginatorLocal: [],
      paymentData: null,
      personType: "individual",
      elementCode: "",
      offerLinkIndividual: "",
      offerLinkLegal: ""
    };
  },
  mounted() {
    this.itemsLocal = this.items.filter(item => {
      return !item.disabled;
    });
    this.paginatorLocal = this.paginator;
  },
  methods: {
    getOfferLink(item) {
      let personType =
        item.personType && personType === "entity" ? "entity" : "individual";
      let sectionCode = item.sectionCode ? item.sectionCode : "programs";

      return this.$t("agreement_link." + sectionCode + "." + personType);
    },
    showAccessionPopup() {
      this.$modal.show("globalDynamicPopup", {
        title: this.$t("accession.title"),
        titleClass: "notify-popup__title_medium",
        text: this.$t("accession.text")
      });
    },
    async loadMore() {
      const options = {
        url: `${this.$t(
          "api_link"
        )}bitrix/services/main/ajax.php?mode=class&c=dalee:user.log&action=log&user-log=page-${
          this.paginatorLocal.next
        }`,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: `sessid=${this.sess}&signedParameters=${this.signedParameters}`
      };

      const response = await axios(options);

      if (response.data.data) {
        let newItems = response.data.data.items.filter(item => {
          return !item.disabled;
        });
        this.itemsLocal = [...this.itemsLocal, ...newItems];
        this.paginatorLocal = response.data.data.paginator;
      }
    },
    async openInvoice(paymentId, personType, elementCode, link) {
      this.personType = personType;
      this.elementCode = elementCode;

      const formData = new FormData();
      formData.append("paymentId", paymentId);

      const options = {
        url: `${this.$t(
          "api_link"
        )}bitrix/services/main/ajax.php?mode=class&c=dalee:sber.acquiring&action=getBillInfo`,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: formData
      };

      const { data } = await axios(options);

      switch (data.status) {
        case "success":
          if (!data.data) return;

          this.paymentData = data.data;
          this.paymentData.documentLink = link;

          if (this.paymentData.links.individual !== "") {
            this.offerLinkIndividual = this.paymentData.links.individual;
          }

          if (this.paymentData.links.entity !== "") {
            this.offerLinkLegal = this.paymentData.links.entity;
          }

          this.$nextTick(() => {
            this.$modal.show("viewInvoiceModal");
          });

          break;

        case "error":
          this.$nextTick(() => {
            this.$modal.show("globalDynamicPopup", {
              icon: "error",
              title: this.$t("errors.invoice.notfound_title"),
              text: data.errors[0].message
            });
          });

          break;
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "no_activities": "No activity found",
    "show_more": "Show more",
    "link_to_school": "Go to training",
    "status": "Status:",
    "view_invoice": "View invoice",
    "view_offer": "View offer",
    "download_accession": "Download the&nbsp;application",
    "accession": {
      "title": "Application for&nbsp;accession",
      "text": "To&nbsp;confirm the&nbsp;fact of&nbsp;joining the&nbsp;program, download and send a&nbsp;scan of&nbsp;the&nbsp;completed application&nbsp;to: <a href='mailto:info@sberuniversity.ru'>info@sberuniversity.ru</a>"
    },
    "api_link": "/en/",
    "agreement_link": {
      "online": "/en/legal/contracts_individual/1/",
      "programs": {
        "individual": "/en/legal/contracts_individual/1/",
        "entity": "/en/legal/contracts_individual/2/"
      },
      "conferences": {
        "individual": "/en/legal/contracts_individual/3/",
        "entity": "/en/legal/contracts_individual/3/"
      },
      "courses": {
        "individual": "/en/legal/contracts_individual/4/",
        "entity": "/en/legal/contracts_individual/4/"
      },
      "events": {
        "individual": "/en/legal/contracts_individual/4/",
        "entity": "/en/legal/contracts_individual/4/"
      }
    },
    "errors": {
      "invoice":{
        "notfound_title": "Error"
      }
    }
  },
  "ru": {
    "no_activities": "Не найдено активности",
    "show_more": "Показать еще",
    "link_to_school": "Перейти к обучению",
    "status": "Статус:",
    "view_invoice": "Посмотреть счет",
    "view_offer": "Посмотреть договор",
    "download_accession": "Скачать заявление",
    "accession": {
      "title": "Заявление о&nbsp;присоединении",
      "text": "Для подтверждения факта присоединения к&nbsp;программе скачайте и&nbsp;пришлите скан заполненного заявления по&nbsp;адресу: <a href='mailto:requisitions@sberuniversity.ru'>requisitions@sberuniversity.ru</a>"
    },
    "api_link": "/",
    "agreement_link": {
      "online": "/legal/contracts_individual/1/",
      "programs": {
        "individual": "/legal/contracts_individual/1/",
        "entity": "/legal/contracts_individual/2/"
      },
      "conferences": {
        "individual": "/legal/contracts_individual/3/",
        "entity": "/legal/contracts_individual/3/"
      },
      "courses": {
        "individual": "/legal/contracts_individual/4/",
        "entity": "/legal/contracts_individual/4/"
      },
      "events": {
        "individual": "/legal/contracts_individual/4/",
        "entity": "/legal/contracts_individual/4/"
      }
    },
    "errors": {
      "invoice":{
        "notfound_title": "Ошибка"
      }
    }
  }
}
</i18n>
