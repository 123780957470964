<template>
  <div
    ref="dropdown"
    class="custom-select tabs-list__item tabs-list__item_dropdown"
    :class="[
      { 'custom-select_opened': isOpen },
      { 'custom-select_changed': isChanged },
      { 'custom-select_flat-input': isFlatInput },
      { 'custom-select_filled': isFilled },
      { 'custom-select_focused': isFocused }
    ]"
    tabindex="0"
    @click="open"
    @focus="onFocus"
    @blur="onBlur"
    @mouseleave="mouseleave"
    @mouseover="mouseover"
    @keydown.esc="close"
  >
    <div
      v-if="isFlatInput ? true : !selectedOption"
      class="custom-select__label"
    >
      {{ placeholder }}
    </div>
    <div v-if="isFilled" class="custom-select__text">
      {{ selectedOption }}
    </div>
    <div
      v-show="isChanged"
      class="custom-select__reset"
      @click.stop.prevent="reset"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" fill="none">
        <path d="M1 10L10 1M1 1L10 10" stroke="#33363A" />
      </svg>
    </div>
    <ul v-show="isOpen" class="tabs-dropdown">
      <li
        v-for="(option, key) in options"
        :key="key"
        class="custom-select__item"
        :class="[
          { _current: option[propNameValue] === value },
          { _selected: key === hoverIndex },
          { _picked: option.isPicked }
        ]"
        @click="changeValue(option)"
        @mouseover="hoverIndex = key"
      >
        {{ option[propNameText] }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    value: { type: [String, Object], default: "" },
    options: { type: Array, default: () => [] },
    placeholder: { type: String, default: "", required: true },
    isFlatInput: { type: Boolean, default: false },
    isReturnObject: { type: Boolean, default: false },
    propNameText: { type: String, default: "text" },
    propNameValue: { type: String, default: "value" }
  },
  data() {
    return {
      isOpen: false,
      hoverIndex: 0,
      isChanged: false,
      isFocused: false,
      current: null
    };
  },
  computed: {
    isFilled() {
      return this.value;
    },
    selectedOption() {
      if (!this.value) return "";

      let result;

      if (this.isReturnObject) {
        result = this.current;
      } else {
        result = this.options.find(
          option => option[this.propNameValue] === this.value
        );
      }

      return result[this.propNameText];
    }
  },
  watch: {
    value(val) {
      if (val.length) {
        this.isChanged = true;
      }
    }
  },
  created() {
    this.current = this.value;

    window.addEventListener("click", e => {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(e.target)) {
        this.close();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    changeValue(option) {
      this.current = option;
      this.$emit(
        "input",
        this.isReturnObject ? option : option[this.propNameValue]
      );
    },
    onFocus(event) {
      this.isFocused = true;

      if (event && event.target) {
        this.$emit("focus", event.target.value);
      } else {
        this.$emit("focus", event);
      }
    },
    onBlur(event) {
      this.isFocused = false;

      if (event && event.target) {
        this.$emit("blur", event.target.value);
      } else {
        this.$emit("blur", event);
      }
    },
    open() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    mouseover() {
      this.isOver = true;
    },
    mouseleave() {
      this.isOver = false;
      setTimeout(() => {
        if (!this.isOver) {
          this.isOpen = false;
        }
      }, 500);
    },
    reset() {
      this.$emit("input", "");
      this.current = null;
      this.isOpen = false;
      this.isChanged = false;
      this.isOver = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/base/_include.scss";
$b: ".custom-select";

#{$b} {
  position: relative;
  outline: none;

  &_changed {
    padding-right: 60px;
    background: #f7f7f7;

    &:before,
    &:after {
      display: none;
    }

    @include md-desktop-only {
      padding-right: 60px * $zoom;
    }
  }
  &_opened {
    .tabs-dropdown {
      z-index: 5;
    }
  }
  &_flat-input {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid $color-text-light-2;
    padding-left: 0;
    background: transparent;

    .tabs-dropdown {
      top: calc(100% + 15px);
    }
  }
  &__label {
    #{$b}_flat-input & {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $color-placeholder;
      line-height: 1.25;
      transition: 0.3s ease;
      transition-property: font-size, top, left, color;
    }

    #{$b}_flat-input#{$b}_filled & {
      top: 0;
      left: 0;
      font-size: 12px;
      z-index: 1;
    }
  }
  &__text {
    position: relative;
    z-index: 2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__reset {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;

    svg {
      width: 9px;
      height: 9px;

      path {
        fill: currentColor;
      }
    }

    @include md-desktop-only {
      width: 44px * $zoom;
      padding-right: 5px * $zoom;

      svg {
        width: 9px * $zoom;
        height: 9px * $zoom;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 24px;
      left: 0;
      background: $border-gray-lt;

      @include md-desktop-only {
        height: 24px * $zoom;
      }
    }
  }
  &__dropdown {
    z-index: 1;
    background: #fff;
    position: absolute;
    list-style-type: none;
    box-shadow: 0 0 15px 1px #0005;
    min-width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    @include md-desktop-only {
      box-shadow: 0 0 15px * $zoom 1px * $zoom #0005;
      border-radius: 0 0 4px * $zoom 4px * $zoom;
    }
  }
  &__item {
    line-height: 1.42;
  }
}
</style>
