<template>
  <section class="section section_alt">
    <div class="container">
      <div class="edu-main-title">
        <main-title
          class="main-title_sm-width"
          :value="$t('all_publications')"
        ></main-title>
      </div>
      <div class="tabs tabs_light">
        <div class="tabs-container">
          <div class="tabs-overflow">
            <ul class="tabs-list">
              <li
                :class="{
                  'tabs-list__item_active': sectionId === 0
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="switchSection(0)"
              >
                {{ $t("all") }}
              </li>
              <li
                v-for="(section, key) in sections"
                :key="key"
                :class="{
                  'tabs-list__item_active': sectionId === section.ID
                }"
                class="tabs-list__item tabs-list__item_mr20"
                @click="switchSection(section.ID)"
              >
                {{ section.NAME }}
              </li>
            </ul>
          </div>
          <sorting-dropdown
            v-model="sortBy"
            :options="sortFields"
          ></sorting-dropdown>
        </div>
      </div>
      <transition-group class="root-news" name="card-list" tag="div">
        <a
          v-for="item in activeItems"
          :key="item.ID"
          :href="item.DETAIL_PAGE_URL"
          class="root-news__item root-news__item_shadow"
          target="_blank"
        >
          <div
            class="root-news__img"
            :style="`background-image:url('${item.PREVIEW_PICTURE.SRC}');`"
            :title="item.PREVIEW_PICTURE.SRC"
          >
            <ul class="root-news__tags root-news__tags_right">
              <li v-for="(section, key2) in item.SECTIONS" :key="key2">
                <div
                  class="root-news__tag"
                  :style="{ 'background-color': section.UF_TAG_COLOR }"
                >
                  {{ section.NAME }}
                </div>
              </li>
            </ul>
          </div>
          <div class="root-news__title h3">
            {{ item.NAME }}
          </div>
          <div class="root-news__date para">
            {{ item.DISPLAY_ACTIVE_FROM.toLowerCase() }}
          </div>
        </a>
      </transition-group>
      <arrow-more-link
        v-show="nextUrl"
        :value="$t('show_more')"
        @load-more="loadMore"
      ></arrow-more-link>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PressCenter",
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => {}
    },
    paginator: {
      type: Object,
      default: () => {}
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initSectionId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sections: [],
      sectionId: 0,
      sortFields: [
        { name: this.$i18n.t("sorting.desc"), value: "desc" },
        { name: this.$i18n.t("sorting.asc"), value: "asc" }
      ],
      sortBy: "desc",
      moreBtn: null,
      activeItems: [],
      nextUrl: ""
    };
  },
  watch: {
    sortBy() {
      this.loadFirstPage();
    },
    sectionId() {
      this.loadFirstPage();
    }
  },
  mounted() {
    this.sectionId = this.initSectionId;
    this.sections = this.params.SECTIONS;
    this.activeItems = this.items;
    this.nextUrl = this.initNextUrl;
  },
  methods: {
    loadFirstPage() {
      const typesStr = "type=" + this.sectionId;
      this.nextUrl = `?ajax=Y&PAGEN_${this.paginator.NavNum}=1&sort=${this.sortBy}&${typesStr}`;
      this.activeItems = [];
      this.loadMore();
    },
    async loadMore() {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.activeItems = [...this.activeItems, ...res.data.ITEMS];
    },
    switchSection(id) {
      this.sectionId = id;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "all": "All",
    "all_publications": "All publications",
    "show_more": "Show more",
    "sorting": {
      "desc": "Latest first",
      "asc": "Oldest first"
    }
  },
  "ru": {
    "all": "Все",
    "all_publications": "Все публикации",
    "show_more": "Показать еще",
    "sorting": {
      "desc": "Сначала новые",
      "asc": "Сначала старые"
    }
  }
}
</i18n>
