<template>
  <div class="show-more-button">
    <span @click="showMore()">
      <span class="_g-hidden-mobile">{{ label }}</span>
      <span class="_g-visible-mobile-inherit">{{ labelMob }}</span>
      <svg
        class="show-more-button__arrow"
        width="13"
        height="19"
        viewBox="0 0 13 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8045 12.7944C13.0652 12.5355 13.0652 12.1047 12.8045 11.8368C12.5526 11.578 12.1334 11.578 11.8821 11.8368L7.15757 16.6921L7.15757 0.67034C7.15699 0.296856 6.86812 -2.68033e-07 6.5047 -2.83919e-07C6.14127 -2.99805e-07 5.84301 0.296856 5.84301 0.670339L5.84301 16.6921L1.12727 11.8368C0.866587 11.578 0.446797 11.578 0.195509 11.8368C-0.0651708 12.1047 -0.0651708 12.5361 0.195509 12.7944L6.03852 18.7991C6.2904 19.067 6.7096 19.067 6.96089 18.7991L12.8045 12.7944Z"
          fill="#131313"
        />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: "ShowMoreButton",
  props: {
    label: {
      type: String,
      default: "показать еще"
    },
    labelMob: {
      type: String,
      default: "показать еще"
    }
  },
  methods: {
    showMore() {
      this.$emit("showMore");
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";
.show-more-button {
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 60px 0 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: $border-gray-lt;
  }

  @include md-desktop-only {
    margin: 60px * $zoom 0 0;
    font-size: 16px * $zoom;
  }

  &__arrow {
    margin-left: 13px;
    display: inline-block;
    vertical-align: middle;

    @include md-desktop-only {
      margin-left: 13px * $zoom;
    }
  }

  > span {
    cursor: pointer;
    display: inline-block;
    padding: 0 24px;
    background-color: $white-true;

    @include md-desktop-only {
      padding: 0 24px * $zoom;
    }

    ._g-bg-gray & {
      background-color: $drop-hover;
    }
  }
}
</style>
