<template>
  <div
    class="file-uploader"
    :class="{
      'is-simple-icon': isSimpleIcon,
      'is-noconfirmed': !isFileConfirmed && isFileOnConfirmation,
      'is-confirmed': isFileConfirmed && isFileOnConfirmation
    }"
  >
    <div class="file-uploader__upload-block">
      <FileUpload
        ref="upload"
        v-model="files"
        class="file-uploader__item"
        :input-id="id"
        :post-action="action"
        :accept="accept"
        :extensions="extensions"
        :multiple="multiple"
        :size="1024 * 1024 * size"
        @input-filter="inputFilter"
        @input-file="inputFile"
      >
        <div class="file-uploader__item-content">
          <!-- Если показываем статус утверждения файла -->
          <template v-if="isShowConfirmStatus && isFileOnConfirmation">
            <div class="file-uploader__item-icon">
              <svg viewBox="0 0 22 30" xml:space="preserve">
                <path
                  d="M20.2 30H1.8c-1 0-1.8-.8-1.8-1.9V1.9C0 .8.8 0 1.8 0h12.8l5.4.1c1.1 0 2 .9 2 2v26.1c0 1-.8 1.8-1.8 1.8zM14.7 1.4H1.8c-.2 0-.4.2-.4.5v26.3c0 .3.2.5.4.5h18.3c.2 0 .4-.2.4-.5V2.1c0-.3-.3-.6-.6-.6l-5.2-.1z"
                />
                <path
                  d="M4 16.6h14V18H4zM4 20.6h9V22H4zM14 12.6h4V14h-4zM12 14H4V6h8v8zm-6.6-1.4h5.2V7.4H5.4v5.2z"
                />
              </svg>
            </div>
          </template>
          <!-- Иначе показываем иконку по умолчанию -->
          <template v-else>
            <div class="file-uploader__item-icon">
              <svg viewBox="0 0 14 17">
                <path d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z" />
              </svg>
            </div>
          </template>
          <!-- eslint-disable vue/no-v-html -->
          <div class="file-uploader__item-body">
            <div class="file-uploader__item-title" v-html="title"></div>
            <div
              v-if="description"
              class="file-uploader__item-desc"
              v-html="description"
            ></div>
          </div>
          <!--eslint-enable-->
        </div>
      </FileUpload>
      <div class="file-uploader__indent-left">
        <!-- Файлы -->
        <ul v-if="files.length" class="file-uploader__files">
          <li v-for="file in files" :key="file.id">
            <div class="file-uploader__file">
              <div class="file-uploader__file-info">
                <span class="file-uploader__file-name">{{ file.name }}</span>
                <span> - </span>
                <span>{{ getFormatSize(file.size) }}</span>
              </div>
              <div
                class="file-uploader__file-remove"
                @click.prevent="remove(file)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                  <path d="M.017 15.99L15.997.01l.99.99-15.98 15.98z" />
                  <path d="M.017 1l.99-.99 15.98 15.98-.99.99z" />
                </svg>
              </div>
              <div v-if="file.error" class="file-uploader__file-error">
                {{ file.error }}
              </div>
            </div>
          </li>
        </ul>
        <!-- Статус -->
        <div
          v-if="isShowConfirmStatus && isFileOnConfirmation"
          class="file-uploader__status"
        >
          <div class="file-uploader__status-label">
            <template v-if="isFileConfirmed">
              {{ $t("confirmed") }}
            </template>
            <template v-if="!isFileConfirmed">
              {{ $t("noconfirmed") }}
            </template>
          </div>
        </div>
        <!-- Ошибки -->
        <div v-if="error" class="file-uploader__errors">
          <div v-if="!isValidFileExtension" class="file-uploader__error">
            {{ $t("error.extension") }}
          </div>
        </div>
        <!-- Кнопка -->
        <div v-if="action && files.length" class="file-uploader__buttons">
          <button
            v-if="!$refs.upload || !$refs.upload.active"
            class="button button_base button_small"
            type="button"
            @click.prevent="$refs.upload.active = true"
          >
            {{ $t("submit_button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "vue-upload-component";
import axios from "axios";

export default {
  name: "FileUploader",
  components: {
    FileUpload
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: "image/png,image/gif,image/jpeg,image/webp"
    },
    extensions: {
      type: String,
      default: "gif,jpg,jpeg,png,webp"
    },
    size: {
      type: Number,
      default: 10 // in Mb
    },
    initFiles: {
      type: Array,
      default: () => []
    },
    fileUrl: {
      type: [String, File, Blob],
      default: ""
    },
    isSimpleIcon: {
      type: Boolean,
      default: false
    },
    isShowConfirmStatus: {
      type: Boolean,
      default: false
    },
    isFileConfirmed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: [],
      isExternalFile: false,
      isFileOnConfirmation: false,
      isValidFileExtension: false,
      error: false
    };
  },
  watch: {
    fileUrl() {
      this.updateFileByUrl();
    }
  },
  mounted() {
    if (this.initFiles && Array.isArray(this.initFiles)) {
      this.files = this.initFiles;
    }

    this.updateFileByUrl();
  },
  methods: {
    validateExtension(fileExtension) {
      if (fileExtension) {
        this.isValidFileExtension = this.extensions.split(",").some(item => {
          return fileExtension === item;
        });
      } else {
        this.isValidFileExtension = this.files.some(file => {
          const fileExtension = file.name.split(".").pop();

          return this.extensions.split(",").some(item => {
            return fileExtension === item;
          });
        });
      }

      if (!this.isValidFileExtension) {
        this.files = [];
      }

      this.error = !this.isValidFileExtension;
    },
    getFormatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.validateExtension();
        if (this.error) return;

        this.$emit("onAddFile", newFile, this.isExternalFile);
        // eslint-disable-next-line no-console
        console.log("Добавлено:", newFile);
      }
      if (newFile && oldFile) {
        this.$emit("onUpdateFile", newFile, this.isExternalFile);
        // eslint-disable-next-line no-console
        console.log("Обновлено:", newFile);
      }
      if (!newFile && oldFile) {
        this.$emit("onRemoveFile", oldFile, this.isExternalFile);
        // eslint-disable-next-line no-console
        console.log("Удалено:", oldFile);

        if (this.isExternalFile) {
          this.isExternalFile = false;
        }

        if (this.isFileOnConfirmation) {
          this.isFileOnConfirmation = false;
        }
      }
    },
    remove(file) {
      this.$refs.upload.remove(file);
    },
    getFileName(url) {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    updateFileByUrl() {
      if (typeof this.fileUrl === "string") {
        if (this.fileUrl) {
          this.getFileByUrl(this.fileUrl);
        } else {
          this.$refs.upload.clear();
        }
      }
    },
    async getFileByUrl(blobUrl) {
      const options = {
        url: blobUrl,
        method: "GET",
        responseType: "blob",
        validateStatus: false
      };

      const response = await axios(options);
      if (response.status === 200) {
        const fileName = this.getFileName(blobUrl);

        if (!fileName) return;

        const fileExtension = fileName.split(".").pop();

        this.validateExtension(fileExtension);

        if (this.error) return;

        let file;

        // For IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let blob = response.data;
          blob["name"] = fileName;
          blob["lastModifiedDate"] = new Date();

          file = Object.assign(blob, {
            lastModified: Date.now()
          });
        } else {
          file = new File([response.data], fileName);
        }

        this.isExternalFile = true;
        this.$refs.upload.add(file);
        this.isFileOnConfirmation = true;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".file-uploader";

#{$b} {
  color: inherit;

  .file-uploads {
    text-align: inherit;

    label {
      cursor: pointer;
    }
  }

  &__item {
    display: block;

    &-content {
      display: flex;
      align-items: center;
    }

    &-icon {
      flex-shrink: 0;
      align-self: baseline;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 45px;
      border: 1px solid currentColor;
      border-radius: 4px;
      margin-right: 18px;

      @include md-desktop-only {
        width: 48px * $zoom;
        height: 45px * $zoom;
        border-radius: 4px * $zoom;
        margin-right: 18px * $zoom;
      }

      @include low-desktop {
        width: 40px;
        height: 38px;
        margin-right: 12px;
      }

      #{$b}.is-simple-icon & {
        width: 14px;
        height: 22px;
        border-radius: 0;
        border: 0;
        margin-right: 13px;

        @include md-desktop-only {
          width: 14px * $zoom;
          height: 22px * $zoom;
          margin-right: 13px * $zoom;
        }
      }

      #{$b}.is-noconfirmed &,
      #{$b}.is-confirmed & {
        border-color: transparent;
      }

      #{$b}.is-noconfirmed & {
        color: #f2994a;
      }

      #{$b}.is-confirmed & {
        color: $color-base;
      }

      svg {
        flex-shrink: 0;
        display: block;
        width: 14px;
        height: 17px;

        @include md-desktop-only {
          width: 14px * $zoom;
          height: 17px * $zoom;
        }

        @include low-desktop {
          width: 12px;
          height: 14px;
        }

        #{$b}.is-noconfirmed &,
        #{$b}.is-confirmed & {
          width: 22px;
          height: 30px;

          @include md-desktop-only {
            width: 22px * $zoom;
            height: 30px * $zoom;
          }
        }

        path {
          fill: currentColor;
        }
      }
    }

    &-title {
      display: block;
      font-size: 16px;
      line-height: 22px;

      @include md-desktop-only {
        font-size: 16px * $zoom;
        line-height: 22px * $zoom;
      }

      @include low-desktop {
        font-size: 14px;
        line-height: 18px;
      }

      &:not(:last-child) {
        margin-bottom: 2px;

        @include md-desktop-only {
          margin-bottom: 2px * $zoom;
        }
      }
    }

    &-desc {
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: $color-text-light-2;

      @include md-desktop-only {
        font-size: 14px * $zoom;
        line-height: 20px * $zoom;
      }

      @include low-desktop {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__indent-left {
    padding-left: 66px;

    @include md-desktop-only {
      padding-left: 66px * $zoom;
    }

    @include low-desktop {
      padding-left: 52px;
    }

    #{$b}.is-simple-icon & {
      padding-left: 27px;

      @include md-desktop-only {
        padding-left: 27px * $zoom;
      }
    }
  }

  &__files {
    list-style: none;
    margin: 0;
    padding: 0;

    &:not(:first-child) {
      margin-top: 12px;

      @include md-desktop-only {
        margin-top: 12px * $zoom;
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;

      @include md-desktop-only {
        margin-bottom: 12px * $zoom;
      }
    }

    & > li {
      &:not(:last-child) {
        margin-bottom: 10px;

        @include md-desktop-only {
          margin-bottom: 10px * $zoom;
        }
      }
    }
  }

  &__file {
    display: inline-block;
    position: relative;
    padding-right: 36px;
    font-size: 14px;
    color: $color-text-dark-2;

    @include md-desktop-only {
      padding-right: 20px * $zoom;
      font-size: 14px * $zoom;
    }

    &-info {
      line-height: 20px;

      @include md-desktop-only {
        line-height: 20px * $zoom;
      }
    }

    &-name {
      display: inline-block;
      vertical-align: bottom;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;

      @include md-desktop-only {
        max-width: 200px * $zoom;
      }
    }

    &-remove {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include md-desktop-only {
        width: 20px * $zoom;
        height: 20px * $zoom;
      }

      svg {
        flex-shrink: 0;
        width: 12px;
        height: 12px;

        @include md-desktop-only {
          width: 12px * $zoom;
          height: 12px * $zoom;
        }

        path {
          fill: currentColor;
        }
      }
    }

    &-error {
      color: $color-error;
      font-size: 12px;
      line-height: 1.2;

      @include md-desktop-only {
        font-size: 12px * $zoom;
      }

      &:not(:first-child) {
        margin-top: 2px;

        @include md-desktop-only {
          margin-top: 2px * $zoom;
        }
      }
    }
  }

  &__status {
    &:not(:first-child) {
      margin-top: 12px;

      @include md-desktop-only {
        margin-top: 12px * $zoom;
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;

      @include md-desktop-only {
        margin-bottom: 12px * $zoom;
      }
    }
  }

  &__status-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 115px;
    height: 28px;
    padding: 0 17px;
    border-radius: 4px;
    background-color: $color-text-light-2;
    color: $white-true;
    font-size: 12px;
    line-height: 1;

    @include md-desktop-only {
      min-width: 115px * $zoom;
      height: 28px * $zoom;
      padding: 0 17px * $zoom;
      border-radius: 4px * $zoom;
      font-size: 12px * $zoom;
    }

    #{$b}.is-noconfirmed & {
      background-color: #f2994a;
      color: $white-true;
    }

    #{$b}.is-confirmed & {
      background-color: $color-base;
      color: $white-true;
    }
  }

  &__errors {
    &:not(:first-child) {
      margin-top: 12px;

      @include md-desktop-only {
        margin-top: 12px * $zoom;
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;

      @include md-desktop-only {
        margin-bottom: 12px * $zoom;
      }
    }
  }

  &__error {
    display: block;
    color: $color-error;
    font-size: 12px;
    line-height: 1.2;

    @include md-desktop-only {
      font-size: 12px * $zoom;
    }

    &:not(:last-child) {
      margin-bottom: 6px;

      @include md-desktop-only {
        margin-bottom: 6px * $zoom;
      }
    }
  }

  &__buttons {
    &:not(:first-child) {
      margin-top: 16px;

      @include md-desktop-only {
        margin-top: 16px * $zoom;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "submit_button": "Upload",
    "confirmed": "Checked",
    "noconfirmed": "On check",
    "error": {
      "extension": "Invalid file extension"
    }
  },
  "ru": {
    "submit_button": "Загрузить",
    "confirmed": "Проверено",
    "noconfirmed": "На проверке",
    "error": {
      "extension": "Неверное расширение файла"
    }
  }
}
</i18n>
