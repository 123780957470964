<template>
  <div class="arrow-more-link" @click="$emit('load-more')">
    <span class="arrow-more-link__item">
      <span class="arrow-more-link__text">{{ value }}</span>
      <span class="arrow-more-link__icon">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 19">
          <path
            d="M12.805 12.794a.684.684 0 000-.957.645.645 0 00-.923 0l-4.724 4.855V.67C7.157.297 6.868 0 6.505 0a.665.665 0 00-.662.67v16.022l-4.716-4.855a.656.656 0 00-.931 0c-.261.268-.261.7 0 .957L6.039 18.8a.63.63 0 00.922 0l5.844-6.005z"
            fill="#000"
          />
        </svg>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "ArrowMoreLink",
  props: {
    value: { type: String, default: "" }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".arrow-more-link";

#{$b} {
  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 4px;
  cursor: pointer;
  color: $black-true;

  @include md-desktop-only {
    padding-bottom: 4px * $zoom;
  }
  &:before,
  &:after {
    content: "";
    width: auto;
    height: 1px;
    background-color: #e0e0e0;
    flex: 1 1 auto;
  }
  &_light {
    color: $white-true;
  }
  &_nolines {
    &:before,
    &:after {
      display: none;
    }
    #{$b}__item {
      margin: 0;
      padding-bottom: 0;
    }
  }
  &__item {
    display: inline-block;
    position: relative;
    margin: 0 24px;
    padding-right: 26px;

    &:hover,
    &:focus {
      #{$b}__icon {
        top: calc(50% + 3px);
      }
    }
    @include md-desktop-only {
      margin: 0 24px * $zoom;
      padding-right: 26px * $zoom;

      &:hover,
      &:focus {
        #{$b}__icon {
          top: calc(50% + #{3px * $zoom});
        }
      }
    }
  }
  &__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.25;
    text-transform: uppercase;

    @include md-desktop-only {
      font-size: 16px * $zoom;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 13px;
    height: 19px;
    transition: top 0.3s;

    @include md-desktop-only {
      width: 13px * $zoom;
      height: 19px * $zoom;
    }
    svg {
      path {
        fill: currentColor;
      }
    }
  }
  &:active {
    color: #ccc;
  }
}
</style>
