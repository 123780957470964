<template>
  <div
    ref="dropdown"
    class="tabs-list__item tabs-list__item_dropdown"
    :class="[
      { 'tabs-list__item_upper': !isUpperSearch },
      { 'tabs-list__item_upper-search': isUpperSearch }
    ]"
    @mouseleave="mouseleave"
    @mouseover="mouseover"
    @click="dropdown"
  >
    {{ optionName }}
    <ul v-show="isOpen" class="tabs-dropdown">
      <li
        v-for="(option, key) in options"
        :key="key"
        :class="{ _current: option.value === value }"
        @click="changeValue(option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SortingDropdown",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: "desc"
    },
    isUpperSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mutableValue: this.value,
      isOpen: false
    };
  },
  computed: {
    optionName() {
      const option = this.options.filter(item => {
        return item.value === this.mutableValue;
      });
      return option[0].name;
    }
  },
  created() {
    window.addEventListener("click", e => {
      if (!this.$refs.dropdown.contains(e.target)) {
        this.close();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    changeValue(option) {
      this.mutableValue = option.value;
      this.$emit("input", option.value);
    },
    dropdown() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    mouseover() {
      this.isOver = true;
    },
    mouseleave() {
      this.isOver = false;
      setTimeout(() => {
        if (!this.isOver) {
          this.isOpen = false;
        }
      }, 500);
    }
  }
};
</script>
