<template>
  <li
    ref="dropdown"
    v-click-outside="closeDropdown"
    class="tabs-list__item tabs-list__item_mr20 tabs-list__item_mb20 tabs-list__item_dropdown"
    :class="{
      'tabs-list__item_active': isActive(section.dropdown, currentId),
      _open: isOpen
    }"
  >
    <span class="tabs-list__item_toggle" @click="isOpen = !isOpen">
      {{ section.name }}
    </span>
    <ul v-if="isOpen" class="tabs-dropdown">
      <li
        v-for="(item, i) in section.dropdown"
        :key="i"
        :class="{ _current: item.id === currentId }"
        @click="
          isLink(item) ? openLink(item.link) : selectFilter(item.id, item.hash)
        "
      >
        <span>
          {{ item.name }}
        </span>
        <svg
          v-if="isLink(item)"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            d="M11.5 9.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L18.3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-7.5 7.5c-.2.2-.4.3-.7.3z"
          />
          <path
            d="M19 7c-.6 0-1-.4-1-1V2h-4c-.6 0-1-.4-1-1s.4-1 1-1h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1zM17 20H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h5c.6 0 1 .4 1 1s-.4 1-1 1H3c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-5c0-.6.4-1 1-1s1 .4 1 1v5c0 1.7-1.3 3-3 3z"
          />
        </svg>
      </li>
    </ul>
  </li>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "LibraryListDropdown",
  directives: {
    ClickOutside
  },
  props: {
    section: {
      type: Object,
      default: () => {}
    },
    currentId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isOpen: false,
      hash: ""
    };
  },
  mounted() {
    this.hash = window.location.hash;
    this.checkHash(this.hash);
  },
  methods: {
    checkHash(hash) {
      if (hash.length > 0) {
        for (let section in this.section.dropdown) {
          if (hash == "#" + this.section.dropdown[section].hash) {
            this.selectFilter(this.section.dropdown[section].id);
            this.$nextTick(() => {
              this.$scrollTo(this.$refs.dropdown, 600, { offset: -250 });
            });
          }
        }
      }
    },
    isActive(items, currentId) {
      for (const key in items) {
        if (items[key].id === currentId) {
          return true;
        }
      }
    },
    isLink(item) {
      return Boolean(item.link);
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    closeDropdown() {
      this.isOpen = false;
    },
    selectFilter(id, hash) {
      this.$emit("filterChange", id, hash);
      this.isOpen = false;
    }
  }
};
</script>
