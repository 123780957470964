<template>
  <FixedHeader
    :threshold="100"
    :fixed-class="fixedClass"
    @change="updateFixedStatus"
  >
    <header
      id="header"
      class="header"
      :class="{ 'is-fixed': isFixed, 'header_search-opened': isSearchOpened }"
    >
      <slot />
    </header>
  </FixedHeader>
</template>

<script>
import { throttle } from "lodash";
import FixedHeader from "vue-fixed-header";
import onLoad from "../utils/onLoad";

export default {
  name: "MainHeader",
  components: {
    FixedHeader
  },
  data() {
    return {
      isFixed: false,
      fixedClass: "header_fixed",
      placeholder: null,
      isSearchOpened: false
    };
  },
  mounted() {
    this.$root.$on("headerSearchToggle", this.headerSearchToggle);
  },
  methods: {
    setPlaceholder() {
      if (document.getElementById("header-placeholder") === null) {
        this.placeholder = document.createElement("div");
        this.placeholder.id = "header-placeholder";

        document.body.insertBefore(this.placeholder, document.body.firstChild);
      }
    },
    setHeight() {
      if (document.getElementById("header-placeholder") != null) {
        document.getElementById("header-placeholder").style.height =
          this.$el.offsetHeight + "px";
      }
    },
    removeHeight() {
      if (document.getElementById("header-placeholder") != null) {
        document.getElementById("header-placeholder").style.height = "";
      }
    },
    updateFixedStatus(next) {
      this.isFixed = next;

      if (this.isFixed) {
        onLoad(() => {
          this.setPlaceholder();
          this.setHeight();
        });

        this.onResize = throttle(this.setHeight, 250);

        window.addEventListener("resize", this.onResize);
      } else {
        this.removeHeight();

        window.removeEventListener("resize", this.onResize);
      }
    },
    headerSearchToggle(value) {
      this.isSearchOpened = value;
    }
  }
};
</script>
