<template>
  <div
    class="event-card-speaker"
    :class="[{ 'event-card-speaker_clickable': isHasDetail }]"
    @click.prevent="openPopup"
  >
    <div
      class="event-card-speaker__photo"
      :style="`background-image: url('${speaker.photo}')`"
    />
    <div class="event-card-speaker__info">
      <div class="event-card-speaker__name">
        {{ speaker.name }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="event-card-speaker__post" v-html="$sanitize(speaker.post)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCardSpeaker",
  props: {
    speaker: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isHasDetail() {
      return Boolean(this.speaker?.quote_text);
    }
  },
  methods: {
    openPopup() {
      if (!this.isHasDetail) {
        return;
      }

      this.$root.$emit("open-speaker-popup", this.speaker);
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/base/_include.scss";

$b: ".event-card-speaker";

#{$b} {
  display: flex;
  transition: transform 0.3s ease-in;

  &_clickable {
    cursor: pointer;
  }

  .swiper-slide-prev > &,
  .swiper-slide-next > & {
    transition-timing-function: ease-in-out;
    transform: translate(-24px, -24px);

    @include md-desktop-only {
      transform: translate(-24px * $zoom, -24px * $zoom);
    }

    @include low-desktop {
      transform: translate(-16px, -16px);
    }
  }

  .swiper-slide-active > & {
    transform: translate(0px, 0px);

    #{$b}__info {
      opacity: 1;
    }
  }

  &__photo {
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    border-radius: 96px;
    margin-right: 16px;
    overflow: hidden;
    color: $color-text-dark;
    border: 2px solid $white-true;
    background-color: $white-true;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include md-desktop-only {
      border-width: 1px;
      @include zoom(width, 96px);
      @include zoom(height, 96px);
      @include zoom(border-radius, 96px);
      @include zoom(margin-right, 16px);
    }

    @include low-desktop {
      border-width: 1px;
      width: 78px;
      height: 78px;
      margin-right: 14px;
    }

    @include tablet {
      width: 66px;
      height: 66px;
      margin-right: 12px;
    }
  }

  &__info {
    opacity: 0;
    flex: 1 1 auto;
    align-self: center;
    transition: opacity 0.3s ease;
  }

  &__name {
    display: block;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.25;
    margin: 0;

    @include md-desktop-only {
      @include zoom(font-size, 20px);
    }

    @include low-desktop {
      font-size: 16px;
    }

    @include tablet {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;

      @include md-desktop-only {
        @include zoom(margin-bottom, 12px);
      }

      @include low-desktop {
        margin-bottom: 8px;
      }
    }
  }

  &__post {
    font-size: 16px;
    line-height: 1.5;

    @include md-desktop-only {
      @include zoom(font-size, 16px);
    }

    @include low-desktop {
      font-size: 13px;
    }

    @include tablet {
      font-size: 12px;
    }
  }
}
</style>
