<template>
  <div class="notify-popup__body">
    <div class="notify-popup__title _g-text-center">
      {{ $t("application_title") }}
    </div>

    <form
      v-if="!form.resend"
      class="form"
      :action="url"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <div class="form-row">
        <form-input
          id="program"
          v-model="form.programm"
          :label="$t('program') + ' *'"
          :error="$v.form.programm.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.programm.$error && !$v.form.programm.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="company"
          v-model="form.company"
          :label="$t('company_name')"
        />
      </div>
      <div class="form-row">
        <form-input
          id="fio"
          v-model="form.fio"
          :label="$t('fio') + ' *'"
          :error="$v.form.fio.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.fio.$error && !$v.form.fio.required
            }
          ]"
        />
      </div>
      <div class="form-row">
        <form-input
          id="email"
          v-model="form.email"
          :label="$t('form.email') + ' *'"
          type="email"
          :error="$v.form.email.$error || !$v.form.email.email"
          :messages="[
            {
              type: 'error',
              i18: 'error.email.invalid',
              state: !$v.form.email.email
            },
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.email.$error && !$v.form.email.required
            }
          ]"
          @blur="$v.form.email.$touch()"
        />
      </div>
      <div class="form-row">
        <form-input
          id="tel"
          v-model="form.phone"
          :label="$t('phone.input') + ' *'"
          type="tel"
          :error="$v.form.phone.$error"
          :messages="[
            {
              type: 'error',
              i18: 'error.required',
              state: $v.form.phone.$error && !$v.form.phone.required
            },
            {
              type: 'error',
              min: $v.form.phone.$params.minLength.min,
              state: !$v.form.phone.minLength
            }
          ]"
          @blur="$v.form.phone.$touch()"
        />
      </div>
      <div class="form-row">
        <label class="form-agree">
          <input
            v-model="form.agreement"
            class="form-agree__checkbox"
            :class="{
              'form-agree__checkbox--error': $v.form.agreement.$error
            }"
            type="checkbox"
            name="data-agreement"
            @change="$v.form.agreement.$touch()"
          />
          <span class="form-agree__text">
            {{ $t("agreement_left") }}
            <a
              href="/upload/iblock/999/09%20%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BF%D0%BE%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.docx"
            >
              {{ $t("agreement_right") }}
            </a>
          </span>
        </label>
        <div
          v-if="$v.form.agreement.$error"
          class="form-input-message is-error"
        >
          {{ $t("agreement_need") }}
        </div>
      </div>
      <div class="mt-14 _g-text-center">
        <button type="submit" class="button button_base">
          {{ $t("application_send") }}
        </button>
      </div>
    </form>

    <div v-else>
      <div class="notify-popup__text _g-text-center" style="max-width: 360px;">
        <p>
          {{ $t("success_left") }}
          <br />
          {{ $t("success_right") }}
        </p>
      </div>

      <div class="notify-popup__buttons">
        <a
          class="notify-popup__link"
          href="#"
          @click.prevent="$modal.hide('campusRegistrationPopup')"
        >
          {{ $t("success_close") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CampusRegistrationForm",
  props: {
    url: { type: String, required: true },
    programm: { type: String, required: true }
  },
  data() {
    return {
      form: {
        programm: "",
        company: "",
        fio: "",
        email: "",
        phone: "",
        agreement: false,
        resend: false
      }
    };
  },
  validations: {
    form: {
      programm: {
        required
      },
      fio: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minLength: minLength(10)
      },
      agreement: {
        required,
        sameAs: sameAs(() => true)
      }
    }
  },
  mounted() {
    this.form.programm = this.programm;
    this.fetchUserData();
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    async fetchUserData() {
      const response = await axios.post(this.url + "?action=init");
      if (response.data.success) {
        const user = response.data.data;
        this.form.company = user.company;
        this.form.fio = user.fio;
        this.form.email = user.email;
        this.form.phone = user.phone;
      }
    },
    async onSubmit() {
      if (this.validate()) {
        const response = await axios.post(
          this.url + "?action=register",
          this.form
        );
        if (response.data.success) {
          this.form.resend = true;
        }
      }
    },
    onReset(e) {
      e.preventDefault();
    }
  }
};
</script>

<i18n>
{
  "en": {
    "application_title": "I want to participate",
    "program": "Program *",
    "company_name": "Company name and your position",
    "fio": "Full name *",
    "phone": {
      "input": "Phone",
      "length_left": "Phone must contain at least",
      "length_right": "characters"
    },
    "agreement_left": "I agree to",
    "agreement_right": "personal data processing",
    "agreement_need": "You must agree to the terms and conditions",
    "application_send": "Book now",
    "success_left": "Thank you for your application!",
    "success_right": "Soon our manager will contact you and clarify the details of the booking.",
    "success_close": "Close the window"
  },
  "ru": {
    "application_title": "Хочу участвовать",
    "program": "Программа *",
    "company_name": "Название компании и ваша должность",
    "fio": "ФИО *",
    "phone": {
      "input": "Телефон",
      "length_left": "Телефон должен содержать минимум",
      "length_right": "символов"
    },
    "agreement_left": "Я согласен(а) на",
    "agreement_right": "обработку персональных данных",
    "agreement_need": "Необходимо согласиться с условиями",
    "application_send": "Забронировать",
    "success_left": "Благодарим за заявку!",
    "success_right": "Скоро с вами свяжется наш менеджер и уточнит детали бронирования.",
    "success_close": "Закрыть окно"
  }
}
</i18n>
