<template>
  <div>
    <div class="edu-search">
      <form
        class="search-field search-field_medium"
        @submit.prevent="throttledSubmit"
      >
        <div class="search-field__inner">
          <button class="search-field__button" type="submit">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
            >
              <path
                d="M17.78 16.722l-4.328-4.328A7.588 7.588 0 007.586 0a7.586 7.586 0 100 15.171 7.545 7.545 0 004.805-1.715l4.327 4.324a.75.75 0 101.062-1.058zm-10.194-3.06a6.084 6.084 0 01-6.08-6.076c0-3.35 2.726-6.08 6.08-6.08a6.09 6.09 0 016.08 6.08c0 3.35-2.73 6.076-6.08 6.076z"
                fill="#C4C7CC"
              />
            </svg>
          </button>
          <div class="search-field__input">
            <input
              :value="value"
              :placeholder="placeholderValue"
              @input="$emit('input', $event.target.value)"
              @keyup="throttledSubmit"
            />
          </div>
          <button
            v-if="value.length > 0"
            class="search-field__clear"
            type="submit"
            @click.prevent="clearField"
          >
            <svg
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM1.35355 11.3536L6.35355 6.35355L5.64645 5.64645L0.646447 10.6464L1.35355 11.3536ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355ZM5.64645 6.35355L10.6464 11.3536L11.3536 10.6464L6.35355 5.64645L5.64645 6.35355ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
                fill="#C4C4C4"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
    <div class="tabs tabs_light">
      <div class="tabs-overflow">
        <ul class="tabs-list">
          <li
            v-for="(label, key) in suggestList"
            :key="key"
            class="tabs-list__item tabs-list__item_mr20"
            @click="setInitValue(label)"
          >
            {{ label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from "lodash";
export default {
  name: "EdutechSearchForm",
  props: {
    value: { type: String, default: "" },
    suggestList: { type: Array, default: () => [] }
  },
  computed: {
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return this.$i18n.t("search_small");
      } else {
        return this.$i18n.t("search_full");
      }
    },
    throttledSubmit() {
      return throttle(this.submit, 1000);
    }
  },
  methods: {
    submit() {
      this.$emit("search");
    },
    setInitValue(label) {
      this.$emit("input", label);
      this.$emit("search");
    },
    clearField() {
      this.$emit("reset");
    }
  }
};
</script>

<i18n>
{
  "en": {
    "search_small": "Search",
    "search_full": "Search by events"
  },
  "ru": {
    "search_small": "Поиск",
    "search_full": "Поиск по мероприятиям, журналам и отчетам"
  }
}
</i18n>
