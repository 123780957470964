<template>
  <div class="avatar-upload">
    <label class="avatar-upload__content" for="avatarUpload">
      <div class="avatar-upload__media">
        <image-uploader
          :id="'avatarUpload'"
          :preview="true"
          :class-name="[
            'avatar-upload__input',
            { 'avatar-upload__input_loaded': hasImage }
          ]"
          :capture="false"
          :debug="0"
          :max-width="1024"
          :max-height="1024"
          :max-size="0.8"
          accept=".gif, .jpg, .png"
          do-not-resize="gif"
          :auto-rotate="true"
          output-format="file"
          @input="setImage"
        >
          <img
            v-show="!hasImage"
            slot="upload-label"
            class="img-preview--default"
            :src="photoUrl"
          />
        </image-uploader>
      </div>
      <div class="avatar-upload__body">
        <div class="avatar-upload__title">
          {{ uploadTitle }}
        </div>
        <div class="avatar-upload__format">JPG, GIF, PNG</div>
      </div>
    </label>
  </div>
</template>

<script>
import Vue from "vue";
import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);

export default {
  name: "AvatarUpload",
  props: {
    photo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hasImage: false
    };
  },
  computed: {
    uploadTitle() {
      return this.hasImage ? this.$i18n.t("change") : this.$i18n.t("choose");
    },
    photoUrl() {
      return this.photo.length > 0
        ? this.photo
        : "/local/templates/main/src/assets/img/lk/default-avatar.svg";
    }
  },
  methods: {
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
      this.$emit("updateAvatar", output);
    }
  }
};
</script>

<i18n>
{
  "en": {
    "change": "Change",
    "choose": "Select a file"
  },
  "ru": {
    "change": "Изменить",
    "choose": "Выберите файл"
  }
}
</i18n>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".avatar-upload";

#{$b} {
  &__input {
    display: none;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__media {
    cursor: pointer;
    overflow: hidden;
    border-radius: 100px;
    min-width: 72px;

    img {
      display: inline-block;
      vertical-align: middle;
      object-fit: cover;
      width: 72px;
      height: 72px;
    }
    @include md-desktop-only {
      border-radius: 100px * $zoom;
      min-width: 72px * $zoom;

      img {
        width: 72px * $zoom;
        height: 72px * $zoom;
      }
    }
  }
  &__body {
    cursor: pointer;
    padding-left: 20px;

    @include md-desktop-only {
      padding-left: 20px * $zoom;
    }
  }
  &__title {
    display: block;
    @include font(16px, 1.25);
    color: $color-bg-dark;
    margin-bottom: 4px;

    @include md-desktop-only {
      font-size: 16px * $zoom;
      margin-bottom: 4px * $zoom;
    }
  }
  &__format {
    display: block;
    @include font(14px, 1.29);
    color: $color-text-light-2;

    @include md-desktop-only {
      font-size: 14px * $zoom;
    }
  }
}
</style>
