<template>
  <div
    v-if="breakpoint.isTablet"
    ref="burger"
    class="burger-menu"
    :class="{ _opened: isOpen }"
  >
    <div class="burger-menu__block" @click="toggleOpen()">
      <div class="burger-menu__btn">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <transition name="fade">
      <nav v-if="isOpen" class="burger-menu__body">
        <div class="burger-menu__container">
          <div class="burger-menu__top">
            <a href="/" class="logo logo_burger">
              <img
                :src="headerLogoLight"
                alt="Корпоративный университет Сбербанка"
              />
            </a>
            <div class="burger-menu__top__row">
              <div class="burger-menu__top__left">
                <language-switch
                  :value="languageValue"
                  :options="languageOptions"
                  class="language-switch_light"
                ></language-switch>
                <telegram-link></telegram-link>
              </div>
              <div class="burger-menu__top__right">
                <header-search
                  is-burger-search
                  action="/search/"
                  input-name="q"
                  class="header-search_light mr-7"
                ></header-search>
                <account-menu
                  v-if="isAuthorize"
                  class="account-menu_light"
                ></account-menu>
                <div v-else class="auth-login">
                  <a
                    href="/lk/"
                    class="button button_light button_small"
                    v-text="enterText"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="burger-menu__content">
            <div class="burger-menu__menu">
              <ul class="burger-menu__list">
                <template v-for="(item, key) in items">
                  <li v-if="!item.hasOwnProperty('children')" :key="key">
                    <a
                      v-underconstruct
                      :href="item.LINK"
                      :class="item.PARAMS.CSS_CLASS"
                      v-text="item.TEXT"
                    />
                  </li>
                  <template v-else>
                    <li
                      :key="key"
                      :link="item.LINK"
                      :text="item.TEXT"
                      class="burger-menu-dropdown"
                      :class="{ _opened: opened == key }"
                    >
                      <div class="burger-menu-dropdown__current">
                        <a
                          v-if="opened == key"
                          :href="item.LINK"
                          v-text="item.TEXT"
                        ></a>
                        <span
                          v-else
                          @click.prevent="selectActive(key)"
                          v-text="item.TEXT"
                        ></span>
                      </div>
                      <div class="burger-menu-dropdown__list">
                        <ul class="burger-menu__sublist">
                          <li
                            v-for="(child, key2) in item.children"
                            :key="key2"
                          >
                            <a :href="child.LINK" v-text="child.TEXT" />
                          </li>
                        </ul>
                      </div>
                    </li>
                  </template>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",
  props: {
    isAuthorize: {
      default: false,
      type: Boolean
    },
    elements: {
      type: Object,
      default: () => {}
    },
    languageData: {
      type: Object,
      default: () => {}
    },
    headerLogoLight: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      opened: null,
      isOpen: false,
      languageOptions: [],
      languageValue: "",
      items: [],
      breakpoint: this.getBreakpoint(),
      enterText: this.$i18n.t("enter"),
      htmlBlock: undefined
    };
  },
  mounted() {
    this.items = this.elements;
    this.languageOptions = this.languageData.items;
    this.languageValue = this.languageData.current;
    window.addEventListener("resize", this.updateBreakpoint);
    this.htmlBlock = document.querySelector("html");
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    selectActive(selectedItem) {
      this.opened = selectedItem;
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
      this.$root.$emit("burgerMenuToggle", this.isOpen);

      if (this.isOpen) {
        this.htmlBlock.classList.add("g-body-scroll-lock");
      } else {
        this.htmlBlock.classList.remove("g-body-scroll-lock");
      }
    },
    closeMenu() {
      this.isOpen = false;
      this.$root.$emit("burgerMenuToggle", this.isOpen);
      this.htmlBlock.classList.remove("g-body-scroll-lock");
    },
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768,
        isTablet: innerWidth < 1024
      };
    },
    updateBreakpoint() {
      const { isMobile, isTablet } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
      this.breakpoint.isTablet = isTablet;

      if (!this.breakpoint.isTablet) {
        this.closeMenu();
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "enter": "Sign In"
  },
  "ru": {
    "enter": "Вход"
  }
}
</i18n>

<style lang="scss">
@import "local/templates/main/scss/base/includes";

$b: ".burger-menu";

#{$b} {
  color: $color-text-base;

  &._opened {
    color: $white-true;
  }

  @include tablet {
    margin-left: 40px;
  }

  ._g-body-light & {
    color: $white-true;
  }

  .header_fixed & {
    color: $color-text-base;

    &._opened {
      color: $white-true;
    }
  }

  &__block {
    min-width: 30px;
  }

  &__body {
    height: 100%;
    background: $color-bg-dark;
    color: $white-true;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &-dropdown {
    &__current {
      display: block;
      cursor: pointer;

      #{$b}-dropdown._opened & {
        text-decoration: underline;
      }

      & > a,
      & > span {
        &:after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          width: 9px;
          height: 6px;
          background-image: url("/local/templates/main/src/assets/img/arrow-down-light.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transition: 0.2s ease;
          transition-property: transform;

          #{$b}-dropdown._opened & {
            transform: rotatex(180deg);
          }

          @include md-desktop-only {
            margin-left: 6px * $zoom;
            width: 9px * $zoom;
            height: 6px * $zoom;
          }
        }
      }
    }
    &__list {
      display: none;
      margin: 10px -40px 10px;
      padding: 10px 40px;
      background: rgba($black-true, 0.9);
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(4px);

      @include md-desktop-only {
        margin: 10px * $zoom -40px * $zoom 10px * $zoom;
        padding: 10px * $zoom 40px * $zoom;
        box-shadow: 0px 2px * $zoom 16px * $zoom rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4px * $zoom);
      }
      @include mobile {
        margin: 10px -20px 10px;
        padding: 10px 20px;
      }
    }

    &._opened {
      #{$b}-dropdown__list {
        display: block;
      }
    }
  }

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;

    @include md-desktop-only {
      width: 30px * $zoom;
      height: 30px * $zoom;
    }
    & > span {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 20px;
      background-color: currentColor;

      &:nth-child(1) {
        top: 6px;
        transform-origin: 50% 50%;
        transition: top 0.3s 0.5s, transform 0.3s;
      }

      &:nth-child(2) {
        top: 15px;
        transition: background-color 0.3s;
      }

      &:nth-child(3) {
        top: 24px;
        transform-origin: 50% 50%;
        transition: top 0.3s 0.5s, transform 0.3s;
      }
      @include md-desktop-only {
        height: 2px * $zoom;
        border-radius: 20px * $zoom;

        &:nth-child(1) {
          top: 6px * $zoom;
        }
        &:nth-child(2) {
          top: 15px * $zoom;
        }
        &:nth-child(3) {
          top: 24px * $zoom;
        }
      }
    }
    #{$b}._opened & {
      position: absolute;
      top: 51px;
      right: 0;

      @include mobile {
        top: 36px;
      }

      & > span {
        &:nth-child(1) {
          top: 15px;
          transition: top 0.3s, transform 0.3s 0.5s;
          transform: rotate3d(0, 0, 1, -45deg);
        }
        &:nth-child(2) {
          background-color: transparent;
        }
        &:nth-child(3) {
          top: 15px;
          transition: top 0.3s, transform 0.3s 0.5s;
          transform: rotate3d(0, 0, 1, 45deg);
        }
        @include md-desktop-only {
          &:nth-child(1) {
            top: 15px * $zoom;
          }
          &:nth-child(3) {
            top: 15px * $zoom;
          }
        }
      }
    }
  }

  .language-switch,
  .auth-login,
  .header-search,
  .account-menu,
  .telegram-link {
    display: inline-block;
    color: $white-true;
  }

  .header_fixed & {
    .language-switch {
      &::after {
        background-image: url("/local/templates/main/src/assets/img/select-arrow-white.svg");
      }

      &__current {
        color: $white-true;
      }

      &__input {
        color: $white-true;
      }
    }

    .header-search {
      color: $white-true;
    }
  }

  .header_search-opened & {
    .language-switch {
      @include mobile {
        display: none;
      }
    }

    .auth-login,
    .account-menu {
      display: none;
    }
  }

  .header-search {
    &_opened {
      width: 100%;
      margin: 0;
    }
  }

  &__container {
    padding: 0 40px;

    @include md-desktop-only {
      padding: 0 40px * $zoom;
    }
    @include mobile {
      padding: 0 20px;
    }
  }

  &__top {
    padding: 32px 0 40px;
    border-bottom: 1px solid rgba($white-true, 0.15);

    @include md-desktop-only {
      padding: 32px * $zoom 0 40px * $zoom;
    }
    @include mobile {
      padding: 20px 0 40px;
    }

    &__row {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include md-desktop-only {
        margin-top: 40px * $zoom;
      }
    }

    &__left {
      display: flex;
      justify-content: center;
      align-items: center;

      & > div:not(:last-child) {
        margin-right: 40px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 48px;
      position: absolute;
      top: 42px;
      right: 110px;
      left: 220px;

      @include md-desktop-only {
        min-height: 48px * $zoom;
        top: 42px * $zoom;
        right: 113px * $zoom;
        left: 220px * $zoom;
      }
      @include mobile {
        position: static;
        left: auto;
        top: auto;
        right: auto;
        flex: 1 1 auto;
      }
    }
  }

  &__content {
    padding: 30px 0 80px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      a,
      span {
        display: block;
        padding: 10px 0;
        text-decoration: none;
        @include font(16px, 1.25, 400);
        color: $white-true;

        @include md-desktop-only {
          padding: 10px * $zoom 0;
          font-size: 16px * $zoom;
        }
      }
    }

    &__neon {
      margin-top: 30px;

      & > a,
      & > span {
        font-weight: 600;
        color: $white-true;
        text-shadow: 0px 0px 10px #2d3ed3;
      }
      @include md-desktop-only {
        margin-top: 30px * $zoom;

        & > a,
        & > span {
          text-shadow: 0px 0px 10px * $zoom #2d3ed3;
        }
      }
    }
  }

  &__sublist {
    & > li {
      & > a,
      & > span {
        color: #d7d7d7;
      }
    }
  }
}
</style>
