var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({ active: _vm.autoplayIfVisible, callback: _vm.onWaypoint }),expression:"{ active: autoplayIfVisible, callback: onWaypoint }"}],staticClass:"video-player",class:{
    _started: _vm.started,
    _error: _vm.isError,
    '_no-title': !_vm.title,
    'video-player_controls-hide':
      !_vm.controls && _vm.videoInfo.techOrder === 'html5',
    'video-player_vimeo': _vm.videoInfo.techOrder === 'vimeo'
  }},[_c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.playPause),expression:"playPause",arg:"tap"}],staticClass:"video-player__container",on:{"click":_vm.playPause}},[_c('video',{ref:"videoPlayer",staticClass:"video-js vjs-default-skin",class:_vm.aspect,attrs:{"id":_vm.id,"crossorigin":"anonymous"}}),(_vm.sourceText && _vm.started)?_c('div',{staticClass:"video-player__source-text"},[_vm._v(" "+_vm._s(_vm.sourceText)+" ")]):_vm._e(),(_vm.isShowButton && !_vm.started && !_vm.isError)?_c('div',{staticClass:"video-player__play"},[_c('svg',{attrs:{"fill":"none","viewBox":"0 0 72 72"}},[_c('circle',{attrs:{"cx":"36","cy":"36","r":"36","fill":"#18B346"}}),_c('path',{attrs:{"fill":"#fff","d":"M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"}})])]):_vm._e(),(_vm.title.length && !_vm.started && !_vm.isError)?_c('div',{staticClass:"video-player__bottom"},[_c('div',{staticClass:"video-player__bottom-icon"},[_c('svg',{attrs:{"fill":"none","viewBox":"0 0 72 72"}},[_c('circle',{attrs:{"cx":"36","cy":"36","r":"36","fill":"#18B346"}}),_c('path',{attrs:{"fill":"#fff","d":"M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"}})])]),_c('span',{staticClass:"video-player__bottom-text",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.videoLength)?_c('span',{staticClass:"video-player__video-length",domProps:{"textContent":_vm._s(_vm.videoLength)}}):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }