<template>
  <div class="collapse-text" :class="{ _shown: !collapsed }">
    <template v-if="collapsed">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="collapse-text__body"
        :class="collapseTextBodyClass"
        v-html="this.$options.filters.truncate(text, limit, '...')"
      />
    </template>
    <template v-else>
      <slot />
    </template>
    <a
      v-if="excess"
      href="#"
      :class="[moreClass, { _shown: !collapsed }]"
      @click.prevent="toggleCollapse()"
    >
      <template v-if="collapsed">
        {{ moreText }}
      </template>
      <template v-else>
        {{ lessText }}
      </template>
    </a>
  </div>
</template>

<script>
export default {
  name: "CollapseText",
  filters: {
    truncate: function(value, limit, clamp) {
      if (value.length > limit) {
        value = value.substring(0, limit) + clamp;
      }
      return value;
    }
  },
  props: {
    text: { type: String, default: "" },
    limit: { type: Number, default: 100 },
    moreText: { type: String, default: "Подробнее" },
    lessText: { type: String, default: "Свернуть" },
    moreClass: { type: String, default: "" },
    onTablet: { type: Boolean, default: false },
    collapseTextBodyClass: { type: String, default: "" }
  },
  data() {
    return {
      collapsed: true,
      excess: false,
      breakpoint: this.getBreakpoint()
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateBreakpoint);

    if (this.onTablet) {
      if (this.breakpoint.isTablet) {
        this.checkOverflow();
      } else {
        this.destroyOverflow();
      }
    } else {
      this.checkOverflow();
    }
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    checkOverflow() {
      if (this.text.length > this.limit) {
        this.excess = true;
      } else {
        this.excess = false;
      }
    },
    destroyOverflow() {
      this.collapsed = false;
      this.excess = false;
    },
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth <= 768,
        isTablet: innerWidth <= 1024
      };
    },
    updateBreakpoint() {
      const { isMobile, isTablet } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
      this.breakpoint.isTablet = isTablet;
    }
  }
};
</script>

<style lang="scss">
@import "local/templates/main/scss/base/includes";
$b: ".collapse-text";

#{$b} {
}
</style>
