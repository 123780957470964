<template>
  <div class="root-news-slider">
    <div class="root-news-slider__inner">
      <div class="root-news-slider__controls">
        <div ref="prevArrow" class="root-news-slider__prev">
          <svg
            width="38"
            height="26"
            viewBox="0 0 38 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5888 0.391022C25.0711 -0.130341 24.2095 -0.130341 23.6737 0.391022C23.156 0.89477 23.156 1.73318 23.6737 2.23575L33.3843 11.6849L1.34068 11.6849C0.593712 11.686 0 12.2638 0 12.9906C0 13.7175 0.593712 14.314 1.34068 14.314L33.3843 14.314L23.6737 23.7455C23.156 24.2668 23.156 25.1064 23.6737 25.609C24.2095 26.1303 25.0723 26.1303 25.5888 25.609L37.5982 13.923C38.1339 13.4192 38.1339 12.5808 37.5982 12.0782L25.5888 0.391022Z"
              fill="white"
            />
          </svg>
        </div>
        <div ref="nextArrow" class="root-news-slider__next">
          <svg
            width="38"
            height="26"
            viewBox="0 0 38 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5888 0.391022C25.0711 -0.130341 24.2095 -0.130341 23.6737 0.391022C23.156 0.89477 23.156 1.73318 23.6737 2.23575L33.3843 11.6849L1.34068 11.6849C0.593712 11.686 0 12.2638 0 12.9906C0 13.7175 0.593712 14.314 1.34068 14.314L33.3843 14.314L23.6737 23.7455C23.156 24.2668 23.156 25.1064 23.6737 25.609C24.2095 26.1303 25.0723 26.1303 25.5888 25.609L37.5982 13.923C38.1339 13.4192 38.1339 12.5808 37.5982 12.0782L25.5888 0.391022Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div ref="container" class="swiper-container">
        <div class="swiper-wrapper">
          <slot />
        </div>
      </div>
      <div ref="pagination" class="swiper-pagination _g-hidden-mobile"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper";
export default {
  name: "RootNewsSlider",
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    this.slider = new Swiper(this.$refs.container, {
      slidesPerView: "auto",
      spaceBetween: 32,
      observer: true,
      observeParents: true,
      slideActiveClass: "_active",
      autoplay: {
        delay: 5000
      },
      navigation: {
        prevEl: this.$refs.prevArrow,
        nextEl: this.$refs.nextArrow,
        disabledClass: "_disabled"
      },
      pagination: {
        el: this.$refs.pagination,
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".root-news-slider";

#{$b} {
  color: $color-bg-dark;

  &_pd {
    padding: 60px 0;

    @include md-desktop-only {
      padding: 60px * $zoom 0;
    }
  }

  &#{$b}_light {
    color: $white-true;

    .swiper-pagination-bullet {
      border-color: $border-gray;

      &:before {
        background-color: $white-true;
      }
    }
  }

  &__inner {
    position: relative;
    padding-bottom: 40px;
  }

  .container {
    box-sizing: border-box;
  }

  &__controls {
    position: absolute;
    z-index: 2;
    left: -70px;
    right: -70px;
    top: 104px;
    margin: 0 auto;

    @include md-desktop-only {
      left: -70px * $zoom;
      right: -70px * $zoom;
      top: 104px * $zoom;
    }

    @include low-desktop {
      left: -20px;
      right: -20px;
      top: 7.4vw;
    }

    @include tablet {
      top: 11vw;
    }

    @include mobile {
      left: -10px;
      right: -10px;
      top: calc((100vw - 50px) - 62vw);
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    position: absolute;
    width: 38px;
    height: 29px;
    transition: 0.3s;
    color: $color-bg-dark;

    @include md-desktop-only {
      width: 38px * $zoom;
      height: 29px * $zoom;
    }

    @include mobile {
      width: 32px;
      height: 20px;
    }

    &:hover {
      &:not(._disabled) {
        color: $color-base;
      }
    }

    #{$b}_light & {
      color: $white-true;
    }

    &._disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__prev {
    left: 0;
    transform: scaleX(-1);
  }

  &__next {
    right: 0;
  }

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 2;
    @include clickable;

    &-bullet {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin: 5px;
      border: 1px solid $color-bg-dark;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-bg-dark;
        transition: 0.3s;
      }

      &:only-child {
        display: none;
      }

      &-active {
        &:before {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
  .root-news__item {
    display: block;
    width: 100%;
    margin: 0;
  }
}
</style>
