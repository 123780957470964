<template>
  <div class="edu-cards">
    <div class="edu-cards__list">
      <card-item v-for="(item, key) in items" :key="key" :item="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EdutechIndexEvents",
  props: {
    items: { type: Array, default: () => [] }
  }
};
</script>
