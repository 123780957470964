<template>
  <div class="container">
    <form class="form mb-10">
      <div class="form-body form-body_width-large">
        <div class="form-grid form-grid_large">
          <div class="form-grid__col-lg-1-2">
            <div class="form-row">
              <form-input
                id="surname"
                v-model="form.surname"
                :label="$t('surname') + ' *'"
                is-capitalize
                :error="$v.form.surname.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.form.surname.$error && !$v.form.surname.required
                  }
                ]"
              />
            </div>
            <div class="form-row">
              <form-input
                id="name"
                v-model="form.name"
                :label="$t('name') + ' *'"
                is-capitalize
                :error="$v.form.name.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.form.name.$error && !$v.form.name.required
                  }
                ]"
              />
            </div>
            <div class="form-row">
              <form-input
                id="middle-name"
                v-model="form.middleName"
                :label="$t('middle_name')"
                is-capitalize
              />
            </div>
            <div class="form-row">
              <form-input
                id="password"
                v-model="form.password"
                :label="$t('password.input') + ' *'"
                autocomplete="off"
                type="password"
                :description="$t('password.text')"
                :error="$v.form.password.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.form.password.$error && !$v.form.password.required
                  },
                  {
                    type: 'error',
                    text: passwordDetail,
                    state:
                      $v.form.password.$error && !$v.form.password.goodPassword
                  }
                ]"
                @blur="$v.form.password.$touch()"
              />
            </div>
            <div class="form-row">
              <form-input
                id="repeat-password"
                v-model="form.confirmPassword"
                :label="$t('password.confirm') + ' *'"
                autocomplete="off"
                type="password"
                :error="$v.form.confirmPassword.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state:
                      $v.form.confirmPassword.$error &&
                      !$v.form.confirmPassword.required
                  },
                  {
                    type: 'error',
                    i18: 'error.password.mismatch',
                    state: !$v.form.confirmPassword.sameAsPassword
                  }
                ]"
                @blur="$v.form.confirmPassword.$touch()"
              />
            </div>
          </div>
          <div class="form-grid__col-lg-1-2">
            <div class="form-row">
              <form-input
                id="phone"
                v-model="form.phone"
                :label="$t('phone.input') + ' *'"
                type="tel"
                :error="$v.form.phone.$error"
                :messages="[
                  {
                    type: 'error',
                    min: $v.form.phone.$params.minLength.min,
                    state: !$v.form.phone.minLength
                  },
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.form.phone.$error && !$v.form.phone.required
                  },
                  {
                    type: 'error',
                    i18: 'error.phone.invalid',
                    state: errors.phoneInvalid
                  }
                ]"
                @blur="$v.form.phone.$touch()"
              />
            </div>
            <div class="form-row">
              <form-input
                id="email"
                v-model="form.email"
                label="E-mail *"
                type="email"
                :error="
                  $v.form.email.$error ||
                    !$v.form.email.email ||
                    errors.emailExist
                "
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.email.invalid',
                    state: !$v.form.email.email
                  },
                  {
                    type: 'error',
                    i18: 'error.required',
                    state: $v.form.email.$error && !$v.form.email.required
                  },
                  {
                    type: 'error',
                    text: $t('email_exist'),
                    state: errors.emailExist
                  }
                ]"
                @blur="$v.form.email.$touch()"
              />
            </div>
            <div class="form-row">
              <form-input
                v-if="!hideDadata"
                id="city"
                v-model="form.whereFrom"
                is-dadata
                dadata-type="address"
                :dadata-token="dadataApiKey"
                :label="$t('location') + ' *'"
                :error="$v.form.whereFrom.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state:
                      $v.form.whereFrom.$error && !$v.form.whereFrom.required
                  }
                ]"
              />
              <form-input
                v-else
                id="cityText"
                v-model="form.whereFrom"
                :label="$t('location') + ' *'"
                :error="$v.form.whereFrom.$error"
                :messages="[
                  {
                    type: 'error',
                    i18: 'error.required',
                    state:
                      $v.form.whereFrom.$error && !$v.form.whereFrom.required
                  }
                ]"
              />
            </div>
          </div>
        </div>
        <div class="form-grid form-grid_large">
          <div class="form-grid__col form-grid__col-lg-1-2">
            <label class="form-agree">
              <input
                v-model="form.agreementSite"
                class="form-agree__checkbox"
                :class="{
                  'form-agree__checkbox--error': $v.form.agreementSite.$error
                }"
                type="checkbox"
                name="data-agreement-site"
                @change="$v.form.agreementSite.$touch()"
              />
              <span class="form-agree__text">
                {{ $t("agreement_site_data.left") }}
                <a :href="$t('agreement_site_data.link')" target="_blank">
                  {{ $t("agreement_site_data.right") }}
                </a>
              </span>
            </label>
            <div
              v-if="$v.form.agreementSite.$error"
              class="form-input-message is-error form-agree-error-text"
            >
              {{ $t("agreement_site_data.need") }}
            </div>
            <label class="form-agree">
              <input
                v-model="form.agreement"
                class="form-agree__checkbox"
                :class="{
                  'form-agree__checkbox--error': $v.form.agreement.$error
                }"
                type="checkbox"
                name="data-agreement"
                @change="$v.form.agreement.$touch()"
              />
              <span class="form-agree__text">
                {{ $t("agreement_personal_data.left") }}
                <a :href="$t('agreement_personal_data.link')" target="_blank">
                  {{ $t("agreement_personal_data.right") }}
                </a>
              </span>
            </label>
            <div
              v-if="$v.form.agreement.$error"
              class="form-input-message is-error form-agree-error-text"
            >
              {{ $t("agreement_personal_data.need") }}
            </div>
            <div class="form__horizontal-items mt-5">
              <template v-if="reCaptchaUse === 'Y'">
                <InvisibleRecaptcha
                  id="do-something-btn"
                  :sitekey="reCaptchaKey"
                  :validate="reCaptchaLoaded"
                  :callback="reCaptchaHandler"
                  class="button button_base"
                  type="submit"
                  :disabled="
                    reCaptchaIsLoaded || !form.agreement || !form.agreementSite
                  "
                >
                  {{ $t("register") }}
                </InvisibleRecaptcha>
              </template>
              <template v-else>
                <button class="button button_base" type="submit">
                  {{ $t("register") }}
                </button>
              </template>
              <a class="form-link" :href="haveAccountUrl">
                {{ $t("have_account") }}
              </a>
            </div>
          </div>
          <div class="form-grid__col form-grid__col-lg-1-2">
            <slot name="social"></slot>
          </div>
        </div>
      </div>
    </form>

    <notify-popup popup="successRegPopup" @onClose="modalClosed">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#18B346" stroke-width="2" />
            <path
              stroke="#18B346"
              stroke-linecap="round"
              stroke-width="2"
              d="M14 17l2.625 3L21 14"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("registration.title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("email_instruction") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <a class="button button_dark" :href="continueUrl">
            {{ $t("continue") }}
          </a>
          <a
            v-if="showAgain"
            class="notify-popup__link notify-popup__link_light _g-text-left"
            href="#"
            @click.prevent="onSubmit('again')"
          >
            {{ $t("send") }}
            <br />
            {{ $t("replay") }}
          </a>
          <p v-else class="_g-text-left">
            {{ $t("again_send") }}
            <br />
            {{ $t("again_replay") }}
          </p>
        </div>
      </div>
    </notify-popup>

    <notify-popup popup="errorRegPopup">
      <div class="notify-popup__body _g-text-center">
        <div class="notify-popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <circle cx="17" cy="17" r="16" stroke="#FF3939" stroke-width="2" />
            <path
              fill="#FF3939"
              d="M15.03 22.902c0-.372.096-.678.288-.918.192-.252.54-.378 1.044-.378.492 0 .84.126 1.044.378.204.24.306.546.306.918s-.102.684-.306.936c-.192.24-.54.36-1.044.36-.492 0-.84-.126-1.044-.378-.192-.252-.288-.558-.288-.918zm.324-11.502h2.016l-.072 8.37h-1.89l-.054-8.37z"
            />
          </svg>
        </div>
        <div class="notify-popup__title">
          {{ $t("registration.title") }}
        </div>
        <div class="notify-popup__text" style="max-width: 360px;">
          <p>
            {{ $t("registration.error") }}
            <br />
            {{ $t("registration.repeat") }}
          </p>
        </div>
        <div class="notify-popup__buttons">
          <button class="button button_dark">
            {{ $t("register") }}
          </button>
        </div>
      </div>
    </notify-popup>
  </div>
</template>

<script>
import axios from "axios";
import InvisibleRecaptcha from "vue-invisible-recaptcha";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Registration",
  components: { InvisibleRecaptcha },
  props: {
    action: {
      type: String,
      default: ""
    },
    hideDadata: {
      type: Boolean,
      default: false
    },
    dadataApiKey: {
      type: String,
      default: ""
    },
    reCaptchaKey: {
      type: String,
      default: ""
    },
    reCaptchaUse: {
      type: String,
      default: "N"
    },
    haveAccountUrl: {
      type: String,
      default: ""
    },
    continueUrl: {
      type: String,
      default: ""
    },
    passwordDetail: {
      type: String,
      default: ""
    },
    loadingForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reCaptchaIsLoaded: false,
      form: {
        surname: "",
        name: "",
        middleName: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        whereFrom: "",
        agreement: false,
        agreementSite: false,
        reCaptchaToken: "",
        resend: false
      },
      errors: {
        emailExist: false,
        phoneInvalid: false
      },
      showAgain: true,
      dataLayer: false
    };
  },
  validations: {
    form: {
      surname: {
        required
      },
      name: {
        required
      },
      phone: {
        required,
        minLength: minLength(10)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        goodPassword(password) {
          let result = true;
          this.passwordDetail = "";

          // Проверка длины пароля
          if (password.length < 8) {
            this.passwordDetail += this.$i18n.t("requirement.length") + "<br>";
            result = false;
          }

          // Проверка на наличие одного заглавного символа
          if (!/[A-Z]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.upper") + "<br>";
            result = false;
          }

          // Проверка на наличие одного спецсимвола
          if (!/[\W_]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.symbol") + "<br>";
            result = false;
          }

          // Проверка на наличие одного числа
          if (!/[0-9]/.test(password)) {
            this.passwordDetail += this.$i18n.t("requirement.number") + "<br>";
            result = false;
          }

          return result;
        }
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
      whereFrom: {
        required
      },
      agreement: {
        required,
        sameAs: sameAs(() => true)
      },
      agreementSite: {
        required,
        sameAs: sameAs(() => true)
      }
    }
  },
  mounted() {
    this.dataLayer = window.dataLayer;
  },
  methods: {
    validate() {
      let flag = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        flag = true;
      }
      return flag;
    },
    reCaptchaHandler(token) {
      this.form.reCaptchaToken = token;
      this.onSubmit();
      this.reCaptchaLoaded();
    },
    reCaptchaLoaded() {
      this.reCaptchaIsLoaded = !this.reCaptchaIsLoaded;
    },
    async onSubmit(formType = "default") {
      if (this.validate()) {
        // Запрещаем повторную отправку формы в процессе загрузки
        if (this.loadingForm) {
          return false;
        }
        this.loadingForm = true;

        if (formType === "again") {
          this.showAgain = false;
        }

        const data = new FormData();
        for (let prop in this.form) {
          data.append(prop, this.form[prop]);
        }
        let options = {
          url: this.action,
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: data
        };
        await axios(options).then(response => {
          if (response.data) {
            switch (response.data.code) {
              case 200:
                this.$modal.show("successRegPopup");
                this.form.resend = true;
                if (this.dataLayer) {
                  this.dataLayer.push({
                    event: "ga_event",
                    event_category: "forms",
                    event_action: "sent",
                    event_label: "register"
                  });
                }
                break;
              case 300:
                this.errors.emailExist = true;
                break;
              case 400:
                this.errors.phoneInvalid = true;
                break;
              default:
                this.$modal.show("errorRegPopup");
            }
            this.reset();
            this.loadingForm = false;
          }
        });
      }
    },
    reset() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    modalClosed() {
      window.location.href = this.continueUrl;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "surname": "Surname",
    "name": "Name",
    "middle_name": "Middle name",
    "password": {
      "input": "Password",
      "text": "Should contain at least 8 characters, uppercase, lowercase Latin letters, non-alphabetic characters and numbers",
      "confirm": "Repeat password",
      "mismatch": "Password mismatch"
    },
    "requirement": {
      "length" : "Must contain at least 8 characters",
      "upper" : "Must contain at least one uppercase character",
      "symbol" : "Must contain at least one special character",
      "number" : "Must contain at least one digit"
    },
    "phone": {
      "input": "Phone"
    },
    "email_invalid": "Invalid e-mail",
    "email_exist": "User with this e-mail already exists",
    "location": "Where are you from?",
    "agreement_personal_data": {
      "left": "I agree to",
      "right": "personal data processing",
      "need": "You must agree to the terms",
      "link": "/upload/about/personal_data_processing_policy.pdf"
    },
    "agreement_site_data": {
      "left": "I am familiar with",
      "right": "user agreement",
      "need": "You must agree to the terms",
      "link": "/legal/obshchie-usloviy/polzovatelskoe-soglashenie-sayta/"
    },
    "register": "Register",
    "have_account": "Already have an account?",
    "email_instruction": "A letter containing further instructions has been sent to your email address",
    "continue": "Continue",
    "send": "Send",
    "replay": "letter again",
    "again_send": "Letter has been",
    "again_replay": "sent again!",
    "registration": {
      "title": "Registration",
      "error": "Something went wrong!",
      "repeat": "Re-register or contact support"
    }
  },
  "ru": {
    "surname": "Фамилия",
    "name": "Имя",
    "middle_name": "Отчество",
    "password": {
      "input": "Пароль",
      "text": "Должен содержать не менее 8 символов, заглавные, строчные латинские буквы, небуквенные символы и цифры",
      "confirm": "Повторить пароль",
      "mismatch": "Пароли не совпадают"
    },
    "requirement": {
      "length" : "Должен содержать не менее 8 символов",
      "upper" : "Должен содержать хотя бы один заглавный символ",
      "symbol" : "Должен содержать хотя бы один спецсимвол",
      "number" : "Должен содержать хотя бы одну цифру"
    },
    "phone": {
      "input": "Телефон"
    },
    "email_invalid": "Некорректный e-mail",
    "email_exist": "Пользователь с таким e-mail уже существует",
    "location": "Откуда вы?",
    "agreement_personal_data": {
      "left": "Я согласен(а) на",
      "right": "обработку персональных данных",
      "need": "Необходимо согласиться с условиями",
      "link": "/upload/about/politika-po-obrabotke-pers-dannih.pdf"
    },
    "agreement_site_data": {
      "left": "Я ознакомлен(а) с",
      "right": "пользовательским соглашением",
      "need": "Необходимо согласиться с условиями",
      "link": "/legal/obshchie-usloviy/polzovatelskoe-soglashenie-sayta/"
    },
    "register": "Зарегистрироваться",
    "have_account": "У вас уже есть аккаунт?",
    "email_instruction": "На ваш e-mail отправлено письмо с дальнейшими инструкциями",
    "continue": "Продолжить",
    "send": "Отправить",
    "replay": "письмо повторно",
    "again_send": "Письмо отправлено",
    "again_replay": "повторно!",
    "registration": {
      "title": "Регистрация",
      "error": "Что-то пошло не так!",
      "repeat": "Пройдите регистрацию повторно или обратитесь в службу поддержки"
    }
  }
}
</i18n>
