<template>
  <div>
    <form class="search-field search-field_medium">
      <div class="search-field__inner">
        <button class="search-field__button" type="submit">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              d="M17.78 16.722l-4.328-4.328A7.588 7.588 0 007.586 0a7.586 7.586 0 100 15.171 7.545 7.545 0 004.805-1.715l4.327 4.324a.75.75 0 101.062-1.058zm-10.194-3.06a6.084 6.084 0 01-6.08-6.076c0-3.35 2.726-6.08 6.08-6.08a6.09 6.09 0 016.08 6.08c0 3.35-2.73 6.076-6.08 6.076z"
              fill="#C4C7CC"
            />
          </svg>
        </button>
        <div class="search-field__input">
          <input v-model.trim="searchTerm" :placeholder="placeholderValue" />
        </div>
        <button
          v-if="searchTerm.trim().length > 0"
          class="search-field__clear"
          type="submit"
          @click.prevent="clearField()"
        >
          <svg
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM1.35355 11.3536L6.35355 6.35355L5.64645 5.64645L0.646447 10.6464L1.35355 11.3536ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355ZM5.64645 6.35355L10.6464 11.3536L11.3536 10.6464L6.35355 5.64645L5.64645 6.35355ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
              fill="#C4C4C4"
            />
          </svg>
        </button>
      </div>
    </form>

    <div class="glossary">
      <ul class="glossary__letters">
        <li v-for="(letter, key) in alphabetRu" :key="key">
          <a v-scroll-to="{ el: `#${letter}`, offset: -80 }" href="#">
            {{ letter }}
          </a>
        </li>
      </ul>

      <div class="glossary__list">
        <div
          v-for="(terms, letter) in termsByLetterSliced"
          :key="letter"
          class="glossary__line"
        >
          <div class="glossary__line__row">
            <div class="glossary__line__left">
              <div :id="letter" class="glossary__line__letter">
                {{ letter.toUpperCase() + letter.toLowerCase() }}
              </div>
              <div class="glossary__line__num-terms">
                {{ termsByLetter[letter].length }}
                {{
                  termsByLetter[letter].length
                    | plural("термин", "термина", "терминов")
                }}
              </div>
            </div>
            <div class="glossary__line__right">
              <ul class="glossary__terms-list">
                <li v-for="(term, key2) in terms" :key="key2">
                  <a :href="term.url">{{ term.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="letterSizes[letter] < termsByLetter[letter].length">
            <arrow-more-link
              value="Показать еще"
              @load-more="showMore(letter)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

export default {
  name: "GlossaryList",
  props: {
    data: { type: Array, default: () => [] },
    pageSize: { type: Number, default: 12 }
  },
  data() {
    return {
      searchTerm: "",
      letterSizes: {},
      termsByLetterSliced: {}
    };
  },
  computed: {
    filteredData() {
      return this.data.filter(item => {
        if (
          item.name.toUpperCase().indexOf(this.searchTerm.toUpperCase()) >= 0
        ) {
          return item;
        }
      });
    },
    termsByLetter() {
      const data = {};
      this.filteredData.map(term => {
        const letter = term.name.substr(0, 1).toUpperCase();
        if (!data[letter]) {
          data[letter] = [];
        }
        data[letter].push(term);
      });
      return data;
    },
    alphabetRu() {
      return Object.keys(this.termsByLetter);
    },
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return "Поиск";
      } else {
        return "Поиск термина по словарю-справочнику";
      }
    }
  },
  watch: {
    searchTerm(value) {
      if (value) {
        this.disablePagination();
      } else {
        this.resetPagination();
      }
      this.makeTermsByLetterSliced();
    }
  },
  mounted() {
    this.resetPagination();
    this.makeTermsByLetterSliced();
  },
  methods: {
    resetPagination() {
      for (let letter of Object.keys(this.termsByLetter)) {
        this.letterSizes[letter] = this.pageSize;
      }
    },
    disablePagination() {
      for (let letter of Object.keys(this.termsByLetter)) {
        this.letterSizes[letter] = 1000;
      }
    },
    makeTermsByLetterSliced() {
      const data = {};
      for (let [key, value] of Object.entries(this.termsByLetter)) {
        data[key] = value.slice(0, this.letterSizes[key]);
      }
      this.termsByLetterSliced = data;
    },
    showMore(letter) {
      this.letterSizes[letter] += this.pageSize;
      this.makeTermsByLetterSliced();
    }
  }
};
</script>
