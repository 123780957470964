<template>
  <div class="search-result">
    <div class="search-result__head">
      <div class="search-result__title">
        {{ $t("title") }}
      </div>
      <div class="search-result__found-text">
        {{ $t("found") }} <b>{{ paginator.NavRecordCount }}</b>
        {{ $t("matches") }}
        <b>«{{ query }}»</b>
      </div>
    </div>
    <div class="search-result__list">
      <div
        v-for="(item, key) in activeItems"
        :key="key"
        class="search-result-item"
      >
        <div class="search-result-item__title">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <a :href="item.URL" v-html="item.TITLE" />
        </div>
        <div class="search-result-item__text">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="item.BODY"></p>
        </div>
        <div class="search-result-item__date">{{ item.DATE_FROM }}</div>
      </div>
    </div>
    <arrow-more-link
      v-show="nextUrl"
      :value="$t('show_more')"
      @load-more="loadMore"
    ></arrow-more-link>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SearchResult",
  props: {
    initNextUrl: {
      type: String,
      default: ""
    },
    query: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    paginator: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      activeItems: [],
      nextUrl: ""
    };
  },
  mounted() {
    this.nextUrl = this.initNextUrl;
    this.activeItems = this.items;
  },
  methods: {
    async loadMore() {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.activeItems = [...this.activeItems, ...res.data.ITEMS];
    }
  }
};
</script>

<i18n>
{
  "en": {
    "title": "Searching results",
    "found": "Found",
    "matches": "matches for your request ",
    "show_more": "Show more"
  },
  "ru": {
    "title": "Результаты поиска",
    "found": "Найдено",
    "matches": "совпадений по вашему запросу",
    "show_more": "Показать еще"
  }
}
</i18n>
