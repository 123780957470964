<template>
  <div class="video-material">
    <template v-if="!isAuthorized">
      <a
        :href="authUrl"
        class="video-material__video"
        :style="`background-image:url(${poster});`"
      >
        <div class="video-material__video__content">
          <div class="video-material__video-disclaimer">
            Доступно только авторизованным пользователям
          </div>
          <div class="video-material__video-play">
            <svg fill="none" viewBox="0 0 72 72">
              <circle cx="36" cy="36" r="36" fill="#18B346" />
              <path
                fill="#fff"
                d="M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"
              />
            </svg>
          </div>
          <h3 class="video-material__video-title">{{ title }}</h3>
        </div>
      </a>
    </template>
    <template v-else>
      <div
        class="video-material__video"
        :style="`background-image:url(${poster});`"
      >
        <div class="video-material__video__content">
          <div v-if="video" class="video-material__video-play">
            <svg fill="none" viewBox="0 0 72 72">
              <circle cx="36" cy="36" r="36" fill="#18B346" />
              <path
                fill="#fff"
                d="M32.033 46.873c-1.951 1.177-3.533.213-3.533-2.15V27.278c0-2.366 1.582-3.328 3.533-2.153l14.503 8.744c1.952 1.177 1.952 3.083 0 4.26l-14.503 8.743z"
              />
            </svg>
          </div>
          <h3 class="video-material__video-title">{{ title }}</h3>
          <template v-if="video">
            <video-player
              :autoplay-if-visible="autoplayIfVisible"
              :src="video"
            ></video-player>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "VideoMaterial",
  props: {
    isAuthorized: {
      type: Boolean,
      default: false
    },
    arResult: {
      type: Object,
      default: () => {}
    },
    authUrl: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    autoplayIfVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      poster: ""
    };
  },
  computed: {
    video() {
      if (this.arResult.VIDEO_URL != undefined) {
        return this.arResult.VIDEO_URL.VALUE;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.poster = this.arResult.VIDEO_PICTURE.FILE_VALUE.SRC;
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".video-material";

#{$b} {
  &__video {
    display: block;
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: $color-bg-gray-2;
    color: $white-true;
    border-radius: 8px;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      padding-top: 70%;
    }

    @include md-desktop-only {
      min-height: 350px * $zoom;
      border-radius: 8px * $zoom;
    }

    &__content {
      @include full-abs;
      display: flex;
      flex-direction: column;
      padding: 40px;

      @include md-desktop-only {
        padding: 40px * $zoom;
      }

      @include low-desktop {
        padding: 30px;
      }

      @include mobile {
        padding: 16px;
      }
    }

    &-icon {
      position: absolute;
      top: 4px;
      left: 0;
      width: 12px;
      height: 14px;
      margin-right: 24px;

      @include md-desktop-only {
        top: 4px * $zoom;
        width: 12px * $zoom;
        height: 14px * $zoom;
        margin-right: 24px * $zoom;
      }
    }

    &-play {
      position: absolute;
      top: calc(50% - 8%);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
      cursor: pointer;

      @include md-desktop-only {
        width: 72px * $zoom;
        height: 72px * $zoom;
      }

      @include low-desktop {
        width: 60px;
        height: 60px;
      }

      @include mobile {
        width: 40px;
        height: 40px;
        top: auto;
        left: auto;
        transform: none;
        right: 16px;
        bottom: 16px;
      }
    }

    &-title {
      margin-top: auto;
      @include font(24px, 1.42, 600);

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @include md-desktop-only {
        font-size: 24px * $zoom;

        &:not(:last-child) {
          margin-bottom: 20px * $zoom;
        }
      }
      @include low-desktop {
        font-size: 20px;
      }
      @include mobile {
        font-size: 14px;
        margin-right: 50px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    &-text {
      @include font(16px, 1.25);

      @include md-desktop-only {
        font-size: 16px * $zoom;
      }
      @include mobile {
        @include font(12px, 2);
        margin-bottom: 10px;
      }
    }

    &-disclaimer {
      position: relative;
      @include font(16px, 1.25);
      margin-bottom: 20px;

      @include md-desktop-only {
        margin-bottom: 20px * $zoom;
        font-size: 16px * $zoom;
      }
      @include mobile {
        @include font(12px, 1.5);
        margin-bottom: 10px;
      }
    }

    &-duration {
      position: absolute;
      right: 37px;
      bottom: 40px;
      display: inline-block;
      font-size: 12px;
      line-height: 15px;
      padding: 8px 15px;
      background: $color-bg-dark;
      border-radius: 4px;

      @include md-desktop-only {
        right: 37px * $zoom;
        bottom: 40px * $zoom;
        font-size: 12px * $zoom;
        line-height: 15px * $zoom;
        padding: 8px * $zoom 15px * $zoom;
        border-radius: 4px * $zoom;
      }
      @include mobile {
        right: 20px;
        bottom: 17px;
      }
    }

    .video-player {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;

      .video-js {
        opacity: 0;

        &.vjs-has-started {
          opacity: 1;
        }

        &.vjs-waiting {
          opacity: 1;

          .vjs-poster {
            display: none;
          }
        }
      }
    }

    // Fluid width video (Youtube and etc iframe based)
    &-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
