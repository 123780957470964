<template>
  <form
    ref="form"
    class="search-field"
    :action="action"
    autocomplete="off"
    @submit.prevent="submit"
  >
    <div class="search-field__inner">
      <button class="search-field__button" type="submit">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path
            d="M17.78 16.722l-4.328-4.328A7.588 7.588 0 007.586 0a7.586 7.586 0 100 15.171 7.545 7.545 0 004.805-1.715l4.327 4.324a.75.75 0 101.062-1.058zm-10.194-3.06a6.084 6.084 0 01-6.08-6.076c0-3.35 2.726-6.08 6.08-6.08a6.09 6.09 0 016.08 6.08c0 3.35-2.73 6.076-6.08 6.076z"
            fill="#C4C7CC"
          />
        </svg>
      </button>
      <div class="search-field__input">
        <input
          v-model="currentValue"
          type="text"
          :name="name"
          :placeholder="placeholderValue"
          @input="$emit('input', $event.target.value)"
        />
      </div>
      <button
        v-if="currentValue.trim().length > 0"
        class="search-field__clear"
        type="submit"
        @click.prevent="clearField()"
      >
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM1.35355 11.3536L6.35355 6.35355L5.64645 5.64645L0.646447 10.6464L1.35355 11.3536ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355ZM5.64645 6.35355L10.6464 11.3536L11.3536 10.6464L6.35355 5.64645L5.64645 6.35355ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
            fill="#C4C4C4"
          />
        </svg>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "SearchField",
  props: {
    action: {
      type: String,
      default: "/search/"
    },
    name: {
      type: String,
      default: "q"
    },
    initValue: {
      type: String,
      default: ""
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    searchFor: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      currentValue: ""
    };
  },
  computed: {
    placeholderValue() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return this.$attrs.placeholdermobile;
      } else {
        return this.$attrs.placeholder;
      }
    }
  },
  mounted() {
    this.currentValue = this.initValue;
    this.$root.$on("clearField", this.clearField);
  },
  methods: {
    clearField() {
      this.currentValue = "";
      if (this.isStatic) {
        if (!this.$parent.clearField === undefined) {
          this.$parent.clearField();
        }
        this.$root.$emit("sendSearch", this.currentValue, this.searchFor);
      }
    },
    submit() {
      if (this.isStatic) {
        this.$root.$emit("sendSearch", this.currentValue, this.searchFor);
      } else {
        this.$refs.form.submit();
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/base/_include.scss";

$b: ".search-field";

#{$b} {
  &__inner {
    position: relative;
  }
  &__button {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    background: transparent;
    padding: 0;
    border: 0;
    color: #c4c7cc;
    transition: color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $black-true;
    }
    #{$b}_medium & {
      width: 38px;
    }
    svg {
      width: 18px;
      height: 18px;

      path {
        fill: currentColor;
      }
    }
    @include md-desktop-only {
      width: 48px * $zoom;
      height: 48px * $zoom;

      #{$b}_medium & {
        width: 38px * $zoom;
      }
      svg {
        width: 18px * $zoom;
        height: 18px * $zoom;
      }
    }
  }
  &__input {
    & > input {
      appearance: none;
      display: block;
      width: 100%;
      height: 48px;
      line-height: 48px;
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 0 48px;
      background: $color-bg-gray;
      font-weight: normal;
      font-size: 14px;
      color: $color-text-base;
      transition: 0.2s ease;
      transition-property: color, border-color, background;

      &:focus {
        border-color: $color-text-light-2;
      }
      #{$b}_light & {
        background-color: $white-true;
      }
      #{$b}_shadow & {
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
      }
      @include placeholder {
        color: $color-text-light-2;
      }
      #{$b}_medium & {
        padding-left: 38px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        background-color: $white-true;
      }
      @include md-desktop-only {
        height: 48px * $zoom;
        line-height: 48px * $zoom;
        border-radius: 8px * $zoom;
        padding: 0 48px * $zoom;
        font-size: 14px * $zoom;

        #{$b}_shadow & {
          box-shadow: 0px 2px * $zoom 16px * $zoom rgba(0, 0, 0, 0.1);
        }
        #{$b}_medium & {
          padding-left: 38px * $zoom;
          box-shadow: 0px 2px * $zoom 16px * $zoom rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &__clear {
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
    padding: 0;
    border: 0;
    color: #c4c4c4;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $black-true;
    }

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: currentColor;
      }
    }

    @include md-desktop-only {
      width: 48px * $zoom;
      height: 48px * $zoom;

      svg {
        width: 10px * $zoom;
        height: 10px * $zoom;
      }
    }
  }
}
</style>
