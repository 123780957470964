<template>
  <form class="form mb-10" @submit.prevent="submit">
    <div class="mb-8 _g-text-center">
      <p>
        {{ $t("choose_format") }}
      </p>
    </div>

    <tabs class="tabs_center" :indent="8" @change="changePresenseType">
      <tab
        v-if="intramural"
        :label="$t('intramural')"
        tab-name="offline"
        :selected="form.PRESENSE_TYPE === 'offline'"
      >
        <template v-if="!form.CAR_TAXI && !form.NEED_TRANSFER">
          <div class="form-row">
            <form-input
              id="car_model"
              v-model="form.CAR_MODEL"
              :label="$t('car_model') + ' *'"
              :error="$v.form.CAR_MODEL.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state: $v.form.CAR_MODEL.$error && !$v.form.CAR_MODEL.required
                }
              ]"
            />
          </div>
          <div class="form-row">
            <form-input
              id="car_number"
              v-model="form.CAR_NUMBER"
              :label="$t('car_number') + ' *'"
              :error="$v.form.CAR_NUMBER.$error"
              :messages="[
                {
                  type: 'error',
                  i18: 'error.required',
                  state:
                    $v.form.CAR_NUMBER.$error && !$v.form.CAR_NUMBER.required
                }
              ]"
            />
          </div>
        </template>
        <div class="form-row">
          <label class="form-agree" for="car_taxi">
            <input
              id="car_taxi"
              v-model="form.CAR_TAXI"
              class="form-agree__checkbox"
              type="checkbox"
            />
            <span class="form-agree__text">
              {{ $t("on_taxi") }}
            </span>
          </label>
        </div>
        <div class="form-row">
          <label class="form-agree" for="need_transfer">
            <input
              id="need_transfer"
              v-model="form.NEED_TRANSFER"
              class="form-agree__checkbox"
              type="checkbox"
            />
            <span class="form-agree__text">
              {{ $t("need_transfer") }}
            </span>
          </label>
        </div>
      </tab>
      <tab
        :label="$t('distantly')"
        tab-name="online"
        :selected="form.PRESENSE_TYPE === 'online'"
      >
        <p>
          {{ $t("distantly") }}
        </p>
      </tab>
    </tabs>

    <div class="mt-15 _g-text-center">
      <button class="button button_base" type="submit">
        <span>
          {{ $t("register_now") }}
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: "EventFormatForm",
  props: {
    editRegistration: { type: Boolean, default: true },
    intramural: { type: Boolean, default: false },
    action: { type: String, default: "" },
    lead: { type: Object, required: true }
  },
  data() {
    return {
      form: {
        PRESENSE_TYPE: this.intramural ? "offline" : "online",
        CAR_MODEL: "",
        CAR_NUMBER: "",
        CAR_TAXI: false,
        NEED_TRANSFER: false
      }
    };
  },
  validations: {
    form: {
      PRESENSE_TYPE: {
        required
      },
      CAR_MODEL: {
        required: requiredIf(function() {
          return (
            this.form.PRESENSE_TYPE === "offline" &&
            !this.form.CAR_TAXI &&
            !this.form.NEED_TRANSFER
          );
        })
      },
      CAR_NUMBER: {
        required: requiredIf(function() {
          return (
            this.form.PRESENSE_TYPE === "offline" &&
            !this.form.CAR_TAXI &&
            !this.form.NEED_TRANSFER
          );
        })
      }
    }
  },
  created() {
    this.form.CAR_TAXI = this.lead.CAR_TAXI;
    this.form.NEED_TRANSFER = this.lead.NEED_TRANSFER;
    this.form.CAR_MODEL = this.lead.CAR_MODEL;
    this.form.CAR_NUMBER = this.lead.CAR_NUMBER;
    this.form.PRESENSE_TYPE = this.lead.PRESENSE_TYPE;
  },
  methods: {
    changePresenseType(value, item) {
      this.form.PRESENSE_TYPE = item.tabName;
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("submit", this.form);
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "choose_format": "Please choose a participation format",
    "intramural": "In person",
    "car_model": "Car Make *",
    "car_number": "Vehicle Registration No *",
    "on_taxi": "Will arrive by taxi",
    "need_transfer": "Need transfer",
    "distantly": "Online",
    "register_now": "Register now"
  },
  "ru": {
    "choose_format": "Пожалуйста, выберите формат мероприятия",
    "intramural": "Очно",
    "car_model": "Модель автомобиля *",
    "car_number": "Гос. номер автомобиля *",
    "on_taxi": "Приеду на такси",
    "need_transfer": "Нужен трансфер",
    "distantly": "Дистанционно",
    "register_now": "Зарегистрироваться"
  }
}
</i18n>
